import React, { useCallback, useEffect, useState } from 'react';
import {
    Button,
    Composite,
    Group,
    Heading,
    Icon,
    Level,
    Size,
    Text,
    TextType,
    useTheme,
    Variant,
} from '@defa/defa-component-library';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import i18n from '../../../../i18n';
import { BillingMethod, ChargingTypeState, Group as GroupModel } from '../../../../models/group';
import { GroupTitleSection } from './private-settings-different-settings.styles';
import { PrivateSettingsProps } from '../../../../models/charge-system';
import { SetupPrivateSettings } from '../../../../fractions/setup-private-settings';
import {
    GET_CHARGE_SYSTEM,
    UPDATE_CHARGE_SYSTEM,
} from './private-settings-different-settings.queries';
import { useCurrency } from '../../../../utils/hooks';
import { Tariff } from '../../../../models';
import { DEFAULT_TARIFF } from '../../../../constants';
import { hasEnergyMeterInGroup } from '../../../../utils/energy-meter';

export function PrivateSettingsDifferentSettingsGroup({
    group,
    isExpanded,
    setIsExpanded,
    setHasNewData,
}: {
    group: GroupModel;
    isExpanded: boolean;
    setIsExpanded: (expandedId: string) => void;
    setHasNewData: (hasNewData: boolean) => void;
}) {
    const theme = useTheme();
    const { id: groupId, name, connectors, privateCharging: initialChargingTypeState } = group;
    const [chargingTypeState, setChargingTypeState] = useState<ChargingTypeState>(
        initialChargingTypeState
    );
    const [currency] = useCurrency();
    const { id } = useParams<{ id: string }>();

    const [updateChargeSystem] = useMutation(UPDATE_CHARGE_SYSTEM);

    const [tariff, setTariff] = useState<Tariff>(DEFAULT_TARIFF);
    const [hasChargingFee, setChargingFee] = useState<boolean>(false);

    const energyMeterAvailable = hasEnergyMeterInGroup(group);

    const tariffChanged = (newTariff: Tariff) => {
        setTariff(newTariff);
    };

    const chargingFeeChanged = (chargingFee: boolean) => {
        setChargingFee(!chargingFee);
    };

    const onSubmitGroup = useCallback(() => {
        updateChargeSystem({
            variables: {
                id,
                groupId,
                billingMethod: BillingMethod.CARD,
                privateCharging: ChargingTypeState.ENABLED,
                tariff: hasChargingFee ? tariff : DEFAULT_TARIFF,
            },
        }).then(() => {
            setChargingTypeState(ChargingTypeState.ENABLED);
            setHasNewData(true);
            setIsExpanded('');
        });
    }, [updateChargeSystem, setIsExpanded, setHasNewData, id, groupId, hasChargingFee, tariff]);

    const onEditClick = useCallback(() => {
        setIsExpanded(groupId);
    }, [setIsExpanded, groupId]);

    const onCancelClick = () => {
        setIsExpanded('');
    };

    const onSubmitNotPrivate = () => {
        updateChargeSystem({
            variables: { id, groupId, privateCharging: ChargingTypeState.DISABLED },
        }).then(() => {
            setChargingTypeState(ChargingTypeState.DISABLED);
            setHasNewData(true);
        });
    };

    const onSubmitUndo = () => {
        updateChargeSystem({
            variables: { id, groupId, privateCharging: ChargingTypeState.SETUP_REQUIRED },
        }).then(() => {
            setChargingTypeState(ChargingTypeState.SETUP_REQUIRED);
            setHasNewData(true);
        });
    };

    const feeOk = hasChargingFee
        ? (!!tariff.pricePerHour && parseFloat(tariff.pricePerHour) > 0) ||
          (!!tariff.pricePerKwh && parseFloat(tariff.pricePerKwh) > 0)
        : true;

    return (
        <Group key={id}>
            <Composite>
                <GroupTitleSection>
                    {group.name && (
                        <Heading
                            level={Level.h3}
                            color={
                                chargingTypeState === ChargingTypeState.DISABLED
                                    ? theme.disabledColor
                                    : theme.textColor
                            }
                        >
                            {name}
                        </Heading>
                    )}
                    <Text
                        type={TextType.description}
                        color={
                            chargingTypeState === ChargingTypeState.DISABLED
                                ? theme.disabledColor
                                : theme.inputLabelColor
                        }
                    >
                        {i18n.t('PrivateSettingsDifferentSettingsGroup.NumberOfChargersLabel', [
                            connectors.length,
                        ])}
                    </Text>
                </GroupTitleSection>
                {isExpanded ? (
                    <Button
                        name={`config-${encodeURIComponent(name)}`}
                        onClick={onCancelClick}
                        fillParent={false}
                        icon="chevronUp"
                        variant={Variant.TERTIARY}
                        size={Size.SMALL}
                    />
                ) : (
                    <>
                        {chargingTypeState === ChargingTypeState.ENABLED && (
                            <Composite gap={16}>
                                <Composite>
                                    <Icon icon="check" color={theme.successColor} size={10} />
                                    <Text
                                        type={TextType.descriptionMedium}
                                        color={theme.successColor}
                                    >
                                        {i18n.t('PrivateSettingsDifferentSettingsGroup.Done')}
                                    </Text>
                                </Composite>
                                <Button
                                    name={`config-${encodeURIComponent(name)}`}
                                    text={i18n.t(
                                        'PrivateSettingsDifferentSettingsGroup.EditButton'
                                    )}
                                    onClick={onEditClick}
                                    fillParent={false}
                                    iconAfter="chevron"
                                    variant={Variant.TERTIARY}
                                    iconUseMargin
                                />
                            </Composite>
                        )}
                        {chargingTypeState === ChargingTypeState.DISABLED && (
                            <Composite gap={16}>
                                <Composite>
                                    <Text
                                        type={TextType.descriptionMedium}
                                        color={theme.buttonTextColorDisabled}
                                    >
                                        {i18n.t('PrivateSettingsDifferentSettingsGroup.NotPrivate')}
                                    </Text>
                                </Composite>
                                <Button
                                    name={`config-${encodeURIComponent(name)}`}
                                    text={i18n.t('PrivateSettingsDifferentSettingsGroup.Undo')}
                                    onClick={onSubmitUndo}
                                    fillParent={false}
                                    variant={Variant.TERTIARY}
                                />
                            </Composite>
                        )}
                        {chargingTypeState === ChargingTypeState.SETUP_REQUIRED && (
                            <Composite>
                                <Button
                                    name={`config-${encodeURIComponent(name)}`}
                                    text={i18n.t(
                                        'PrivateSettingsDifferentSettingsGroup.SetupButton'
                                    )}
                                    onClick={() => setIsExpanded(groupId)}
                                    fillParent={false}
                                    icon="chevron"
                                    variant={Variant.PRIMARY}
                                    iconUseMargin
                                />
                                <Button
                                    name={`disable-${encodeURIComponent(name)}`}
                                    variant={Variant.SECONDARY}
                                    text={i18n.t(
                                        'PrivateSettingsDifferentSettingsGroup.NotPrivate'
                                    )}
                                    fillParent={false}
                                    onClick={onSubmitNotPrivate}
                                />
                            </Composite>
                        )}
                    </>
                )}
            </Composite>
            {isExpanded && (
                <Group>
                    <SetupPrivateSettings
                        tariff={tariff}
                        tariffChanged={tariffChanged}
                        chargingFeeChanged={chargingFeeChanged}
                        hasChargingFee={hasChargingFee}
                        currency={currency}
                        energyMeterAvailable={energyMeterAvailable}
                    />
                    <Composite justify="end">
                        <Button
                            name={`cancel-${encodeURIComponent(name)}`}
                            fillParent={false}
                            onClick={onCancelClick}
                            text={i18n.t('PrivateSettingsDifferentSettingsGroup.Cancel')}
                            variant={Variant.SECONDARY}
                        />
                        <Button
                            name={`submit-${encodeURIComponent(name)}`}
                            fillParent={false}
                            onClick={onSubmitGroup}
                            text={i18n.t('PrivateSettingsDifferentSettingsGroup.Done')}
                            variant={Variant.PRIMARY}
                            disabled={!feeOk}
                        />
                    </Composite>
                </Group>
            )}
        </Group>
    );
}

export function PrivateSettingsDifferentSettings({ match }: PrivateSettingsProps) {
    const { id: chargeSystemId } = useParams<{ id: string }>();
    const [expanded, setExpanded] = useState<string>('');
    const [hasNewData, setHasNewData] = useState<boolean>(false);
    const history = useHistory();

    const groupMatch = useRouteMatch<{ id?: string }>(`${match.path}/:groupId/*`);

    const { id: groupId } = groupMatch?.params || { id: undefined };

    const { refetch, data = { chargeSystem: {} } } = useQuery(GET_CHARGE_SYSTEM, {
        variables: { id: chargeSystemId, groupId },
        fetchPolicy: 'network-only',
    });

    const { chargeSystem = {} } = data;

    const { groups = [] } = chargeSystem || {};

    const onSubmit = useCallback(() => {
        history.replace(`/charge-system/${chargeSystemId}/setup/users/add`);
    }, [history, chargeSystemId]);

    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        if (hasNewData) {
            refetch();
            setHasNewData(false);
        }
    }, [hasNewData, refetch]);

    return (
        <Group width="640px">
            <Group>
                <Heading level={Level.h1}>
                    {i18n.t('PrivateSettingsDifferentSettings.Header')}
                </Heading>
            </Group>
            <Group>
                {groups.map((g: GroupModel) => (
                    <PrivateSettingsDifferentSettingsGroup
                        key={g.id}
                        group={g}
                        isExpanded={expanded === g.id}
                        setIsExpanded={setExpanded}
                        setHasNewData={setHasNewData}
                    />
                ))}
            </Group>
            <Group>
                <Button
                    name="submit-button"
                    text={i18n.t('PrivateSettingsDifferentSettings.Continue')}
                    onClick={onSubmit}
                    disabled={groups.some(
                        (g: GroupModel) => g.privateCharging === ChargingTypeState.SETUP_REQUIRED
                    )}
                />
            </Group>
        </Group>
    );
}
