import { gql } from '@apollo/client';

export const GET_CONNECTOR = gql`
    query GetConnector($id: ID!, $connectorAlias: ID!) {
        connector(chargeSystemId: $id, id: $connectorAlias) {
            alias
            displayName
            id
            status
            power
            type
            operationalData {
                ocpp {
                    chargingState
                    status
                    version
                }
                hbTimeout
                hbLastAlive
            }
        }
    }
`;

export const DO_CONNECTOR_ACTION = gql`
    mutation doConnectorAction($id: ID!, $action: String!) {
        connectorAction(input: { id: $id, action: $action }) {
            ok
        }
    }
`;
