import React from 'react';
import {
    CheckBox,
    Composite,
    Switch,
    TextInput,
    TextInputMode,
} from '@defa/defa-component-library';
import { PublicChargingTime } from '../../models/group';
import i18n from '../../i18n';

interface TimeInput {
    to?: string;
    from?: string;
    isAllDay?: boolean;
}

export function WeeklyTimeSection({
    days,
    daySettings,
    setDaySettings,
}: {
    days: string[];
    daySettings: PublicChargingTime[];
    setDaySettings: (daySettings: PublicChargingTime[]) => void;
}) {
    const updateTimeOnDay = (inputDay: string, inputTime: TimeInput) => {
        const daySettingsToUpdate = [...daySettings];
        const indexToUpdate = daySettings.findIndex(({ day }) => day === inputDay);
        const newDay = { ...daySettingsToUpdate[indexToUpdate], ...inputTime };
        daySettingsToUpdate[indexToUpdate] = newDay;
        setDaySettings(daySettingsToUpdate);
    };

    const updateDaySettings = (daySetting: PublicChargingTime) => {
        setDaySettings([...daySettings, daySetting]);
    };

    const deleteDayFromDaySettings = (dayToDelete: string) => {
        const arrayWithDeletedItem = [...daySettings];
        arrayWithDeletedItem.splice(
            daySettings.findIndex(({ day }) => day === dayToDelete),
            1
        );
        setDaySettings(arrayWithDeletedItem);
    };

    const onFromChanged = (day: string) => (value: string) => {
        updateTimeOnDay(day, { from: value });
    };

    const onToChanged = (day: string) => (value: string) => {
        updateTimeOnDay(day, { to: value });
    };

    const onAllDaySettingChanged = (day: string) => (checked: boolean) => {
        updateTimeOnDay(day, { isAllDay: checked });
    };

    const onDaySettingChanged = (day: string) => (active: boolean) => {
        if (active) {
            updateDaySettings({ day, from: '', to: '', isAllDay: true });
        } else {
            deleteDayFromDaySettings(day);
        }
    };

    const getDaySettingFromDay = (inputDay: string) => {
        const result = daySettings.find(({ day }) => day === inputDay);
        return result || { day: inputDay, from: '', to: '', isAllDay: false };
    };
    return (
        <>
            {days.map((day: string, index: number) => {
                // This should be moved to its own fraction
                const currentDaySetting = getDaySettingFromDay(day);
                const checked = daySettings.some((item) => item.day === day);
                return (
                    <Composite key={`composite-day-${day}`}>
                        <Switch
                            key={`day-${day}`}
                            name={`day-toggle-${day}`}
                            label={i18n.t(`DayNames.${index}`)}
                            checked={checked}
                            onChange={onDaySettingChanged(day)}
                        />
                        {checked && (
                            <>
                                {!currentDaySetting.isAllDay && (
                                    <>
                                        <TextInput
                                            key={`from-${day}`}
                                            label={i18n.t('RentalTimes.FromInputLabel')}
                                            name={`from-${day}`}
                                            mode={TextInputMode.PLAIN_TEXT}
                                            value={currentDaySetting?.from || ''}
                                            onBlur={onFromChanged(day)}
                                            size={5}
                                        />
                                        <TextInput
                                            key={`to-${day}`}
                                            label={i18n.t('RentalTimes.ToInputLabel')}
                                            name={`to-${day}`}
                                            value={currentDaySetting?.to || ''}
                                            mode={TextInputMode.PLAIN_TEXT}
                                            onBlur={onToChanged(day)}
                                            size={5}
                                        />
                                    </>
                                )}
                                <CheckBox
                                    fillContainer={false}
                                    key={`all-day-${day}`}
                                    name={`all-day-${day}`}
                                    checked={currentDaySetting.isAllDay}
                                    label={i18n.t('RentalTimes.AllDayCheckboxLabel')}
                                    onChange={onAllDaySettingChanged(day)}
                                />
                            </>
                        )}
                    </Composite>
                );
            })}
        </>
    );
}
