import React from 'react';
import { SideMenu, SideMenuItem, SideMenuItemProps, useTheme } from '@defa/defa-component-library';
import i18n from '../../i18n';

export enum Step {
    ABOUT_CHARGE_SYSTEM = 10,
    CHARGER_GROUPS = 20,
    PUBLIC_SETTINGS = 30,
    PRIVATE_SETTINGS = 40,
    USERS = 50,
    KEYS = 60,
}

function SetupMenuItem(props: SideMenuItemProps & { currentStep: Step; itemStep: Step }) {
    const theme = useTheme();
    const { itemStep, currentStep } = props;
    let iconColor = theme.textColor;
    let { icon } = props;
    if (itemStep === currentStep) {
        iconColor = theme.colorPrimary;
    } else if (itemStep < currentStep) {
        icon = 'checkOutline';
        iconColor = theme.successColor;
    }
    const disabled = currentStep < itemStep;

    return (
        <SideMenuItem
            {...props}
            padding={`${theme.spacing(2)} 0`}
            icon={icon}
            iconSize={24}
            iconColor={iconColor}
            disabled={disabled}
        />
    );
}

export function SetupMenu({ currentStep, id }: { currentStep: Step; id: string }) {
    const theme = useTheme();
    return (
        <SideMenu
            headerText={i18n.t('SetUpChargeSystem.FlowHeader')}
            padding={`${theme.spacing(1)} 0 ${theme.spacing(16)} 0`}
        >
            <SetupMenuItem
                currentStep={currentStep}
                itemStep={Step.ABOUT_CHARGE_SYSTEM}
                icon="information"
                text={i18n.t('AboutChargeSystem.Navigation')}
                href={`/charge-system/${id}/setup/about`}
            />
            <SetupMenuItem
                currentStep={currentStep}
                itemStep={Step.CHARGER_GROUPS}
                icon="grid"
                text={i18n.t('ChargerGroups.Navigation')}
                href={`/charge-system/${id}/setup/groups`}
            />
            <SetupMenuItem
                currentStep={currentStep}
                itemStep={Step.PUBLIC_SETTINGS}
                icon="chargingStation"
                text={i18n.t('PublicSettings.Navigation')}
                href={`/charge-system/${id}/setup/public`}
            />
            <SetupMenuItem
                currentStep={currentStep}
                itemStep={Step.PRIVATE_SETTINGS}
                icon="privateCharging"
                text={i18n.t('PrivateSettings.Navigation')}
                href={`/charge-system/${id}/setup/private`}
            />
            <SetupMenuItem
                currentStep={currentStep}
                itemStep={Step.USERS}
                icon="users"
                text={i18n.t('AddUsers.Navigation')}
                href={`/charge-system/${id}/setup/users/add`}
            />
            <SetupMenuItem
                currentStep={currentStep}
                itemStep={Step.KEYS}
                icon="keyCard"
                text={i18n.t('Keys.Navigation')}
                href={`/charge-system/${id}/setup/keys`}
            />
        </SideMenu>
    );
}
