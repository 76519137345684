import { Loading } from '@defa/defa-component-library';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const GOOGLE_MAP_SCRIPT_BASE_URL = 'https://maps.googleapis.com/maps/api/js';
export const isBrowser = typeof window !== 'undefined' && window.document;

export const loadGoogleMapScript = () => {
    const apiKey = process.env.REACT_APP_GOOGLE;
    const libraries = ['places'];
    const languageQueryParam = '';
    // const languageQueryParam = language ? `&language=${language}` : "";
    const googleMapsScriptUrl = `${GOOGLE_MAP_SCRIPT_BASE_URL}?key=${apiKey}&libraries=${libraries}${languageQueryParam}`;

    if (!isBrowser) return Promise.resolve();
    if (typeof google !== 'undefined') {
        if (google.maps) return Promise.resolve();
    }
    const scriptElements = document.querySelectorAll(
        `script[src*="${GOOGLE_MAP_SCRIPT_BASE_URL}"]`
    );
    if (scriptElements && scriptElements.length) {
        return new Promise<void>((resolve) => {
            // in case we already have a script on the page and it's loaded we resolve
            if (typeof google !== 'undefined') return resolve();
            // otherwise we wait until it's loaded and resolve
            scriptElements[0].addEventListener('load', () => resolve());
            return undefined;
        });
    }
    const el = document.createElement('script');
    el.src = googleMapsScriptUrl;
    document.body.appendChild(el);

    return new Promise<void>((resolve) => {
        el.addEventListener('load', () => resolve());
    });
};

interface WrapperProps {
    children?: React.ReactNode;
}
const Wrapper: React.FC<WrapperProps> = ({ children }) => {
    const [loading, setLoading] = useState(true);
    // load google
    useEffect(() => {
        loadGoogleMapScript().then(() => setLoading(false));
    }, []);
    // whhen loaded show children
    return loading ? <Loading /> : <>{children}</>;
};

interface MapProps extends google.maps.MapOptions {
    style: { [key: string]: string };
    onClick?: (e: google.maps.MapMouseEvent) => void;
    onIdle?: (map: google.maps.Map) => void;
    children?: React.ReactNode;
    latitude: number;
    longitude: number;
}

const GMap: React.FC<MapProps> = ({
    onClick,
    onIdle,
    children,
    style,
    latitude,
    longitude,
    ...options
}) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();

    useEffect(() => {
        if (ref.current && !map) {
            setMap(
                new window.google.maps.Map(ref.current, {
                    ...options,
                    center: { lat: latitude, lng: longitude },
                })
            );
        }
    }, [ref, map, latitude, longitude, options]);

    useEffect(() => {
        map?.panTo({ lat: latitude, lng: longitude });
    }, [map, latitude, longitude]);

    // because React does not do deep comparisons, a custom hook is used
    // see discussion in https://github.com/googlemaps/js-samples/issues/946
    // React.useEffect(() => {
    //     if (map) {
    //         map.setOptions(options);
    //     }
    // }, [map, options]);

    useEffect(() => {
        if (map) {
            ['click', 'idle'].forEach((eventName) =>
                google.maps.event.clearListeners(map, eventName)
            );

            if (onClick) {
                map.addListener('click', onClick);
            }

            if (onIdle) {
                map.addListener('idle', () => onIdle(map));
            }
        }
    }, [map, onClick, onIdle]);

    return (
        <>
            <div ref={ref} style={style} />
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    // set the map prop on the child component
                    return React.cloneElement(child, { map });
                }
                return <></>;
            })}
        </>
    );
};

interface MarkerProps extends google.maps.MarkerOptions {
    onClick?: (e: google.maps.MapMouseEvent) => void;
    onDblClick?: (e: google.maps.MapMouseEvent) => void;
    onDragEnd?: (e: google.maps.MapMouseEvent) => void;
    onChange: (latlng: google.maps.LatLng | null) => void;
}
const Marker: React.FC<MarkerProps> = ({
    onClick,
    onDblClick,
    onDragEnd,
    onChange,
    ...options
}) => {
    const [marker, setMarker] = useState<google.maps.Marker>();

    useEffect(() => {
        if (!marker) {
            setMarker(new google.maps.Marker());
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);

    useEffect(() => {
        if (marker) {
            marker.setOptions(options);
        }
    }, [marker, options]);

    useEffect(() => {
        if (marker) {
            ['click', 'dblclick', 'dragend'].forEach((eventName) =>
                google.maps.event.clearListeners(marker, eventName)
            );

            if (onClick) {
                marker.addListener('click', onClick);
            }

            if (onDblClick) {
                marker.addListener('dblclick', onDblClick);
            }

            if (onDragEnd) {
                marker.addListener('dragend', (e: google.maps.MapMouseEvent) => {
                    onDragEnd(e);
                    onChange(e.latLng);
                });
            }
        }
    }, [marker, onClick, onChange, onDblClick, onDragEnd]);

    return null;
};

interface MapInterface {
    latitude?: number;
    longitude?: number;
    onChange: ({ latitude, longitude }: { latitude: number; longitude: number }) => void;
}

export const Map: React.FC<MapInterface> = ({
    latitude = 57.7002324,
    longitude = 11.962902,
    onChange,
}) => {
    const [, setCenter] = useState<google.maps.LatLngLiteral>({
        lat: latitude,
        lng: longitude,
    });

    const onMarkerChange = (newValue: google.maps.LatLng | null) => {
        const returnValue = { latitude: newValue?.lat() || 0, longitude: newValue?.lng() || 0 };
        onChange(returnValue);
    };

    useEffect(() => {
        setCenter({ lat: latitude, lng: longitude });
    }, [latitude, longitude]);

    return (
        <div style={{ display: 'flex', height: '480px' }}>
            <Wrapper>
                <GMap
                    latitude={latitude}
                    longitude={longitude}
                    zoom={16}
                    style={{ flexGrow: '1', height: '100%' }}
                    clickableIcons={false}
                    fullscreenControl={false}
                    mapTypeControl={false}
                    streetViewControl={false}
                >
                    <Marker
                        key={uuidv4()}
                        position={{ lat: latitude, lng: longitude }}
                        draggable
                        clickable
                        onDragEnd={(e) => console.log('Drag end', e?.latLng?.toJSON())}
                        onClick={(e) => console.log('click', e)}
                        onDblClick={(e) => console.log('dblclick', e)}
                        onChange={onMarkerChange}
                    />
                </GMap>
            </Wrapper>
        </div>
    );
};

export const MemoizedMap = React.memo(Map);
