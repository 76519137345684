import i18n from '../i18n';
import { AddressDetails } from '../models';

export const getStreetAddress = (address?: string, streetNumber?: string) =>
    [address, streetNumber].filter(Boolean).join(' ');

export const getDisplayAddress = (addressDetails: AddressDetails | undefined) => {
    const { address, streetNumber, zipCode, city, country } = addressDetails ?? {};
    if (!address || !zipCode || !city || !country) {
        return i18n.t('NA');
    }
    return [getStreetAddress(address, streetNumber), zipCode, city, country].join(', ');
};
