import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import {
    Group,
    Heading,
    TextType,
    Level,
    Text,
    Composite,
    Switch,
} from '@defa/defa-component-library';
import i18n from '../../i18n';
import { Product, ProductPrice, Subscription as SubscriptionType } from '../../models/subscription';
import { Summery, Block } from './subscription.styles';
import { formatCurrencyWithLocale } from '../../utils/math';

const getSubscriptionPlanKey = (
    subscription: SubscriptionType | undefined,
    text: 'Header' | 'Description'
) =>
    subscription && subscription.products && subscription?.products[0]?.productName
        ? `Subscription.Product.${subscription?.products[0]?.productName}.${text}`
        : '';
const getSubscriptionBillingPeriodKey = (
    subscription: SubscriptionType | undefined,
    text: 'Header' | 'Description'
) =>
    subscription && subscription.products && subscription?.products[0]?.productName
        ? `Subscription.ProductPrice.${subscription?.products[0]?.productPrices[0]?.billingPeriod}.${text}`
        : '';

export function Subscription({
    subscription,
    connectorCount = 1,
    chargeSystemCount = 1,
}: {
    subscription: SubscriptionType | undefined;
    connectorCount: number;
    chargeSystemCount: number;
}) {
    const [showVAT, setShowVAT] = useState(false);

    const product: Product | undefined =
        (subscription?.products && subscription?.products[0]) || undefined;
    const productPrice: ProductPrice | undefined = product?.productPrices[0];

    const { price, priceInclVAT, billingPeriod } = productPrice || {
        price: 0,
        priceInclVAT: 0,
        billingPeriod: 0,
    };

    const outletMonthlyCost = price / billingPeriod;
    const outletMonthlyCostVAT = priceInclVAT / billingPeriod;

    const totalMonthlyCost = outletMonthlyCost * connectorCount;
    const totalMonthlyCostVAT = outletMonthlyCostVAT * connectorCount;

    const totalBillingPeriodCost = price * connectorCount;
    const totalBillingPeriodCostVAT = priceInclVAT * connectorCount;

    const onShowVATChange = (show: boolean) => {
        setShowVAT(show);
    };

    return (
        <>
            <Group>
                <Summery>
                    <Block>
                        <Text type={TextType.descriptionMedium}>{i18n.t('Subscription.Plan')}</Text>
                        <Heading level={Level.h2}>
                            {i18n.t(getSubscriptionPlanKey(subscription, 'Header'))}
                        </Heading>
                        <Text type={TextType.descriptionSmall}>
                            {i18n.t(getSubscriptionPlanKey(subscription, 'Description'))}
                        </Text>
                    </Block>
                    <Block>
                        <Text type={TextType.descriptionMedium}>
                            {i18n.t('Subscription.BillingPeriod')}
                        </Text>
                        <Heading level={Level.h2}>
                            {i18n.t(getSubscriptionBillingPeriodKey(subscription, 'Header'))}
                        </Heading>
                        <Text type={TextType.descriptionSmall}>
                            {i18n.t(getSubscriptionBillingPeriodKey(subscription, 'Description'))}
                        </Text>
                    </Block>
                </Summery>
            </Group>
            <Group>
                <Composite>
                    <Heading level={Level.h4}>{i18n.t('Subscription.Summary.Header')}</Heading>
                    <Switch
                        name="vat"
                        label={i18n.t('Subscription.Summary.VATToggle')}
                        onChange={onShowVATChange}
                    />
                </Composite>
                <ReactMarkdown>{i18n.t('Subscription.Summary.Description')}</ReactMarkdown>
                <Summery>
                    <Block>
                        <Text type={TextType.descriptionMedium}>
                            {i18n.t('Subscription.Summary.Outlets.Header')}
                        </Text>
                        <Heading level={Level.h1}>{connectorCount}</Heading>
                        <Text type={TextType.descriptionSmall}>
                            {i18n.t('Subscription.Summary.Outlets.Description', {
                                count: chargeSystemCount,
                            })}
                        </Text>
                    </Block>
                    <Block>
                        <Text type={TextType.descriptionMedium}>
                            {i18n.t('Subscription.Summary.Single.Header')}
                        </Text>
                        <Heading level={Level.h1}>
                            {i18n.t('Subscription.Summary.Single.Value', {
                                amount: formatCurrencyWithLocale(
                                    showVAT ? outletMonthlyCostVAT : outletMonthlyCost,
                                    navigator.language,
                                    productPrice?.currency ?? 'EUR'
                                ),
                            })}
                        </Heading>
                        <Text type={TextType.descriptionSmall}>
                            {i18n.t('Subscription.Summary.Single.Description')}
                        </Text>
                    </Block>
                    <Block>
                        <Text type={TextType.descriptionMedium}>
                            {i18n.t('Subscription.Summary.Monthly.Header')}
                        </Text>
                        <Heading level={Level.h1}>
                            {i18n.t('Subscription.Summary.Monthly.Value', {
                                amount: formatCurrencyWithLocale(
                                    showVAT ? totalMonthlyCostVAT : totalMonthlyCost,
                                    navigator.language,
                                    productPrice?.currency ?? 'EUR'
                                ),
                            })}
                        </Heading>
                        <Text type={TextType.descriptionSmall}>
                            {i18n.t(`Subscription.Summary.Monthly.Description.${billingPeriod}`, {
                                amount: formatCurrencyWithLocale(
                                    showVAT ? totalBillingPeriodCostVAT : totalBillingPeriodCost,
                                    navigator.language,
                                    productPrice?.currency ?? 'EUR'
                                ),
                            })}
                        </Text>
                    </Block>
                </Summery>
            </Group>
        </>
    );
}
