/*
1. Password is at least 10 characters long
2. Password contains at least 1 lowercase alphabetical character
3. Password contains at least 1 uppercase alphabetical character
4. Password contains at least 1 numeric character
5. Password contains at least one special character
*/

const passwordPolicyRegExp = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!#%&/()=?@$+-.])[\\S]{10,}$'
);

export const testPasswordPolicy = (password: string): boolean =>
    passwordPolicyRegExp.test(password);
