// Parse number with NumberFormat and Locale
export function parseLocaleFormattedNumber(localeFormatedNumber: string, locale: string): string {
    const thousandSeparator = Intl.NumberFormat(locale).formatToParts(11111)[1].value;
    const decimalSeparator = Intl.NumberFormat(locale).formatToParts(1.1)[1].value;
    const normalized = localeFormatedNumber
        .replaceAll(thousandSeparator, '')
        .replaceAll(decimalSeparator, '.')
        .replace(/\s/g, '');
    const normalizedNumber = parseFloat(normalized);
    return normalizedNumber.toString();
}
export function formatNumberToLocale(value: number, locale: string): string {
    const numberFormat = new Intl.NumberFormat(locale);
    return numberFormat.format(value).replace(/\s/g, ' ');
}

export function formatCurrencyWithLocale(value: number, locale: string, currency: string): string {
    const numberFormat = new Intl.NumberFormat(locale, { style: 'currency', currency });
    return numberFormat.format(value).replace(/\s/g, ' ');
}
