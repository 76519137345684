import { useMutation } from '@apollo/client';
import {
    Button,
    Heading,
    InformationType,
    Level,
    Modal,
    Table,
    TextInput,
} from '@defa/defa-component-library';
import React, { useState } from 'react';
import i18n from '../../../i18n';
import { Admin } from '../../../models/admin';
import { Root } from './add-administrators.styles';
import { useOrganization } from '../../../utils/hooks';
import { UPDATE_ORGANIZATION } from './add-administrators.queries';
import { validateEmail } from '../../../utils/email';

const EmailFieldInner = ({
    onBlur,
    email,
    index,
}: {
    onBlur: Function;
    email?: string;
    index: number;
}) => {
    const [emailValue, setEmailValue] = useState(email);

    const valid = emailValue ? validateEmail(emailValue) : true;
    const message = valid ? undefined : i18n.t('EmailInvalid');
    const informationType = valid ? undefined : InformationType.ERROR;

    return (
        <TextInput
            name={`email-${index}`}
            label={i18n.t('AddAdministrators.EmailLabel')}
            value={emailValue}
            onChange={(text) => {
                setEmailValue(text);
            }}
            message={message}
            informationType={informationType}
            onBlur={() => onBlur(index, emailValue)}
        />
    );
};

export function EmailField(updateAdminInviteByIndex: Function) {
    return (admin: Partial<Admin>, index: number) => {
        const { email, id } = admin;
        return (
            <EmailFieldInner
                onBlur={updateAdminInviteByIndex}
                email={email}
                index={index}
                key={`email-${id}`}
            />
        );
    };
}

function defaultAdmin() {
    return { email: '', groups: [], chargeSystems: [], id: Math.random() * 100000, connectors: [] };
}

export function AddAdministratorsModal({
    show,
    onClose,
    onSubmit,
}: {
    show: boolean;
    onClose: () => void;
    onSubmit: Function;
}) {
    const [organizationId] = useOrganization();
    const [invites, setInvites] = useState<Admin[]>([defaultAdmin()]);

    const [updateOrganization, { data: updatedAdminData = {}, loading }] = useMutation(
        UPDATE_ORGANIZATION
    );

    // TODO: Fix error/unhappy-paths handling
    const { status: updatedChargeSystem } = updatedAdminData;
    console.log(updatedChargeSystem);

    const invitesValid =
        invites.filter((i) => i.email && i.email !== '' && validateEmail(i.email)).length < 1;

    const updateAdminInviteByIndex = (index: number, email: string) => {
        setInvites((current) => {
            const updatedArray = [
                ...current.slice(0, index),
                { ...current[index], email },
                ...current.slice(index + 1),
            ];

            if (index === invites.length - 1) {
                updatedArray.push(defaultAdmin());
            }
            return updatedArray;
        });
    };

    const onSubmitPress = async () => {
        await updateOrganization({
            variables: {
                id: organizationId,
                invites: invites
                    .filter((i) => i.email && i.email !== '')
                    .map((a) => ({
                        email: a.email,
                        organizations: [
                            {
                                id: organizationId,
                            },
                        ],
                    })),
            },
        });
        setInvites([defaultAdmin()]);
        onSubmit();
    };

    const onClosePress = () => {
        onClose();
        setInvites([defaultAdmin()]);
    };

    return (
        <Modal
            titleContent={<Heading level={Level.h3}>{i18n.t('AddAdministrators.Header')}</Heading>}
            bodyContent={
                <Root>
                    <Table<Admin>
                        columnGap="small"
                        items={invites}
                        columnWidths={[undefined]}
                        columnNames={['']}
                        columns={[EmailField(updateAdminInviteByIndex)]}
                        noChildrenMargin
                    />
                </Root>
            }
            actionContent={
                <Button
                    name="add-administrators-button"
                    fillParent
                    disabled={invitesValid}
                    loading={loading}
                    text={i18n.t('AddAdministrators.AddButton')}
                    onClick={onSubmitPress}
                />
            }
            showModal={show}
            onClosePress={onClosePress}
            zIndex={5}
            width="545px"
        />
    );
}
