import React from 'react';
import { Group, SplitScreen } from '@defa/defa-component-library';
import { useParams, Switch, Route } from 'react-router-dom';
import { BrandPanel } from '../../../fractions/brand-panel';
import { SetupMenu, Step } from '../../../fractions/setup-menu';
import { GroupsInfo } from './groups-info';
import { GroupsProps } from '../../../models/charge-system';
import { ArrangeGroups } from './arrange-groups';
import i18n from '../../../i18n';

export function Groups({ match }: GroupsProps) {
    const { id: chargeSystemId } = useParams<{ id: string }>();
    return (
        <SplitScreen
            first={
                <Group>
                    <Group>
                        <BrandPanel
                            heading={i18n.t('BrandPanel.Header')}
                            subHeading={i18n.t('BrandPanel.SubHeader')}
                        />
                        <SetupMenu id={chargeSystemId} currentStep={Step.CHARGER_GROUPS} />
                    </Group>
                </Group>
            }
            secondSideLoading={false}
            second={
                <Switch>
                    <Route path={`${match.path}/arrange`} component={ArrangeGroups} />
                    <Route path={match.path} component={GroupsInfo} />
                </Switch>
            }
        />
    );
}
