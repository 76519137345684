import { styled } from '@defa/defa-component-library';

export const Root = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1px;
    width: 100%;
    box-sizing: border-box;
    & & {
        padding: 0 ${({ theme }) => theme.spacing(2)};
    }
`;

export const Term = styled.dt`
    padding: ${({ theme }) => theme.spacing(2)};
    flex: 0 1;
    font-weight: bold;
    min-width: 15ch;
    background-color: ${({ theme }) => theme.firstSideBackgroundColor};
    border-radius: ${({ theme }) => theme.spacing(1)} 0 0 ${({ theme }) => theme.spacing(1)};
`;

export const Definition = styled.dd`
    padding: ${({ theme }) => theme.spacing(2)};
    word-break: break-all;
    flex: 1 1;
    margin-inline-start: 0;
    background-color: ${({ theme }) => theme.firstSideBackgroundColor};
    border-radius: 0 ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(1)} 0;
`;
export const List = styled.dl`
    margin: 0;
    display: grid;
    grid-auto-flow: row;
    grid-auto-columns: max-content;
    grid-template-columns: max-content auto;
    grid-gap: 1px;

    & &:nth-child(odd) {
        background-color: ${({ theme }) => theme.modalBackgroundColor};
    }
`;
