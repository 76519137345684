import { styled } from '@defa/defa-component-library';

export const Root = styled.div``;

export const ResultsTable = styled.table`
    flex: 1;
`;

export const TextArea = styled.textarea`
    width: 100%;
    height: 60px;
    box-sizing: border-box;
`;
