import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { useHistory } from 'react-router-dom';

import { Loading } from '@defa/defa-component-library';
import { Root, Content } from './dashboard.styles';

import { ChargeSystemItem } from '../../fractions/charge-system-item/charge-system-item';
import { Header } from '../../fractions/header';
import { useOrganization } from '../../utils/hooks';
import { GET_ORGANIZATION } from './dashboard.queries';
import { UIState } from '../../models/ui';
import { ConfigurationStatus } from '../../models/organization';
import { ChargeSystem } from '../../models/charge-system';

interface OrganizationData {
    organization: {
        id: string;
        configurationStatus: ConfigurationStatus;
        chargeSystems: ChargeSystem[];
    };
}

export const Dashboard: React.FunctionComponent = () => {
    const [uiState, setUiState] = useState<UIState>(UIState.LOADING);
    const [getOrganization, { error, data }] = useLazyQuery<OrganizationData>(GET_ORGANIZATION);
    const [organizationId] = useOrganization();

    const { organization } = data ?? {};
    const { id: orgId, configurationStatus, chargeSystems } = organization ?? {};

    const history = useHistory();
    const setupClick = (id: string) => (e: MouseEvent) => {
        e.stopPropagation();
        history.push(`/charge-system/${id}/setup/about`);
    };
    const itemClick = (id: string) => () => history.push(`/charge-system/${id}`);

    useEffect(() => {
        if (organizationId === '') {
            history.push('/organizations');
        } else {
            setUiState(UIState.LOADING);
            getOrganization({
                variables: {
                    id: organizationId,
                },
            })
                .then(() => setUiState(UIState.READY))
                .catch(() => setUiState(UIState.ERROR));
        }
    }, [organizationId, history, getOrganization]);

    useEffect(() => {
        if (error) {
            setUiState(UIState.READY);
        }
    }, [error]);

    useEffect(() => {
        if (configurationStatus === ConfigurationStatus.SETUP_REQUIRED) {
            history.replace(`/onboarding/organization/${orgId ?? ''}`);
        } else if (chargeSystems?.length === 1) {
            history.replace(`/charge-system/${chargeSystems[0]?.id}`);
        }
    }, [configurationStatus, history, orgId, chargeSystems]);

    return (
        <Root>
            <Header />
            {uiState === UIState.LOADING && <Loading />}
            {uiState === UIState.ERROR && <p>ERROR...</p>}
            {uiState === UIState.READY && (
                <Content>
                    {organization?.chargeSystems?.map((chargeSystem: any) => (
                        <ChargeSystemItem
                            chargeSystem={chargeSystem}
                            setupClick={setupClick}
                            itemClick={itemClick}
                            key={chargeSystem.id}
                        />
                    ))}
                </Content>
            )}
        </Root>
    );
};
