import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import {
    Button,
    Group,
    Heading,
    Level,
    Size,
    Text,
    TopActionBar,
    Variant,
} from '@defa/defa-component-library';
import { Root } from './rearrange-groups.styles';
import {
    ADD_GROUP,
    CHARGE_SYSTEM,
    ChargeSystemResponseType,
    DELETE_GROUP,
    UPDATE_CHARGE_SYSTEM,
} from './rearrange-groups.queries';
import { ChargingTypeState, Group as GroupModel } from '../../models/group';
import { AddGroupFraction, ConnectorsSection } from '../setup';
import { Connector } from '../../models/connector';
import { NotificationContext } from '../../utils/notification';
import i18n from '../../i18n';
import { getDisplayAddress } from '../../utils/address';
import { CantMoveModal } from '../../fractions/cant-move-modal/cant-move-modal';
import { connectorHasEnergyMeter } from '../../utils/energy-meter';
import { groupHasKwhPrice } from '../../utils/group';

export function RearrangeGroups() {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { data, refetch } = useQuery<ChargeSystemResponseType>(CHARGE_SYSTEM, {
        variables: {
            id,
        },
    });

    const { chargeSystem } = data || {};

    const [groups, setGroups] = useState<any[]>([]);
    const [selectedConnectors, setSelectedConnectors] = useState<string[]>([]);
    const [addGroupModalVisible, setAddGroupModalVisible] = useState(false);

    const { add: addNotification } = useContext(NotificationContext);
    const [working, setWorking] = useState<boolean>(false);
    const [nameForGroupThatHasPricePerKwh, setNameForGroupThatHasPricePerKwh] = useState('');

    const [updateChargeSystem] = useMutation(UPDATE_CHARGE_SYSTEM);

    const [addGroup] = useMutation(ADD_GROUP);
    const [deleteGroup] = useMutation(DELETE_GROUP);

    const onDeleteGroup = async (groupId: string) => {
        setWorking(true);
        addNotification({
            id: 'DeleteGroup',
            message: i18n.t('RearrangeGroups.Notification.Delete'),
        });
        await deleteGroup({
            variables: {
                id: chargeSystem?.id,
                group: { id: groupId },
            },
        });
        await refetch();
        setWorking(false);
    };

    const { connectors = [] as Connector[], groups: initialGroups } = chargeSystem || {};

    const connectorsInGroups = groups.reduce(
        (acc, group) => [...acc, ...(group.connectors || [])],
        []
    );

    const ungroupedConnectors = [
        ...connectors.filter(
            (c: Connector) => !connectorsInGroups.some((cig: Connector) => cig.id === c.id)
        ),
    ];

    useEffect(() => {
        setGroups(initialGroups || []);
    }, [initialGroups]);

    const invalid = !groups || groups.length < 1;

    const onSelectedConnectorsChanged = (selected: string[]) => {
        setSelectedConnectors(selected);
    };

    const showAddGroupModal = () => {
        setAddGroupModalVisible(true);
    };

    const hideAddGroupModal = () => {
        setAddGroupModalVisible(false);
    };

    const submit = useCallback(() => {
        history.replace(`/charge-system/${id}`);
    }, [history, id]);

    const onGroupSelected = async (
        selectedGroup: GroupModel,
        currentGroupConnectors: string[] = [],
        isNew: boolean = false
    ) => {
        setWorking(true);
        const selectedConns =
            currentGroupConnectors.length > 0
                ? [...currentGroupConnectors]
                : [...selectedConnectors];

        if (!isNew) {
            // Ensure that the group does not has a public/private tariff with pricePerKwh set
            const hasAnyTariffSetToPricePerKwh = groupHasKwhPrice(selectedGroup);
            if (hasAnyTariffSetToPricePerKwh) {
                // if the selected has connectors with energyMeterAvailable NOT set to 'PRESENT'
                const theSelectedConnectors = connectors.filter((c) =>
                    selectedConnectors.includes(c.id)
                );
                // check if all connectors have energyMeterAvailable set to 'PRESENT'
                const allHasEnergyMeterAvailable = theSelectedConnectors.every((c) =>
                    connectorHasEnergyMeter(c)
                );
                if (!allHasEnergyMeterAvailable) {
                    setNameForGroupThatHasPricePerKwh(selectedGroup.name);
                    setSelectedConnectors([]);
                    setWorking(false);
                    return;
                }
            }
        }

        setSelectedConnectors([]);
        const groupsWithSelectedConnectorsRemoved = groups.map((g) => ({
            ...g,
            connectors: (g.connectors || []).filter(
                (c: Connector) => !selectedConns.some((sc) => sc === c.id)
            ),
        }));

        let newGroups: any[] = [];

        if (!selectedGroup) {
            newGroups = groupsWithSelectedConnectorsRemoved;
        } else if (isNew) {
            newGroups = [
                {
                    ...selectedGroup,
                    connectors: selectedConns.map((sc) =>
                        connectors.find((c: Connector) => c.id === sc)
                    ),
                },
                ...groupsWithSelectedConnectorsRemoved,
            ];
        } else {
            const groupIndex = groups.map((g) => g.id).indexOf(selectedGroup.id);

            const existingSelectedGroupData = groupsWithSelectedConnectorsRemoved[groupIndex];

            const group = {
                ...existingSelectedGroupData,
                connectors: [
                    ...(existingSelectedGroupData.connectors || []),
                    ...selectedConns.map((sc) => connectors.find((c: Connector) => c.id === sc)),
                ],
            };

            newGroups = [
                ...groupsWithSelectedConnectorsRemoved.slice(0, groupIndex),
                group,
                ...groupsWithSelectedConnectorsRemoved.slice(groupIndex + 1),
            ];
        }
        setGroups(newGroups);
        addNotification({
            id: 'AlterGroup',
            message: i18n.t('RearrangeGroups.Notification.Alter'),
        });
        console.log({ newGroups });
        await updateChargeSystem({
            variables: {
                id,
                groupsWithConnectors: newGroups.map((g) => ({
                    id: g.id,
                    connectors: g.connectors.map((c: Connector) => c.id),
                })),
            },
        });
        setWorking(false);
    };

    const onMoveGroup = async (fromGroupId: string, toGroupId: string) => {
        const fromGroup = groups.find((group) => group.id === fromGroupId) as GroupModel;
        const fromConnectors = fromGroup.connectors.map((c) => c.id);
        setSelectedConnectors(fromConnectors);
        const toGroup = groups.find((group) => group.id === toGroupId) as GroupModel;
        setWorking(true);
        await onGroupSelected(toGroup, fromConnectors);
        await onDeleteGroup(fromGroupId);
    };

    const onAddGroup = async (name: string) => {
        const newGroup = {
            name,
            publicCharging: ChargingTypeState.DISABLED,
            privateCharging: ChargingTypeState.DISABLED,
        };

        hideAddGroupModal();
        setWorking(true);
        addNotification({ id: 'AddGroup', message: i18n.t('RearrangeGroups.Notification.Add') });

        const result = await addGroup({
            variables: {
                id,
                group: newGroup,
            },
        });
        const { data: addGroupResultData } = result;
        const { chargeSystem: addGroupResultChargeSystemData } = addGroupResultData;
        const { addGroup: addedGroup } = addGroupResultChargeSystemData;

        onGroupSelected(addedGroup, [], true);
        hideAddGroupModal();
        setWorking(false);
    };

    return (
        <Root>
            <TopActionBar
                header={i18n.t('RearrangeGroups.Title')}
                description={i18n.t('RearrangeGroups.Description')}
            >
                <Button
                    name="done-button"
                    text={i18n.t('RearrangeGroups.Done')}
                    fillParent={false}
                    size={Size.TINY}
                    variant={Variant.TERTIARY}
                    onClick={submit}
                    loading={working}
                    disabled={working}
                />
            </TopActionBar>
            <Group verticalMargin="40px">
                <Group tight>
                    <Heading level={Level.h2} textAlign="center">
                        {chargeSystem?.name ?? chargeSystem?.id}
                    </Heading>
                    <Text alignment="center">
                        {getDisplayAddress(chargeSystem?.addressDetails)}
                    </Text>
                </Group>
                <ConnectorsSection
                    ungroupedConnectors={ungroupedConnectors}
                    onSelectedConnectorsChanged={onSelectedConnectorsChanged}
                    selectedConnectors={selectedConnectors}
                    groups={groups}
                    invalid={invalid}
                    moveGroup={onMoveGroup}
                    submit={submit}
                    deleteGroup={onDeleteGroup}
                    rearrange
                    working={working}
                />
            </Group>
            <AddGroupFraction
                selectedConnectors={selectedConnectors}
                groups={groups}
                onGroupSelected={onGroupSelected}
                showAddGroupModal={showAddGroupModal}
                addGroupModalVisible={addGroupModalVisible}
                hideAddGroupModal={hideAddGroupModal}
                onAddGroup={onAddGroup}
                working={working}
            />
            <CantMoveModal
                groupName={nameForGroupThatHasPricePerKwh}
                onClosePress={() => {
                    setNameForGroupThatHasPricePerKwh('');
                }}
            />
        </Root>
    );
}
