import React from 'react';
import {
    Button,
    Composite,
    Group,
    Heading,
    Icon,
    Level,
    Size,
    Variant,
} from '@defa/defa-component-library';
import { useHistory } from 'react-router-dom';

import ReactMarkdown from 'react-markdown';
import i18n from '../../../i18n';
import { GroupsProps } from '../../../models/charge-system';
import { Description } from './groups.styles';

export function GroupsInfo({ match }: GroupsProps) {
    const history = useHistory();

    const next = () => {
        history.push(`${match.url}/arrange`);
    };
    return (
        <Group maxWidth="640px" flex align="center" justify="center">
            <Icon icon="grid" background="#F2F2F4" backgroundSize={128} color="#4775E9" size={60} />
            <Group>
                <Heading level={Level.h1} textAlign="center">
                    {i18n.t('GroupsInfo.Header')}
                </Heading>
                <Description>
                    <ReactMarkdown>{i18n.t('GroupsInfo.Description')}</ReactMarkdown>
                </Description>
            </Group>
            <Composite>
                <Button
                    name="setup-button"
                    size={Size.LARGE}
                    variant={Variant.PRIMARY}
                    onClick={next}
                >
                    {i18n.t('GroupsInfo.Button')}
                </Button>
            </Composite>
        </Group>
    );
}
