import { Button, Heading, Level, Modal } from '@defa/defa-component-library';
import React, { useState } from 'react';
import { ImportedRfid } from '.';
import { ImportMultipleKeys } from '../../../fractions/import-multiple-keys';
import i18n from '../../../i18n';
import { NestedArray } from '../../../models/general';
import { Root } from './keys.styles';

interface AddMultipleKeysModalProps {
    show: boolean;
    onClose: () => void;
    onSubmit: Function;
}

export function AddMultipleKeysModal({ show, onClose, onSubmit }: AddMultipleKeysModalProps) {
    const [multipleRfids, setMultipleRfids] = useState<ImportedRfid[]>([]);
    const [localData, setLocalData] = useState<NestedArray<string>>([]);

    const onSubmitPress = () => {
        onSubmit(multipleRfids);
        setLocalData([]);
        setMultipleRfids([]);
    };

    const onClosePress = () => {
        onClose();
        setLocalData([]);
        setMultipleRfids([]);
    };

    return (
        <Modal
            titleContent={<Heading level={Level.h3}>{i18n.t('AddMultipleKeys.Header')}</Heading>}
            bodyContent={
                <Root>
                    <ImportMultipleKeys
                        localData={localData}
                        setLocalData={setLocalData}
                        setMultipleRfids={setMultipleRfids}
                    />
                </Root>
            }
            showModal={show}
            actionContent={
                <Button
                    fillParent
                    text={i18n.t('AddMultipleKeys.AddButton')}
                    disabled={multipleRfids.length < 1}
                    onClick={onSubmitPress}
                />
            }
            onClosePress={onClosePress}
            zIndex={5}
            width="545px"
        />
    );
}
