import { NestedArray } from '../models/general';

export const generateCsv = (rows: NestedArray<string>, fileName: string) => {
    const universalBOM = '\uFEFF';
    const csvContent = `${rows.map((e) => e.join(';')).join('\n')}`;
    const url = `data:text/csv; charset=utf-16,${universalBOM}${encodeURI(csvContent)}`;
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
};
