import { gql } from '@apollo/client';
import { Admin } from '../../../models';

export interface AdminResponseType {
    admin: Pick<Admin, 'id' | 'email' | 'firstName' | 'lastName' | 'phoneNumber'>;
}

export const ADMIN = gql`
    query {
        admin {
            id
            email
            firstName
            lastName
            phoneNumber
        }
    }
`;

export const UPDATE_ADMIN = gql`
    mutation updateAdmin(
        $firstName: String!
        $lastName: String!
        $phoneNumber: String!
        $email: String!
        $acceptedPrivacyConditions: Boolean!
    ) {
        admin {
            update(
                input: {
                    firstName: $firstName
                    lastName: $lastName
                    phoneNumber: $phoneNumber
                    email: $email
                    acceptedPrivacyConditions: $acceptedPrivacyConditions
                }
            ) {
                id
                firstName
                lastName
                phoneNumber
                acceptedPrivacyConditions
            }
        }
    }
`;
