import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    SplitScreen,
    Group,
    Heading,
    Level,
    Button,
    Size,
    Icon,
    useTheme,
    Composite,
    Variant,
    Modal,
    TextInput,
} from '@defa/defa-component-library';
import ReactMarkdown from 'react-markdown';
import { useMutation, useQuery } from '@apollo/client';
import { BrandPanel } from '../../../fractions/brand-panel';
import i18n from '../../../i18n';
import { OnboardingMenu, Step } from '../../../fractions/onboarding-menu';
import { Description } from './done.styles';
import { validateEmail } from '../../../utils/email';
import {
    CHARGE_SYSTEM_CONFIGURATION_STATUS,
    ConfigurationStatusResponseData,
    INVITE_ADMINS,
    InviteAdminsResponseData,
    UPSERT_ORGANIZATION,
    UpsertOrganizationResponseData,
} from './done.queries';
import { ConfigurationStatus } from '../../../models';
import { useOrganization } from '../../../utils/hooks';

export function Done() {
    const theme = useTheme();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [organizationId] = useOrganization();

    const [adminToInvite, setAdminToInvite] = useState('');
    const [showInvite, setShowInvite] = useState(false);
    const [otherAdminInvited, setOtherAdminInvited] = useState(false);

    const { loading, data: organizationsData } = useQuery<ConfigurationStatusResponseData>(
        CHARGE_SYSTEM_CONFIGURATION_STATUS,
        {
            variables: {
                id,
            },
        }
    );

    const { chargeSystems } = organizationsData?.organization ?? {};
    const chargeSystemToConfigure =
        chargeSystems?.some((cs) => cs.configurationStatus !== ConfigurationStatus.COMPLETED) ??
        false;

    const [upsertOrganization, { loading: loadingUpsertOrganization }] = useMutation<
        UpsertOrganizationResponseData
    >(UPSERT_ORGANIZATION);
    const [inviteAdmins, { loading: loadingInviteAdmins }] = useMutation<InviteAdminsResponseData>(
        INVITE_ADMINS
    );

    const invitesValid = validateEmail(adminToInvite);

    const completeOnboarding = async () => {
        const response = await upsertOrganization({
            variables: {
                id: organizationId,
                configurationStatus: ConfigurationStatus.COMPLETED,
            },
        });

        if (otherAdminInvited || !chargeSystemToConfigure) {
            history.push('/');
        } else {
            const chargeSystemId = response.data?.upsertOrganization?.chargeSystems[0].id;
            history.push(`/charge-system/${chargeSystemId}/setup/about`);
        }
    };

    const inviteOtherAdmin = useCallback(async () => {
        const response = await inviteAdmins({
            variables: {
                id: organizationId,
                invites: [
                    {
                        email: adminToInvite,
                        organizations: [
                            {
                                id: organizationId,
                            },
                        ],
                    },
                ],
            },
        });

        const inviteAdminsResponse = response.data?.organization.inviteAdmins;
        if (inviteAdminsResponse?.status) {
            setShowInvite(false);
            setOtherAdminInvited(true);
        }
    }, [adminToInvite, inviteAdmins, organizationId]);

    return (
        <SplitScreen
            first={
                <Group>
                    <Group>
                        <BrandPanel
                            heading={i18n.t('BrandPanel.Header')}
                            subHeading={i18n.t('BrandPanel.SubHeader')}
                        />
                        <OnboardingMenu id={id} currentStep={Step.DONE} />
                    </Group>
                </Group>
            }
            secondLoading={loading}
            second={
                <>
                    <Group flex align="center" justify="center" minWidth="480px">
                        <Icon icon="checkOutline" color={theme.successColor} size={60} />
                        <Group>
                            <Heading level={Level.h1} textAlign="center">
                                {i18n.t(
                                    otherAdminInvited
                                        ? 'OnboardingComplete.HeaderAdminInvited'
                                        : 'OnboardingComplete.Header',
                                    { invited: adminToInvite }
                                )}
                            </Heading>
                            {!otherAdminInvited && (
                                <Description>
                                    <ReactMarkdown>
                                        {i18n.t('OnboardingComplete.Description')}
                                    </ReactMarkdown>
                                </Description>
                            )}
                        </Group>
                        {chargeSystemToConfigure ? (
                            <Composite>
                                {!otherAdminInvited && (
                                    <Button
                                        variant={Variant.SECONDARY}
                                        size={Size.LARGE}
                                        fillParent={false}
                                        onClick={() => setShowInvite(true)}
                                    >
                                        {i18n.t('OnboardingComplete.ButtonInvite')}
                                    </Button>
                                )}
                                <Button
                                    size={Size.LARGE}
                                    onClick={completeOnboarding}
                                    loading={loadingUpsertOrganization}
                                >
                                    {i18n.t(
                                        otherAdminInvited
                                            ? 'OnboardingComplete.ButtonOk'
                                            : 'OnboardingComplete.ButtonContinue'
                                    )}
                                </Button>
                            </Composite>
                        ) : (
                            <Button
                                size={Size.LARGE}
                                onClick={completeOnboarding}
                                loading={loadingUpsertOrganization}
                            >
                                {i18n.t('Continue')}
                            </Button>
                        )}
                    </Group>
                    <Modal
                        titleContent={
                            <Heading level={Level.h3}>
                                {i18n.t('OnboardingComplete.HeaderInviteAdministrator')}
                            </Heading>
                        }
                        bodyContent={
                            <TextInput
                                value={adminToInvite}
                                label={i18n.t('OnboardingComplete.EmailTitle')}
                                name="email"
                                onChange={(val) => setAdminToInvite(val)}
                            />
                        }
                        actionContent={
                            <Button
                                name="add-administrators-button"
                                fillParent
                                disabled={!invitesValid}
                                loading={loadingInviteAdmins}
                                text={i18n.t('OnboardingComplete.ButtonSendInvite')}
                                onClick={inviteOtherAdmin}
                            />
                        }
                        showModal={showInvite}
                        onClosePress={() => setShowInvite(false)}
                        zIndex={10}
                        width="300px"
                    />
                </>
            }
        />
    );
}
