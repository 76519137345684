import React, { useState, useEffect } from 'react';
import {
    Button,
    Composite,
    formatRFIDKey,
    Group,
    Heading,
    InformationType,
    Level,
    Size,
    SplitScreen,
    Table,
    Text,
    TextInput,
    TextType,
    Variant,
} from '@defa/defa-component-library';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { BrandPanel } from '../../../fractions/brand-panel';
import { SetupMenu, Step } from '../../../fractions/setup-menu';
import {
    AddKeysResponseData,
    ConnectorAccess,
    GroupAccess,
    Rfid,
    RfidInputData,
} from '../../../models/user';
import i18n from '../../../i18n';
import { AccessDropdown } from '../../../fractions/access-dropdown';
import { ChargerAccessContextWindow } from '../../../fractions/charger-access-context-window';
import { ADD_KEYS, GET_CHARGE_SYSTEM } from './keys.queries';
import { getSortedPrivateGroupsAndConnectors } from '../../../utils/sorted-private-groups-and-connectors';
import { ChargeSystemGroupsAndConnectors } from '../../../models/charge-system';
import { Group as GroupModel } from '../../../models/group';
import { AddMultipleKeysModal } from './add-multiple-keys-modal';
import { KeyList } from '../../../fractions/key-list';
import { Error } from './keys.styles';
import { cleanRfidKey, keyExist } from '../../../utils/rfid';

export interface ImportedRfid {
    name: string;
    key: string;
}

interface ChargeSystemData {
    chargeSystem: {
        id: string;
        groups: GroupModel[];
        rfidKeys: Rfid[];
    };
}

function RfidInputField({
    rfid,
    index,
    setRfidKeyId,
}: {
    rfid: RfidInputData;
    index: number;
    setRfidKeyId: (id: string, index: number) => void;
}) {
    const [hexValue, setHexValue] = React.useState(rfid.keys[0]);
    const { id, errorMessage } = rfid;

    const handleChange = (inputValue: string) => {
        const formattedValue = formatRFIDKey(cleanRfidKey(inputValue)) ?? '';
        setHexValue(formattedValue);
        setRfidKeyId(formattedValue, index);
    };

    return (
        <TextInput
            name={`rfid-key-id-${id}`}
            value={hexValue}
            slim
            label={i18n.t('Keys.RfidLabel')}
            onChange={handleChange}
            informationType={errorMessage ? InformationType.ERROR : undefined}
            message={errorMessage ? i18n.t(`AddMoreKeys.${errorMessage}`) : undefined}
        />
    );
}

export function KeyIdWrapper(setRfidKeyId: (id: string, index: number) => void) {
    return (rfid: RfidInputData, index: number) => (
        <RfidInputField
            key={`rfid-key-id-${index}`}
            rfid={rfid}
            index={index}
            setRfidKeyId={setRfidKeyId}
        />
    );
}

export function DescriptionWrapper(setRfidDescription: (id: string, index: number) => void) {
    return (rfid: Rfid, index: number) => {
        const { id, name } = rfid;
        return (
            <TextInput
                key={`rfid-description-${id}`}
                name={`rfid-description-${id}`}
                value={name}
                slim
                label={i18n.t('Keys.DescriptionLabel')}
                onChange={(text) => setRfidDescription(text, index)}
            />
        );
    };
}

export function emptyRfid(id?: string) {
    return { id: id || '0', keys: [''], name: '', groups: [], connectors: [] };
}

export function Keys() {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const { data, refetch } = useQuery<ChargeSystemData>(GET_CHARGE_SYSTEM, {
        variables: { id },
    });
    const [addKeys] = useMutation<AddKeysResponseData>(ADD_KEYS);

    const { chargeSystem } = data || {};
    const { rfidKeys } = chargeSystem || { rfidKeys: [] };
    const [rfids, setRfids] = useState<RfidInputData[]>([]);
    const [initialRfids, setInitialRfids] = useState<Rfid[]>([]);
    const [showAddKeysModal, setShowAddKeysModal] = useState<boolean>(false);
    const [ok, setOk] = useState<boolean>(true);

    const [menuPositioningElement, setMenuPositioningElement] = useState<HTMLElement | undefined>(
        undefined
    );

    const [selectedRfid, setSelectedRfid] = useState<Rfid>();

    const [error, setError] = useState<string>();

    const defaultRfids = React.useMemo(() => [emptyRfid()], []);

    const [privateDataset, setPrivateDataset] = useState<ChargeSystemGroupsAndConnectors>({
        groups: [],
        connectors: [],
    });

    const onDropdownClick = (e: MouseEvent, rfid: Rfid) => {
        const { target } = e;
        setSelectedRfid(rfid);
        setMenuPositioningElement(target as HTMLElement);
    };

    const onLastRowBlur = () => {
        const [lastRfid] = rfids.slice(-1);
        if (lastRfid && lastRfid.name !== '') {
            setRfids((current) => [...current, emptyRfid(`${rfids.length + 1}`)]);
        }
    };

    function setRfidKeyId(rfidKeyId: string, index: number) {
        setRfids((current) => {
            const cleanRfidKeyId = cleanRfidKey(rfidKeyId);
            const duplicateKey = keyExist([...current, ...rfidKeys], cleanRfidKeyId, index);

            return [
                ...current.slice(0, index),
                {
                    ...current[index],
                    keys: [cleanRfidKeyId],
                    errorMessage: duplicateKey ? 'DuplicateKeyID' : '',
                },
                ...current.slice(index + 1),
            ];
        });
    }

    function setRfidName(name: string, index: number) {
        setRfids((current) => [
            ...current.slice(0, index),
            { ...current[index], name },
            ...current.slice(index + 1),
        ]);
    }

    function setRfidNameAndKeyId(name: string, rfidKeyId: string, index: number) {
        setRfids((current) => [
            ...current.slice(0, index),
            {
                ...current[index],
                name,
                keys: [rfidKeyId],
                id: `${rfidKeyId}-${index}`,
                groups: [],
                connectors: [],
            },
            ...current.slice(index + 1),
        ]);
    }

    const setRfidErrorMessage = (rfidKey: string, errorMessage: string) => {
        const rfidIndex = rfids.findIndex((rfid) => rfid.keys.find((key) => key === rfidKey));
        const rfidInputFound = rfidIndex > -1;

        if (rfidInputFound) {
            setRfids((current) => [
                ...current.slice(0, rfidIndex),
                { ...current[rfidIndex], errorMessage },
                ...current.slice(rfidIndex + 1),
            ]);
        }
    };

    const onChargerAccessSubmit = (groups: GroupAccess[], connectors: ConnectorAccess[]) => {
        setRfids((current: Rfid[]) => {
            const rfidIndex = current.findIndex((r) => r.id === selectedRfid?.id);

            if (!selectedRfid || rfidIndex === -1) {
                return current;
            }

            return [
                ...current.slice(0, rfidIndex),
                { ...selectedRfid, groups, connectors },
                ...current.slice(rfidIndex + 1),
            ];
        });

        setMenuPositioningElement(undefined);
        setSelectedRfid(undefined);
        onLastRowBlur();
    };

    const removeEmptyRows = (rfidArray: Rfid[]) =>
        rfidArray.filter(
            (r) =>
                r.name.length > 0 &&
                r.keys.filter((key: string) => key.length > 0).length > 0 &&
                (r.connectors.length > 0 || r.groups.length > 0)
        );

    const onSubmitModal = (multipleKeys: ImportedRfid[]) => {
        const numberOfItems = rfids.length;
        setRfids((current) => removeEmptyRows(current));
        multipleKeys.map((rfid, index) =>
            setRfidNameAndKeyId(rfid.name, rfid.key, numberOfItems + index)
        );
    };

    const onSubmit = async () => {
        const continueSetup = () => history.push(`/charge-system/${id}/setup/complete`);

        const filteredRfids = removeEmptyRows(rfids);
        const keysInputData = filteredRfids.map((r: Rfid) => ({
            keys: r.keys,
            name: r.name,
            connectors: r.connectors.map((c: ConnectorAccess) => ({
                id: c.data.id,
                accessLevel: c.accessLevel,
            })),
            groups: r.groups.map((g: GroupAccess) => ({
                id: g.data.id,
                accessLevel: g.accessLevel,
            })),
        }));

        if (keysInputData.length > 0) {
            const response = await addKeys({
                variables: {
                    id,
                    rfidKeys: keysInputData,
                },
            });

            const addKeysResponse = response.data?.chargeSystem.addRfidKeys;
            if (addKeysResponse?.status === 200) {
                continueSetup();
            } else if (addKeysResponse?.status === 406) {
                setError(undefined);
                addKeysResponse?.body?.forEach((body) =>
                    body.data.forEach((item) => setRfidErrorMessage(item, body.message))
                );
            } else {
                setError(i18n.t('Keys.Error'));
            }
        } else {
            continueSetup();
        }
    };

    useEffect(() => {
        if (defaultRfids.length > 0) {
            setRfids(defaultRfids.map((r: Rfid) => ({ ...r })));
        }
    }, [defaultRfids]);

    useEffect(() => {
        const { groups } = chargeSystem || {};
        if (groups) {
            setPrivateDataset(getSortedPrivateGroupsAndConnectors(groups));
        }
    }, [chargeSystem]);

    useEffect(() => {
        if (rfidKeys?.length > 0) {
            setInitialRfids(rfidKeys);
        }
    }, [rfidKeys]);

    useEffect(() => {
        const errorRows = rfids.filter((r) => {
            const hasErrorMessage = r.errorMessage && r.errorMessage !== '';
            const hasIdNumber = r.keys.find((key) => key.length > 0);
            const hasNoName = r.name.length === 0;
            const hasNoConnectorAccess = r.connectors.length === 0;
            const hasNoGroupAccess = r.groups.length === 0;
            const hasNoAccess = hasNoConnectorAccess && hasNoGroupAccess;

            return hasIdNumber ? hasErrorMessage || hasNoAccess || hasNoName : false;
        });
        setOk(rfids.length > 0 && errorRows.length < 1);
    }, [rfids, setOk]);

    return (
        <SplitScreen
            first={
                <Group>
                    <Group>
                        <BrandPanel
                            heading={i18n.t('Keys.FlowHeader')}
                            subHeading={i18n.t('BrandPanel.SubHeader')}
                        />
                        <SetupMenu id={id} currentStep={Step.KEYS} />
                    </Group>
                </Group>
            }
            second={
                <Group maxWidth="640px">
                    <Group>
                        <Composite>
                            <Heading level={Level.h1}>{i18n.t('Keys.Header')}</Heading>
                            <Button
                                text={i18n.t('Keys.ImportButton')}
                                icon="add"
                                variant={Variant.SECONDARY}
                                size={Size.SMALL}
                                iconUseMargin
                                fillParent={false}
                                onClick={() => setShowAddKeysModal(true)}
                            />
                        </Composite>
                        <ReactMarkdown>{i18n.t('Keys.Description')}</ReactMarkdown>
                    </Group>
                    <Group divider>
                        <Table<Rfid>
                            items={rfids}
                            columnWidths={[undefined, '33%', '33%']}
                            columnNames={[
                                i18n.t('Keys.ColumnDescription'),
                                i18n.t('Keys.ColumnRfid'),
                                i18n.t('Keys.ColumnAccess'),
                            ]}
                            columnGap="small"
                            columns={[
                                DescriptionWrapper(setRfidName),
                                KeyIdWrapper(setRfidKeyId),
                                AccessDropdown(onDropdownClick),
                            ]}
                        />
                        <ChargerAccessContextWindow
                            positioningElement={menuPositioningElement}
                            onClosePress={() => setMenuPositioningElement(undefined)}
                            onSubmit={onChargerAccessSubmit}
                            userAccess={selectedRfid}
                            dataset={privateDataset}
                            userCanBeOwner={false}
                            side="left"
                        />
                        <Text type={TextType.descriptionSmall}>
                            {i18n.t('Keys.AddMoreDescription')}
                        </Text>
                    </Group>
                    {initialRfids.length > 0 && (
                        <Group>
                            <Heading level={Level.h3}>{i18n.t('Keys.AlreadyAdded')}</Heading>
                            <KeyList
                                rfids={initialRfids}
                                dataset={privateDataset}
                                refetchKeys={refetch}
                            />
                        </Group>
                    )}
                    {error && <Error>{error}</Error>}
                    <Group>
                        <Button
                            name="submit-button"
                            text={i18n.t('Keys.Continue')}
                            variant={Variant.PRIMARY}
                            onClick={onSubmit}
                            disabled={!ok}
                        />
                    </Group>
                    <AddMultipleKeysModal
                        show={showAddKeysModal}
                        onClose={() => setShowAddKeysModal(false)}
                        onSubmit={(multipleKeys: ImportedRfid[]) => {
                            onSubmitModal(multipleKeys);
                            setShowAddKeysModal(false);
                        }}
                    />
                </Group>
            }
        />
    );
}
