import React, { ReactElement } from 'react';
import ReactMarkdown from 'react-markdown';
import {
    Heading,
    Icon,
    Level,
    Text,
    TextType,
    WithTooltip,
    useTheme,
} from '@defa/defa-component-library';

export interface MarkDownComponentProps {
    children?: ReactElement | string;
}

export function MarkDown({ children }: MarkDownComponentProps) {
    const theme = useTheme();
    const reactMarkDownComponents = {
        h1: ({ children: componentChildren }: MarkDownComponentProps) => (
            <Heading level={Level.h1}>{componentChildren}</Heading>
        ),
        h2: ({ children: componentChildren }: MarkDownComponentProps) => (
            <Heading level={Level.h2}>{componentChildren}</Heading>
        ),
        h3: ({ children: componentChildren }: MarkDownComponentProps) => (
            <Heading level={Level.h3}>{componentChildren}</Heading>
        ),
        h4: ({ children: componentChildren }: MarkDownComponentProps) => (
            <Heading level={Level.h4}>{componentChildren}</Heading>
        ),
        p: ({ children: componentChildren }: MarkDownComponentProps) => (
            <Text type={TextType.body}>{componentChildren}</Text>
        ),
        em: ({ children: componentChildren }: MarkDownComponentProps) => (
            <WithTooltip inline text={`${componentChildren}`} key="informationTooltip">
                <Icon icon="information" size={16} key="information" color={theme.textColor} />
            </WithTooltip>
        ),
    };
    return <ReactMarkdown components={reactMarkDownComponents}>{`${children}`}</ReactMarkdown>;
}
