import { ChargeSystemGroupsAndConnectors } from '../models/charge-system';
import { Group } from '../models/group';
import { localeObjectSort } from './localeObjectSort';

export const getSortedPrivateGroupsAndConnectors = (
    groups: Group[]
): ChargeSystemGroupsAndConnectors => {
    const privateGroups = groups.filter((g) => g.privateCharging === 'ENABLED') || [];
    const sortedPrivateGroups = localeObjectSort(privateGroups, 'name');

    const privateConnectors = privateGroups.flatMap((g) => g.connectors) || [];
    const sortedPrivateConnectors = localeObjectSort(privateConnectors, 'alias');

    return {
        groups: sortedPrivateGroups,
        connectors: sortedPrivateConnectors,
    };
};
