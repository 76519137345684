import React from 'react';
import { SideMenu, SideMenuItem, SideMenuItemProps, useTheme } from '@defa/defa-component-library';
import i18n from '../../i18n';

export enum Step {
    ORGANIZATION = 10,
    SUBSCRIPTION = 20,
    PAYMENT_OPTIONS = 25,
    ADMINISTRATORS = 30,
    DONE = 40,
}

function OnboardingMenuItem(props: SideMenuItemProps & { currentStep: Step; itemStep: Step }) {
    const theme = useTheme();
    const { itemStep, currentStep } = props;
    let iconColor = theme.textColor;
    let { icon } = props;
    if (itemStep === currentStep) {
        iconColor = theme.colorPrimary;
    } else if (itemStep < currentStep) {
        icon = 'checkOutline';
        iconColor = theme.successColor;
    }
    const disabled = currentStep < itemStep;

    return (
        <SideMenuItem
            {...props}
            padding={`${theme.spacing(2)} 0`}
            icon={icon}
            iconSize={24}
            iconColor={iconColor}
            disabled={disabled}
        />
    );
}

export function OnboardingMenu({ currentStep, id }: { currentStep: Step; id: string }) {
    const theme = useTheme();
    return (
        <SideMenu
            headerText={i18n.t('SetUpOrganization.FlowHeader')}
            padding={`${theme.spacing(1)} 0 ${theme.spacing(16)} 0`}
        >
            <OnboardingMenuItem
                currentStep={currentStep}
                itemStep={Step.ORGANIZATION}
                icon="building"
                text={i18n.t('SetUpOrganization.Navigation')}
                href={`/onboarding/organization/${id}`}
            />
            <OnboardingMenuItem
                currentStep={currentStep}
                itemStep={Step.SUBSCRIPTION}
                icon="refresh"
                iconColor={theme.disabledColor}
                text={i18n.t('Subscription.Navigation')}
                disabled
                href={`/onboarding/subscription/${id}`}
            />
            <OnboardingMenuItem
                currentStep={currentStep}
                itemStep={Step.PAYMENT_OPTIONS}
                icon="cashLayer"
                iconColor={theme.disabledColor}
                text={i18n.t('PaymentMethodSettings.Navigation')}
                disabled
                href={`/onboarding/payment-methods/${id}`}
            />
        </SideMenu>
    );
}
