import { gql } from '@apollo/client';

export const GET_CHARGE_SYSTEM = gql`
    query GetChargeSystem($id: ID!) {
        chargeSystem(id: $id) {
            id
            name
            addressDetails {
                streetNumber
                address
                zipCode
                city
                country
                latitude
                longitude
            }
            category
            subCategory
        }
    }
`;

export const UPDATE_CHARGE_SYSTEM = gql`
    mutation UpdateChargeSystem(
        $id: ID!
        $name: String
        $addressDetails: AddressDetailsInput
        $category: String
        $subCategory: String
    ) {
        chargeSystem(id: $id) {
            update(
                input: {
                    name: $name
                    addressDetails: $addressDetails
                    category: $category
                    subCategory: $subCategory
                }
            ) {
                id
                name
            }
        }
    }
`;

export const GET_CHARGE_SYSTEM_CATEGORIES = gql`
    query GetChargeSystemCategories {
        chargeSystemCategories {
            id
            item
        }
    }
`;
