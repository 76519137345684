import { styled, Button } from '@defa/defa-component-library';

export const AccessListItemRoot = styled(Button)`
    flex-direction: row;
    display: flex;
    align-items: center;
    & > * {
        margin-right: ${({ theme }) => theme.spacing(2)} !important;
        overflow: hidden;
        max-width: 175px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;
