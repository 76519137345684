import { styled } from '@defa/defa-component-library';

export const Root = styled.div``;

export const Summery = styled.div`
    display: grid;
    box-sizing: border-box;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    gap: ${({ theme }) => theme.spacing(1)};
`;

export const Block = styled.div`
    display: flex;
    box-sizing: border-box;
    align-items: start;
    flex-direction: column;
    background: ${({ theme }) => theme.inputBackground};
    border-radius: ${({ theme }) => theme.spacing(3)};
    padding: ${({ theme }) => theme.spacing(7)};
    min-height: ${({ theme }) => theme.spacing(38)};
    gap: ${({ theme }) => theme.spacing(2)};
`;