import React, { ReactNode } from 'react';

import {
    Card,
    Composite,
    Grid,
    Group,
    Heading,
    Level,
    SelectableGrid,
    Text,
    Icon,
    IconName,
    useTheme,
    TextType,
    ProgressIndicator,
    ProgressType,
} from '@defa/defa-component-library';
import i18n from '../../i18n';

import { ChargingTypeState, Group as GroupModel, GroupPriceModel } from '../../models/group';
import { IconLabel } from '../icon-label';
import {
    ConnectorStatus,
    EnergyMeterAvailable,
    getOCPPStatus,
    getVisualStatus,
} from '../../models/connector';
import { localeObjectSort } from '../../utils/localeObjectSort';
import { getStatus } from '../../utils/status';
import { IconContainer } from './group-section.styles';
import { connectorHasEnergyMeter } from '../../utils/energy-meter';

export const getPriceModel = (priceModel: GroupPriceModel) => {
    switch (priceModel) {
        case GroupPriceModel.POWER_USAGE:
            return 'kWh';
        case GroupPriceModel.TIME_USAGE:
            return 'h';
        case GroupPriceModel.FLAT_RATE:
            return 'mo';
        default:
        case GroupPriceModel.FREE:
            return 'Free';
    }
};

export const getChargingTypes = (
    group: Partial<GroupModel>,
    onPublicClick?: () => void,
    onPrivateClick?: () => void
) => {
    const chargingTypes = [];
    if (group.privateCharging && group.privateCharging !== ChargingTypeState.DISABLED) {
        chargingTypes.push({
            label: i18n.t('ChargerGroups.PrivateCharging'),
            iconType: 'privateCharging',
            link: onPrivateClick,
        });
    }
    if (group.publicCharging && group.publicCharging !== ChargingTypeState.DISABLED) {
        chargingTypes.push({
            label: i18n.t('ChargerGroups.PublicCharging'),
            iconType: 'chargingStation',
            link: onPublicClick,
        });

        const states = [];
        if (group.visibleInApps) {
            states.push(i18n.t('ChargerGroups.Visible'));
        }
        if (group.broadcast) {
            states.push(i18n.t('ChargerGroups.Broadcasted'));
        }
        if (states.length > 0) {
            const label = states.join(i18n.t('ChargerGroups.And'));
            chargingTypes.push({
                label,
                iconType: 'pin',
                link: onPublicClick,
            });
        } else {
            chargingTypes.push({
                label: i18n.t('ChargerGroups.Invisible'),
                iconType: 'pinHidden',
                link: onPublicClick,
            });
        }
    }
    return chargingTypes;
};

export function GroupSection({
    group,
    disabled,
    onChange,
    selectedConnectors = [],
    actionItems,
    hideNumberOfItems = false,
    hideChargingTypes = false,
    onConnectorClick,
    selectable = true,
    onPublicClick,
    onPrivateClick,
}: {
    group: Partial<GroupModel>;
    disabled?: boolean;
    onChange?: (connectors: string[]) => void;
    selectedConnectors?: string[];
    actionItems?: ReactNode[];
    hideNumberOfItems?: boolean;
    hideChargingTypes?: boolean;
    selectable?: boolean;
    onConnectorClick?: (connector: string) => void;
    onPublicClick?: () => void;
    onPrivateClick?: () => void;
}) {
    const { name, connectors = [] } = group;
    const theme = useTheme();

    const sortedConnectors = localeObjectSort(connectors, 'alias');
    const chargingTypes = getChargingTypes(group, onPublicClick, onPrivateClick);

    return (
        <Group>
            <Composite childMargin={20}>
                <Composite fillParent justify="flex-start" overflow="hidden">
                    <Heading color={!name ? '#BDBDBD' : undefined} level={Level.h3} ellipsis>
                        {name || i18n.t('ChargerGroups.UngroupedHeader')}
                    </Heading>
                    {!hideNumberOfItems && connectors.length > 0 && (
                        <Text noWrap>
                            {i18n.t('ChargerGroups.NumberOfChargers', {
                                count: connectors.length,
                            })}
                        </Text>
                    )}
                </Composite>
                <Composite childMargin={4}>
                    <Composite childMargin={4}>
                        {!hideChargingTypes &&
                            chargingTypes.map((chargingType) => (
                                <IconLabel
                                    key={chargingType.iconType}
                                    primaryText={chargingType.label}
                                    iconName={chargingType.iconType as IconName}
                                    onClick={chargingType.link}
                                />
                            ))}
                    </Composite>
                    {actionItems}
                </Composite>
            </Composite>

            {selectable ? (
                <SelectableGrid
                    items={sortedConnectors.map((connector) => ({
                        icon: 'boltBox',
                        value: connector.id,
                        label: connector.displayName || connector.alias,
                        description: i18n.t(
                            `Connector.EnergyMeterAvailable.${
                                connectorHasEnergyMeter(connector)
                                    ? EnergyMeterAvailable.PRESENT
                                    : EnergyMeterAvailable.UNKNOWN
                            }`
                        ),
                    }))}
                    name={name || 'unnamed-selectable-grid'}
                    onChange={onChange}
                    disabled={disabled}
                    defaultChecked={selectedConnectors}
                    textColors={['#4F4F4F']}
                    onItemClick={onConnectorClick}
                    selectable={selectable}
                />
            ) : (
                <Grid>
                    {sortedConnectors.map((connector) => {
                        const { hbTimeout } = connector?.operationalData || {};
                        const { version, status, chargingState } =
                            connector?.operationalData?.ocpp || {};
                        const ocpp = getOCPPStatus(version, status, chargingState);
                        const isRestarting = connector.status === ConnectorStatus.RESTARTING;
                        const {
                            background,
                            titleColor,
                            iconColor,
                            iconBackgroundColor,
                            icon,
                            descriptionColor,
                        } = getVisualStatus(ocpp, theme, hbTimeout, isRestarting, connector?.type);

                        return (
                            <Card
                                tid={`card-${connector.id}`}
                                key={`Card-${connector.id}`}
                                background={background}
                                minHeight={theme.spacingRaw(28)}
                                thumbnail={
                                    isRestarting ? (
                                        <ProgressIndicator
                                            progress={0}
                                            type={ProgressType.DONUT_LOADING}
                                        />
                                    ) : (
                                        <IconContainer color={iconBackgroundColor}>
                                            <Icon
                                                icon={icon as IconName}
                                                color={iconColor}
                                                size={16}
                                            />
                                        </IconContainer>
                                    )
                                }
                                title={
                                    <Heading level={Level.h4} color={titleColor}>
                                        {connector.displayName || connector.alias}
                                    </Heading>
                                }
                                onClick={() => {
                                    if (onConnectorClick) {
                                        onConnectorClick(connector.id);
                                    }
                                }}
                            >
                                <Text type={TextType.descriptionSmall} color={descriptionColor}>
                                    {i18n.t(
                                        `ChargerGroups.ConnectorStatus.${getStatus(
                                            hbTimeout,
                                            ocpp,
                                            isRestarting
                                        )}.Title`
                                    )}
                                </Text>
                            </Card>
                        );
                    })}
                </Grid>
            )}
        </Group>
    );
}
