import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
    Button,
    Composite,
    Group,
    Heading,
    Icon,
    Level,
    Loading,
    Modal,
    Size,
    Text,
    TextType,
    Variant,
    useTheme,
} from '@defa/defa-component-library';
import { GET_CS, ChargeSystemReponseType } from './energy-meter-available.queries';
import { EnergyMeterAvailable } from '../../models';
import { Title } from './energy-meter-available.styles';
import i18n from '../../i18n';

function EnergyMeterAvailableModal({
    id,
    show,
    onClosePress,
}: {
    id: string;
    show: boolean;
    onClosePress: () => void;
}) {
    const theme = useTheme();
    const history = useHistory();

    const { data, loading } = useQuery<ChargeSystemReponseType>(GET_CS, {
        variables: { id },
        fetchPolicy: 'network-only',
    });

    const connectors = data?.chargeSystem?.groups?.map((group) => group.connectors).flat() ?? [];
    const withoutEnergyMeter =
        connectors.filter((c) => c.energyMeterAvailable !== EnergyMeterAvailable.PRESENT) ?? [];
    const withEnergyMeter =
        connectors.filter((c) => c.energyMeterAvailable === EnergyMeterAvailable.PRESENT) ?? [];

    return (
        <Modal
            showModal={show}
            maxWidth="80vw"
            titleGap={theme.spacingRaw(4)}
            titleDivider
            titleContent={
                <Title>
                    <Heading level={Level.h2}>
                        {i18n.t('EnergyMeterAvailableModal.TitleText', {
                            ChargeSystem: data?.chargeSystem?.name ?? '',
                        })}
                    </Heading>
                    <Text>{i18n.t('EnergyMeterAvailableModal.DescriptionText')}</Text>
                </Title>
            }
            bodyContent={
                loading ? (
                    <Loading />
                ) : (
                    <>
                        {withoutEnergyMeter.length > 0 && (
                            <Group divider tight>
                                <Heading level={Level.h4}>
                                    {i18n.t('EnergyMeterAvailableModal.withoutEnergyMeterHeading')}
                                </Heading>
                                {withoutEnergyMeter?.map((con) => (
                                    <Composite key={con.id} justify="start">
                                        <Icon
                                            size={theme.spacingRaw(5)}
                                            icon="boltCircle"
                                            color={theme.foreground.success}
                                        />
                                        <Text type={TextType.descriptionMedium}>
                                            {con?.displayName || con?.alias}
                                        </Text>
                                    </Composite>
                                ))}
                            </Group>
                        )}
                        {withEnergyMeter.length > 0 && (
                            <Group divider tight>
                                <Heading level={Level.h4}>
                                    {i18n.t('EnergyMeterAvailableModal.withEnergyMeterHeading')}
                                </Heading>
                                {withEnergyMeter?.map((con) => (
                                    <Composite key={con.id} justify="start">
                                        <Icon
                                            size={theme.spacingRaw(5)}
                                            icon="boltCircle"
                                            color={theme.foreground.success}
                                        />
                                        <Text type={TextType.descriptionMedium}>
                                            {con?.displayName || con?.alias}
                                        </Text>
                                    </Composite>
                                ))}
                            </Group>
                        )}
                    </>
                )
            }
            actionContent={
                <Composite align="flex-end" justify="end">
                    <Button
                        text={i18n.t('RearrangeGroups.Title')}
                        fillParent={false}
                        onClick={() => {
                            history.push(`/charge-system/${id}/rearrange`);
                        }}
                    />
                </Composite>
            }
            onClosePress={onClosePress}
        />
    );
}

export function EnergyMeterAvailableMessage({ id }: { id?: string | undefined }) {
    const [showEnergyMeterAvailable, setShowEnergyMeterAvailable] = useState(false);
    const theme = useTheme();

    return (
        <>
            <Composite align="start" justify="start">
                <Icon icon="alertTriangle" size={16} color={theme.foreground.attention} />
                <Heading level={Level.h4} color={theme.foreground.alternative}>
                    {i18n.t('EnergyMeterAvailableMessage.Title')}
                </Heading>
            </Composite>
            <Text color={theme.foreground.alternative}>
                {i18n.t('EnergyMeterAvailableMessage.Description')}
            </Text>
            {id && (
                <>
                    <Button
                        text={i18n.t('EnergyMeterAvailableMessage.ButtonText')}
                        variant={Variant.PRIMARY}
                        size={Size.SMALL}
                        fillParent={false}
                        onClick={() => setShowEnergyMeterAvailable(true)}
                    />
                    <EnergyMeterAvailableModal
                        id={id}
                        show={showEnergyMeterAvailable}
                        onClosePress={() => {
                            setShowEnergyMeterAvailable(false);
                        }}
                    />
                </>
            )}
        </>
    );
}
