// import RNLanguages from 'react-native-languages';
import i18n from 'i18n-js';

import en from './translations/en.json';
import nb from './translations/nb.json';
import sv from './translations/sv.json';
import fi from './translations/fi.json';

const updateLocale = (language: string) => {
    const languageCode = language.split('-')[0];
    if (!i18n.translations[languageCode]) {
        return;
    }
    i18n.locale = languageCode;
};

i18n.defaultLocale = 'en';
i18n.fallbacks = true;
i18n.translations = { en, nb, nn: nb, no: nb, sv, fi };

export { updateLocale };

export default i18n;
