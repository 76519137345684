import React, { useCallback, useState } from 'react';
import { Button, Group, Heading, Level, Variant } from '@defa/defa-component-library';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import i18n from '../../../../i18n';
import { BillingMethod, ChargingTypeState } from '../../../../models/group';
import { SetupPrivateSettings } from '../../../../fractions/setup-private-settings';
import { GET_CHARGE_SYSTEM, UPDATE_CHARGE_SYSTEM } from './private-settings-same-settings.queries';
import { useCurrency } from '../../../../utils/hooks';
import { DEFAULT_TARIFF } from '../../../../constants';
import { Tariff } from '../../../../models';

export function PrivateSettingsSameSettings() {
    const history = useHistory();
    const [currency] = useCurrency();
    const { id } = useParams<{ id: string }>();

    const [tariff, setTariff] = useState<Tariff>(DEFAULT_TARIFF);
    const [hasChargingFee, setChargingFee] = useState(false);

    const { data = { chargeSystem: {} } } = useQuery(GET_CHARGE_SYSTEM, {
        variables: { id },
        fetchPolicy: 'network-only',
    });

    const [updateChargeSystem] = useMutation(UPDATE_CHARGE_SYSTEM);

    const tariffChanged = (newTariff: Tariff) => {
        setTariff(newTariff);
    };

    const chargingFeeChanged = (chargingFee: boolean) => {
        setChargingFee(!chargingFee);
    };

    const onSubmitClick = useCallback(() => {
        updateChargeSystem({
            variables: {
                id,
                groups: data.chargeSystem.groups.map((g: any) => ({
                    id: g.id,
                    privateChargingSettings: {
                        billingMethod: BillingMethod.CARD,
                        tariff: hasChargingFee ? tariff : DEFAULT_TARIFF,
                    },
                    privateCharging: ChargingTypeState.ENABLED,
                })),
            },
        }).then(() => {
            history.replace(`/charge-system/${id}/setup/users/add`);
        });
    }, [updateChargeSystem, id, data, history, tariff, hasChargingFee]);

    const feeOk = hasChargingFee
        ? (!!tariff.pricePerHour && parseFloat(tariff.pricePerHour) > 0) ||
          (!!tariff.pricePerKwh && parseFloat(tariff.pricePerKwh) > 0)
        : true;

    return (
        <Group minWidth="640px">
            <Group>
                <Heading level={Level.h1}>{i18n.t('PrivateSettingsSameSettings.Header')}</Heading>
            </Group>
            <SetupPrivateSettings
                currency={currency}
                tariff={tariff}
                tariffChanged={tariffChanged}
                chargingFeeChanged={chargingFeeChanged}
                hasChargingFee={hasChargingFee}
            />
            <Button variant={Variant.PRIMARY} disabled={!feeOk} onClick={onSubmitClick}>
                {i18n.t('PrivateSettingsSameSettings.Continue')}
            </Button>
        </Group>
    );
}
