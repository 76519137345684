import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
    Heading,
    Level,
    Button,
    Group,
    ActionRow,
    Link,
    Dropdown,
    Text,
    Variant,
    Size,
} from '@defa/defa-component-library';
import { useHistory, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import { Connector, EnergyMeterAvailable } from '../../../models/connector';
import { ChargingTypeState, Group as GroupModel } from '../../../models/group';
import { AddGroupModal } from '../../../fractions/add-group-modal';
import { GroupSection } from '../../../fractions/group-section';
import i18n from '../../../i18n';
import {
    ADD_GROUP,
    GET_CHARGE_SYSTEM,
    DELETE_GROUP,
    UPDATE_CHARGE_SYSTEM,
    ChargeSystemResponseType,
} from './groups.queries';
import { DeleteGroupModal } from '../../../fractions/delete-group-modal';
import { MoveGroupModal } from '../../../fractions/move-group-modal';
import { NotificationContext } from '../../../utils/notification';
import { localeObjectSort } from '../../../utils/localeObjectSort';
import { CantMoveModal } from '../../../fractions/cant-move-modal/cant-move-modal';
import { groupHasKwhPrice } from '../../../utils/group';

export function ConnectorsSection({
    ungroupedConnectors,
    onSelectedConnectorsChanged,
    deleteGroup,
    moveGroup,
    selectedConnectors,
    groups,
    invalid,
    submit,
    rearrange = false,
    working = false,
}: {
    ungroupedConnectors: Connector[];
    onSelectedConnectorsChanged: (selected: string[]) => void;
    deleteGroup: (id: string) => void;
    moveGroup: (fromGroupId: string, toGroupId: string) => void;
    selectedConnectors: string[];
    groups: GroupModel[];
    invalid: boolean;
    submit: () => void;
    rearrange?: boolean;
    working?: boolean;
}) {
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showMoveModal, setShowMoveModal] = useState<boolean>(false);
    const [currentGroup, setCurrentGroup] = useState<{ id: string; name: string }>({
        id: '',
        name: '',
    });
    const onShowDeleteModal = (id: string, name: string) => {
        setCurrentGroup({ id, name });
        setShowDeleteModal(true);
    };

    const onMoveGroup = (newGroupId: string) => {
        moveGroup(currentGroup.id, newGroupId);
        setShowMoveModal(false);
    };

    const onDeleteGroup = () => {
        const selectedGroupConnectorCount = groups.find((group) => currentGroup.id === group.id)
            ?.connectors.length;
        if (selectedGroupConnectorCount && selectedGroupConnectorCount > 0) {
            setShowMoveModal(true);
        } else {
            deleteGroup(currentGroup.id);
        }
        setShowDeleteModal(false);
    };
    return (
        <>
            {ungroupedConnectors.length > 0 && (
                <GroupSection
                    group={{ connectors: ungroupedConnectors }}
                    onChange={onSelectedConnectorsChanged}
                    selectedConnectors={selectedConnectors}
                    hideNumberOfItems
                    hideChargingTypes
                    actionItems={[
                        <Link
                            key="select-all"
                            action
                            text={i18n.t('ChargerGroups.UngroupedSelectAll')}
                            onClick={() =>
                                onSelectedConnectorsChanged(
                                    ungroupedConnectors.map((c: Connector) => c.id)
                                )
                            }
                        />,
                    ]}
                />
            )}
            {localeObjectSort(groups, 'name').map((group) => (
                <GroupSection
                    group={group}
                    key={group.name}
                    onChange={onSelectedConnectorsChanged}
                    selectedConnectors={selectedConnectors}
                    hideChargingTypes
                    actionItems={[
                        groups.length > 1 ? (
                            <Button
                                name={`delete-button-${group.name}`}
                                key={group.name}
                                icon="close"
                                variant={Variant.SECONDARY}
                                size={Size.SMALL}
                                fillParent={false}
                                onClick={() => onShowDeleteModal(group.id, group.name)}
                                disabled={working}
                            />
                        ) : (
                            <React.Fragment key={group.name} />
                        ),
                    ]}
                />
            ))}
            {!rearrange && ungroupedConnectors.length === 0 && (
                <Button
                    text={i18n.t('Continue')}
                    disabled={invalid || working}
                    onClick={submit}
                    name="submit-button"
                    loading={working}
                />
            )}
            {!rearrange && ungroupedConnectors.length > 0 && (
                <Text alignment="center">{i18n.t('ChargerGroups.SubmitButtonInfoMessage')}</Text>
            )}
            <DeleteGroupModal
                onClosePress={() => setShowDeleteModal(false)}
                onSubmit={onDeleteGroup}
                showModal={showDeleteModal}
                groupName={currentGroup.name}
            />
            <MoveGroupModal
                onClosePress={() => setShowMoveModal(false)}
                onSubmit={onMoveGroup}
                showModal={showMoveModal}
                groups={groups.filter((group: any) => group.id !== currentGroup.id)}
                groupName={currentGroup.name}
            />
        </>
    );
}

export function AddGroupFraction({
    selectedConnectors,
    groups,
    onGroupSelected,
    showAddGroupModal,
    addGroupModalVisible,
    hideAddGroupModal,
    onAddGroup,
    working = false,
}: {
    selectedConnectors: string[];
    groups: any[];
    onGroupSelected: (
        selectedGroup: GroupModel,
        currentGroupConnectors?: string[],
        isNew?: boolean
    ) => void;
    showAddGroupModal: () => void;
    addGroupModalVisible: boolean;
    hideAddGroupModal: () => void;
    onAddGroup: (name: string) => Promise<void>;
    working?: boolean;
}) {
    return working ? (
        <></>
    ) : (
        <>
            {selectedConnectors.length > 0 && (
                <ActionRow floating>
                    {groups.length > 0 && (
                        <Dropdown<GroupModel>
                            label={i18n.t('ChargerGroups.SelectGroupDropdownLabel')}
                            name="select-group"
                            items={localeObjectSort(groups, 'name')}
                            keyExtractor={(group: GroupModel) => group.id}
                            labelExtractor={(group: GroupModel) => group.name}
                            onChange={onGroupSelected}
                        />
                    )}

                    <Button
                        text={i18n.t('ChargerGroups.AddGroupButtonText')}
                        onClick={showAddGroupModal}
                        icon="add"
                        iconUseMargin
                        name="add-group-button"
                    />
                </ActionRow>
            )}
            <AddGroupModal
                showModal={addGroupModalVisible}
                onClose={hideAddGroupModal}
                onSave={onAddGroup}
            />
        </>
    );
}

export function ArrangeGroups() {
    const { id } = useParams<{ id: string }>();

    const history = useHistory();

    const { data, refetch } = useQuery<ChargeSystemResponseType>(GET_CHARGE_SYSTEM, {
        variables: { id },
        fetchPolicy: 'network-only',
    });

    const { add: addNotification } = useContext(NotificationContext);
    const [groups, setGroups] = useState<any[]>([]);
    const [selectedConnectors, setSelectedConnectors] = useState<string[]>([]);
    const [addGroupModalVisible, setAddGroupModalVisible] = useState(false);
    const [working, setWorking] = useState<boolean>(false);
    const [nameForGroupThatHasPricePerKwh, setNameForGroupThatHasPricePerKwh] = useState('');

    const [updateChargeSystem] = useMutation(UPDATE_CHARGE_SYSTEM);

    const [addGroup] = useMutation(ADD_GROUP);
    const [deleteGroup] = useMutation(DELETE_GROUP);

    const { chargeSystem } = data || {};

    const { connectors = [] as Connector[], groups: initialGroups } = chargeSystem || {};

    const connectorsInGroups = groups.reduce(
        (acc, group) => [...acc, ...(group.connectors || [])],
        []
    );
    const ungroupedConnectors = [
        ...connectors.filter(
            (c: Connector) => !connectorsInGroups.some((cig: Connector) => cig.id === c.id)
        ),
    ];

    useEffect(() => {
        setGroups(initialGroups || []);
    }, [initialGroups]);

    const invalid = !groups || groups.length < 1;

    const onSelectedConnectorsChanged = (selected: string[]) => {
        setSelectedConnectors(selected);
    };

    const onDeleteGroup = async (groupId: string) => {
        setWorking(true);
        addNotification({
            id: 'UpdateGroup',
            message: i18n.t('ChargerGroups.Notification.Delete'),
        });
        await deleteGroup({
            variables: {
                id: chargeSystem?.id,
                group: { id: groupId },
            },
        });
        await refetch();
        setWorking(false);
    };

    const showAddGroupModal = () => {
        setAddGroupModalVisible(true);
    };

    const hideAddGroupModal = () => {
        setAddGroupModalVisible(false);
    };

    const submit = useCallback(() => {
        history.push(`/charge-system/${id}/setup/public`);
    }, [history, id]);

    const onGroupSelected = async (
        selectedGroup: GroupModel,
        currentGroupConnectors: string[] = [],
        isNew: boolean = false
    ) => {
        setWorking(true);
        const selectedConns =
            currentGroupConnectors.length > 0
                ? [...currentGroupConnectors]
                : [...selectedConnectors];

        if (!isNew) {
            // Ensure that the group does not has a public/private tariff with pricePerKwh set
            const hasAnyTariffSetToPricePerKwh = groupHasKwhPrice(selectedGroup);
            if (hasAnyTariffSetToPricePerKwh) {
                // if the selected has connectors with energyMeterAvailable NOT set to 'PRESENT'
                const theSelectedConnectors = connectors.filter((c) =>
                    selectedConnectors.includes(c.id)
                );
                // check if all connectors have energyMeterAvailable set to 'PRESENT'
                const allHasEnergyMeterAvailable = theSelectedConnectors.every(
                    (c) => c?.energyMeterAvailable === EnergyMeterAvailable.PRESENT
                );
                if (!allHasEnergyMeterAvailable) {
                    setNameForGroupThatHasPricePerKwh(selectedGroup.name);
                    setSelectedConnectors([]);
                    setWorking(false);
                    return;
                }
            }
        }

        setSelectedConnectors([]);
        const groupsWithSelectedConnectorsRemoved = groups.map((g) => ({
            ...g,
            connectors: (g.connectors || []).filter(
                (c: Connector) => !selectedConns.some((sc) => sc === c.id)
            ),
        }));

        let newGroups: any[] = [];

        if (!selectedGroup) {
            newGroups = groupsWithSelectedConnectorsRemoved;
        } else if (isNew) {
            newGroups = [
                {
                    ...selectedGroup,
                    connectors: selectedConns.map((sc) =>
                        connectors.find((c: Connector) => c.id === sc)
                    ),
                },
                ...groupsWithSelectedConnectorsRemoved,
            ];
        } else {
            const groupIndex = groups.map((g) => g.id).indexOf(selectedGroup.id);

            const existingSelectedGroupData = groupsWithSelectedConnectorsRemoved[groupIndex];

            const group = {
                ...existingSelectedGroupData,
                connectors: [
                    ...(existingSelectedGroupData.connectors || []),
                    ...selectedConns.map((sc) => connectors.find((c: Connector) => c.id === sc)),
                ],
            };

            newGroups = [
                ...groupsWithSelectedConnectorsRemoved.slice(0, groupIndex),
                group,
                ...groupsWithSelectedConnectorsRemoved.slice(groupIndex + 1),
            ];
        }
        setGroups(newGroups);
        addNotification({
            id: 'UpdateGroup',
            message: i18n.t('ChargerGroups.Notification.Alter'),
        });
        await updateChargeSystem({
            variables: {
                id,
                groupsWithConnectors: newGroups.map((g) => ({
                    id: g.id,
                    connectors: g.connectors.map((c: Connector) => c.id),
                })),
            },
        });
        setWorking(false);
    };

    const onMoveGroup = async (fromGroupId: string, toGroupId: string) => {
        const fromGroup = groups.find((group) => group.id === fromGroupId) as GroupModel;
        const fromConnectors = fromGroup.connectors.map((c) => c.id);
        setSelectedConnectors(fromConnectors);
        const toGroup = groups.find((group) => group.id === toGroupId) as GroupModel;
        setWorking(true);
        await onGroupSelected(toGroup, fromConnectors);
        await onDeleteGroup(fromGroupId);
        setWorking(false);
    };

    const onAddGroup = async (name: string) => {
        const newGroup = {
            name,
            publicCharging: ChargingTypeState.DISABLED,
            privateCharging: ChargingTypeState.DISABLED,
        };
        hideAddGroupModal();
        setWorking(true);
        addNotification({ id: 'UpdateGroup', message: i18n.t('ChargerGroups.Notification.Add') });
        const result = await addGroup({
            variables: {
                id,
                group: newGroup,
            },
        });

        const { data: addGroupResultData } = result;
        const { chargeSystem: addGroupResultChargeSystemData } = addGroupResultData;
        const { addGroup: addedGroup } = addGroupResultChargeSystemData;

        onGroupSelected(addedGroup, [], true);
        setWorking(false);
    };
    return (
        <>
            <Group width="640px">
                <Group>
                    <Heading level={Level.h1}>{i18n.t('ChargerGroups.Header')}</Heading>
                    <ReactMarkdown>{i18n.t('ChargerGroups.Description')}</ReactMarkdown>
                </Group>
                <ConnectorsSection
                    ungroupedConnectors={ungroupedConnectors}
                    onSelectedConnectorsChanged={onSelectedConnectorsChanged}
                    selectedConnectors={selectedConnectors}
                    groups={groups}
                    invalid={invalid}
                    deleteGroup={onDeleteGroup}
                    moveGroup={onMoveGroup}
                    submit={submit}
                    working={working}
                />
            </Group>
            <AddGroupFraction
                selectedConnectors={selectedConnectors}
                groups={groups}
                onGroupSelected={onGroupSelected}
                showAddGroupModal={showAddGroupModal}
                addGroupModalVisible={addGroupModalVisible}
                hideAddGroupModal={hideAddGroupModal}
                onAddGroup={onAddGroup}
                working={working}
            />
            <CantMoveModal
                groupName={nameForGroupThatHasPricePerKwh}
                onClosePress={() => {
                    setNameForGroupThatHasPricePerKwh('');
                }}
            />
        </>
    );
}
