import { styled } from '@defa/defa-component-library';

interface MenuItemRootProps {
    disabled?: boolean;
}

export const Nav = styled.nav`
    align-items: center;
    display: flex;

    & > * {
        margin-left: ${({ theme }) => theme.spacing(4)};
        margin-right: ${({ theme }) => theme.spacing(4)};
    }
`;

export const Header = styled.div`
    padding: ${({ theme }) => theme.spacing(0)} ${({ theme }) => theme.spacing(6)};
    background-color: ${({ theme }) => theme.menuBackgroundColor};
    height: 60px;
    justify-content: space-between;
    display: flex;
    align-self: stretch;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);

    @media print {
        display: none;
    }
`;

export const HeaderItemRoot = styled.div`
    flex-direction: row;
    display: flex;
    align-items: center;
    & > * {
        margin-right: ${({ theme }) => theme.spacing(1)};
    }
    cursor: pointer;
`;

export const MenuItemRoot = styled.a<MenuItemRootProps>`
    display: block;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    padding: ${({ theme }) => theme.spacing(4)} 0;
    & > * {
        margin-bottom: 0;
    }
    border-bottom: solid 1px ${({ theme }) => theme.menuDividerColor};
    &:first-child {
        padding-top: 0;
    }
    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
