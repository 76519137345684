import React, { useCallback, useContext, useState } from 'react';
import { Heading, Level, Button, Modal, TextInput } from '@defa/defa-component-library';
import { useMutation } from '@apollo/client';
import i18n from '../../i18n';
import { validateEmail } from '../../utils/email';
import {
    INVITE_ADMINS,
    InviteAdminsResponseData,
} from '../../screens/onboarding/done/done.queries';
import { useOrganization } from '../../utils/hooks';
import { NotificationContext } from '../../utils/notification';

export interface InviteAdminProps {
    showInvite: boolean;
    setShowInvite: (showInvite: boolean) => void;
}

export function InviteAdmin({ showInvite, setShowInvite }: InviteAdminProps) {
    const [organizationId] = useOrganization();
    const { add: addNotification } = useContext(NotificationContext);
    const [adminToInvite, setAdminToInvite] = useState('');

    const [inviteAdmins, { loading: loadingInviteAdmins }] = useMutation<InviteAdminsResponseData>(
        INVITE_ADMINS
    );

    const invitesValid = validateEmail(adminToInvite);

    const inviteOtherAdmin = useCallback(async () => {
        const response = await inviteAdmins({
            variables: {
                id: organizationId,
                invites: [
                    {
                        email: adminToInvite,
                        organizations: [
                            {
                                id: organizationId,
                            },
                        ],
                    },
                ],
            },
        });

        const inviteAdminsResponse = response.data?.organization.inviteAdmins;
        if (inviteAdminsResponse?.status) {
            addNotification({
                id: 'admin',
                message: i18n.t('OnboardingComplete.AdministratorInvited'),
            });
            setShowInvite(false);
        }
    }, [addNotification, adminToInvite, inviteAdmins, organizationId, setShowInvite]);

    return (
        <Modal
            titleContent={
                <Heading level={Level.h3}>
                    {i18n.t('OnboardingComplete.HeaderInviteAdministrator')}
                </Heading>
            }
            bodyContent={
                <TextInput
                    value={adminToInvite}
                    label={i18n.t('OnboardingComplete.EmailTitle')}
                    name="email"
                    onChange={(val) => setAdminToInvite(val)}
                />
            }
            actionContent={
                <Button
                    name="add-administrators-button"
                    fillParent
                    disabled={!invitesValid}
                    loading={loadingInviteAdmins}
                    text={i18n.t('OnboardingComplete.ButtonSendInvite')}
                    onClick={inviteOtherAdmin}
                />
            }
            showModal={showInvite}
            onClosePress={() => setShowInvite(false)}
            zIndex={10}
            width="300px"
        />
    );
}
