import { useQuery } from '@apollo/client';

import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useQueryParam } from '../../utils/hooks';
import { Loading } from '../loading';
import { VALIDATE_INVITATION } from './accept-invite.queries';

export const AcceptInvitation: React.FunctionComponent = () => {
    const query = useQueryParam();
    const token = query.get('token');
    const adminId = query.get('adminId');

    const { refetch, data = { invitation: {} }, loading } = useQuery(VALIDATE_INVITATION, {
        variables: { token },
    });

    const { invitation } = data;

    const { valid, isNewAdmin } = invitation;

    useEffect(() => {
        refetch();
    }, [refetch]);

    /** *
     * DISCLAIMER: This solution is not the best, but it works for now.
     * TODO: the name isNewAdmin is confusing and really means isExistingAdmin
     ** */

    if (!loading) {
        return (
            <Redirect
                to={
                    valid && !isNewAdmin
                        ? `/welcome?adminId=${adminId}&token=${token}`
                        : '/login/invalidtokenoradminexist'
                }
            />
        );
    }

    return <Loading />;
};
