import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import ReactMarkdown from 'react-markdown';
import {
    Composite,
    Group,
    Heading,
    Icon,
    IconName,
    Level,
    SideMenu,
    SplitScreen,
    Text,
    TextType,
    useTheme,
} from '@defa/defa-component-library';
import { Root, Content, Second, SupportCardRoot } from './support.styles';
import i18n from '../../i18n';
import { Header } from '../../fractions/header';
import { SettingsSideMenuItem } from '../../fractions/settings-side-menu';
import { GET_COUNTRIES, GET_ORGANIZATION_COUNTRY } from './support.queries';
import { useOrganization } from '../../utils/hooks';

interface SupportDetails {
    openingHours: string;
    phoneNumber: string;
    email: string;
}

export const SupportCard = ({
    icon,
    primaryText,
    secondaryText,
}: {
    icon: IconName;
    primaryText: string;
    secondaryText: string;
}) => {
    const theme = useTheme();
    return (
        <SupportCardRoot>
            <Composite>
                <Icon icon={icon} color={theme.textColor} />
                <Group tight noMargin>
                    <Heading level={Level.h3}>{primaryText}</Heading>
                    <Text type={TextType.descriptionSmall} color={theme.descriptionColor}>
                        {secondaryText}
                    </Text>
                </Group>
            </Composite>
        </SupportCardRoot>
    );
};

export const Support: React.FunctionComponent = () => {
    const theme = useTheme();
    const [organizationId] = useOrganization();
    const [supportDetails, setSupportDetails] = useState<SupportDetails>({
        phoneNumber: '',
        openingHours: '',
        email: '',
    });
    const { data: organizationData } = useQuery(GET_ORGANIZATION_COUNTRY, {
        variables: {
            id: organizationId,
        },
    });
    const { organization } = organizationData || {};
    const { contactAddress } = organization || {};
    const { country: organizationCountry } = contactAddress || {};
    const { data: countryData } = useQuery(GET_COUNTRIES);
    const { countries } = countryData || {};

    useEffect(() => {
        if (organizationCountry && countries) {
            const countryDetails = countries.find(
                (c: { id: string }) => c.id === organizationCountry
            );
            setSupportDetails({
                email: countryDetails?.email || i18n.t('Support.MissingInformation'),
                openingHours: countryDetails?.openingHours || i18n.t('Support.MissingInformation'),
                phoneNumber: countryDetails?.phoneNumber || i18n.t('Support.MissingInformation'),
            });
        }
    }, [organizationCountry, countries]);

    return (
        <Root>
            <Header />
            <Content>
                <SplitScreen
                    first={
                        <SideMenu
                            headerText={i18n.t('Support.NavHeader')}
                            width="180px"
                            margin={`0 ${theme.spacing(10)} 0 0`}
                        >
                            <SettingsSideMenuItem
                                icon="phone"
                                text={i18n.t('Support.NavItemHeader')}
                                href="/support"
                            />
                        </SideMenu>
                    }
                    firstSideBackgroundColor="transparent"
                    secondSideBackgroundColor="transparent"
                    padding={`${theme.spacing(10)} 0`}
                    firstContentAlign="end"
                    second={
                        <Second>
                            <Group>
                                <Group>
                                    <Heading level={Level.h3}>{i18n.t('Support.Header')}</Heading>
                                    <ReactMarkdown>{i18n.t('Support.Description')}</ReactMarkdown>
                                </Group>
                                <Composite>
                                    <SupportCard
                                        icon="phone"
                                        primaryText={supportDetails.phoneNumber}
                                        secondaryText={supportDetails.openingHours}
                                    />
                                    <SupportCard
                                        icon="email"
                                        primaryText={supportDetails.email}
                                        secondaryText={i18n.t('Support.ResponseTime')}
                                    />
                                </Composite>
                            </Group>
                        </Second>
                    }
                />
            </Content>
        </Root>
    );
};
