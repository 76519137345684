import { gql } from '@apollo/client';

export const GET_ORGANIZATION = gql`
    query GetOrganization($id: ID!) {
        organization(id: $id) {
            id
            stripeAccountId
            stripeStatus
            stripeAccount {
                email
                name
                status
            }
        }
    }
`;

export const CREATE_STRIPE_ACCOUNT = gql`
    query($organizationId: ID!, $onboarding: Boolean!) {
        createStripeAccount(organizationId: $organizationId, onboarding: $onboarding)
    }
`;

export const GENERATE_STRIPE_LINK = gql`
    query($organizationId: ID!, $onboarding: Boolean!) {
        generateStripeAccountLink(organizationId: $organizationId, onboarding: $onboarding)
    }
`;

export const UPDATE_STRIPE_STATUS = gql`
    mutation UpsertOrganization($id: ID, $stripeStatus: StripeStatus) {
        upsertOrganization(input: { id: $id, stripeStatus: $stripeStatus }) {
            id
        }
    }
`;
