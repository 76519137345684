import { gql } from '@apollo/client';

export const UPDATE_ORGANIZATION = gql`
    mutation UpdateOrganization($id: ID!, $invites: [AdminInviteInput!]!) {
        organization(id: $id) {
            inviteAdmins(input: $invites) {
                status
            }
        }
    }
`;

export const GET_ORGANIZATION = gql`
    query GetOrganization($id: ID!) {
        organization(id: $id) {
            id
            chargeSystems {
                id
                name
                groups {
                    id
                    name
                }
            }
        }
    }
`;
