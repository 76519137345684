import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
    Avatar,
    Composite,
    Currency,
    Group,
    Heading,
    Icon,
    Level,
    Modal,
    ProgressIndicator,
    ProgressType,
    Size,
    Switch,
    Table,
    Text,
    TextInput,
    TextType,
    WithTooltip,
    useTheme,
} from '@defa/defa-component-library';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { InfoWrapper, ListItem, HeadingWithTooltip } from './payment-method.styles';
import { STRIPE_INDEX } from '../../constants';
import i18n from '../../i18n';
import { CompanyItem } from '../../fractions/company-item';
import { StripeModal } from '../../fractions/stripe-modal';
import {
    CreationStatus,
    StripeStatus as STRIPE_STATUS,
    StripeAccountStatus,
} from '../../models/organization';
import { StripeStatusBadge } from '../../fractions/stripe-status-badge';
import {
    GENERATE_STRIPE_LINK,
    GET_ORGANIZATION,
    UPDATE_STRIPE_STATUS,
    CREATE_STRIPE_ACCOUNT,
    UPDATE_CHARGE_SYSTEM_INVOICING,
} from './payment-method.queries';
import { useCurrency, useOrganization, useStripeStatus } from '../../utils/hooks';
import { LoadingWrapper } from './settings.styles';
import { ChargeSystem, InvoicingInfo, UIState } from '../../models';
import { NotificationContext } from '../../utils/notification';
import { MarkDown } from '../../fractions/mark-down';

interface InvoicingInput {
    id: string;
    fixedCostPerUser: number;
    invoicingActivated: boolean;
}

function ChargeSystemListItem(data: ChargeSystem) {
    const { id, name, connectors } = data;
    // TODO: Creta a avatar:typed for charge system
    return (
        <Avatar
            key={`${id}-avatar-access`}
            user={{
                name: `${name}`,
                phoneNumber: i18n.t('PaymentMethodSettings.InvoicingList.ChargeSystemConnectors', {
                    connectors: connectors?.length ?? 0,
                }),
            }}
            tid={`access-${id}`}
            size={Size.SMALL}
        />
    );
}

function InvoiceAbleListItem(onChangeHandler: (data: InvoicingInput) => void, loading: UIState) {
    return (data: ChargeSystem) => {
        const { id, invoicingInfo } = data;
        const { invoicingActivated, fixedCostPerUser } = invoicingInfo ?? {};

        const onToggleInvoiceAbility = () => {
            onChangeHandler({
                id,
                invoicingActivated: !invoicingActivated,
                fixedCostPerUser: fixedCostPerUser ?? 0,
            });
        };

        return (
            <ListItem key={`${id}-able`}>
                <Switch
                    disabled={UIState.LOADING === loading}
                    key={`${id}-switch-able`}
                    checked={invoicingActivated}
                    name="vat"
                    label={i18n.t('PaymentMethodSettings.InvoicingList.AbilityLabel')}
                    onChange={onToggleInvoiceAbility}
                />
            </ListItem>
        );
    };
}

const FixedCostField = ({
    id,
    onBlur,
    invoicingInfo,
    currency,
    loading,
}: {
    id: string;
    onBlur: (newCost: number) => void;
    invoicingInfo: InvoicingInfo | undefined;
    currency: Currency;
    loading?: UIState;
}) => {
    const { fixedCostPerUser, invoicingActivated } = invoicingInfo ?? {};
    const [val, setVal] = useState(fixedCostPerUser ?? 0);
    return (
        <TextInput
            currency={currency}
            type="number"
            disabled={!invoicingActivated || UIState.LOADING === loading}
            label={i18n.t('PaymentMethodSettings.InvoicingList.FeeLabel')}
            locale={navigator.language}
            name={`fixedcostfield-${id}`}
            value={val.toString()}
            onChange={(text) => {
                setVal(parseInt(text, 10));
            }}
            onBlur={() => onBlur(val)}
        />
    );
};

function FixedCostListItem(
    onChangeHandler: (data: InvoicingInput) => void,
    loading: UIState,
    currency: Currency
) {
    return (data: ChargeSystem) => {
        const { id, invoicingInfo } = data;
        const updateFixedFee = (newCost: number) => {
            onChangeHandler({
                id,
                invoicingActivated: true,
                fixedCostPerUser: newCost,
            });
        };

        return (
            <ListItem key={`${id}-fixedfee`}>
                <FixedCostField
                    currency={currency}
                    id={id}
                    invoicingInfo={invoicingInfo}
                    loading={loading}
                    onBlur={updateFixedFee}
                />
            </ListItem>
        );
    };
}

export const PaymentMethodSettings: React.FunctionComponent = () => {
    const theme = useTheme();
    const [organizationId] = useOrganization();
    const [, storeStripeStatus] = useStripeStatus();
    const [currency] = useCurrency();
    const [uiState, setUiState] = useState(UIState.READY);
    const { add: addNotification } = useContext(NotificationContext);

    const [stripeModalOpen, setStripeModalOpen] = useState(false);
    const { refetch, loading, data: organizationsData } = useQuery(GET_ORGANIZATION, {
        variables: {
            id: organizationId,
        },
    });
    const { organization } = organizationsData ?? {};
    const { stripeStatus, stripeAccountId, stripeAccount } = organization ?? {};
    const { status: stripeAccountStatus } = stripeAccount ?? {};

    const [createAccount] = useLazyQuery(CREATE_STRIPE_ACCOUNT, {
        variables: { organizationId, onboarding: false },
        fetchPolicy: 'network-only',
    });

    const [generateLink] = useLazyQuery(GENERATE_STRIPE_LINK, {
        variables: { organizationId, onboarding: false },
        fetchPolicy: 'network-only',
    });
    const [updateInvoiceInfo] = useMutation(UPDATE_CHARGE_SYSTEM_INVOICING);
    const [updateStripeStatus] = useMutation(UPDATE_STRIPE_STATUS);
    const params = new URLSearchParams(window.location.search);
    const connectStripeAccountId = params.get('accountId');
    const stripeEnabled = stripeStatus === STRIPE_STATUS.ENABLED;

    const onChangeInvoiceInfo = useCallback(
        ({ id, invoicingActivated, fixedCostPerUser }: InvoicingInput) => {
            const runUpdate = async () => {
                const feeResponse = await updateInvoiceInfo({
                    variables: {
                        id,
                        invoicingInfo: {
                            useInvoicing: invoicingActivated,
                            fixedPerUserFee: invoicingActivated ? fixedCostPerUser : 0,
                        },
                    },
                });
                if (feeResponse.data?.chargeSystem?.updateInvoicingInfo?.status) {
                    addNotification({
                        id: 'invoiceSaved',
                        message: i18n.t('PaymentMethodSettings.InvoicingList.SaveSuccess'),
                    });
                    refetch();
                } else {
                    addNotification({
                        id: 'invoiceSaved',
                        message: i18n.t('PaymentMethodSettings.InvoicingList.SaveFail'),
                    });
                }
            };
            runUpdate();
        },
        [updateInvoiceInfo, refetch, addNotification]
    );

    const doGenerateLink = async () => {
        if (!stripeAccountId) {
            const { data } = await createAccount();
            if (data.createStripeAccount === CreationStatus.FAILED) {
                setStripeModalOpen(false);
                addNotification({
                    id: 'StripeFailedToCreateAccount',
                    message: i18n.t('PaymentMethodSettings.Stripe.FailedToCreateAccount'),
                });
                return;
            }
        }
        // Redirect to stripe
        const { data } = await generateLink();
        if (data.generateStripeAccountLink) {
            if (!stripeAccountId) {
                window.location.href = data.generateStripeAccountLink;
            } else {
                window.open(data.generateStripeAccountLink, '_blank');
            }
        } else {
            addNotification({
                id: 'StripeFailedToCreateLink',
                message: i18n.t('PaymentMethodSettings.Stripe.FailedToCreateLink'),
            });
        }
    };

    useEffect(() => {
        if (stripeStatus) {
            storeStripeStatus(stripeStatus);
        }
    }, [storeStripeStatus, stripeStatus]);

    useEffect(() => {
        if (
            connectStripeAccountId &&
            organizationId &&
            stripeAccountStatus === StripeAccountStatus.COMPLETED
        ) {
            const runUpdate = async () => {
                await updateStripeStatus({
                    variables: {
                        id: organizationId,
                        stripeStatus: STRIPE_STATUS.ENABLED,
                    },
                });
                setUiState(UIState.LOADING);
                await refetch();
                setUiState(UIState.READY);
            };
            runUpdate();
        }
    }, [
        connectStripeAccountId,
        organization,
        refetch,
        organizationId,
        updateStripeStatus,
        stripeAccountStatus,
    ]);

    return (
        <>
            <Group>
                <Group>
                    <Heading level={Level.h3}>{i18n.t('PaymentMethodSettings.Header')}</Heading>
                    <MarkDown>{i18n.t('PaymentMethodSettings.Description')}</MarkDown>
                </Group>
                <Group divider>
                    <MarkDown>{i18n.t('PaymentMethodSettings.DescriptionBefore')}</MarkDown>
                    {loading ? (
                        <LoadingWrapper>
                            <ProgressIndicator type={ProgressType.DONUT_LOADING} progress={50} />
                        </LoadingWrapper>
                    ) : (
                        <>
                            {stripeAccountId || stripeStatus === STRIPE_STATUS.ENABLED ? (
                                <CompanyItem
                                    logoIndex={STRIPE_INDEX}
                                    heading={i18n.t('PaymentMethodSettings.Stripe.Header')}
                                    description={<StripeStatusBadge status={stripeAccountStatus} />}
                                    primaryButtonText={i18n.t(
                                        'PaymentMethodSettings.Stripe.ActivatedText'
                                    )}
                                    onClick={doGenerateLink}
                                    activated
                                >
                                    <InfoWrapper>
                                        <Composite>
                                            <Icon
                                                icon="store"
                                                size={theme.spacingRaw(4)}
                                                color={theme.buttonTextColorDisabled}
                                            />
                                            <Text type={TextType.descriptionBold}>
                                                {stripeAccount?.name}
                                            </Text>
                                        </Composite>
                                        <Composite>
                                            <Text>{i18n.t('PaymentMethodSettings.Stripe.Id')}</Text>
                                            <Text color={theme.buttonTextColorDisabled}>
                                                {stripeAccountId}
                                            </Text>
                                        </Composite>
                                        <Composite>
                                            <Text>
                                                {i18n.t('PaymentMethodSettings.Stripe.Email')}
                                            </Text>
                                            <Text>{stripeAccount?.email}</Text>
                                        </Composite>
                                    </InfoWrapper>
                                </CompanyItem>
                            ) : (
                                <CompanyItem
                                    logoIndex={STRIPE_INDEX}
                                    heading={i18n.t('PaymentMethodSettings.Stripe.Header')}
                                    description={
                                        <Text type={TextType.descriptionSmall}>
                                            {i18n.t('PaymentMethodSettings.Stripe.Description')}
                                        </Text>
                                    }
                                    primaryButtonText={i18n.t(
                                        'PaymentMethodSettings.PrimaryButton'
                                    )}
                                    onClick={() => setStripeModalOpen(true)}
                                    activated={false}
                                />
                            )}
                        </>
                    )}
                </Group>
                <Group divider>
                    <MarkDown>
                        {i18n.t(
                            `PaymentMethodSettings.${
                                stripeEnabled ? 'DescriptionAfter' : 'DescriptionAfterDisabled'
                            }`
                        )}
                    </MarkDown>
                </Group>
                {stripeEnabled && (
                    <Table<ChargeSystem>
                        items={organization?.chargeSystems ?? []}
                        columnWidths={['40%', '30%', '30%']}
                        columnNames={[
                            i18n.t('PaymentMethodSettings.InvoicingList.ChargeSystem'),
                            i18n.t('PaymentMethodSettings.InvoicingList.Ability'),
                            <WithTooltip
                                text={i18n.t('PaymentMethodSettings.InvoicingList.FeeTooltip')}
                                key="informationTooltip"
                            >
                                <HeadingWithTooltip>
                                    {i18n.t('PaymentMethodSettings.InvoicingList.Fee')}{' '}
                                    <Icon
                                        icon="information"
                                        size={16}
                                        key="information"
                                        color={theme.textColor}
                                    />
                                </HeadingWithTooltip>
                            </WithTooltip>,
                        ]}
                        columns={[
                            ChargeSystemListItem,
                            InvoiceAbleListItem(onChangeInvoiceInfo, uiState),
                            FixedCostListItem(onChangeInvoiceInfo, uiState, currency),
                        ]}
                    />
                )}
            </Group>
            <Modal
                maxWidth="750px"
                padding="0"
                onClosePress={() => setStripeModalOpen(false)}
                showModal={stripeModalOpen}
                bodyContent={
                    <StripeModal
                        onClick={doGenerateLink}
                        onClose={() => setStripeModalOpen(false)}
                    />
                }
                actionContent={<></>}
            />
        </>
    );
};
