import { gql } from '@apollo/client';
import { Connector } from '../../../models';

export interface EventLogResponseType {
    connector: Connector;
}

export const GET_EVENT_LOG_URL = gql`
    query Connectors($chargeSystemId: ID!, $id: ID!) {
        connector(chargeSystemId: $chargeSystemId, id: $id) {
            id
            alias
            connectorNumber
            eventLogUrl
            chargePointId
        }
    }
`;
