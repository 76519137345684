import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Group, Heading, Level, SplitScreen } from '@defa/defa-component-library';
import ReactMarkdown from 'react-markdown';

import { BrandPanel } from '../../fractions/brand-panel';

import i18n from '../../i18n';
import { useQueryParam } from '../../utils/hooks';

export const Welcome: React.FunctionComponent = () => {
    const query = useQueryParam();
    const history = useHistory();
    const token = query.get('token');
    const adminId = query.get('adminId');

    return (
        <SplitScreen
            first={
                <Group>
                    <BrandPanel heading={i18n.t('Welcome.FlowHeader')} />
                    <Heading level={Level.h3}>{i18n.t('Welcome.SubHeader')}</Heading>
                </Group>
            }
            second={
                <Group minWidth="480px" maxWidth="500px">
                    <Group>
                        <Heading level={Level.h1}>{i18n.t('Welcome.Header')}</Heading>
                    </Group>
                    <ReactMarkdown>{i18n.t('Welcome.Description')}</ReactMarkdown>
                    <Group>
                        <Button
                            text={i18n.t('Welcome.Button')}
                            onClick={() =>
                                history.push(`/create-account?adminId=${adminId}&token=${token}`)
                            }
                        />
                    </Group>
                </Group>
            }
        />
    );
};
