import { styled } from '@defa/defa-component-library';

export const Root = styled.div``;

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    background-color: #fff;
    border-radius: ${({ theme }) => theme.spacing(3)};
    padding: ${({ theme }) => theme.spacing(4)};
    gap: ${({ theme }) => theme.spacing(2)};
`;