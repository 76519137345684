import { styled } from '@defa/defa-component-library';

export const Root = styled.div``;

export const GroupWrapper = styled.div`
    & > * {
        margin-right: 0 !important;
        margin-left: 20px !important;
        margin-bottom: 0 !important;
        margin-top: ${({ theme }) => theme.spacing(8)} !important;
    }
`;
