/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Heading,
    Level,
    Group,
    Table,
    Button,
    Size,
    Variant,
    ContextWindow,
    Composite,
} from '@defa/defa-component-library';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import i18n from '../../i18n';
import { ConnectorAccess, GroupAccess, User, UserPaymentTypeResponseData } from '../../models/user';
import { MenuItem } from '../../fractions/header';
import {
    AccessListItem,
    AccessPills,
    InviteListItem,
    PaymentTypeListItem,
} from '../../fractions/access-list';
import { AddUsersModal } from './add-users';
import { UserDiscountModal } from './user-discount';
import {
    GET_CHARGE_SYSTEM,
    DELETE_USERS,
    REVOKE_INVITED_USERS,
    RESEND_INVITE_USERS,
    ALTER_ACCESS_USERS,
    UPDATE_USER_PAYMENT_TYPE,
} from './users.queries';
import { ChargerAccessContextWindow } from '../../fractions/charger-access-context-window';
import { getSortedPrivateGroupsAndConnectors } from '../../utils/sorted-private-groups-and-connectors';
import { ChargeSystemGroupsAndConnectors } from '../../models/charge-system';

export const MoreButton = (onClick: (user?: User, e?: MouseEvent) => void) => (data: User) => {
    const { id, email, phoneNumber } = data;
    return (
        <Button
            size={Size.TINY}
            fillParent={false}
            variant={Variant.TERTIARY}
            icon="more"
            key={`${encodeURIComponent(email || phoneNumber || id)}-more`}
            onClick={(e) => onClick(data, e)}
        />
    );
};

function DiscountListItem(data: User) {
    const { discount, id } = data;
    const displayValue = discount ? `${discount}%` : '';
    return (
        <span
            key={`${encodeURIComponent(id)}-discount`}
            data-tid={`${encodeURIComponent(id)}-discount`}
        >
            {displayValue}
        </span>
    );
}

export function Users({ onModalCloseClick }: { onModalCloseClick: () => void }) {
    const { id } = useParams<{ id: string }>();

    const [userAccess, setUserAccess] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<User>();
    const [privateDataset, setPrivateDataset] = useState<ChargeSystemGroupsAndConnectors>({
        groups: [],
        connectors: [],
    });

    const [menuInvitePositioningElement, setMenuInvitePositioningElement] = useState<
        HTMLElement | undefined
    >(undefined);

    const [menuUserPositioningElement, setMenuUserPositioningElement] = useState<
        HTMLElement | undefined
    >(undefined);

    const [menuChargerAccessPositioningElement, setMenuChargerAccessPositioningElement] = useState<
        HTMLElement | undefined
    >(undefined);

    const [resendInviteUsers] = useMutation(RESEND_INVITE_USERS);
    const [revokeInvitedUsers] = useMutation(REVOKE_INVITED_USERS);
    const [deleteUsers] = useMutation(DELETE_USERS);
    const [alterAccessUsers] = useMutation(ALTER_ACCESS_USERS);

    const [showAddUsersModal, setShowAddUsersModal] = useState(false);
    const [showSetUserDiscountModal, setShowSetUserDiscountModal] = useState(false);

    const { refetch, data = { chargeSystem: {} } } = useQuery(GET_CHARGE_SYSTEM, {
        variables: { id },
        fetchPolicy: 'network-only',
    });

    const [updatePaymentType] = useMutation<UserPaymentTypeResponseData>(UPDATE_USER_PAYMENT_TYPE);

    const { chargeSystem } = data;
    const { invoicingInfo } = chargeSystem ?? {};
    const { invoicingActivated } = invoicingInfo ?? {};
    const defaultUsers = React.useMemo(() => [], []);
    const users = chargeSystem.users || defaultUsers;

    const invitedUsers = chargeSystem.invitedUsers || defaultUsers;

    useEffect(() => {
        setUserAccess(
            users
                .map((u: User) => ({ ...u }))
                .sort(
                    (a: User, b: User) =>
                        a?.name?.localeCompare(b?.name ?? '', navigator.language) ?? 0
                )
        );
    }, [users]);

    const onPillClick = (e: React.MouseEvent, user: User) => {
        console.log({ e, user });
    };

    const onInvitePillClick = (e: React.MouseEvent, user: User) => {
        console.log({ e, user });
    };

    const onMoreInviteClick = (user?: User, e?: MouseEvent) => {
        setMenuChargerAccessPositioningElement(undefined);
        setMenuUserPositioningElement(undefined);
        setSelectedUser(menuInvitePositioningElement ? undefined : user);
        const { target } = e || ({} as MouseEvent);
        setMenuInvitePositioningElement(
            menuInvitePositioningElement ? undefined : (target as HTMLElement)
        );
    };

    const onMoreUserClick = (user?: User, e?: MouseEvent) => {
        setMenuChargerAccessPositioningElement(undefined);
        setMenuInvitePositioningElement(undefined);
        setSelectedUser(menuUserPositioningElement ? undefined : user);
        const { target } = e || ({} as MouseEvent);
        const htmlTarget = target as HTMLElement;
        setMenuUserPositioningElement(
            menuUserPositioningElement && menuUserPositioningElement === htmlTarget
                ? undefined
                : htmlTarget
        );
    };

    const onResendInviteClick = useMemo(
        () => async () => {
            const currentUser = invitedUsers.find(
                (u: User) => u.phoneNumber === selectedUser?.phoneNumber
            );
            if (currentUser) {
                await resendInviteUsers({
                    variables: {
                        id,
                        invites: [
                            {
                                phoneNumber: selectedUser?.phoneNumber,
                                connectors:
                                    currentUser.connectors?.map((uac: ConnectorAccess) => ({
                                        accessLevel: uac.accessLevel,
                                        id: uac.data.id,
                                    })) || [],
                                groups:
                                    currentUser.groups?.map((uag: GroupAccess) => ({
                                        accessLevel: uag.accessLevel,
                                        id: uag.data.id,
                                    })) || [],
                            },
                        ],
                    },
                });
                await refetch();
            }
            setMenuInvitePositioningElement(undefined);
        },
        [id, resendInviteUsers, selectedUser, refetch, invitedUsers]
    );

    const onDiscountClick = useMemo(
        () => async () => {
            setMenuUserPositioningElement(undefined);
            setShowSetUserDiscountModal(true);
        },
        []
    );

    const setUseInvoicing = useCallback(
        async (useInvoicing) => {
            setMenuUserPositioningElement(undefined);
            await updatePaymentType({
                variables: {
                    id,
                    userId: selectedUser?.id,
                    useInvoicing,
                },
            });
            refetch();
        },
        [id, selectedUser, updatePaymentType, refetch]
    );

    const onDeleteInviteClick = useMemo(
        () => async () => {
            await revokeInvitedUsers({
                variables: {
                    chargeSystemId: id,
                    invites: [
                        {
                            phoneNumber: selectedUser?.phoneNumber,
                            connectors: [],
                            groups: [],
                        },
                    ],
                },
            });
            await refetch();
            setMenuInvitePositioningElement(undefined);
        },
        [revokeInvitedUsers, id, selectedUser, refetch]
    );

    const onDeleteUserClick = useMemo(
        () => async () => {
            await deleteUsers({
                variables: {
                    chargeSystemId: id,
                    users: [{ phoneNumber: selectedUser?.phoneNumber }],
                },
            });
            await refetch();
            setMenuUserPositioningElement(undefined);
        },
        [deleteUsers, id, selectedUser, refetch]
    );

    const onUpdateAccessClick = () => {
        setMenuChargerAccessPositioningElement(menuUserPositioningElement);
        setMenuUserPositioningElement(undefined);
    };
    const onChargerAccessSubmit = useMemo(
        () => async (currentGroups: GroupAccess[], currentConnectors: ConnectorAccess[]) => {
            await alterAccessUsers({
                variables: {
                    id,
                    users: [
                        {
                            id: selectedUser?.id,
                            connectors:
                                currentConnectors.map((uac: ConnectorAccess) => ({
                                    accessLevel: uac.accessLevel,
                                    id: uac.data.id,
                                })) || [],
                            groups:
                                currentGroups.map((uag: GroupAccess) => ({
                                    accessLevel: uag.accessLevel,
                                    id: uag.data.id,
                                })) || [],
                        },
                    ],
                },
            });
            await refetch();
            setMenuChargerAccessPositioningElement(undefined);
        },
        [alterAccessUsers, refetch, id, selectedUser]
    );

    useEffect(() => {
        const { groups } = chargeSystem;
        if (groups) {
            setPrivateDataset(getSortedPrivateGroupsAndConnectors(groups));
        }
    }, [chargeSystem]);

    return (
        <>
            <Group>
                <Composite>
                    <Heading level={Level.h2}>{i18n.t('ChargeSystemSettings.Users')}</Heading>
                    <Composite>
                        <Button
                            name="add-button"
                            size={Size.TINY}
                            text={i18n.t('ChargeSystemSettings.AddUsers')}
                            variant={Variant.TERTIARY}
                            icon="add"
                            iconUseMargin
                            fillParent={false}
                            onClick={() => setShowAddUsersModal(true)}
                        />
                        <Button
                            name="close-button"
                            size={Size.TINY}
                            fillParent={false}
                            variant={Variant.SECONDARY}
                            icon="close"
                            onClick={onModalCloseClick}
                        />
                    </Composite>
                </Composite>
            </Group>
            <Group>
                {invitedUsers?.length > 0 && (
                    <Table<User>
                        items={invitedUsers}
                        columnWidths={[undefined, '37%', '10%']}
                        columnNames={[
                            i18n.t('Users.ColumnInvited'),
                            i18n.t('Users.ColumnAccess'),
                            '',
                        ]}
                        columns={[
                            InviteListItem,
                            AccessPills<User>(onInvitePillClick),
                            MoreButton(onMoreInviteClick),
                        ]}
                    />
                )}
                <Table<User>
                    items={userAccess}
                    columnWidths={
                        invoicingActivated
                            ? [undefined, undefined, undefined, '37%', '10%']
                            : [undefined, undefined, '37%', '10%']
                    }
                    columnNames={[
                        i18n.t('Users.ColumnUser'),
                        invoicingActivated ? i18n.t('Users.ColumnPaymentType') : undefined,
                        i18n.t('Users.ColumnDiscount'),
                        i18n.t('Users.ColumnAccess'),
                        ' ',
                    ].filter(Boolean)}
                    columns={[
                        AccessListItem,
                        invoicingActivated ? PaymentTypeListItem : undefined,
                        DiscountListItem,
                        AccessPills<User>(onPillClick),
                        MoreButton(onMoreUserClick),
                    ].filter(Boolean)}
                />
            </Group>
            <ContextWindow
                positioningElement={menuInvitePositioningElement}
                show={!!menuInvitePositioningElement}
                onClosePress={() => setMenuInvitePositioningElement(undefined)}
                maxWidth="145px"
                autoHeight
                side="left"
                bodyContent={
                    <>
                        <MenuItem
                            color="black"
                            icon="refresh"
                            text={i18n.t('Users.ResendUserInvite')}
                            onClick={onResendInviteClick}
                        />
                        <MenuItem
                            color="red"
                            icon="close"
                            text={i18n.t('Users.DeleteUserInvite')}
                            onClick={onDeleteInviteClick}
                        />
                    </>
                }
            />
            <ContextWindow
                positioningElement={menuUserPositioningElement}
                show={!!menuUserPositioningElement}
                onClosePress={() => {
                    setMenuUserPositioningElement(undefined);
                }}
                maxWidth="190px"
                autoHeight
                side="left"
                bodyContent={
                    <>
                        <MenuItem
                            color="black"
                            icon="grid"
                            text={i18n.t('Users.UpdateAccess')}
                            onClick={onUpdateAccessClick}
                        />
                        <MenuItem
                            color="black"
                            icon="cashLayer"
                            text={i18n.t('Users.SetDiscount')}
                            onClick={onDiscountClick}
                        />
                        {invoicingActivated && selectedUser?.activeGroupInvoice ? (
                            <MenuItem
                                color="black"
                                icon="creditCard"
                                text={i18n.t('Users.SetCard')}
                                onClick={() => setUseInvoicing(false)}
                            />
                        ) : (
                            invoicingActivated && (
                                <MenuItem
                                    color="black"
                                    icon="invoice"
                                    text={i18n.t('Users.SetInvoice')}
                                    onClick={() => setUseInvoicing(true)}
                                />
                            )
                        )}
                        <MenuItem
                            color="red"
                            icon="close"
                            text={i18n.t('Users.DeleteUser')}
                            onClick={onDeleteUserClick}
                        />
                    </>
                }
            />
            <ChargerAccessContextWindow
                positioningElement={menuChargerAccessPositioningElement}
                onClosePress={() => setMenuChargerAccessPositioningElement(undefined)}
                onSubmit={onChargerAccessSubmit}
                userAccess={selectedUser}
                zIndex={10}
                dataset={privateDataset}
                side="left"
            />
            {showAddUsersModal && (
                <AddUsersModal
                    onClose={() => setShowAddUsersModal(false)}
                    onSubmit={() => {
                        refetch();
                        setShowAddUsersModal(false);
                    }}
                    dataset={privateDataset}
                />
            )}
            {showSetUserDiscountModal && (
                <UserDiscountModal
                    onClose={() => setShowSetUserDiscountModal(false)}
                    onSubmit={() => {
                        refetch();
                        setShowSetUserDiscountModal(false);
                    }}
                    selectedUser={selectedUser}
                />
            )}
        </>
    );
}
