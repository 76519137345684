import { styled } from '@defa/defa-component-library';

export const Grid = styled.div`
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: ${({ theme }) => theme.spacing(5)};
    background-color: ${({ theme }) => theme.firstSideBackgroundColor};
    padding: ${({ theme }) => theme.spacing(5)};
    border-radius: ${({ theme }) => theme.spacing(2)};

    &:empty {
        display: none;
    }
`;
