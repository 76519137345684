import { gql } from '@apollo/client';

export const GET_ADMINS = gql`
    query GetOrganization($id: ID!) {
        organization(id: $id) {
            admins {
                firstName
                lastName
                email
                phoneNumber
            }
        }
    }
`;

export const GET_ALERT_GROUPS = gql`
    query GetAlertGroups($id: ID!) {
        chargeSystem(id: $id) {
            alertGroup {
                phone
                sendSms
            }
        }
    }
`;

export const ADD_ALERT_GROUP = gql`
    mutation AddAlertGroup($id: ID!, $groupMembers: [AlertGroupMemberInput!]!) {
        chargeSystem(id: $id) {
            addAlertGroup(input: $groupMembers) {
                status
            }
        }
    }
`;
