import { gql } from '@apollo/client';

export const GET_ORGANIZATION = gql`
    query GetOrganization($id: ID!) {
        organization(id: $id) {
            chargeSystems {
                id
                name
            }
            stripeStatus
            stripeAccountId
            vatLiable
        }
    }
`;

export const CHARGING_SESSIONS = gql`
    query GetChargingSessions($id: ID!, $year: String!, $month: String!) {
        chargeSystem(id: $id) {
            sessions(input: { year: $year, month: $month }) {
                facility
                name
                user
                phone
                keyId
                chargerID
                connectorID
                startChargingDate
                startChargingTime
                endChargingDate
                endChargingTime
                sessionTimeMin
                energyConsumption
                sessionAmountIncVAT
                sessionAmountExVAT
                vatAmount
                paymentProvider
                paymentStatus
                cloudChargeTransactionFee
                payoutAmount
                stripeTransactionID
            }
        }
    }
`;

export const REPORTS_AVAILABLE = gql`
    query GetReportAvailable($id: ID!) {
        chargeSystem(id: $id) {
            reportAvailable {
                year
                month
            }
        }
    }
`;

export const REPORTS = gql`
    query GetReport($id: ID!, $year: String!, $month: String!) {
        chargeSystem(id: $id) {
            report(input: { year: $year, month: $month }) {
                total {
                    energyConsumption {
                        amount
                        unit
                    }
                    revenue {
                        amount
                        unit
                    }
                    occupationRate {
                        amount
                        unit
                    }
                    occupiedTime {
                        amount
                        unit
                    }
                    chargingSessions {
                        amount
                    }
                }
                trend {
                    energyConsumption {
                        amount
                        unit
                    }
                    revenue {
                        amount
                        unit
                    }
                }
                perDay {
                    energyConsumption {
                        date
                        amount
                        unit
                    }
                    revenue {
                        date
                        amount
                        unit
                    }
                }
                data {
                    chargerUsage {
                        alias
                        displayName
                        chargingSessions {
                            amount
                        }
                        occupiedTime {
                            amount
                            unit
                        }
                        energyConsumption {
                            amount
                            unit
                        }
                        revenue {
                            amount
                            unit
                        }
                    }
                    userUsage {
                        user
                        phone
                        chargingSessions {
                            amount
                            unit
                        }
                        occupiedTime {
                            amount
                            unit
                        }
                        energyConsumption {
                            amount
                            unit
                        }
                        revenue {
                            amount
                            unit
                        }
                    }
                    visitorRfidUsage {
                        keyId
                        description
                        chargingSessions {
                            amount
                            unit
                        }
                        occupiedTime {
                            amount
                            unit
                        }
                        energyConsumption {
                            amount
                            unit
                        }
                        revenue {
                            amount
                            unit
                        }
                    }
                }
            }
        }
    }
`;

export const GENERATE_STRIPE_LINK = gql`
    mutation generateStripeLink($input: GenerateStripeAccountLinkInput!) {
        organizations {
            generateStripeAccountLink(input: $input)
        }
    }
`;
