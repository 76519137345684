// \p{L}: Matches any Unicode letter (including international characters like Swedish and Danish).
// \p{N}: Matches any Unicode digit (numbers).
// [ ]: Matches a literal space.
// *: Ensures that empty string is ok, or that the string contains one or more of the allowed characters.
// ^ and $: Ensure the pattern matches the entire string.
// /u: Enables Unicode matching for international character sets.
const customerReferenceRegExp = new RegExp(/^[\p{L}\p{N} ]*$/u);

export const validateCustomerReference = (customerReference: string) =>
    customerReferenceRegExp.test(customerReference);
