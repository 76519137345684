import { gql } from '@apollo/client';

export const GET_ORGANIZATION_COUNTRY = gql`
    query GetOrganization($id: ID!) {
        organization(id: $id) {
            contactAddress {
                country
            }
        }
    }
`;

export const GET_COUNTRIES = gql`
    query GetCountries {
        countries {
            id
            openingHours
            phoneNumber
            email
        }
    }
`;
