import { Connector, EnergyMeterAvailable, Group } from '../models';

export const hasEnergyMeter = (energyMeterAvailable: EnergyMeterAvailable | undefined) =>
    energyMeterAvailable === EnergyMeterAvailable.PRESENT;

export const hasEnergyMeterInGroup = (group: Group | undefined) =>
    (group &&
        group?.connectors &&
        group?.connectors?.length > 0 &&
        group?.connectors?.every((c) => hasEnergyMeter(c.energyMeterAvailable))) ??
    false;

export const connectorHasEnergyMeter = (connector: Connector) =>
    hasEnergyMeter(connector.energyMeterAvailable);
