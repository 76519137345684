/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Text, Theme, useTheme } from '@defa/defa-component-library';
import { ResultsTable, Root, TextArea } from './import-multiple-users.styles';
import i18n from '../../i18n';
import { ImportedUser } from '../../screens/setup';
import { NestedArray } from '../../models/general';

function renderRow(rowData: string[], index: React.Key | null | undefined, theme: Theme) {
    return (
        <tr key={index}>
            {rowData
                .slice(0, 1)
                .map(
                    (
                        cell:
                            | boolean
                            | React.ReactChild
                            | React.ReactFragment
                            | React.ReactPortal
                            | null
                            | undefined,
                        cellIndex: number
                    ) => (
                        <td
                            style={{
                                backgroundColor:
                                    cellIndex % 2 === 0
                                        ? theme.simpleTableBorderColor
                                        : theme.inputMessageColor,
                            }}
                            key={`${index}-${cellIndex}`}
                        >
                            {cell}
                        </td>
                    )
                )}
        </tr>
    );
}

function parseData(data: string) {
    const rows = data.split('\n');
    const table = rows.map((row: string) => row.replace(/,/g, '\t').split('\t'));
    return table;
}

interface ImportMultipleUsersProps {
    localData: NestedArray<string>;
    setLocalData: (data: NestedArray<string>) => void;
    setMultipleUsers: (users: ImportedUser[]) => void;
}

export const ImportMultipleUsers = ({
    localData,
    setLocalData,
    setMultipleUsers,
}: ImportMultipleUsersProps) => {
    const theme = useTheme();
    const refreshTable = (data: string) => {
        const parsedData = parseData(data);
        setLocalData(parsedData);
        const users = parsedData.map((row) => ({
            phoneNumber: row[0],
        }));
        setMultipleUsers(users);
    };
    return (
        <Root>
            <Text>{i18n.t('ImportMultipleUsers.Description')}</Text>
            <TextArea
                placeholder={i18n.t('ImportMultipleUsers.Placeholder')}
                value={localData.join('\n')}
                onChange={(event) => refreshTable(event.target.value)}
            />
            <Text>{i18n.t('ImportMultipleUsers.HelperText')}</Text>
            <ResultsTable>
                <thead>
                    <tr>
                        <td>{i18n.t('ImportMultipleUsers.PhoneNumberLabel')}</td>
                    </tr>
                </thead>
                <tbody>{localData.map((row, index) => renderRow(row, index, theme))}</tbody>
            </ResultsTable>
        </Root>
    );
};
