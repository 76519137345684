import { gql } from '@apollo/client';

export const ALTER_ACCESS_KEYS = gql`
    mutation AlterAccessKeys($id: ID!, $rfidKeys: [UserAlterAccessInput!]!) {
        chargeSystem(id: $id) {
            alterAccessKeys(input: $rfidKeys) {
                status
            }
        }
    }
`;

export const DELETE_KEY = gql`
    mutation DeleteKey($id: ID!, $rfidKey: DeleteRfidKeyInput!) {
        chargeSystem(id: $id) {
            deleteRfidKey(input: $rfidKey) {
                status
            }
        }
    }
`;
