import { gql } from '@apollo/client';

export const UPDATE_USER_DISCOUNT = gql`
    mutation UserDiscunt($id: ID!, $userId: ID!, $discount: Int!) {
        chargeSystem(id: $id) {
            userDiscount(input: { userId: $userId, discount: $discount }) {
                status
            }
        }
    }
`;

export const GET_GROUPS = gql`
    query GetGroups($id: ID!) {
        chargeSystem(id: $id) {
            id
            groups {
                id
                name
                privateCharging
                discount
                connectors {
                    id
                    alias
                    displayName
                }
            }
        }
    }
`;
