import { gql } from '@apollo/client';

export const GROUP = gql`
    query Group($chargeSystemId: ID!, $id: ID!) {
        chargeSystem(id: $chargeSystemId) {
            group(id: $id) {
                privateCharging
                publicCharging
            }
        }
    }
`;
