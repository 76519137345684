import React, { useState, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Group, Heading, Level, Text, TextInput } from '@defa/defa-component-library';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Root } from './reset-password.styles';
import { BrandPanel } from '../../fractions/brand-panel';
import i18n from '../../i18n';
import { RESET_PASSWORD } from './reset-password.queries';

interface ResetPasswordProps extends RouteComponentProps<{}>, React.Props<{}> {}
export const ResetPassword = ({ match }: ResetPasswordProps) => {
    const [email, setEmail] = useState<string>('');
    const history = useHistory();
    const [resetPassword] = useMutation(RESET_PASSWORD);

    const valid = email.length > 0;

    const onEmailChange = useMemo(
        () => (text: string): void => {
            setEmail(text);
        },
        [setEmail]
    );

    const submit = useMemo(
        () => async () => {
            if (valid) {
                const { data } = await resetPassword({ variables: { email } });
                const { resetPassword: authResponse } = data;
                const { status } = authResponse;
                if (status === 200) {
                    history.push(`${match.url}/${email}`);
                }
            }
        },
        [email, valid, resetPassword, history, match.url]
    );

    return (
        <Root
            first={
                <Group>
                    <BrandPanel
                        heading={i18n.t('BrandPanel.Header')}
                        subHeading={i18n.t('BrandPanel.SubHeader')}
                    />
                </Group>
            }
            second={
                <Group minWidth="480px">
                    <Group>
                        <Heading level={Level.h1}>{i18n.t('ResetPassword.Header')}</Heading>
                        <Text>{i18n.t('ResetPassword.Description')}</Text>
                    </Group>

                    <Group>
                        <TextInput
                            label={i18n.t('ResetPassword.EmailLabel')}
                            name="email"
                            value={email}
                            onChange={onEmailChange}
                        />
                    </Group>
                    <Group>
                        <Button
                            text={i18n.t('ResetPassword.SubmitButton')}
                            disabled={!valid}
                            onClick={submit}
                            name="submit"
                        />
                    </Group>
                </Group>
            }
        />
    );
};
