import React from 'react';
import { Group, SplitScreen } from '@defa/defa-component-library';
import { useParams, Switch, Route } from 'react-router-dom';
import { BrandPanel } from '../../../fractions/brand-panel';
import { SetupMenu, Step } from '../../../fractions/setup-menu';
import { PrivateSettingsGroupInfo } from './private-settings-group-info/private-settings-group-info';
import { PrivateSettingsProps } from '../../../models/charge-system';
import { PrivateSettingsSameSettings } from './private-settings-same-settings';
import { PrivateSettingsDifferentSettings } from './private-settings-different-settings';
import { PrivateSettingsinfo } from './private-settings-info';
import i18n from '../../../i18n';

export function PrivateSettings({ match }: PrivateSettingsProps) {
    const { id: chargeSystemId } = useParams<{ id: string }>();
    return (
        <SplitScreen
            first={
                <Group>
                    <Group>
                        <BrandPanel
                            heading={i18n.t('BrandPanel.Header')}
                            subHeading={i18n.t('BrandPanel.SubHeader')}
                        />
                        <SetupMenu id={chargeSystemId} currentStep={Step.PRIVATE_SETTINGS} />
                    </Group>
                </Group>
            }
            secondSideLoading={false}
            second={
                <Switch>
                    <Route
                        path={`${match.path}/group-settings/different`}
                        component={PrivateSettingsDifferentSettings}
                    />
                    <Route
                        path={`${match.path}/group-settings/same`}
                        component={PrivateSettingsSameSettings}
                    />
                    <Route
                        path={`${match.path}/group-settings`}
                        component={PrivateSettingsGroupInfo}
                    />
                    <Route path={match.path} component={PrivateSettingsinfo} />
                </Switch>
            }
        />
    );
}
