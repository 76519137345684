import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    SplitScreen,
    Heading,
    Level,
    Button,
    Group,
    Text,
    TextType,
    Table,
    TextInput,
    Composite,
    Variant,
    Size,
    ContextWindow,
    Dropdown,
    InformationType,
} from '@defa/defa-component-library';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import parsePhoneNumberFromString from 'libphonenumber-js';
import ReactMarkdown from 'react-markdown';
import { BrandPanel } from '../../../fractions/brand-panel';
import i18n from '../../../i18n';

import { SetupMenu, Step } from '../../../fractions/setup-menu';
import { ConnectorAccess, GroupAccess, InviteUsersResponseData, User } from '../../../models/user';
import { ChargerAccessContextWindow } from '../../../fractions/charger-access-context-window';
import { AccessPills, InviteListItem } from '../../../fractions/access-list';
import { AccessDropdown } from '../../../fractions/access-dropdown';
import { GET_CHARGE_SYSTEM, INVITE_USERS, REVOKE_INVITED_USERS } from './add-users.queries';
import { MoreButton } from '../../charge-system-settings/users';
import { MenuItem } from '../../../fractions/header';
import { testPhonenumberPolicy } from '../../../utils/phonenumber-policy';
import { PhoneCountryCode } from '../../../models/phone';
import { PHONE_COUNTRY_CODES } from '../../../constants';
import { useCountry } from '../../../utils/hooks';
import { Group as GroupModel } from '../../../models/group';
import { Connector } from '../../../models/connector';
import { getSortedPrivateGroupsAndConnectors } from '../../../utils/sorted-private-groups-and-connectors';
import { AddMultipleUsersModal } from './add-multiple-users-modal';
import { Error } from './add-users.styles';

export interface ImportedUser {
    phoneNumber: string;
}

function emptyUser(id?: string, countryCode?: PhoneCountryCode) {
    return {
        id: id || '0',
        phoneNumber: '',
        phoneCountryCode: countryCode,
        groups: [],
        connectors: [],
    };
}

export function PhoneCountryCodeWrapper(
    setUserPhoneCountryCode: (phoneCountryCode: PhoneCountryCode, index: number) => void
) {
    return (user: User, index: number) => {
        const { id, phoneCountryCode } = user;
        return (
            <Dropdown<PhoneCountryCode>
                key={`phoneCountryCode-${id}`}
                name={`phone-country-codes-${id}`}
                label={i18n.t('CreateAccount.PhoneNumberCountry')}
                placeholder={i18n.t('CreateAccount.PhoneNumberCountry')}
                onChange={(code) => setUserPhoneCountryCode(code, index)}
                value={phoneCountryCode}
                items={PHONE_COUNTRY_CODES}
                keyExtractor={(item) => item.label}
                labelExtractor={(item) => item.label}
            />
        );
    };
}

function PhoneNumberWrapper(setUserPhoneNumber: (phoneNumber: string, index: number) => void) {
    return (user: User, index: number) => {
        const { id, phoneNumber, errorMessage } = user;

        const valid = phoneNumber === '' || testPhonenumberPolicy(phoneNumber || '');
        const phoneNumberMessage = valid ? undefined : i18n.t('PhoneNumberInvalid');
        return (
            <TextInput
                key={`phoneNumber-${id}`}
                name={`phoneNumber-${id}`}
                value={phoneNumber}
                slim
                message={errorMessage ? i18n.t(`AddUsers.${errorMessage}`) : phoneNumberMessage}
                label={i18n.t('AddUsers.PhoneNumberLabel')}
                onChange={(text) => setUserPhoneNumber(text, index)}
                informationType={errorMessage ? InformationType.ERROR : InformationType.INFO}
            />
        );
    };
}

export function AddUsers() {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [country] = useCountry();

    const [users, setUsers] = useState<User[]>([]);
    const [invited, setInvited] = useState<User[]>([]);
    const [privateDataset, setPrivateDataset] = useState<{
        groups: GroupModel[];
        connectors: Connector[];
    }>({ groups: [], connectors: [] });
    const [showAddUsersModal, setShowAddUsersModal] = useState<boolean>(false);

    const [menuPositioningElement, setMenuPositioningElement] = useState<HTMLElement | undefined>(
        undefined
    );

    const [menuInvitePositioningElement, setMenuInvitePositioningElement] = useState<
        HTMLElement | undefined
    >(undefined);

    const [selectedInviteUser, setSelectedInviteUser] = useState<User>();
    const [selectedUser, setSelectedUser] = useState<User>();

    const [error, setError] = useState<string>();

    const { refetch, data = { chargeSystem: {} }, loading } = useQuery(GET_CHARGE_SYSTEM, {
        variables: { id },
        fetchPolicy: 'network-only',
    });
    const [revokeInvitedUsers] = useMutation(REVOKE_INVITED_USERS);
    const [inviteUsers] = useMutation<InviteUsersResponseData>(INVITE_USERS);

    const { chargeSystem } = data;

    const currentCountryCode = useMemo(
        () => PHONE_COUNTRY_CODES.find((c) => c.country === country),
        [country]
    );

    const defaultUsers = useMemo(() => [emptyUser('0', currentCountryCode)], [currentCountryCode]);
    const initialUsers = chargeSystem.users || defaultUsers;
    const defaultInvited = useMemo(() => [], []);
    const initialInvited = chargeSystem.invitedUsers || defaultInvited;

    const onLastRowBlur = () => {
        const [lastUser] = users.slice(-1);
        console.log({ lastUser });
        if (lastUser && lastUser.phoneNumber !== '') {
            setUsers((current) => [
                ...current,
                emptyUser(`${users.length + 1}`, currentCountryCode),
            ]);
        }
    };

    function setUserPhoneCountryCode(phoneCountryCode: PhoneCountryCode, index: number) {
        setUsers((current) => [
            ...current.slice(0, index),
            {
                ...current[index],
                id: current[index]?.id || `${Math.random() * 10000}`,
                phoneCountryCode,
            },
            ...current.slice(index + 1),
        ]);
    }

    function setUserPhoneNumber(phoneNumber: string, index: number) {
        setUsers((current) => [
            ...current.slice(0, index),
            {
                ...current[index],
                id: current[index]?.id || `${Math.random() * 10000}`,
                phoneNumber,
            },
            ...current.slice(index + 1),
        ]);
    }

    function setUserPhoneNumberAndCountryCode(
        phoneCountryCode: PhoneCountryCode,
        phoneNumber: string,
        index: number
    ) {
        setUsers((current) => [
            ...current.slice(0, index),
            {
                ...current[index],
                id: current[index]?.id || `${Math.random() * 10000}`,
                phoneNumber,
                phoneCountryCode,
            },
            ...current.slice(index + 1),
        ]);
    }

    const setUserErrorMessage = useCallback(
        (errorMessage: string, phoneNumber?: string) => {
            const userIndex = users.findIndex((user) => user.phoneNumber === phoneNumber);
            const userFound = userIndex > -1;

            if (userFound) {
                setUsers((current) => [
                    ...current.slice(0, userIndex),
                    { ...current[userIndex], errorMessage },
                    ...current.slice(userIndex + 1),
                ]);
            }
        },
        [users]
    );

    const onDropdownClick = (e: React.MouseEvent, user: User) => {
        const { target } = e;
        setSelectedUser(user);
        setMenuPositioningElement(target as HTMLElement);
    };

    const onChargerAccessSubmit = (groups: GroupAccess[], connectors: ConnectorAccess[]) => {
        setUsers((current: User[]) => {
            const userIndex = current.findIndex((u) => u.id === selectedUser?.id);

            if (!selectedUser || userIndex === -1) {
                return current;
            }

            return [
                ...current.slice(0, userIndex),
                { ...selectedUser, groups, connectors },
                ...current.slice(userIndex + 1),
            ];
        });

        setMenuPositioningElement(undefined);
        setSelectedUser(undefined);
        onLastRowBlur();
    };

    const removeEmptyRows = (usersArray: User[]) =>
        usersArray.filter(
            (user) => user.phoneNumber && (user?.connectors?.length > 0 || user?.groups?.length > 0)
        );

    const onMoreClick = (user?: User, e?: MouseEvent) => {
        setSelectedInviteUser(menuInvitePositioningElement ? undefined : user);
        const { target } = e || ({} as MouseEvent);
        setMenuInvitePositioningElement(
            menuInvitePositioningElement ? undefined : (target as HTMLElement)
        );
    };

    const onSubmitModal = (multipleUsers: ImportedUser[]) => {
        const numberOfItems = users.length;
        setUsers((current) => removeEmptyRows(current));
        multipleUsers.forEach((user, index) => {
            const currentIndex = index + numberOfItems;
            const parsedPhoneNumber = parsePhoneNumberFromString(`+${user.phoneNumber}`);
            if (parsedPhoneNumber) {
                const countryCode = PHONE_COUNTRY_CODES.find(
                    (c) => c.value === parsedPhoneNumber.countryCallingCode
                );
                if (countryCode) {
                    setUserPhoneNumberAndCountryCode(
                        countryCode,
                        parsedPhoneNumber.nationalNumber,
                        currentIndex
                    );
                } else {
                    setUserPhoneNumber(user.phoneNumber, currentIndex);
                }
            } else {
                setUserPhoneNumber(user.phoneNumber, currentIndex);
            }
        });
    };

    const onDeleteInviteClick = useCallback(async () => {
        await revokeInvitedUsers({
            variables: {
                chargeSystemId: id,
                invites: [
                    {
                        phoneNumber: selectedInviteUser?.phoneNumber,
                        connectors: [],
                        groups: [],
                    },
                ],
            },
        });
        setMenuInvitePositioningElement(undefined);
        await refetch();
    }, [revokeInvitedUsers, id, selectedInviteUser, refetch]);

    const submit = useCallback(async () => {
        // Manually mapping fields to get rid of __typename automatically added to result from graphql query.
        const newUsers = users
            .filter(
                ({ phoneNumber, phoneCountryCode }) =>
                    phoneNumber && phoneNumber !== '' && phoneCountryCode?.value
            )
            .map(({ phoneNumber, phoneCountryCode, groups, connectors }) => ({
                phoneNumber: `${phoneCountryCode?.value}${phoneNumber}`,
                connectors:
                    connectors?.map((uac) => ({
                        accessLevel: uac.accessLevel,
                        id: uac.data.id,
                    })) || [],
                groups:
                    groups?.map((uag) => ({
                        accessLevel: uag.accessLevel,
                        id: uag.data.id,
                    })) || [],
            }));

        const response = await inviteUsers({
            variables: {
                id,
                users: newUsers,
            },
        });

        const inviteUsersResponse = response.data?.chargeSystem.inviteUsers;
        if (inviteUsersResponse?.status === 200) {
            history.push(`/charge-system/${id}/setup/keys`);
        } else if (inviteUsersResponse?.status === 406) {
            setError(undefined);
            const responseBody = inviteUsersResponse?.body?.map((elem) => ({
                ...elem,
                data: elem.data.map(
                    (item: string) => parsePhoneNumberFromString(`+${item}`)?.nationalNumber
                ),
            }));
            responseBody?.forEach((elem) =>
                elem.data.forEach((item) => setUserErrorMessage(elem.message, item))
            );
        } else {
            setError(i18n.t('AddUsers.Error'));
        }
    }, [history, id, inviteUsers, setUserErrorMessage, users]);

    useEffect(() => {
        if (initialUsers.length > 0) {
            setUsers(initialUsers.map((u: User) => ({ ...u, email: u.phoneNumber })));
        }
    }, [initialUsers]);

    useEffect(() => {
        if (initialInvited.length > 0) {
            setInvited(initialInvited);
        }
    }, [initialInvited]);

    useEffect(() => {
        const { groups } = chargeSystem || {};
        if (groups) {
            setPrivateDataset(getSortedPrivateGroupsAndConnectors(groups));
        }
    }, [chargeSystem]);

    return (
        <SplitScreen
            first={
                <Group>
                    <Group>
                        <BrandPanel
                            heading={i18n.t('AddUsers.FlowHeader')}
                            subHeading={i18n.t('BrandPanel.SubHeader')}
                        />
                        <SetupMenu id={id} currentStep={Step.USERS} />
                    </Group>
                </Group>
            }
            secondSideLoading={loading}
            second={
                <Group minWidth="640px">
                    <Group>
                        <Composite>
                            <Heading level={Level.h1}>{i18n.t('AddUsers.Header')}</Heading>
                            <Button
                                text={i18n.t('AddUsers.BulkImportLabel')}
                                onClick={() => setShowAddUsersModal(true)}
                                fillParent={false}
                                variant={Variant.SECONDARY}
                                size={Size.SMALL}
                                icon="add"
                                iconUseMargin
                            />
                        </Composite>
                        <ReactMarkdown>{i18n.t('AddUsers.Description')}</ReactMarkdown>
                    </Group>
                    <Group divider>
                        <Table<User>
                            items={users}
                            columnWidths={[undefined, '33%', '33%']}
                            columnNames={[
                                i18n.t('AddUsers.ColumnCountryCode'),
                                i18n.t('AddUsers.ColumnPhone'),
                                i18n.t('AddUsers.ColumnAccess'),
                            ]}
                            columnGap="small"
                            columns={[
                                PhoneCountryCodeWrapper(setUserPhoneCountryCode),
                                PhoneNumberWrapper(setUserPhoneNumber),
                                AccessDropdown(onDropdownClick),
                            ]}
                        />
                        <ChargerAccessContextWindow
                            positioningElement={menuPositioningElement}
                            onClosePress={() => setMenuPositioningElement(undefined)}
                            onSubmit={onChargerAccessSubmit}
                            userAccess={selectedUser}
                            dataset={privateDataset}
                            side="left"
                        />
                        <Text type={TextType.descriptionSmall}>
                            {i18n.t('AddUsers.AddMoreDescription')}
                        </Text>
                    </Group>
                    <Group>
                        {invited.length > 0 && (
                            <>
                                <Heading level={Level.h3}>
                                    {i18n.t('AddUsers.AlreadyInvited')}
                                </Heading>
                                <Table<User>
                                    items={invited}
                                    columnWidths={[undefined, '37%', '10%']}
                                    columnNames={[
                                        i18n.t('AddUsers.ColumnPhone'),
                                        i18n.t('AddUsers.ColumnAccess'),
                                        '',
                                    ]}
                                    columnGap="small"
                                    columns={[
                                        InviteListItem,
                                        AccessPills<User>(() => {}),
                                        MoreButton(onMoreClick),
                                    ]}
                                />
                            </>
                        )}
                        <ContextWindow
                            positioningElement={menuInvitePositioningElement}
                            onClosePress={() => {
                                setMenuInvitePositioningElement(undefined);
                            }}
                            show={!!menuInvitePositioningElement}
                            maxWidth="143px"
                            autoHeight
                            side="left"
                            bodyContent={
                                <>
                                    <MenuItem
                                        color="red"
                                        icon="close"
                                        text={i18n.t('AddUsers.DeleteUserInvite')}
                                        onClick={onDeleteInviteClick}
                                    />
                                </>
                            }
                        />
                    </Group>
                    {error && <Error>{error}</Error>}
                    <Group>
                        <Button name="submit-button" text={i18n.t('Continue')} onClick={submit} />
                    </Group>
                    <AddMultipleUsersModal
                        show={showAddUsersModal}
                        onClose={() => setShowAddUsersModal(false)}
                        onSubmit={(multipleKeys) => {
                            onSubmitModal(multipleKeys);
                            setShowAddUsersModal(false);
                        }}
                    />
                </Group>
            }
        />
    );
}
