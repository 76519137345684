import { styled } from '@defa/defa-component-library';

export const Image = styled.img`
    max-width: 100%;
    margin-right: ${({ theme }) => theme.spacing(4)}; ;
`;

interface RootProps {
    activated?: boolean;
}

export const Root = styled.div<RootProps>`
    display: flex;
    min-height: 72px;
    align-items: center;
    justify-content: space-between;
    ${(props) =>
        props.activated
            ? 'background-color: #F9F9FB; border: 1px solid #F9F9FB;'
            : 'border: 1px solid #e1e1e7;'}
    border-radius: 12px;
    padding: 24px;

    display: flex;
    align-items: stretch;
    flex: 1;

    & > :first-child {
        align-self: start;
    }
    & > :last-child {
        flex: 1;
        & button {
            margin-left: auto;
        }
    }
`;
