import { Theme, styled } from '@defa/defa-component-library';

import { OCCPStatus } from '../../models/connector';

const statusBackground = (theme: Theme, status: OCCPStatus, inactive: boolean) => {
    if (inactive) {
        return theme.firstSideBackgroundColor;
    }
    switch (status) {
        case OCCPStatus.OCPP16_CHARGING:
        case OCCPStatus.OCPP16_FINISHING:
        case OCCPStatus.OCPP20_OCCUPIED_CHARGING:
            return theme.colorPrimary;
        default:
            return theme.firstSideBackgroundColor;
    }
};

const statusBorder = (theme: Theme, status: OCCPStatus, inactive: boolean) => {
    if (inactive) {
        return 'none';
    }
    switch (status) {
        case OCCPStatus.OCPP15_AVAILABLE:
        case OCCPStatus.OCPP16_AVAILABLE:
        case OCCPStatus.OCPP20_AVAILABLE_IDLE:
            return `2px solid ${theme.successColor}`;
        case OCCPStatus.OCPP15_OCCUPIED:
        case OCCPStatus.OCPP16_PREPARING:
        case OCCPStatus.OCPP16_SUSPENDED_EV:
        case OCCPStatus.OCPP16_SUSPENDED_EVSE:
        case OCCPStatus.OCPP20_OCCUPIED_EVCONNECTED:
        case OCCPStatus.OCPP20_OCCUPIED_SUSPENDEDEVSE:
        case OCCPStatus.OCPP20_OCCUPIED_SUSPENDEDEV:
            return `2px solid ${theme.colorPrimary}`;
        case OCCPStatus.OCPP15_FAULTED:
        case OCCPStatus.OCPP16_FAULTED:
        case OCCPStatus.OCPP20_FAULTED:
            return `2px solid ${theme.errorColor}`;
        case OCCPStatus.OCPP15_RESERVED:
        case OCCPStatus.OCPP16_RESERVED:
        case OCCPStatus.OCPP20_RESERVED:
            return '2px solid #FA8316';
        default:
            return 'none';
    }
};

const statusTitleColor = (theme: Theme, status: OCCPStatus, inactive: boolean) => {
    if (inactive) {
        return theme.descriptionColor;
    }
    switch (status) {
        case OCCPStatus.OCPP16_CHARGING:
        case OCCPStatus.OCPP16_FINISHING:
        case OCCPStatus.OCPP20_OCCUPIED_CHARGING:
            return 'white';
        case OCCPStatus.OCPP15_FAULTED:
        case OCCPStatus.OCPP16_FAULTED:
        case OCCPStatus.OCPP20_FAULTED:
            return theme.errorColor;
        default:
            return theme.textColor;
    }
};

export const Root = styled.div``;

export const TopSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StatusBoxRoot = styled.div<{
    status: OCCPStatus;
    inactive: boolean;
}>`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: ${({ theme }) => theme.spacing(4)};
    padding: ${({ theme }) => theme.spacing(6)};
    background: ${({ theme, status, inactive }) => statusBackground(theme, status, inactive)};
    border: ${({ theme, status, inactive }) => statusBorder(theme, status, inactive)};
    min-height: 238px;
    box-sizing: border-box;
    position: relative;

    & > * {
        margin-bottom: ${({ theme }) => theme.spacing(6)};
        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    & ${TopSection} h4 {
        color: ${({ theme, status, inactive }) => statusTitleColor(theme, status, inactive)};
    }
    & ${TopSection} p {
        color: ${({ theme, status, inactive }) => statusTitleColor(theme, status, inactive)};
    }
`;

export const PillCotainer = styled.div`
    position: absolute;
    z-index: 10;
    bottom: ${({ theme }) => theme.spacing(6)};
    margin: auto;
`;
