import React, { useCallback } from 'react';
import { SideMenu, SideMenuItem, SideMenuItemProps } from '@defa/defa-component-library';
import { useHistory, useRouteMatch } from 'react-router-dom';
import i18n from '../../i18n';

export function SettingsSideMenuItem(props: SideMenuItemProps) {
    const { href, icon } = props;
    const match = useRouteMatch<{ id?: string }>(href || '');
    const history = useHistory();
    const { isExact } = match || {};
    const selected = isExact;
    const clickHandler = useCallback(() => {
        history.replace(href || '#');
    }, [history, href]);

    return <SideMenuItem icon={icon} {...props} onClick={clickHandler} selected={selected} />;
}

export function SettingsSideMenu({ isUserSettings = false }: { isUserSettings?: boolean }) {
    return (
        <SideMenu headerText={i18n.t('Settings.SideMenu.Header')} width="180px" margin="0 40px 0 0">
            {isUserSettings && (
                <SettingsSideMenuItem
                    tid="settings-side-menu-item-account"
                    icon="user"
                    text={i18n.t('Settings.SideMenu.Account')}
                    href="/settings/account"
                />
            )}
            {!isUserSettings && (
                <>
                    <SettingsSideMenuItem
                        tid="settings-side-menu-item-organization"
                        icon="organization"
                        text={i18n.t('Settings.SideMenu.Organization')}
                        href="/settings/organization"
                    />
                    <SettingsSideMenuItem
                        tid="settings-side-menu-item-administrators"
                        icon="users"
                        text={i18n.t('Settings.SideMenu.Administrators')}
                        href="/settings/administrators"
                    />
                    <SettingsSideMenuItem
                        tid="settings-side-menu-item-subscription"
                        icon="refresh"
                        text={i18n.t('Settings.SideMenu.Subscription')}
                        href="/settings/subscription"
                    />
                    <SettingsSideMenuItem
                        tid="settings-side-menu-item-payment-methods"
                        icon="cashLayer"
                        text={i18n.t('Settings.SideMenu.PaymentMethods')}
                        href="/settings/payment-methods"
                    />
                </>
            )}
        </SideMenu>
    );
}
