import React, { useEffect } from 'react';
import { Switch, Route, RouteComponentProps, useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Keys } from './keys';
import { SetupChargeSystemComplete } from '../setup-charge-system-complete';
import { AboutChargeSystem } from './about-charge-system';
import { Reset } from './reset';
import { AddUsers } from './add-users';
import { Groups } from './groups';
import { PrivateSettings } from './private-settings';
import { PublicSettings } from './public-settings';
import { Root } from './setup.styles';
import { GET_CHARGE_SYSTEM } from './setup.queries';
import { Loading } from '../loading';
import { ConfigurationStatus } from '../../models/organization';

interface PortalProps extends RouteComponentProps<{}>, React.Props<{}> {}

export const Setup = ({ match }: PortalProps) => {
    const { id: chargeSystemId } = useParams<{ id: string }>();
    const history = useHistory();
    const { data = { chargeSystem: {} }, loading } = useQuery(GET_CHARGE_SYSTEM, {
        variables: { id: chargeSystemId },
    });
    const { chargeSystem } = data;
    const { configurationStatus } = chargeSystem || { configurationStatus: '' };

    useEffect(() => {
        if (
            configurationStatus === ConfigurationStatus.COMPLETED &&
            process.env.REACT_APP_DEBUG_SETUP !== 'true'
        ) {
            history.replace(`/charge-system/${chargeSystemId}`);
        }
    }, [configurationStatus, history, chargeSystemId]);

    if (loading || configurationStatus.length < 1) {
        return <Loading />;
    }

    return (
        <Root>
            <Switch>
                <Route path={`${match.path}/about`} component={AboutChargeSystem} />
                <Route path={`${match.path}/reset`} component={Reset} />
                <Route path={`${match.path}/groups`} component={Groups} />
                <Route path={`${match.path}/private`} component={PrivateSettings} />
                <Route path={`${match.path}/public`} component={PublicSettings} />
                <Route path={`${match.path}/users/add`} component={AddUsers} />
                <Route path={`${match.path}/keys`} component={Keys} />
                <Route path={`${match.path}/complete`} component={SetupChargeSystemComplete} />
            </Switch>
        </Root>
    );
};
