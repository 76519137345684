import React, { useState, useMemo, useEffect } from 'react';
import {
    Button,
    Composite,
    ContextWindow,
    Group,
    Heading,
    Level,
    ProgressIndicator,
    ProgressType,
    Size,
    Table,
    Variant,
} from '@defa/defa-component-library';
import { useMutation, useQuery } from '@apollo/client';
import { MenuItem } from '../../fractions/header';
import { AccessListItem, InviteListItem } from '../../fractions/access-list';
import i18n from '../../i18n';
import { Admin } from '../../models/admin';
import { AddAdministratorsModal } from './add-administrators';
import { ADMIN, DELETE_ADMINS, GET_ADMINS, REVOKE_INVITE_ADMINS } from './administrator.queries';
import { useOrganization } from '../../utils/hooks';
import { LoadingWrapper } from './settings.styles';

export const MoreButton = (onClick: (email?: string, e?: MouseEvent) => void) => (data: Admin) => {
    const { id, email, phoneNumber, isSignedIn } = data;
    if (isSignedIn) {
        return <div key={email} />;
    }
    return (
        <Button
            name={`more-${email || phoneNumber}`}
            size={Size.TINY}
            fillParent={false}
            variant={Variant.TERTIARY}
            icon="more"
            key={`${encodeURIComponent(email || phoneNumber || id)}-more`}
            onClick={(e) => onClick(email, e)}
        />
    );
};

export const AdministratorSettings: React.FunctionComponent = () => {
    const [signedInAdmin, setSignedInAdmin] = useState<string>();
    const [selectedAdmin, setSelectedAdmin] = useState<string>();
    const [inviteMenuPositioningElement, setInviteMenuPositioningElement] = useState<
        HTMLElement | undefined
    >(undefined);
    const [adminMenuPositioningElement, setAdminMenuPositioningElement] = useState<
        HTMLElement | undefined
    >(undefined);
    const [organizationId] = useOrganization();
    const [revokeInvitedAdmins] = useMutation(REVOKE_INVITE_ADMINS);
    const [deleteAdmins] = useMutation(DELETE_ADMINS);

    const { data: AdminData } = useQuery(ADMIN);
    const { admin } = AdminData || { admin: {} };

    const { refetch, data, loading } = useQuery(GET_ADMINS, {
        variables: {
            id: organizationId,
        },
    });
    const { organization } = data || { organization: {} };

    const { invitedAdmins, admins } = organization || [];
    const [showAddAdministratorsModal, setShowAddAdministratorsModal] = useState(false);

    const mappedAdmins = admins?.map(({ id, email, firstName, lastName }: Admin) => ({
        id,
        name: firstName,
        firstName,
        lastName,
        email,
        isSignedIn: email === signedInAdmin,
    })) as Admin[];

    const onMoreInviteClick = (email?: string, e?: MouseEvent) => {
        setAdminMenuPositioningElement(undefined);
        setSelectedAdmin(inviteMenuPositioningElement ? undefined : email);
        const { target } = e || ({} as MouseEvent);
        setInviteMenuPositioningElement(
            inviteMenuPositioningElement ? undefined : (target as HTMLElement)
        );
    };

    const onMoreAdminClick = (email?: string, e?: MouseEvent) => {
        setInviteMenuPositioningElement(undefined);
        setSelectedAdmin(adminMenuPositioningElement ? undefined : email);
        const { target } = e || ({} as MouseEvent);
        setAdminMenuPositioningElement(
            adminMenuPositioningElement ? undefined : (target as HTMLElement)
        );
    };

    const onDeleteInviteClick = useMemo(
        () => async () => {
            await revokeInvitedAdmins({
                variables: {
                    id: organizationId,
                    invites: [
                        {
                            email: selectedAdmin,
                            organizations: [
                                {
                                    id: organizationId,
                                },
                            ],
                        },
                    ],
                },
            });
            await refetch();
            setInviteMenuPositioningElement(undefined);
        },
        [selectedAdmin, organizationId, revokeInvitedAdmins, refetch]
    );

    const onDeleteAdminClick = useMemo(
        () => async () => {
            await deleteAdmins({
                variables: {
                    id: organizationId,
                    admins: [{ email: selectedAdmin, organizations: [{ id: organizationId }] }],
                },
            });
            await refetch();
            setAdminMenuPositioningElement(undefined);
        },
        [deleteAdmins, organizationId, refetch, selectedAdmin]
    );
    useEffect(() => {
        if (admin) {
            setSignedInAdmin(admin.email);
        }
    }, [admin]);

    return (
        <Group>
            <Group>
                <Composite>
                    <Heading level={Level.h3}>{i18n.t('AdministratorSettings.Header')}</Heading>
                    <Button
                        name="add-button"
                        icon="add"
                        iconUseMargin
                        text={i18n.t('AdministratorSettings.AddAdministratorButton')}
                        size={Size.SMALL}
                        variant={Variant.SECONDARY}
                        fillParent={false}
                        onClick={() => setShowAddAdministratorsModal(true)}
                    />
                </Composite>
            </Group>
            {loading ? (
                <LoadingWrapper>
                    <ProgressIndicator type={ProgressType.DONUT_LOADING} progress={50} />
                </LoadingWrapper>
            ) : (
                <>
                    <Group>
                        {invitedAdmins?.length > 0 && (
                            <Table<Admin>
                                items={invitedAdmins}
                                columnWidths={[undefined, '10%']}
                                columnNames={[
                                    i18n.t('AdministratorSettings.TableColumnInvited'),
                                    '',
                                ]}
                                columns={[InviteListItem, MoreButton(onMoreInviteClick)]}
                            />
                        )}
                    </Group>
                    <Group>
                        {mappedAdmins?.length > 0 && (
                            <Table<Admin>
                                items={mappedAdmins}
                                columnWidths={[undefined, '10%']}
                                columnNames={[i18n.t('AdministratorSettings.TableColumnName'), '']}
                                columns={[AccessListItem, MoreButton(onMoreAdminClick)]}
                            />
                        )}
                    </Group>
                    <AddAdministratorsModal
                        show={showAddAdministratorsModal}
                        onClose={() => setShowAddAdministratorsModal(false)}
                        onSubmit={() => {
                            refetch();
                            setShowAddAdministratorsModal(false);
                        }}
                    />
                    <ContextWindow
                        positioningElement={inviteMenuPositioningElement}
                        onClosePress={() => {
                            setInviteMenuPositioningElement(undefined);
                        }}
                        show={!!inviteMenuPositioningElement}
                        maxWidth="143px"
                        autoHeight
                        side="left"
                        bodyContent={
                            <>
                                <MenuItem
                                    color="red"
                                    icon="close"
                                    text={i18n.t('AdministratorSettings.DeleteAdminInvite')}
                                    onClick={onDeleteInviteClick}
                                />
                            </>
                        }
                    />
                    <ContextWindow
                        positioningElement={adminMenuPositioningElement}
                        onClosePress={() => {
                            setAdminMenuPositioningElement(undefined);
                        }}
                        show={!!adminMenuPositioningElement}
                        maxWidth="150px"
                        autoHeight
                        side="left"
                        bodyContent={
                            <>
                                <MenuItem
                                    color="red"
                                    icon="close"
                                    text={i18n.t('AdministratorSettings.DeleteAdmin')}
                                    onClick={onDeleteAdminClick}
                                />
                            </>
                        }
                    />
                </>
            )}
        </Group>
    );
};
