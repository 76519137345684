import { ApolloQueryResult, OperationVariables, useMutation } from '@apollo/client';
import {
    Avatar,
    Button,
    ContextWindow,
    Group,
    Size,
    Table,
    useTheme,
    Variant,
} from '@defa/defa-component-library';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import i18n from '../../i18n';
import { ChargeSystemGroupsAndConnectors } from '../../models/charge-system';
import { ConnectorAccess, GroupAccess, Rfid } from '../../models/user';
import { localeObjectSort } from '../../utils/localeObjectSort';
import { AccessPills } from '../access-list';
import { ChargerAccessContextWindow } from '../charger-access-context-window';
import { MenuItem } from '../header';
import { ALTER_ACCESS_KEYS, DELETE_KEY } from './key-list.queries';
import { ModifyKeyModal } from '../../screens/charge-system-settings/modify-key';

interface KeyListProps {
    rfids: Rfid[];
    dataset: ChargeSystemGroupsAndConnectors;
    refetchKeys: (variables?: Partial<OperationVariables>) => Promise<ApolloQueryResult<any>>;
}

export function AddedRfidDescription(data: Rfid) {
    const theme = useTheme();
    const { name } = data;

    return (
        <Avatar
            key={`${encodeURIComponent(name)}-avatar-rfid`}
            user={data}
            tid={`access-${encodeURIComponent(name)}`}
            size={Size.SMALL}
            secondaryTextColor={theme.avatarSecondaryColor}
        />
    );
}

export const MoreButton = (onClick: (rfidKey?: Rfid, e?: MouseEvent) => void) => (data: Rfid) => {
    const { id, name } = data;

    return (
        <Button
            size={Size.TINY}
            fillParent={false}
            variant={Variant.TERTIARY}
            icon="more"
            key={`${encodeURIComponent(name || id)}-more`}
            onClick={(e) => onClick(data, e)}
        />
    );
};

export const KeyList = ({ rfids, dataset, refetchKeys }: KeyListProps) => {
    const { id } = useParams<{ id: string }>();
    const theme = useTheme();

    const [selectedRfidKey, setSelectedRfidKey] = useState<Rfid>();
    const [menuKeyPositioningElement, setMenuKeyPositioningElement] = useState<HTMLElement>();
    const [menuChargerAccessPositioningElement, setMenuChargerAccessPositioningElement] = useState<
        HTMLElement
    >();
    const [showModifyKeyModal, setShowModifyKeyModal] = useState(false);

    const [alterAccessKeys] = useMutation(ALTER_ACCESS_KEYS);
    const [deleteKey] = useMutation(DELETE_KEY);

    const sortedKeys = useMemo(() => localeObjectSort(rfids, 'name'), [rfids]);

    const onKeyPillClick = (e: React.MouseEvent, rfid: Rfid) => {
        console.log({ e, rfid });
    };

    const onMoreClick = (rfidKey?: Rfid, e?: MouseEvent) => {
        const { target } = e || ({} as MouseEvent);
        setSelectedRfidKey(menuKeyPositioningElement ? undefined : rfidKey);
        setMenuKeyPositioningElement(
            menuKeyPositioningElement ? undefined : (target as HTMLElement)
        );
    };

    const onDeleteKey = useCallback(async () => {
        if (selectedRfidKey) {
            await deleteKey({
                variables: {
                    id,
                    rfidKey: { id: selectedRfidKey.keys[0] },
                },
            });
            setMenuKeyPositioningElement(undefined);
            await refetchKeys();
        }
    }, [deleteKey, id, refetchKeys, selectedRfidKey]);

    const onUpdate = () => {
        setMenuKeyPositioningElement(undefined);
        if (selectedRfidKey) {
            setShowModifyKeyModal(true);
        }
    };

    const onUpdateAccessClick = () => {
        setMenuChargerAccessPositioningElement(menuKeyPositioningElement);
        setMenuKeyPositioningElement(undefined);
    };

    const onUpdateSubmit = () => {
        refetchKeys();
        setShowModifyKeyModal(false);
    };

    const onChargerAccessSubmit = useCallback(
        async (currentGroups: GroupAccess[], currentConnectors: ConnectorAccess[]) => {
            if (selectedRfidKey) {
                await alterAccessKeys({
                    variables: {
                        id,
                        rfidKeys: [
                            {
                                id: selectedRfidKey?.id,
                                connectors:
                                    currentConnectors.map((uac: ConnectorAccess) => ({
                                        accessLevel: uac.accessLevel,
                                        id: uac.data.id,
                                    })) || [],
                                groups:
                                    currentGroups.map((uag: GroupAccess) => ({
                                        accessLevel: uag.accessLevel,
                                        id: uag.data.id,
                                    })) || [],
                            },
                        ],
                    },
                });
                setMenuChargerAccessPositioningElement(undefined);
                await refetchKeys();
            }
        },
        [alterAccessKeys, id, refetchKeys, selectedRfidKey]
    );

    return (
        <Group>
            <Table<Rfid>
                items={sortedKeys}
                columnWidths={[undefined, undefined, '10%']}
                columnNames={[i18n.t('Rfid.ColumnKey'), i18n.t('Rfid.ColumnAccess'), '']}
                columns={[
                    AddedRfidDescription,
                    AccessPills<Rfid>(onKeyPillClick),
                    MoreButton(onMoreClick),
                ]}
                columnGap="small"
            />
            <ContextWindow
                positioningElement={menuKeyPositioningElement}
                onClosePress={() => {
                    setMenuKeyPositioningElement(undefined);
                }}
                show={!!menuKeyPositioningElement}
                maxWidth="max-content"
                autoHeight
                side="left"
                bodyContent={
                    <>
                        <MenuItem
                            color={theme.foreground.default}
                            icon="grid"
                            text={i18n.t('Rfid.UpdateAccess')}
                            onClick={onUpdateAccessClick}
                        />
                        <MenuItem
                            color={theme.foreground.default}
                            icon="pen"
                            text={i18n.t('Rfid.Edit')}
                            onClick={onUpdate}
                        />
                        <MenuItem
                            color={theme.foreground.error}
                            icon="close"
                            text={i18n.t('Rfid.DeleteKey')}
                            onClick={onDeleteKey}
                        />
                    </>
                }
            />
            <ChargerAccessContextWindow
                positioningElement={menuChargerAccessPositioningElement}
                onClosePress={() => setMenuChargerAccessPositioningElement(undefined)}
                onSubmit={onChargerAccessSubmit}
                userAccess={selectedRfidKey}
                zIndex={10}
                dataset={dataset}
                side="left"
                userCanBeOwner={false}
            />
            {showModifyKeyModal && selectedRfidKey && (
                <ModifyKeyModal
                    onClose={() => setShowModifyKeyModal(false)}
                    onSubmit={onUpdateSubmit}
                    selectedKey={selectedRfidKey}
                />
            )}
        </Group>
    );
};
