import React from 'react';
import {
    Button,
    Composite,
    Group,
    Heading,
    Icon,
    Level,
    Size,
    Variant,
} from '@defa/defa-component-library';
import { useHistory, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import i18n from '../../../../i18n';
import { PublicSettingsProps } from '../../../../models/charge-system';
import { Description } from '../public-settings.styles';

export function PublicSettingsInfo({ match }: PublicSettingsProps) {
    const history = useHistory();
    const { id: chargeSystemId } = useParams<{ id: string }>();
    const skipPublicSettings = () => {
        history.push(`/charge-system/${chargeSystemId}/setup/private`);
    };

    const setupPublicSettings = () => {
        history.push(`${match.url}/group-settings`);
    };
    return (
        <Group maxWidth="640px" flex align="center" justify="center">
            <Icon
                icon="chargingStation"
                background="#F2F2F4"
                backgroundSize={128}
                color="#4775E9"
                size={60}
            />
            <Group>
                <Heading level={Level.h1} textAlign="center">
                    {i18n.t('PublicSettingsInfo.Header')}
                </Heading>
                <Description>
                    <ReactMarkdown>{i18n.t('PublicSettingsInfo.Description')}</ReactMarkdown>
                </Description>
            </Group>
            <Composite>
                <Button
                    name="skip-button"
                    size={Size.LARGE}
                    variant={Variant.SECONDARY}
                    onClick={skipPublicSettings}
                >
                    {i18n.t('PublicSettingsInfo.SkipButton')}
                </Button>
                <Button
                    name="setup-button"
                    size={Size.LARGE}
                    variant={Variant.PRIMARY}
                    onClick={setupPublicSettings}
                >
                    {i18n.t('PublicSettingsInfo.SetupButton')}
                </Button>
            </Composite>
        </Group>
    );
}
