import { gql } from '@apollo/client';
import { Connector } from '../../../models';

export interface ConnectorResponse {
    connector: Connector;
}

export interface ConnectorMutationResponse {
    chargeSystem: {
        updateConnector: {
            status: boolean;
        };
    };
}

export const GET_CONNECTOR_DETAILS = gql`
    query Connectors($chargeSystemId: ID!, $id: ID!) {
        connector(chargeSystemId: $chargeSystemId, id: $id) {
            id
            alias
            displayName
            connectorNumber
            meterValue
            power
            type
        }
    }
`;

export const UPDATE_DISPLAY_NAME = gql`
    mutation updateDisplayName($id: ID!, $connector: ConnectorInput!) {
        chargeSystem(id: $id) {
            updateConnector(input: $connector) {
                status
            }
        }
    }
`;
