import { gql } from '@apollo/client';

export const UPDATE_KEY = gql`
    mutation UpdateKey($id: ID!, $input: UpdateRfidKeyInput!) {
        chargeSystem(id: $id) {
            updateRfidKey(input: $input) {
                status
            }
        }
    }
`;
