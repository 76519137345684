import { gql } from '@apollo/client';

export const GET_ORGANIZATION = gql`
    query GetOrganization($id: ID!) {
        organization(id: $id) {
            id
            chargeSystems {
                id
                connectors {
                    id
                }
            }
            subscription {
                subscriptionId
                startDate
                endDate
                nextInvoiceDate
                products {
                    productId
                    productName
                    productPrices {
                        productPriceId
                        price
                        priceInclVAT
                        billingPeriod
                        currency
                    }
                }
            }
        }
    }
`;
