import { gql } from '@apollo/client';

export const NEW_PASSWORD = gql`
    mutation newPassword($id: String!, $passwordToken: String!, $newPass: String!) {
        newPassword(input: { id: $id, passwordToken: $passwordToken, newPass: $newPass }) {
            status
        }
    }
`;

export const GET_PASSWORD_TOKEN_VALID = gql`
    query getPasswordTokenValid($id: String!, $passwordToken: String!) {
        getPasswordTokenValid(input: { id: $id, passwordToken: $passwordToken }) {
            status
        }
    }
`;
