import React, { useCallback } from 'react';
import { Text, TextType, Icon, IconName, Composite, useTheme } from '@defa/defa-component-library';
import { Root } from './icon-label.styles';

export function IconLabel({
    primaryText,
    iconName,
    onClick,
}: {
    primaryText: string;
    iconName: IconName;
    onClick?: () => void;
}) {
    const theme = useTheme();
    const onClickWrapper = useCallback(
        (event) => {
            event.preventDefault();
            if (onClick) onClick();
        },
        [onClick]
    );

    return (
        <Root onClick={onClickWrapper} href="#">
            <Composite>
                <Icon color={theme.textColor} icon={iconName} size={16} />
                <Text type={TextType.description}>{primaryText}</Text>
            </Composite>
        </Root>
    );
}
