import { SplitScreen, useTheme } from '@defa/defa-component-library';
import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch, matchPath } from 'react-router-dom';
import { Header } from '../../fractions/header';
import { SettingsSideMenu } from '../../fractions/settings-side-menu';
import { AccountSettings } from './account';
import { AdministratorSettings } from './administrator';
import { OrganizationSettings } from './organization';
import { PaymentMethodSettings } from './payment-method';
import { SubscriptionSettings } from './subscription';
import { Content, Root, Second } from './settings.styles';

export const Settings: React.FunctionComponent<RouteComponentProps> = ({ match, location }) => {
    const theme = useTheme();

    const isAccountSettingsMatch = matchPath(location.pathname, {
        path: '/settings/account',
    })?.isExact;

    return (
        <Root>
            <Header />
            <Content>
                <SplitScreen
                    first={<SettingsSideMenu isUserSettings={isAccountSettingsMatch} />}
                    firstSideBackgroundColor="transparent"
                    secondSideBackgroundColor="transparent"
                    padding={`${theme.spacing(10)} 0`}
                    firstContentAlign="end"
                    second={
                        <Second>
                            <Switch>
                                <Route
                                    path={`${match.path}/account`}
                                    component={() => <AccountSettings />}
                                />
                                <Route
                                    path={`${match.path}/organization`}
                                    component={() => <OrganizationSettings />}
                                />
                                <Route
                                    path={`${match.path}/administrators`}
                                    component={() => <AdministratorSettings />}
                                />
                                <Route
                                    path={`${match.path}/subscription`}
                                    component={() => <SubscriptionSettings />}
                                />
                                <Route
                                    path={`${match.path}/payment-methods`}
                                    component={() => <PaymentMethodSettings />}
                                />
                                <Redirect to={`${match.path}/account`} />
                            </Switch>
                        </Second>
                    }
                />
            </Content>
        </Root>
    );
};
