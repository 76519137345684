import React from 'react';
import {
    Illustration,
    Composite,
    Heading,
    Level,
    Text,
    TextType,
    useTheme,
} from '@defa/defa-component-library';
import { logos } from '../../assets/logos';
import { THEME_INDEX } from '../../constants';

export function BrandPanel({ heading, subHeading }: { heading?: string; subHeading?: string }) {
    const theme = useTheme();
    return (
        <Composite>
            <Composite>
                <Illustration src={logos[THEME_INDEX]} centerInContainer={false} />
                <Heading level={Level.h4}>{heading}</Heading>
                {subHeading && (
                    <Text type={TextType.descriptionSmall} color={theme.listHeaderColor}>
                        {subHeading}
                    </Text>
                )}
            </Composite>
        </Composite>
    );
}
