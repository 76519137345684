export enum ResetResponseCode {
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    FAILURE = 'FAILURE',
    UNREACHABLE = 'UNREACHABLE',
    REBOOT_REQUIRED = 'REBOOT_REQUIRED',
    NOT_SUPPORTED = 'NOT_SUPPORTED',
    PENDING = 'PENDING',
}

export interface Reset {
    status?: boolean;
    responseCode?: ResetResponseCode;
}

export interface ChargePoint {
    id?: string;
    chargePointVendor?: string;
    chargePointModel?: string;
    chargePointSerialNumber?: string;
    firmwareVersion?: string;
    loadBalancingActive?: boolean;
    protocol?: string;
    reset?: Reset;
}
