import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import {
    Button,
    Composite,
    Group,
    Heading,
    Level,
    Size,
    Variant,
    Text,
    Accordion,
    AccordionItem,
} from '@defa/defa-component-library';
import { EventLogResponseType, GET_EVENT_LOG_URL } from './event-log.queries';
import i18n from '../../../i18n';
import { localizeUnserializedDate } from '../../../utils/date';
import { DrillDown } from '../../../fractions/drilldown';
import { useAuthState } from '../../../auth';

interface MessageType {
    timestamp: string;
    ocppRequest?: object;
    ocppResponse?: object;
    operation: string;
}

export function EventLog({
    onChargerDetailsCloseClick,
}: {
    onChargerDetailsCloseClick: () => void;
}) {
    const { id, connectorAlias } = useParams<{ id: string; connectorAlias: string }>();
    const { data } = useQuery<EventLogResponseType>(GET_EVENT_LOG_URL, {
        variables: {
            chargeSystemId: id,
            id: connectorAlias,
        },
    });

    const { connector } = data ?? {};
    const { eventLogUrl, chargePointId } = connector ?? {};

    const [messageHistory, setMessageHistory] = useState<MessageType[]>([]);
    const [active, setActive] = useState('');

    const { token } = useAuthState();

    const { lastJsonMessage, readyState } = useWebSocket(
        `${eventLogUrl}/${chargePointId}`,
        { queryParams: { 'X-Authorization': token ?? '' } },
        eventLogUrl !== undefined
    );

    useEffect(() => {
        if (lastJsonMessage !== null) {
            setMessageHistory((prev) => [lastJsonMessage, ...prev]);
        }
    }, [lastJsonMessage, setMessageHistory]);

    return (
        <>
            <Group>
                <Composite>
                    <Heading level={Level.h2}>{i18n.t('EventLog.Header')}</Heading>
                    <Button
                        name="close-button"
                        icon="close"
                        fillParent={false}
                        variant={Variant.SECONDARY}
                        size={Size.TINY}
                        onClick={onChargerDetailsCloseClick}
                    />
                </Composite>
            </Group>
            <Group>
                {readyState !== ReadyState.OPEN && (
                    <Text>{i18n.t('EventLog.CouldNotConnect')}</Text>
                )}
                <Accordion selected={active} onChange={setActive}>
                    {messageHistory.map((message) => (
                        <AccordionItem
                            icon="clock"
                            key={message.timestamp}
                            title={message.operation}
                            value={message.timestamp}
                            description={`${localizeUnserializedDate(message.timestamp)}`}
                        >
                            <DrillDown data={message} />
                        </AccordionItem>
                    ))}
                </Accordion>
            </Group>
        </>
    );
}
