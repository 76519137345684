import { styled } from '@defa/defa-component-library';

export const Root = styled.div`
    display: flex;
    flex: 1;
    align-items: stretch;
    flex-direction: column;
    background-color: ${({ theme }) => theme.buttonColorDisabled};
    max-width: 100vw;

    @media print {
        & canvas {
            min-height: 100%;
            max-width: 100%;
            max-height: 100%;
            height: auto !important;
            width: auto !important;
        }
    }
`;

export const ChartContainer = styled.div`
    @media screen {
        min-height: 30vh;
        width: 100%;
    }
`;

export const Content = styled.section`
    display: grid;
    flex: 1;
    padding: 0 ${({ theme }) => theme.spacing(10)} ${({ theme }) => theme.spacing(10)};
    grid-template-columns: repeat(6, 1fr);
    gap: ${({ theme }) => theme.spacing(6)};

    & > * {
        grid-column: span 2;
    }
`;

export const RedirectErrorContent = styled.section`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    max-width: 100vw;
`;

export const ChartOneSectionWrapper = styled.div`
    grid-column: span 3;
`;

export const ChartTwoSectionWrapper = styled.div`
    grid-column: span 3;
`;

export const TableSectionWrapper = styled.div`
    grid-column: span 6;
`;

export const TopSection = styled.div`
    padding: ${({ theme }) => theme.spacing(5)} ${({ theme }) => theme.spacing(10)}
        ${({ theme }) => theme.spacing(10)} ${({ theme }) => theme.spacing(10)};
`;

export const InfoBoxRoot = styled.div<{ color: string }>`
    padding: ${({ theme }) => theme.spacing(2)};
    background-color: ${({ color }) => `${color}`};

    border-radius: ${({ theme }) => theme.spacing(2)};

    & > * {
        color: #fff;
    }

    @media print {
        display: none;
    }
`;
