import { styled } from '@defa/defa-component-library';

export const Content = styled.div`
    height: 60vh;
    display: flex;
    align-items: stretch;
    justify-items: stretch;
    overflow: hidden;
    width: 100%;
`;

export const Second = styled.div`
    display: flex;
    flex: 2 2;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;

    & > * {
        margin: 0;
        align-self: stretch;
        overflow: auto;
        flex: 1 0;
        padding: ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing(10)};
        box-sizing: border-box;
    }
    & > *:nth-child(1) {
        align-self: auto;
        overflow: visible;
        border-bottom: 1px solid ${({ theme }) => theme.sideMenuAlternativeBackground};
        padding: ${({ theme }) => theme.spacing(8)} ${({ theme }) => theme.spacing(10)}
            ${({ theme }) => theme.spacing(6)};
        flex: 0 1;
    }
    & > *:nth-child(3) {
        align-self: auto;
        justify-self: flex-end;
        overflow: visible;
        border-top: 1px solid ${({ theme }) => theme.sideMenuAlternativeBackground};
        flex: 0 1;
    }
`;
