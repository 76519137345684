import { gql } from '@apollo/client';

export const GET_CHARGEPOINT_ID = gql`
    query Connectors($chargeSystemId: ID!, $id: ID!) {
        connector(chargeSystemId: $chargeSystemId, id: $id) {
            chargePointId
        }
    }
`;

export const GET_CHARGEPOINT = gql`
    query ChargePoint($chargeSystemId: ID!, $chargePointId: ID!) {
        chargePoint(chargeSystemId: $chargeSystemId, chargePointId: $chargePointId) {
            id
            chargePointVendor
            chargePointModel
            chargePointSerialNumber
            firmwareVersion
            loadBalancingActive
            protocol
        }
    }
`;

export interface VariablesResponseType {
    readChargePointConfiguration?: {
        status: number;
        keys?: object;
    };
}

export const GET_CHARGEPOINT_VARIABLES = gql`
    query ChargePoint($chargePointId: ID!) {
        readChargePointConfiguration(chargePointId: $chargePointId) {
            keys
            status
        }
    }
`;

export const RESET_CHARGEPOINT = gql`
    mutation ChargePoint($chargeSystemId: ID!, $chargePointId: ID!, $type: ResetType!) {
        chargePoint(chargeSystemId: $chargeSystemId, chargePointId: $chargePointId) {
            reset(input: $type) {
                status
                responseCode
            }
        }
    }
`;
