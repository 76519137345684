import React, { useEffect, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import {
    ButtonOptions,
    Heading,
    Text,
    Loading,
    Level,
    Group,
    useTheme,
    getColorByInitials,
    getCurrencyFromCountry,
    Country,
} from '@defa/defa-component-library';
import { useHistory } from 'react-router-dom';
import { Root, Content, InfoBoxRoot } from './organizations.styles';

import { Header } from '../../fractions/header';
import i18n from '../../i18n';
import { useCountry, useCurrency, useOrganization, useStripeStatus } from '../../utils/hooks';
import { Organization, StripeStatus } from '../../models/organization';
import { ORGANIZATIONS } from './organizations.queries';

function OrganizationAvatar({ description }: { description: string }) {
    const theme = useTheme();
    const initials = description?.substring(0, 2) ?? '??';
    const color = getColorByInitials(initials, theme);
    return (
        <InfoBoxRoot color={color}>
            <Heading level={Level.h4}>{initials.toLocaleUpperCase()}</Heading>
        </InfoBoxRoot>
    );
}

export const Organizations: React.FunctionComponent = () => {
    const theme = useTheme();
    const { refetch, loading, error, data } = useQuery(ORGANIZATIONS);
    const [selectedOrganization, setOrganization] = useOrganization();
    const [, setStripeStatus] = useStripeStatus();
    const [, setCurrency] = useCurrency();
    const [, setCountry] = useCountry();
    const history = useHistory();
    const { organizations } = data || {};

    const setCurrencyByOrganizationId = useCallback(
        (id: string) => {
            const currentOrganization = organizations?.find((org: Organization) => org.id === id);
            if (currentOrganization) {
                setCurrency(getCurrencyFromCountry(currentOrganization.contactAddress?.country));
                setCountry(currentOrganization.contactAddress?.country as Country);
            }
        },
        [organizations, setCurrency, setCountry]
    );

    const selectOrganization = useCallback(
        (org: Organization) => {
            setOrganization(org.id as string);
            setStripeStatus(org.stripeStatus as StripeStatus);
            setCurrencyByOrganizationId(org.id as string);
            history.push('/');
        },
        [history, setOrganization, setCurrencyByOrganizationId, setStripeStatus]
    );

    useEffect(() => {
        if (organizations?.length === 0) {
            history.push('/no-organization');
            return;
        }
        if (organizations?.length === 1) {
            selectOrganization(organizations[0]);
        }
    }, [history, organizations, selectOrganization]);

    const click = (org: Organization) => () => {
        selectOrganization(org);
    };

    useEffect(() => {
        refetch();
    }, [refetch]);

    return (
        <Root>
            <Header />
            {loading && <Loading />}
            {error && <p>ERROR...</p>}
            {!loading && (
                <>
                    <Group tight verticalMargin={theme.spacing(10)}>
                        <Heading level={Level.h1}>{i18n.t('Organizations.Header')}</Heading>
                        <Text>{i18n.t('Organizations.Description')}</Text>
                    </Group>
                    <Content>
                        {organizations?.map((org: Organization) => (
                            <ButtonOptions
                                name={`${org.name}-button`}
                                selected={selectedOrganization === org.id}
                                title={<Heading level={Level.h4}>{org.name || org.id}</Heading>}
                                description={
                                    <Text>
                                        {i18n.t('Organinizations.NumberOfChargeSystemsLabel', {
                                            count: org.chargeSystems?.length,
                                        })}
                                    </Text>
                                }
                                key={org.id}
                                onClick={click(org)}
                            >
                                <OrganizationAvatar description={org.name || ''} />
                            </ButtonOptions>
                        ))}
                    </Content>
                </>
            )}
        </Root>
    );
};
