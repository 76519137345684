import React, { useEffect, useState } from 'react';
import {
    Heading,
    Level,
    Group,
    Text,
    TextType,
    useTheme,
    ActionRow,
    Button,
    Table,
    Avatar,
    Size,
    Switch,
    Composite,
    Variant,
} from '@defa/defa-component-library';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { AdminstratorsContainer, TableWrapper } from './sms-alerts.styles';
import i18n from '../../i18n';
import { Admin } from '../../models/admin';
import { useOrganization } from '../../utils/hooks';
import { ADD_ALERT_GROUP, GET_ADMINS, GET_ALERT_GROUPS } from './sms-alerts.queries';

function AdminListItem(data: Admin) {
    const { id, phoneNumber, firstName, lastName } = data;
    const name = `${firstName ?? ''} ${lastName ?? ''}`;
    const theme = useTheme();
    return (
        <Avatar
            key={`${encodeURIComponent(phoneNumber || id)}-admin-avatar-list`}
            user={{ phoneNumber, name }}
            tid={`invite-${encodeURIComponent(phoneNumber || id)}`}
            size={Size.SMALL}
            secondaryTextColor={theme.descriptionColor}
        />
    );
}

export const AdminSwitch = (onChange: (phoneNumber?: string, enabled?: boolean) => void) => (
    data: Admin
) => {
    const { id, phoneNumber, hasSmsAlerts } = data;
    return (
        <Switch
            key={`${encodeURIComponent(phoneNumber || id)}-admin-avatar-switch`}
            onChange={(enabled: boolean) => onChange(phoneNumber, enabled)}
            checked={hasSmsAlerts}
            name={phoneNumber || ''}
        />
    );
};

export function SmsAlerts({ onModalCloseClick }: { onModalCloseClick: () => void }) {
    const theme = useTheme();
    const [admins, setAdmins] = useState<Admin[]>([]);
    const { id } = useParams<{ id: string }>();
    const [organizationId] = useOrganization();

    const { data: AdminData } = useQuery(GET_ADMINS, {
        variables: {
            id: organizationId,
        },
    });
    const { organization } = AdminData || { organization: {} };
    const { admins: fetchedAdmins } = organization || [];
    const { data: AlertGroupData } = useQuery(GET_ALERT_GROUPS, {
        variables: {
            id,
        },
    });
    const [addAlertGroup] = useMutation(ADD_ALERT_GROUP);
    const { chargeSystem } = AlertGroupData || { chargeSystem: {} };
    const { alertGroup } = chargeSystem || [];

    useEffect(() => {
        const smsAdmins =
            fetchedAdmins
                ?.map((fa: Admin) => ({
                    ...fa,
                    hasSmsAlerts:
                        alertGroup?.find((ag: any) => ag.phone === fa.phoneNumber)?.sendSms ||
                        false,
                }))
                .filter((fa: Admin) => fa.phoneNumber) || [];
        setAdmins(smsAdmins);
    }, [fetchedAdmins, alertGroup]);

    const adminOnClick = (phoneNumber?: string, enabled?: boolean) => {
        setAdmins((current) => {
            const indexOfAdmin = current.findIndex((admin) => admin.phoneNumber === phoneNumber);
            return [
                ...current.slice(0, indexOfAdmin),
                { ...current[indexOfAdmin], hasSmsAlerts: enabled },
                ...current.slice(indexOfAdmin + 1),
            ];
        });
    };

    const onSubmit = async () => {
        await addAlertGroup({
            variables: {
                id,
                groupMembers: admins.map((admin) => ({
                    phone: admin.phoneNumber,
                    sendSms: admin.hasSmsAlerts,
                    availability: 'ALWAYS',
                })),
            },
        });
        onModalCloseClick();
    };

    return (
        <>
            <Group>
                <Composite>
                    <Heading level={Level.h2}>{i18n.t('SmsAlerts.Header')}</Heading>
                    <Button
                        name="close-button"
                        size={Size.TINY}
                        fillParent={false}
                        variant={Variant.SECONDARY}
                        icon="close"
                        onClick={onModalCloseClick}
                    />
                </Composite>
            </Group>
            <Group>
                <Group>
                    <Text type={TextType.description} color={theme.descriptionColor}>
                        {i18n.t('SmsAlerts.Description')}
                    </Text>
                    <AdminstratorsContainer>
                        <Text type={TextType.descriptionBold}>
                            {i18n.t('SmsAlerts.AdministratorsHeader')}
                        </Text>
                        <TableWrapper>
                            <Table<Admin>
                                items={admins}
                                columnWidths={[undefined, '10%']}
                                columnNames={[]}
                                columns={[AdminListItem, AdminSwitch(adminOnClick)]}
                                noChildrenMargin
                            />
                        </TableWrapper>
                    </AdminstratorsContainer>
                </Group>
            </Group>
            <ActionRow>
                <Button
                    name="submit-button"
                    text={i18n.t('Save')}
                    onClick={onSubmit}
                    size={Size.SMALL}
                />
            </ActionRow>
        </>
    );
}
