import React from 'react';
import { SideMenu, useTheme } from '@defa/defa-component-library';
import { useParams } from 'react-router-dom';
import i18n from '../../i18n';
import { SelectableSettingsSideMenuItem } from '../group-settings-side-menu';

export function ChargerDetailsSideMenu() {
    const { id: chargeSystemId, connectorAlias } = useParams<{
        id: string;
        connectorAlias: string;
    }>();
    const rootUrl = `/charge-system/${chargeSystemId}/charger/${connectorAlias}/details`;
    const theme = useTheme();
    return (
        <SideMenu
            headerText={i18n.t('ChargerDetailsSideMenu.Header')}
            width="fit-content"
            padding={`${theme.spacing(9)} ${theme.spacing(6)}`}
            background={theme.sideMenuAlternativeBackground}
        >
            <SelectableSettingsSideMenuItem
                tid="charger-details-side-menu-connector"
                icon="information"
                text={i18n.t('ChargerDetailsSideMenu.Connector')}
                href={`${rootUrl}/connector`}
            />
            <SelectableSettingsSideMenuItem
                tid="charger-details-side-menu-status-log"
                icon="information"
                text={i18n.t('ChargerDetailsSideMenu.StatusLog')}
                href={`${rootUrl}/status-log/`}
            />
            <SelectableSettingsSideMenuItem
                tid="charger-details-side-menu-charge-point"
                icon="information"
                text={i18n.t('ChargerDetailsSideMenu.ChargePoint')}
                href={`${rootUrl}/charge-point`}
            />
            <SelectableSettingsSideMenuItem
                tid="charger-details-side-menu-event-log"
                icon="clock"
                text={i18n.t('ChargerDetailsSideMenu.EventLog')}
                href={`${rootUrl}/event-log/`}
            />
        </SideMenu>
    );
}
