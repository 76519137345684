import { gql } from '@apollo/client';
import { ChargeSystem } from '../../models';

export interface ChargeSystemReponseType {
    chargeSystem: ChargeSystem;
}

export const GET_CHARGE_SYSTEM = gql`
    query GetChargeSystem($id: ID!, $groupId: ID!) {
        chargeSystem(id: $id) {
            group(id: $groupId) {
                privateCharging
                privateChargingSettings {
                    billingMethod
                    billingInterval
                    tariff {
                        type
                        fixedCost
                        pricePerKwh
                        pricePerHour
                        hourDivisor
                    }
                }
                connectors {
                    id
                    energyMeterAvailable
                    meterValue
                }
            }
        }
    }
`;

export const UPDATE_CHARGE_SYSTEM = gql`
    mutation UpdateChargeSystem(
        $id: ID!
        $groupId: ID!
        $privateCharging: ChargingTypeState
        $billingMethod: BillingMethod
        $billingInterval: BillingInterval
        $tariff: TariffInput
    ) {
        chargeSystem(id: $id) {
            group(id: $groupId) {
                update(
                    input: {
                        privateChargingSettings: {
                            billingMethod: $billingMethod
                            billingInterval: $billingInterval
                            tariff: $tariff
                        }
                        privateCharging: $privateCharging
                    }
                ) {
                    privateChargingSettings {
                        billingMethod
                        billingInterval
                        tariff {
                            type
                            fixedCost
                            pricePerKwh
                            pricePerHour
                            hourDivisor
                        }
                    }
                    privateCharging
                }
            }
        }
    }
`;
