import { gql } from '@apollo/client';

export const ADMIN = gql`
    query {
        admin {
            id
            email
            firstName
            lastName
            phoneNumber
        }
    }
`;

export const UPDATE_ADMIN = gql`
    mutation updateAdmin(
        $firstName: String!
        $lastName: String!
        $phoneNumber: String!
        $email: String!
    ) {
        admin {
            update(
                input: {
                    firstName: $firstName
                    lastName: $lastName
                    phoneNumber: $phoneNumber
                    email: $email
                }
            ) {
                id
                firstName
                lastName
                phoneNumber
                email
            }
        }
    }
`;

export const UPDATE_PASSWORD = gql`
    mutation changePassword($currentPassword: String!, $newPassword: String!) {
        changePassword(input: { currentPassword: $currentPassword, newPassword: $newPassword }) {
            status
        }
    }
`;
