import { styled } from '@defa/defa-component-library';

export const Root = styled.div`
    height: 328px;
    overflow: auto;

    & > * {
        margin-left: 0;
        margin-right: 0;
    }
`;

export const Error = styled.div`
    color: ${({ theme }) => theme.errorColor};
    margin-bottom: ${({ theme }) => theme.spacing(8)};
`;
