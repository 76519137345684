import React, { useEffect, useState, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import ReactMarkdown from 'react-markdown';
import {
    SplitScreen,
    Heading,
    Level,
    TextInput,
    Button,
    Group,
    ActionRow,
    RadioList,
    Dropdown,
    Suggest,
    Loading,
    SuggestType,
    RadioDefinition,
    Composite,
    Size,
    Variant,
} from '@defa/defa-component-library';
import { useHistory, useParams } from 'react-router-dom';
import { BrandPanel } from '../../../fractions/brand-panel';
import { MemoizedMap } from '../../../fractions/map';

import i18n from '../../../i18n';
import { SetupMenu, Step } from '../../../fractions/setup-menu';
import {
    GET_CHARGE_SYSTEM,
    UPDATE_CHARGE_SYSTEM,
    GET_CHARGE_SYSTEM_CATEGORIES,
} from './about-charge-system.queries';

type ChargeSystemCategoryID =
    | 'CUSTOMER_PARKING'
    | 'PUBLIC_CHARGING'
    | 'RESIDENCE'
    | 'TRANSPORT'
    | 'WORKPLACE'
    | 'OTHER';

const ChargeSystemCategoryOrder = {
    RESIDENCE: 10,
    WORKPLACE: 20,
    TRANSPORT: 30,
    CUSTOMER_PARKING: 40,
    PUBLIC_CHARGING: 50,
    OTHER: 60,
};

interface ChargeSystemCategory {
    id: ChargeSystemCategoryID;
    item: string[];
}
const getIconFromCategory = (category: ChargeSystemCategoryID) => {
    const categories = {
        CUSTOMER_PARKING: 'parkingSquare',
        PUBLIC_CHARGING: 'chargingEV',
        RESIDENCE: 'house',
        TRANSPORT: 'bus',
        WORKPLACE: 'building',
        OTHER: '',
    };
    return categories[category];
};

const defaultLatLng = { latitude: 57.69723160180292, longitude: 11.97965288732344 };

function Wizard({
    children,
    id,
    loading = false,
}: {
    children: React.ReactNode;
    loading?: boolean;
    id: string;
}) {
    return (
        <SplitScreen
            first={
                <Group>
                    <Group>
                        <BrandPanel
                            heading={i18n.t('AboutChargeSystem.FlowHeader')}
                            subHeading={i18n.t('BrandPanel.SubHeader')}
                        />
                        <SetupMenu id={id} currentStep={Step.ABOUT_CHARGE_SYSTEM} />
                    </Group>
                </Group>
            }
            secondSideLoading={loading}
            second={
                <Group minWidth="480px" maxWidth="640px">
                    {children}
                </Group>
            }
        />
    );
}

function EditMode({
    children,
    onSubmit,
    loading,
    id,
}: {
    children: React.ReactNode;
    onSubmit?: () => void;
    loading?: boolean;
    id: string;
}) {
    console.log({ onSubmit, id });
    return <>{loading ? <Loading /> : <>{children}</>}</>;
}

export function AboutChargeSystem({
    editMode,
    onSubmit,
    onModalCloseClick,
    headingLevel = Level.h1,
    buttonSize = Size.NORMAL,
}: {
    editMode?: boolean;
    onSubmit?: () => void;
    onModalCloseClick?: () => void;
    headingLevel: Level;
    buttonSize: Size;
}) {
    const { id } = useParams<{ id: string }>();

    const history = useHistory();

    const { loading, data = { chargeSystem: {} } } = useQuery(GET_CHARGE_SYSTEM, {
        variables: { id },
        fetchPolicy: 'network-only',
    });
    const [updateChargeSystem, { loading: loadingUpdateChargeSystem }] = useMutation(
        UPDATE_CHARGE_SYSTEM
    );

    const { data: ChargeSystemCategories } = useQuery(GET_CHARGE_SYSTEM_CATEGORIES);
    const { chargeSystemCategories } = ChargeSystemCategories || { chargeSystemCategories: [] };

    const {
        name: initialName,
        addressDetails: initialAddressDetails,
        category: initialCategory,
        subCategory: initialSubCategory,
    } = data.chargeSystem;

    const [name, setName] = useState<string>();
    const [addressDetails, setAddressDetails] = useState<SuggestType>();
    const [selectedCategory, setSelectedCategory] = useState<string>();
    const [selectedSubCategory, setSelectedSubCategory] = useState<string>();
    const [sortedChargeSystemCategories, setSortedChargeSystemCategories] = useState<
        ChargeSystemCategory[]
    >();

    const {
        streetNumber,
        address,
        zipCode,
        city,
        country,
        latitude,
        longitude,
    } = addressDetails ?? { ...defaultLatLng };

    const invalid = !(
        name &&
        zipCode &&
        address &&
        streetNumber &&
        city &&
        country &&
        selectedCategory &&
        (selectedCategory === 'OTHER' || selectedSubCategory)
    );

    useEffect(() => {
        if (!latitude || !longitude) {
            setAddressDetails({ ...addressDetails, ...defaultLatLng });
        }
    }, [latitude, longitude, addressDetails, setAddressDetails]);

    useEffect(() => {
        setName(initialName);
        setAddressDetails(initialAddressDetails);
        setSelectedCategory(initialCategory);
        setSelectedSubCategory(initialSubCategory);
    }, [initialName, initialAddressDetails, initialCategory, initialSubCategory]);

    const updateAddressDetailsPart = (newDetails: Partial<SuggestType>) =>
        setAddressDetails((currentAddressDetails) => ({
            ...currentAddressDetails,
            ...newDetails,
        }));

    const onNameChanged = (newName: string) => setName(newName);

    const onAddressChanged = (newAddress: string) => {
        updateAddressDetailsPart({ address: newAddress });
    };
    const onZipCodeChanged = (newZipCode: string) =>
        updateAddressDetailsPart({ zipCode: newZipCode });
    const onStreetNumberChanged = (newStreetNumber: string) =>
        updateAddressDetailsPart({ streetNumber: newStreetNumber });
    const onCityChanged = (newCity: string) => updateAddressDetailsPart({ city: newCity });
    const onCountryChanged = (newCountry: string) =>
        updateAddressDetailsPart({ country: newCountry });

    const onSelectAddressChanged = (newSelectedAddress: SuggestType) =>
        setAddressDetails(newSelectedAddress);

    const onMapChange = useCallback(
        ({ latitude: newLat, longitude: newLng }: { latitude: number; longitude: number }) =>
            updateAddressDetailsPart({ latitude: newLat, longitude: newLng }),
        []
    );

    const onCategoryChanged = (category: string) => {
        setSelectedSubCategory(undefined);
        setSelectedCategory(category);
    };
    const onSubCategoryChanged = (subCategory: string) => setSelectedSubCategory(subCategory);

    const submit = useCallback(async () => {
        await updateChargeSystem({
            variables: {
                id,
                name,
                addressDetails: {
                    streetNumber,
                    address,
                    zipCode,
                    city,
                    country,
                    latitude,
                    longitude,
                },
                category: selectedCategory,
                subCategory: selectedSubCategory,
            },
        });
        if (editMode && onModalCloseClick) {
            onModalCloseClick();
        } else {
            history.push(`/charge-system/${id}/setup/reset`);
        }
    }, [
        updateChargeSystem,
        id,
        name,
        streetNumber,
        address,
        zipCode,
        city,
        country,
        latitude,
        longitude,
        selectedCategory,
        selectedSubCategory,
        editMode,
        onModalCloseClick,
        history,
    ]);

    useEffect(() => {
        if (chargeSystemCategories.length > 0) {
            setSortedChargeSystemCategories(
                chargeSystemCategories
                    .slice()
                    .sort(
                        (a: ChargeSystemCategory, b: ChargeSystemCategory) =>
                            ChargeSystemCategoryOrder[a.id] - ChargeSystemCategoryOrder[b.id]
                    )
            );
        }
    }, [chargeSystemCategories]);

    const Root = editMode ? EditMode : Wizard;
    return (
        <Root onSubmit={onSubmit} loading={loading} id={id}>
            <Composite>
                <Heading level={headingLevel}>
                    {i18n.t(`AboutChargeSystem.${editMode ? 'HeaderSettings' : 'Header'}`)}
                </Heading>
                {editMode && (
                    <Button
                        name="close-button"
                        size={Size.TINY}
                        fillParent={false}
                        variant={Variant.SECONDARY}
                        icon="close"
                        onClick={onModalCloseClick}
                    />
                )}
            </Composite>
            <Group>
                <ReactMarkdown>{i18n.t('AboutChargeSystem.Description')}</ReactMarkdown>
                <Group tight={editMode}>
                    <TextInput
                        name="name"
                        label={i18n.t('AboutChargeSystem.NameLabel')}
                        value={name}
                        onChange={onNameChanged}
                    />
                    <Composite fillParent>
                        <Suggest
                            name="address"
                            label={i18n.t('AboutChargeSystem.AddressLabel')}
                            value={address}
                            onChange={onAddressChanged}
                            onSelect={onSelectAddressChanged}
                        />
                        <TextInput
                            name="streetNumber"
                            label={i18n.t('AboutChargeSystem.StreetNumberLabel')}
                            value={streetNumber}
                            onChange={onStreetNumberChanged}
                            flex="0 1"
                        />
                    </Composite>
                    <Composite fillParent>
                        <TextInput
                            name="zipCode"
                            label={i18n.t('AboutChargeSystem.ZipCodeLabel')}
                            value={zipCode}
                            onChange={onZipCodeChanged}
                            flex="0 1"
                        />
                        <TextInput
                            name="city"
                            label={i18n.t('AboutChargeSystem.CityLabel')}
                            value={city}
                            onChange={onCityChanged}
                        />
                    </Composite>
                    <TextInput
                        name="country"
                        label={i18n.t('AboutChargeSystem.CountryLabel')}
                        value={country}
                        onChange={onCountryChanged}
                    />
                    <ReactMarkdown>{i18n.t('AboutChargeSystem.MapDescription')}</ReactMarkdown>
                    <MemoizedMap latitude={latitude} longitude={longitude} onChange={onMapChange} />
                </Group>
                <Group>
                    <Heading level={Level.h4}>
                        {i18n.t('AboutChargeSystem.CategoryRadioHeader')}
                    </Heading>
                    <ReactMarkdown>
                        {i18n.t('AboutChargeSystem.Categories.Description')}
                    </ReactMarkdown>
                    {sortedChargeSystemCategories && (
                        <RadioList
                            name="type"
                            gridList
                            onChange={onCategoryChanged}
                            defaultChecked={selectedCategory}
                            items={
                                sortedChargeSystemCategories.map(
                                    (category: ChargeSystemCategory) => ({
                                        label: i18n.t(
                                            `AboutChargeSystem.Categories.${category.id}`
                                        ),
                                        value: category.id,
                                        icon: getIconFromCategory(category.id),
                                    })
                                ) as RadioDefinition[]
                            }
                        />
                    )}
                    {selectedCategory &&
                        chargeSystemCategories?.find(
                            (category: ChargeSystemCategory) => category.id === selectedCategory
                        )?.item.length > 0 && (
                            <Dropdown<string>
                                name="subCategory"
                                label={i18n.t(
                                    `AboutChargeSystem.CategoryDropDownLabel.${selectedCategory}`
                                )}
                                placeholder={i18n.t(
                                    `AboutChargeSystem.CategoryDropDownLabel.${selectedCategory}`
                                )}
                                onChange={onSubCategoryChanged}
                                value={selectedSubCategory}
                                items={
                                    chargeSystemCategories.find(
                                        (category: ChargeSystemCategory) =>
                                            category.id === selectedCategory
                                    )?.item
                                }
                                keyExtractor={(item: string) => item}
                                labelExtractor={(item: string) =>
                                    i18n.t(`AboutChargeSystem.SubCategories.${item}`)
                                }
                            />
                        )}
                </Group>
            </Group>
            <ActionRow>
                <Button
                    name="submit-button"
                    text={i18n.t(editMode ? 'Save' : 'Continue')}
                    disabled={invalid}
                    loading={loadingUpdateChargeSystem}
                    onClick={submit}
                    size={buttonSize}
                />
            </ActionRow>
        </Root>
    );
}
