import { styled } from '@defa/defa-component-library';

export const Root = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    background-color: ${({ theme }) => theme.buttonColorDisabled};
`;

export const Content = styled.section`
    flex: 1;
    align-items: stretch;
    display: flex;
`;

export const Second = styled.div`
    background-color: white;
    border-radius: ${({ theme }) => theme.spacing(4)};
    width: 701px;
`;

export const LoadingWrapper = styled.div`
    display: flex;
    min-height: 100px;
`;
