import { useMutation } from '@apollo/client';
import { Button, Heading, Level, Modal, TextInput } from '@defa/defa-component-library';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import i18n from '../../../i18n';
import { Rfid } from '../../../models/user';
import { Root } from './modify-key.styles';
import { UPDATE_KEY } from './modify-key.queries';
import { NotificationContext } from '../../../utils/notification';

export function ModifyKeyModal({
    onClose,
    onSubmit,
    selectedKey,
}: {
    onClose: () => void;
    onSubmit: Function;
    selectedKey: Rfid;
}) {
    const { id } = useParams<{ id: string }>();
    const [name, setName] = useState(selectedKey.name ?? '');
    const [ok, setOk] = useState(true);
    const { add: addNotification } = useContext(NotificationContext);
    const [update, { loading }] = useMutation(UPDATE_KEY);

    const onSubmitPress = async () => {
        const response = await update({
            variables: {
                id,
                input: {
                    id: selectedKey.id,
                    name,
                },
            },
        });
        const responseStatus = response.data?.chargeSystem.updateRfidKey.status;
        if (responseStatus) {
            addNotification({
                message: i18n.t('ModifyKey.MessageSuccess'),
            });
            onSubmit();
        } else {
            addNotification({
                message: i18n.t('ModifyKey.MessageFail'),
            });
            onClose();
        }
    };

    useEffect(() => {
        setOk(name.length > 2);
    }, [name, setOk]);

    return (
        <Modal
            titleContent={<Heading level={Level.h3}>{i18n.t('ModifyKey.Header')}</Heading>}
            bodyContent={
                <Root>
                    <TextInput
                        name="name"
                        label={i18n.t('ModifyKey.Label')}
                        value={name}
                        onChange={(text) => {
                            setName(text);
                        }}
                    />
                </Root>
            }
            actionContent={
                <Button
                    fillParent
                    disabled={!ok}
                    loading={loading}
                    text={i18n.t('Save')}
                    onClick={onSubmitPress}
                />
            }
            onClosePress={onClose}
            zIndex={5}
            width="400px"
            showModal
        />
    );
}
