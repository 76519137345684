import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import {
    SplitScreen,
    Heading,
    Level,
    TextInput,
    Button,
    Group,
    ActionRow,
    Dropdown,
    Composite,
    ProgressIndicator,
    ProgressType,
    TextInputMode,
    CheckBox,
    Link,
} from '@defa/defa-component-library';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { BrandPanel } from '../../../fractions/brand-panel';

import i18n from '../../../i18n';
import { AdminResponseType, ADMIN, UPDATE_ADMIN } from './account.queries';
import { NotificationContext } from '../../../utils/notification';
import { PhoneCountryCode } from '../../../models';
import { PhoneNumberContainer } from './account.styles';
import { PHONE_COUNTRY_CODES } from '../../../constants';
import { testPhonenumberPolicy } from '../../../utils/phonenumber-policy';

export function SetupAccount() {
    const history = useHistory();
    const { add: addNotification } = useContext(NotificationContext);
    const { data: adminData, loading } = useQuery<AdminResponseType>(ADMIN);
    const { admin } = adminData ?? {};

    const {
        email: initialEmail = '',
        firstName: initialFirstName = '',
        lastName: initialLastName = '',
        phoneNumber: initialPhone = '',
    } = admin ?? {};

    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phoneCountryCode, setPhoneCountryCode] = useState<PhoneCountryCode>();
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [privacyAccepted, setPrivacyAccepted] = useState<boolean>(false);

    const validPhoneNumber = phoneNumber === '' || testPhonenumberPolicy(phoneNumber);
    const phoneNumberMessage = validPhoneNumber ? undefined : i18n.t('PhoneNumberInvalid');

    const valid =
        privacyAccepted &&
        firstName &&
        lastName &&
        phoneNumber &&
        phoneCountryCode &&
        validPhoneNumber;

    const [updateAdmin, { loading: loadingUpdateChargeSystem }] = useMutation(UPDATE_ADMIN);

    useEffect(() => {
        setFirstName(initialFirstName);
        setLastName(initialLastName);
        setEmail(initialEmail);
    }, [initialEmail, initialFirstName, initialLastName]);

    useEffect(() => {
        if (initialPhone?.length > 0) {
            const parsedPhoneNumber = parsePhoneNumberFromString(`+${initialPhone}`);
            if (parsedPhoneNumber) {
                const countryCode = PHONE_COUNTRY_CODES.find(
                    (c) => c.value === parsedPhoneNumber.countryCallingCode
                );
                if (countryCode) {
                    setPhoneCountryCode(countryCode);
                    setPhoneNumber(parsedPhoneNumber.nationalNumber);
                } else {
                    setPhoneNumber(initialPhone);
                }
            }
        }
    }, [initialPhone]);

    const submit = useCallback(() => {
        if (valid) {
            updateAdmin({
                variables: {
                    firstName,
                    lastName,
                    phoneNumber: `${phoneCountryCode?.value}${phoneNumber}`,
                    email,
                    acceptedPrivacyConditions: privacyAccepted,
                },
            })
                .then(() => {
                    addNotification({
                        message: i18n.t('AccountSettings.Success'),
                    });
                    history.replace('/');
                })
                .catch(() => {
                    addNotification({
                        message: i18n.t('AccountSettings.Error'),
                    });
                });
        }
    }, [
        valid,
        updateAdmin,
        firstName,
        lastName,
        phoneCountryCode?.value,
        phoneNumber,
        email,
        privacyAccepted,
        addNotification,
        history,
    ]);

    const showPrivacyPolicy = () => {
        window.open('https://www.defa.com/defa-privacy-policy/', '_blank');
    };

    return (
        <SplitScreen
            first={
                <Group>
                    <Group>
                        <BrandPanel
                            heading={i18n.t('AboutChargeSystem.FlowHeader')}
                            subHeading={i18n.t('BrandPanel.SubHeader')}
                        />
                    </Group>
                </Group>
            }
            secondSideLoading={loading}
            second={
                <Group minWidth="480px">
                    <Composite>
                        <Heading level={Level.h1}>{i18n.t('AccountSettings.Header')}</Heading>
                        {loading && (
                            <ProgressIndicator type={ProgressType.DONUT_LOADING} progress={50} />
                        )}
                    </Composite>
                    {!loading && (
                        <Group>
                            <TextInput
                                label={i18n.t('AccountSettings.FirstNameLabel')}
                                name="first-name"
                                value={firstName}
                                onChange={setFirstName}
                                disabled={loadingUpdateChargeSystem}
                            />
                            <TextInput
                                label={i18n.t('AccountSettings.LastNameLabel')}
                                name="last-name"
                                value={lastName}
                                onChange={setLastName}
                                disabled={loadingUpdateChargeSystem}
                            />
                            <TextInput
                                label={i18n.t('AccountSettings.EmailLabel')}
                                name="email"
                                mode={TextInputMode.EMAIL}
                                value={email}
                                disabled
                            />
                            <PhoneNumberContainer>
                                <Dropdown<PhoneCountryCode>
                                    name="phone-country-codes"
                                    label={i18n.t('AccountSettings.PhoneNumberCountryLabel')}
                                    placeholder={i18n.t('AccountSettings.PhoneNumberCountryLabel')}
                                    onChange={setPhoneCountryCode}
                                    value={phoneCountryCode}
                                    items={PHONE_COUNTRY_CODES}
                                    keyExtractor={(item) => item.label}
                                    labelExtractor={(item) => item.label}
                                    disabled={loadingUpdateChargeSystem}
                                />
                                <TextInput
                                    label={i18n.t('AccountSettings.PhoneNumberLabel')}
                                    name="phone-number"
                                    value={phoneNumber}
                                    message={phoneNumberMessage}
                                    onChange={setPhoneNumber}
                                    disabled={loadingUpdateChargeSystem}
                                />
                            </PhoneNumberContainer>
                            <CheckBox
                                name="privacy"
                                label={i18n.t('CreateAccount.PrivacyConditionsLabel')}
                                checked={privacyAccepted}
                                onChange={setPrivacyAccepted}
                                extra={
                                    <Link href="#" onClick={showPrivacyPolicy}>
                                        {i18n.t('CreateAccount.PrivacyConditionsLink')}
                                    </Link>
                                }
                            />
                        </Group>
                    )}
                    <ActionRow>
                        <Button
                            name="submit-button"
                            text={i18n.t('Continue')}
                            disabled={!valid}
                            loading={loadingUpdateChargeSystem}
                            onClick={submit}
                        />
                    </ActionRow>
                </Group>
            }
        />
    );
}
