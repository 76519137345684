import { gql } from '@apollo/client';
import { Organization } from '../../../models';

export interface CountryType {
    id: string;
}

export interface CountryResponseType {
    countries: CountryType[];
}

export interface CategoryResponseType {
    organizationCategories?: string[];
}

export interface OrganizationResponseType {
    organization: Organization;
}

export const GET_ORGANIZATION = gql`
    query GetOrganization($id: ID!) {
        organization(id: $id) {
            id
            name
            vatNr
            orgNr
            customerNr
            customerReference
            category
            invoiceEmail
            contactEmail
            contactPhone
            contactAddress {
                streetNumber
                address
                zipCode
                city
                country
                latitude
                longitude
            }
            billingAddress {
                streetNumber
                address
                zipCode
                city
                country
                latitude
                longitude
            }
            vatLiable
        }
    }
`;

export const GET_COUNTRIES = gql`
    query GetCountries {
        countries {
            id
        }
    }
`;

export const GET_ORGANIZATION_CATEGORIES = gql`
    query GetOrganizationCategories {
        organizationCategories
    }
`;

export const UPSERT_ORGANIZATION = gql`
    mutation UpsertOrganization(
        $id: ID
        $name: String
        $vatNr: String
        $orgNr: String
        $category: String
        $contactAddress: AddressDetailsInput
        $billingAddress: AddressDetailsInput
        $vatLiable: Boolean
        $invoiceEmail: String
        $contactEmail: String
        $contactPhone: String
        $customerReference: String
    ) {
        upsertOrganization(
            input: {
                id: $id
                name: $name
                vatNr: $vatNr
                orgNr: $orgNr
                category: $category
                contactAddress: $contactAddress
                billingAddress: $billingAddress
                vatLiable: $vatLiable
                invoiceEmail: $invoiceEmail
                contactEmail: $contactEmail
                contactPhone: $contactPhone
                customerReference: $customerReference
            }
        ) {
            id
            name
        }
    }
`;
