import React, { useCallback, useEffect, useState } from 'react';
import { SideMenu, SideMenuItem, SideMenuItemProps, useTheme } from '@defa/defa-component-library';
import { useRouteMatch, useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import i18n from '../../i18n';
import { ChargingTypeState } from '../../models/group';
import { GROUP } from './group-settings-side-menu.queries';

export function SelectableSettingsSideMenuItem(props: SideMenuItemProps) {
    const { href } = props;
    const match = useRouteMatch<{ id?: string }>(href || '');
    const history = useHistory();

    const clickHandler = useCallback(() => {
        history.replace(href || '#');
    }, [history, href]);

    const { isExact } = match || {};
    const selected = isExact;

    return <SideMenuItem {...props} onClick={clickHandler} selected={selected} />;
}

export function GroupSettingsSideMenu() {
    const { id: chargeSystemId, groupId: id } = useParams<{ id: string; groupId: string }>();
    const rootUrl = `/charge-system/${chargeSystemId}/group/${id}`;
    const theme = useTheme();

    const { data: chargeSystemData = { chargeSystem: {} } } = useQuery(GROUP, {
        variables: { chargeSystemId, id },
    });
    const { group } = chargeSystemData?.chargeSystem || {};
    const { privateCharging, publicCharging } = group || {};

    const [privateOn, setPrivateOn] = useState(false);
    const [publicOn, setPublicOn] = useState(false);

    useEffect(() => {
        setPrivateOn(privateCharging !== ChargingTypeState.DISABLED);
    }, [privateCharging]);

    useEffect(() => {
        setPublicOn(publicCharging !== ChargingTypeState.DISABLED);
    }, [publicCharging]);

    // TODO: Theme px based
    return (
        <SideMenu
            headerText={i18n.t('GroupSettings.SideMenuHeader')}
            width="227px"
            padding={`${theme.spacing(5)} ${theme.spacing(4)}`}
            background={theme.sideMenuAlternativeBackground}
        >
            <SelectableSettingsSideMenuItem
                tid="group-settings-side-menu-about"
                icon="information"
                text={i18n.t('GroupSettings.About')}
                href={`${rootUrl}/about`}
            />
            <SelectableSettingsSideMenuItem
                tid="group-settings-side-menu-private"
                icon="privateCharging"
                text={i18n.t('GroupSettings.Private.Header')}
                description={i18n.t(`GroupSettings.${privateOn ? 'On' : 'Off'}`)}
                href={`${rootUrl}/private`}
            />
            <SelectableSettingsSideMenuItem
                tid="group-settings-side-menu-public"
                icon="chargingStation"
                text={i18n.t('GroupSettings.Public.Header')}
                description={i18n.t(`GroupSettings.${publicOn ? 'On' : 'Off'}`)}
                href={`${rootUrl}/public`}
            />
        </SideMenu>
    );
}
