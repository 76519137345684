import React, { useState, useMemo, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    Button,
    Group,
    Heading,
    Level,
    Switch,
    Text,
    TextInput,
    TextInputMode,
    TextType,
} from '@defa/defa-component-library';
import { useHistory } from 'react-router-dom';
import { Root } from './reset-password.styles';
import { BrandPanel } from '../../fractions/brand-panel';
import i18n from '../../i18n';
import { GET_PASSWORD_TOKEN_VALID, NEW_PASSWORD } from './reset-password-new-password.queries';
import { testPasswordPolicy } from '../../utils/password-policy';

export const ResetPasswordNewPassword = () => {
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const history = useHistory();
    const params = new URLSearchParams(window.location.search);
    const adminId = params.get('adminId');
    const token = params.get('token');
    const [NewPassword] = useMutation(NEW_PASSWORD);
    const [getPasswordTokenValid, { data: PasswordTokenValidData }] = useLazyQuery(
        GET_PASSWORD_TOKEN_VALID,
        {
            variables: { id: adminId, passwordToken: token },
        }
    );

    const valid = testPasswordPolicy(newPassword) && newPassword === confirmPassword;

    const onNewPasswordChange = useMemo(
        () => (text: string): void => {
            setNewPassword(text);
        },
        [setNewPassword]
    );

    const onConfirmPasswordChange = useMemo(
        () => (text: string): void => {
            setConfirmPassword(text);
        },
        [setConfirmPassword]
    );

    const onShowPasswordChange = useMemo(
        () => (value: boolean): void => {
            setShowPassword(value);
        },
        [setShowPassword]
    );

    useEffect(() => {
        if (token && adminId) {
            getPasswordTokenValid();
        }
    }, [token, adminId, getPasswordTokenValid]);

    useEffect(() => {
        const { getPasswordTokenValid: authResponse } = PasswordTokenValidData || {
            getPasswordTokenValid: null,
        };
        const { status } = authResponse || { status: null };
        if (status && status !== 200) {
            history.replace('/');
        }
    }, [PasswordTokenValidData, history]);

    const submit = useMemo(
        () => async () => {
            if (valid) {
                const { data } = await NewPassword({
                    variables: { id: adminId, passwordToken: token, newPass: newPassword },
                });
                const { newPassword: authResponse } = data;
                const { status } = authResponse;
                if (status === 200) {
                    history.push('/');
                }
            }
        },
        [adminId, token, newPassword, NewPassword, valid, history]
    );

    return (
        <Root
            first={
                <Group>
                    <BrandPanel
                        heading={i18n.t('BrandPanel.Header')}
                        subHeading={i18n.t('BrandPanel.SubHeader')}
                    />
                </Group>
            }
            second={
                <Group minWidth="480px">
                    <Group>
                        <Heading level={Level.h1}>
                            {i18n.t('ResetPasswordNewPassword.Header')}
                        </Heading>
                        <Text>{i18n.t('ResetPasswordNewPassword.Description')}</Text>
                    </Group>

                    <Group>
                        <TextInput
                            label={i18n.t('ResetPasswordNewPassword.NewPasswordLabel')}
                            mode={showPassword ? TextInputMode.PLAIN_TEXT : TextInputMode.PASSWORD}
                            name="newPassword"
                            value={newPassword}
                            message={
                                newPassword.length > 0 &&
                                !testPasswordPolicy(newPassword) &&
                                i18n.t('ResetPasswordNewPassword.PasswordPolicyErrorMessage')
                            }
                            onChange={onNewPasswordChange}
                        />
                        <TextInput
                            label={i18n.t('ResetPasswordNewPassword.ConfirmPasswordLabel')}
                            mode={showPassword ? TextInputMode.PLAIN_TEXT : TextInputMode.PASSWORD}
                            name="confirmPassword"
                            value={confirmPassword}
                            message={
                                confirmPassword.length > 0 &&
                                newPassword !== confirmPassword &&
                                i18n.t('ResetPasswordNewPassword.MessageMatch')
                            }
                            onChange={onConfirmPasswordChange}
                        />
                        <Text type={TextType.description}>
                            {i18n.t('ResetPasswordNewPassword.PasswordDescription')}
                        </Text>
                        <Switch
                            justify="start"
                            reverse
                            label={i18n.t('ResetPasswordNewPassword.ShowPasswordSwitch')}
                            name="show-password-switch"
                            checked={showPassword}
                            onChange={onShowPasswordChange}
                        />
                    </Group>
                    <Group>
                        <Button
                            text={i18n.t('ResetPasswordNewPassword.SubmitButton')}
                            disabled={!valid}
                            onClick={submit}
                            name="submit"
                        />
                    </Group>
                </Group>
            }
        />
    );
};
