import { styled } from '@defa/defa-component-library';

export const Root = styled.div<{ clickable: boolean }>`
    padding: ${({ theme }) => theme.spacing(4)};
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    border-radius: ${({ theme }) => theme.spacing(3)};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${({ clickable }) => clickable && 'cursor: pointer;'}
`;

export const Title = styled.div`
    & > * {
        margin-bottom: 4px !important;
    }
    &:last-child {
        margin-bottom: 0px !important;
    }
`;

export const InfoBoxRoot = styled.div`
    padding: ${({ theme }) => theme.spacing(2)};

    border-radius: 8px;

    & > * {
        margin: 0px !important;
    }
`;
