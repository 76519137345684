import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
    Button,
    Composite,
    Group,
    Heading,
    SimpleTable,
    Level,
    Size,
    Variant,
    Text,
} from '@defa/defa-component-library';
import { StatusLogResponseType, GET_STATUS_LOG } from './status-log.queries';
import i18n from '../../../i18n';
import { getDateByTimeStamp, getTimeByTimestamp } from '../../../utils/date';
import { DateCell, Cell } from './status-log.styles';
import { StatusLogItem } from '../../../models';

function DateColumn() {
    return (data: StatusLogItem) => {
        const { timeStamp, status } = data ?? {};
        return (
            <DateCell key={`Date-${timeStamp}-${status}`}>{getDateByTimeStamp(timeStamp)}</DateCell>
        );
    };
}
function TimeColumn() {
    return (data: StatusLogItem) => {
        const { timeStamp, status } = data ?? {};
        return (
            <DateCell key={`Time-${timeStamp}-${status}`}>{getTimeByTimestamp(timeStamp)}</DateCell>
        );
    };
}
function OtherColumn(key: 'status' | 'errorCode' | 'errorInfo') {
    return (data: StatusLogItem) => {
        const { [key]: value, timeStamp, status } = data ?? {};
        return <Cell key={`${timeStamp}-${status}-${key}`}>{value}</Cell>;
    };
}

const columns = [
    DateColumn(),
    TimeColumn(),
    OtherColumn('status'),
    OtherColumn('errorCode'),
    OtherColumn('errorInfo'),
];
const columnNames = [
    i18n.t('StatusLog.date'),
    i18n.t('StatusLog.time'),
    i18n.t('StatusLog.status'),
    i18n.t('StatusLog.errorCode'),
    i18n.t('StatusLog.errorInfo'),
];

export function StatusLog({
    onChargerDetailsCloseClick,
}: {
    onChargerDetailsCloseClick: () => void;
}) {
    const { id, connectorAlias } = useParams<{ id: string; connectorAlias: string }>();
    const { data } = useQuery<StatusLogResponseType>(GET_STATUS_LOG, {
        variables: {
            chargeSystemId: id,
            id: connectorAlias,
        },
        pollInterval: 5000,
    });
    const { connector } = data || {};

    const sortedLog = [...(connector?.statusLog?.statusHistory ?? [])].sort(
        (a, b) => parseInt(b.timeStamp, 10) - parseInt(a.timeStamp, 10)
    );

    return (
        <>
            <Group>
                <Composite>
                    <Heading level={Level.h2}>{i18n.t('StatusLog.Header')}</Heading>
                    <Button
                        name="close-button"
                        icon="close"
                        fillParent={false}
                        variant={Variant.SECONDARY}
                        size={Size.TINY}
                        onClick={onChargerDetailsCloseClick}
                    />
                </Composite>
            </Group>
            <Group>
                {connector && connector?.statusLog?.status === 200 && (
                    <SimpleTable<StatusLogItem>
                        items={sortedLog}
                        columns={columns}
                        columnNames={columnNames}
                    />
                )}
                {connector && connector?.statusLog?.status !== 200 && (
                    <Text>{i18n.t('StatusLog.NoLogFound', { alias: connector.alias })}</Text>
                )}
            </Group>
        </>
    );
}
