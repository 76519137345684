import React from 'react';
import { useQuery } from '@apollo/client';

import { Level, Size } from '@defa/defa-component-library';
import { Route, RouteComponentProps, Switch, useParams } from 'react-router-dom';
import { Second, Content } from '../group-settings/group-settings.styles';

import { AboutChargeSystem } from '../setup/about-charge-system';
import { ChargeSystemSettingsSideMenu } from '../../fractions/charge-system-settings-side-menu';
import { Users } from './users';
import { CHARGE_SYSTEM } from './charge-system-settings.queries';
import { Keys } from './keys';
import { SmsAlerts } from './sms-alerts';

interface ChargeSystemSettingsProps extends RouteComponentProps<{}>, React.Props<{}> {
    onModalCloseClick: () => void;
}

export const ChargeSystemSettings = ({ match, onModalCloseClick }: ChargeSystemSettingsProps) => {
    const { id: chargeSystemId } = useParams<{ id: string }>();
    const { data: chargeSystemData = { chargeSystem: {} } } = useQuery(CHARGE_SYSTEM, {
        variables: { id: chargeSystemId },
    });
    const { name: initialName } = chargeSystemData?.chargeSystem || {};

    console.log({ initialName });

    return (
        <Content>
            <ChargeSystemSettingsSideMenu />
            <Second>
                <Switch>
                    <Route
                        path={`${match.path}/about`}
                        component={() => (
                            <AboutChargeSystem
                                editMode
                                headingLevel={Level.h2}
                                buttonSize={Size.SMALL}
                                onModalCloseClick={onModalCloseClick}
                            />
                        )}
                    />
                    <Route
                        path={`${match.path}/sms`}
                        component={() => <SmsAlerts onModalCloseClick={onModalCloseClick} />}
                    />
                    <Route
                        path={`${match.path}/users`}
                        component={() => <Users onModalCloseClick={onModalCloseClick} />}
                    />
                    <Route
                        path={`${match.path}/keys`}
                        component={() => <Keys onModalCloseClick={onModalCloseClick} />}
                    />
                </Switch>
            </Second>
        </Content>
    );
};
