import { gql } from '@apollo/client';

export const ADMIN = gql`
    query {
        admin {
            email
        }
    }
`;

export const GET_ADMINS = gql`
    query GetOrganization($id: ID!) {
        organization(id: $id) {
            invitedAdmins {
                email
                expirationDate
            }
            admins {
                firstName
                lastName
                email
            }
        }
    }
`;

export const REVOKE_INVITE_ADMINS = gql`
    mutation RevokeInviteAdmins($id: ID!, $invites: [AdminInviteInput!]!) {
        organization(id: $id) {
            revokeInviteAdmins(input: $invites) {
                status
            }
        }
    }
`;

export const DELETE_ADMINS = gql`
    mutation DeleteAdmins($id: ID!, $admins: [AdminInviteInput!]!) {
        organization(id: $id) {
            deleteAdmins(input: $admins) {
                status
            }
        }
    }
`;
