import { styled } from '@defa/defa-component-library';

export const Root = styled.div`
    display: flex;
    flex: 1;
    align-items: stretch;
    flex-direction: column;
    background-color: ${({ theme }) => theme.buttonColorDisabled};
    max-width: 100vw;
`;

export const Content = styled.section`
    display: grid;
    flex: 1;
    padding: ${({ theme }) => theme.spacing(10)};
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(auto-fill, 218px);
    gap: ${({ theme }) => theme.spacing(6)};
`;
