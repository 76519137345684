import { gql } from '@apollo/client';

export const UPDATE_CHARGE_SYSTEM = gql`
    mutation UpdateChargeSystem($id: ID!, $invites: [UserInviteInput!]!) {
        chargeSystem(id: $id) {
            inviteUsers(input: $invites) {
                status
                body {
                    message
                    data
                }
            }
        }
    }
`;

export const GET_GROUPS = gql`
    query GetGroups($id: ID!) {
        chargeSystem(id: $id) {
            id
            groups {
                id
                name
                privateCharging
                connectors {
                    id
                    alias
                    displayName
                }
            }
        }
    }
`;
