import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
    useHistory,
    useParams,
    Switch,
    Route,
    useRouteMatch,
    RouteComponentProps,
} from 'react-router-dom';
import {
    Button,
    Composite,
    Heading,
    Level,
    Text,
    Variant,
    Group,
    Loading,
    Modal,
    Size,
    useTheme,
} from '@defa/defa-component-library';
import { Root } from './charge-system.styles';
import i18n from '../../i18n';
import { Header } from '../../fractions/header';
import { GroupSection } from '../../fractions/group-section';
import { ChargerInfo } from '../charger-info';
import { GroupSettings } from '../group-settings';
import { ChargeSystemSettings } from '../charge-system-settings';
import { CHARGE_SYSTEM, ChargeSystemResponseType } from './charge-system.queries';
import { GRAPHQL_POLLINTERVAL_SECONDS } from '../../constants';
import { ChargerDetails } from '../charger-details';
import { localeObjectSort } from '../../utils/localeObjectSort';
import { getDisplayAddress } from '../../utils/address';
import { ConfigurationStatus } from '../../models';
import { useOrganization } from '../../utils/hooks';

interface ChargeSystemProps extends RouteComponentProps<{}>, React.Props<{}> {}

export function ChargeSystem({ match }: ChargeSystemProps) {
    const { id } = useParams<{ id: string }>();
    const theme = useTheme();
    const connectorMatch = useRouteMatch<{ connectorAlias?: string }>(
        `${match.path}/charger/:connectorAlias/*`
    );
    const [currentOrganization] = useOrganization();
    const [chargeSystemAmount, setChargeSystemAmount] = useState(1);

    const groupMatch = useRouteMatch<{ groupId?: string }>(`${match.path}/group/:groupId/*`);

    const chargeSystemSettingsMatch = useRouteMatch<{ groupId?: string }>(
        `${match.path}/settings/*`
    );

    const { connectorAlias } = connectorMatch?.params || { connectorAlias: undefined };
    const { groupId } = groupMatch?.params || { groupId: undefined };

    const history = useHistory();

    const { refetch, loading, error, data, startPolling, stopPolling } = useQuery<
        ChargeSystemResponseType
    >(CHARGE_SYSTEM, {
        variables: {
            id,
        },
        pollInterval: GRAPHQL_POLLINTERVAL_SECONDS,
    });

    const { chargeSystem, organizations } = data || { chargeSystem: {} };

    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        const setupRequired =
            chargeSystem.configurationStatus === ConfigurationStatus.SETUP_REQUIRED;
        if (setupRequired) {
            history.push(`/charge-system/${id}/setup/about`);
        }
    }, [chargeSystem, history, id]);

    useEffect(() => {
        const systems =
            organizations?.find((org) => org.id === currentOrganization)?.chargeSystems ?? [];
        setChargeSystemAmount(systems.length);
    }, [organizations, currentOrganization]);

    const resumePolling = () => {
        refetch();
        startPolling(GRAPHQL_POLLINTERVAL_SECONDS);
    };

    const onChargeSystemSettingsClick = (subPath: string) => {
        stopPolling();
        history.push(`${match.url}${subPath}`);
    };

    const onGroupSettingsClick = (gid: string) => {
        stopPolling();
        history.push(`${match.url}/group/${gid}/about`);
    };

    const onModalCloseClick = () => {
        resumePolling();
        history.push(`/charge-system/${id}`);
    };

    const onChargerDetailsCloseClick = () => {
        resumePolling();
        history.push(`/charge-system/${id}`);
    };

    return (
        <Root>
            <Header />
            {error && (
                <Text color={theme.errorColor} alignment="center">
                    Error...
                </Text>
            )}
            {loading && <Loading />}
            {!loading && (
                <Group verticalMargin="40px">
                    <Composite justify="space-between">
                        {chargeSystemAmount > 1 && (
                            <Button
                                name="back-button"
                                variant={Variant.SECONDARY}
                                icon="chevronLeft"
                                fillParent={false}
                                onClick={() => history.replace('/')}
                                size={Size.SMALL}
                            />
                        )}
                        <Group tight noMargin>
                            <Heading level={Level.h2}>
                                {chargeSystem.name ?? chargeSystem.id}
                            </Heading>
                            <Text>{getDisplayAddress(chargeSystem?.addressDetails)}</Text>
                        </Group>
                        <Composite gap={theme.spacingRaw(2)}>
                            <Button
                                name="users-settings-button"
                                variant={Variant.SECONDARY}
                                icon="users"
                                fillParent={false}
                                onClick={() => onChargeSystemSettingsClick('/settings/users')}
                                size={Size.SMALL}
                                data-title={i18n.t('ChargeSystemSettings.Users')}
                                tooltip={i18n.t('ChargeSystemSettings.Users')}
                            />
                            <Button
                                name="charge-system-settings-button"
                                variant={Variant.SECONDARY}
                                icon="settings"
                                fillParent={false}
                                onClick={() => onChargeSystemSettingsClick('/settings/about')}
                                size={Size.SMALL}
                                data-title={i18n.t('ChargeSystem.ChargeSystemSettings')}
                                tooltip={i18n.t('ChargeSystem.ChargeSystemSettings')}
                            />
                            <Button
                                name="charge-system-rearrange-button"
                                variant={Variant.SECONDARY}
                                icon="reorder"
                                fillParent={false}
                                onClick={() => onChargeSystemSettingsClick('/rearrange')}
                                size={Size.SMALL}
                                data-title={i18n.t('RearrangeGroups.Title')}
                                tooltip={i18n.t('RearrangeGroups.Title')}
                            />
                        </Composite>
                    </Composite>
                    {localeObjectSort(chargeSystem?.groups ?? [], 'name')?.map((group) => (
                        <GroupSection
                            actionItems={[
                                <Button
                                    name={`group-settings-button-${group.name}`}
                                    key="group-settings-button"
                                    variant={Variant.TEXT}
                                    icon="settings"
                                    fillParent={false}
                                    onClick={() => onGroupSettingsClick(group.id)}
                                    size={Size.SMALL}
                                    tooltip={i18n.t('GroupSettings.SideMenuHeader')}
                                />,
                            ]}
                            key={group.id}
                            group={group}
                            selectable={false}
                            onPublicClick={() =>
                                history.push(`${match.url}/group/${group.id}/public`)
                            }
                            onPrivateClick={() =>
                                history.push(`${match.url}/group/${group.id}/private`)
                            }
                            onConnectorClick={(alias: string) =>
                                history.push(`/charge-system/${id}/charger/${alias}/info`)
                            }
                        />
                    ))}
                </Group>
            )}
            <Switch>
                <Route
                    path={`${match.path}/charger/:connectorAlias/info`}
                    component={ChargerInfo}
                />
            </Switch>
            <Modal
                maxWidth="80vw"
                width="70vw"
                padding="0"
                onClosePress={onModalCloseClick}
                showModal={!!groupId}
                bodyContent={
                    <Switch>
                        <Route
                            path={`${match.path}/group/:groupId`}
                            component={(props: RouteComponentProps) => (
                                <GroupSettings {...props} onModalCloseClick={onModalCloseClick} />
                            )}
                        />
                    </Switch>
                }
                actionContent={<></>}
            />
            <Modal
                maxWidth="80vw"
                width="70vw"
                padding="0"
                onClosePress={onModalCloseClick}
                showModal={!!chargeSystemSettingsMatch}
                bodyContent={
                    <Switch>
                        <Route
                            path={`${match.path}/settings`}
                            component={(props: RouteComponentProps) => (
                                <ChargeSystemSettings
                                    {...props}
                                    onModalCloseClick={onModalCloseClick}
                                />
                            )}
                        />
                    </Switch>
                }
                actionContent={<></>}
            />
            <Modal
                maxWidth="80vw"
                width="70vw"
                padding="0"
                onClosePress={onChargerDetailsCloseClick}
                showModal={!!connectorAlias}
                bodyContent={
                    <Switch>
                        <Route
                            path={`${match.path}/charger/:connectorAlias/details`}
                            component={(props: RouteComponentProps) => (
                                <ChargerDetails
                                    {...props}
                                    onChargerDetailsCloseClick={onChargerDetailsCloseClick}
                                />
                            )}
                        />
                    </Switch>
                }
                actionContent={<></>}
            />
        </Root>
    );
}
