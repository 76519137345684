import { gql } from '@apollo/client';

export const VALIDATE_INVITATION = gql`
    query ValidateInvitation($token: String!) {
        invitation(token: $token) {
            valid
            isNewAdmin
        }
    }
`;
