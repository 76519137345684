import { gql } from '@apollo/client';

export const GET_CHARGE_SYSTEM = gql`
    query GetChargeSystem($id: ID!) {
        chargeSystem(id: $id) {
            id
            groups {
                id
                name
                privateCharging
                connectors {
                    id
                    alias
                    displayName
                }
            }
            users {
                id
                name
                phoneNumber
                connectors {
                    id
                    accessLevel
                    data {
                        id
                        alias
                        displayName
                    }
                }
                groups {
                    id
                    accessLevel
                    data {
                        id
                        name
                    }
                }
            }
            invitedUsers {
                phoneNumber
                expirationDate
                connectors {
                    id
                    accessLevel
                    data {
                        id
                        alias
                        displayName
                    }
                }
                groups {
                    id
                    accessLevel
                    data {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const INVITE_USERS = gql`
    mutation InviteUsers($id: ID!, $users: [UserInviteInput!]!) {
        chargeSystem(id: $id) {
            inviteUsers(input: $users) {
                status
                body {
                    message
                    data
                }
            }
        }
    }
`;

export const REVOKE_INVITED_USERS = gql`
    mutation RevokeInvitedUsers($chargeSystemId: ID!, $invites: [UserInviteInput]!) {
        chargeSystem(id: $chargeSystemId) {
            revokeInvitedUsers(input: $invites) {
                status
            }
        }
    }
`;
