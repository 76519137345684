import { gql } from '@apollo/client';
import { ChargeSystem } from '../../models';

export interface ChargeSystemReponseType {
    chargeSystem: ChargeSystem;
}

export const GET_CS = gql`
    query ChargeSystem($id: ID!) {
        chargeSystem(id: $id) {
            name
            groups {
                name
                id
                connectors {
                    id
                    alias
                    displayName
                    energyMeterAvailable
                }
            }
        }
    }
`;
