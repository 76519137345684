import { gql } from '@apollo/client';
import { Admin, Organization } from '../../models';

export interface AdminResponseType {
    admin: Pick<Admin, 'id' | 'email' | 'firstName' | 'lastName' | 'acceptedPrivacyConditions'>;
    organizations: Pick<Organization, 'id' | 'name'>[];
}

export const ADMIN = gql`
    query {
        admin {
            id
            email
            firstName
            lastName
            acceptedPrivacyConditions
        }
        organizations {
            id
            name
        }
    }
`;
