import React, { useEffect, useRef, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TextAlign,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { getLocalDay } from './reports';
import { ChartProps } from './reports.types';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        tooltip: {
            displayColors: false,
            titleAlign: 'center' as TextAlign,
            bodyAlign: 'center' as TextAlign,
            padding: 8,
            callbacks: {
                title: (context: any) => {
                    const [current] = context;
                    return getLocalDay(current.dataset.dates[current.dataIndex]);
                },
                label: (context: any) => `${context.formattedValue} ${context.dataset.unit}`,
            },
        },
        legend: {
            display: false,
        },
    },
    scales: {
        x: {
            grid: {
                color: 'rgba(242, 242, 244, 1)',
            },
            ticks: {
                maxRotation: 0,
                minRotation: 0,
            },
        },
        y: {
            grid: {
                color: 'rgba(0,0,0,0)',
            },
            ticks: {
                autoSkip: false,
            },
            beginAtZero: true,
        },
    },
};

interface ChartDataObject {
    labels: string[];
    datasets: any[];
}

export function RevenueChart({ id, label, datalabels, datapoints, dates, unit }: ChartProps) {
    const chartRef = useRef<any>(null);
    const [data, setData] = useState<ChartDataObject>({ labels: [], datasets: [{}] });

    useEffect(() => {
        setData({
            labels: datalabels,
            datasets: [
                {
                    label,
                    data: datapoints,
                    backgroundColor: 'rgba(83, 214, 135, 1)',
                    borderRadius: 8,
                    dates,
                    unit,
                },
            ],
        });
        chartRef?.current?.update();
    }, [label, datapoints, datalabels, dates, unit]);

    return <Bar ref={chartRef} datasetIdKey={id} options={options} data={data} />;
}
