import { styled } from '@defa/defa-component-library';

export const AdminstratorsContainer = styled.div`
    background-color: ${({ theme }) => theme.buttonColorDisabled};
    border-radius: ${({ theme }) => theme.spacing(3)};
    padding: ${({ theme }) => theme.spacing(4)};
`;

export const TableWrapper = styled.div`
    margin: 16px 0;
`;
