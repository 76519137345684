import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import {
    Button,
    Composite,
    Group,
    Heading,
    Icon,
    Level,
    Size,
    Text,
    TextType,
    useTheme,
    Variant,
} from '@defa/defa-component-library';
import { useMutation, useQuery } from '@apollo/client';
import {
    ChargingTypeState,
    Group as GroupModel,
    PublicChargingTime,
} from '../../../../models/group';
import { PublicSettingsProps } from '../../../../models/charge-system';
import { GroupTitleSection } from './public-settings-different-settings.styles';
import i18n from '../../../../i18n';
import { SetupPublicSettings } from '../../../../fractions/setup-public-settings';
import {
    GET_CHARGE_SYSTEM,
    UPDATE_CHARGE_SYSTEM,
} from './public-settings-different-settings.queries';
import { FeatureToggle, UserHasFeature } from '../../../../utils/feature';
import { useCurrency } from '../../../../utils/hooks';
import { Tariff } from '../../../../models';
import { DEFAULT_TARIFF } from '../../../../constants';
import { hasEnergyMeterInGroup } from '../../../../utils/energy-meter';

export function PublicSettingsDifferentSettingsGroup({
    group,
    isExpanded,
    setIsExpanded,
    setHasNewData,
}: {
    group: GroupModel;
    isExpanded: boolean;
    setIsExpanded: (expandedId: string) => void;
    setHasNewData: (hasNewData: boolean) => void;
}) {
    const theme = useTheme();
    const [hasPublicBroadcastingFeature] = UserHasFeature(FeatureToggle.PublicBroadcasting);
    const [hasPublicAvailableFeature] = UserHasFeature(FeatureToggle.PublicAvailable);
    const [currency] = useCurrency();

    const {
        id: groupId,
        name,
        connectors,
        publicChargingSettings,
        broadcast: initialBroadcast,
        visibleInApps: initialVisibleInApps,
        publicCharging: initialChargingTypeState,
    } = group;
    const {
        durationLimit: initialDurationLimit,
        schema: initialSchema,
    } = publicChargingSettings || {
        durationLimit: -1,
        schema: null,
    };

    const [chargingTypeState, setChargingTypeState] = useState<ChargingTypeState>(
        initialChargingTypeState
    );
    const [durationLimit, setDurationLimit] = useState<string>('');
    const [hasDurationLimit, setHasDurationLimit] = useState<string>('');
    const [hasPubliclyAvailable, setHasPubliclyAvailable] = useState<string>('');
    const [hasVisibility, setHasVisibility] = useState<string>('');
    const [hasBroadcast, setHasBroadcast] = useState<string>('');
    const [daySettings, setDaySettings] = useState<Array<PublicChargingTime>>([]);
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const { id } = useParams<{ id: string }>();

    const [updateChargeSystem] = useMutation(UPDATE_CHARGE_SYSTEM);

    const [tariff, setTariff] = useState<Tariff>(DEFAULT_TARIFF);
    const [hasChargingFee, setChargingFee] = useState<boolean>(false);

    const energyMeterAvailable = hasEnergyMeterInGroup(group);

    const tariffChanged = (newTariff: Tariff) => {
        setTariff(newTariff);
    };

    const chargingFeeChanged = (chargingFee: boolean) => {
        setChargingFee(!chargingFee);
    };

    const onEditClick = useCallback(() => {
        setIsExpanded(groupId);
    }, [setIsExpanded, groupId]);

    const onCancelClick = () => {
        setIsExpanded('');
    };

    const onHasDurationLimitChanged = (newHasDurationLimit: string) => {
        setHasDurationLimit(newHasDurationLimit);
    };

    const onDurationLimitChanged = (newDurationLimit: string) => {
        setDurationLimit(newDurationLimit);
    };

    const onHasPubliclyAvailableChanged = (newHasPubliclyAvailable: string) => {
        if (newHasPubliclyAvailable === 'SPECIFIC' && daySettings.length === 0) {
            const allDaySettings = days.map((day: string) => ({ day, isAllDay: true }));
            setDaySettings(allDaySettings);
        }
        setHasPubliclyAvailable(newHasPubliclyAvailable);
    };

    const onHasVisibilityChanged = (newHasVisibility: string) => {
        setHasVisibility(newHasVisibility);
    };
    const onHasBroadcastChanged = (newHasBroadcast: string) => {
        setHasBroadcast(newHasBroadcast);
    };

    const onSubmitNotPublic = () => {
        updateChargeSystem({
            variables: { id, groupId, publicCharging: ChargingTypeState.DISABLED },
        }).then(() => {
            setChargingTypeState(ChargingTypeState.DISABLED);
            setHasNewData(true);
        });
    };

    const onSubmitUndo = () => {
        updateChargeSystem({
            variables: { id, groupId, publicCharging: ChargingTypeState.SETUP_REQUIRED },
        }).then(() => {
            setChargingTypeState(ChargingTypeState.SETUP_REQUIRED);
            setHasNewData(true);
        });
    };

    const onSubmitGroup = useCallback(() => {
        const schema =
            hasPubliclyAvailable === 'SPECIFIC'
                ? daySettings.map(({ day, to, from, isAllDay }) => ({
                      day,
                      from,
                      to,
                      isAllDay,
                  }))
                : [];
        updateChargeSystem({
            variables: {
                id,
                groupId,
                durationLimit: durationLimit ? parseFloat(durationLimit) : 0,
                schema,
                publicCharging: ChargingTypeState.ENABLED,
                broadcast: hasBroadcast === 'YES',
                visibleInApps: hasVisibility === 'YES',
                tariff: hasChargingFee ? tariff : DEFAULT_TARIFF,
            },
        }).then(() => {
            setChargingTypeState(ChargingTypeState.ENABLED);
            setHasNewData(true);
            setIsExpanded('');
        });
    }, [
        updateChargeSystem,
        setIsExpanded,
        setHasNewData,
        id,
        groupId,
        durationLimit,
        hasPubliclyAvailable,
        daySettings,
        hasBroadcast,
        hasVisibility,
        tariff,
        hasChargingFee,
    ]);

    useEffect(() => {
        setChargingTypeState(initialChargingTypeState);

        if (initialDurationLimit >= 0) {
            setHasDurationLimit(initialDurationLimit > 0 ? 'YES' : 'NO');
            setDurationLimit(initialDurationLimit ? `${initialDurationLimit}` : '');
        }
        if (initialBroadcast !== null) {
            setHasBroadcast(initialBroadcast ? 'YES' : 'NO');
        }
        if (initialVisibleInApps !== null) {
            setHasVisibility(initialVisibleInApps ? 'YES' : 'NO');
        }
        setHasPubliclyAvailable(initialSchema && initialSchema.length > 0 ? 'SPECIFIC' : 'ALWAYS');
        setDaySettings(initialSchema || []);
    }, [
        initialChargingTypeState,
        initialDurationLimit,
        initialBroadcast,
        initialVisibleInApps,
        initialSchema,
    ]);

    return (
        <Group key={id}>
            <Composite>
                <GroupTitleSection>
                    {group.name && (
                        <Heading
                            level={Level.h3}
                            color={
                                chargingTypeState === ChargingTypeState.DISABLED
                                    ? theme.disabledColor
                                    : theme.textColor
                            }
                        >
                            {name}
                        </Heading>
                    )}
                    <Text
                        type={TextType.description}
                        color={
                            chargingTypeState === ChargingTypeState.DISABLED
                                ? theme.disabledColor
                                : theme.inputLabelColor
                        }
                    >
                        {i18n.t('PublicSettingsDifferentSettingsGroup.NumberOfChargersLabel', [
                            connectors.length,
                        ])}
                    </Text>
                </GroupTitleSection>
                {isExpanded ? (
                    <Button
                        name={`config-${encodeURIComponent(name)}`}
                        onClick={onCancelClick}
                        fillParent={false}
                        icon="chevronUp"
                        variant={Variant.TERTIARY}
                        size={Size.SMALL}
                    />
                ) : (
                    <>
                        {chargingTypeState === ChargingTypeState.ENABLED && (
                            <Composite gap={16}>
                                <Composite>
                                    <Icon icon="check" color={theme.successColor} size={10} />
                                    <Text
                                        type={TextType.descriptionMedium}
                                        color={theme.successColor}
                                    >
                                        {i18n.t('PublicSettingsDifferentSettingsGroup.Done')}
                                    </Text>
                                </Composite>
                                <Button
                                    name={`config-${encodeURIComponent(name)}`}
                                    text={i18n.t('PublicSettingsDifferentSettingsGroup.EditButton')}
                                    onClick={onEditClick}
                                    fillParent={false}
                                    iconAfter="chevron"
                                    variant={Variant.TERTIARY}
                                    iconUseMargin
                                />
                            </Composite>
                        )}
                        {chargingTypeState === ChargingTypeState.DISABLED && (
                            <Composite gap={16}>
                                <Composite>
                                    <Text
                                        type={TextType.descriptionMedium}
                                        color={theme.buttonTextColorDisabled}
                                    >
                                        {i18n.t('PublicSettingsDifferentSettingsGroup.NotPublic')}
                                    </Text>
                                </Composite>
                                <Button
                                    name={`config-${encodeURIComponent(name)}`}
                                    text={i18n.t('PublicSettingsDifferentSettingsGroup.Undo')}
                                    onClick={onSubmitUndo}
                                    fillParent={false}
                                    variant={Variant.TERTIARY}
                                />
                            </Composite>
                        )}
                        {chargingTypeState === ChargingTypeState.SETUP_REQUIRED && (
                            <Composite>
                                <Button
                                    name={`config-${encodeURIComponent(name)}`}
                                    text={i18n.t(
                                        'PublicSettingsDifferentSettingsGroup.SetupButton'
                                    )}
                                    onClick={() => setIsExpanded(groupId)}
                                    fillParent={false}
                                    icon="chevron"
                                    variant={Variant.PRIMARY}
                                    iconUseMargin
                                />
                                <Button
                                    name={`disable-${encodeURIComponent(name)}`}
                                    variant={Variant.SECONDARY}
                                    text={i18n.t('PublicSettingsDifferentSettingsGroup.NotPublic')}
                                    fillParent={false}
                                    onClick={onSubmitNotPublic}
                                />
                            </Composite>
                        )}
                    </>
                )}
            </Composite>
            {isExpanded && (
                <Group>
                    <SetupPublicSettings
                        tariff={tariff}
                        tariffChanged={tariffChanged}
                        chargingFeeChanged={chargingFeeChanged}
                        hasChargingFee={hasChargingFee}
                        hasDurationLimit={hasDurationLimit}
                        onHasDurationLimitChanged={onHasDurationLimitChanged}
                        durationLimit={durationLimit}
                        onDurationLimitChanged={onDurationLimitChanged}
                        hasPubliclyAvailable={hasPubliclyAvailable}
                        onHasPubliclyAvailableChanged={onHasPubliclyAvailableChanged}
                        hasVisibility={hasVisibility}
                        onHasVisibilityChanged={onHasVisibilityChanged}
                        hasBroadcast={hasBroadcast}
                        onHasBroadcastChanged={onHasBroadcastChanged}
                        days={days}
                        daySettings={daySettings}
                        setDaySettings={setDaySettings}
                        currency={currency}
                        energyMeterAvailable={energyMeterAvailable}
                    />
                    <Composite justify="end">
                        <Button
                            name={`cancel-${encodeURIComponent(name)}`}
                            fillParent={false}
                            onClick={onCancelClick}
                            text={i18n.t('PublicSettingsDifferentSettingsGroup.Cancel')}
                            variant={Variant.SECONDARY}
                        />
                        <Button
                            fillParent={false}
                            name={`submit-${encodeURIComponent(name)}`}
                            onClick={onSubmitGroup}
                            text={i18n.t('PublicSettingsDifferentSettingsGroup.Done')}
                            variant={Variant.PRIMARY}
                            disabled={
                                (hasChargingFee &&
                                    tariff.pricePerHour &&
                                    tariff.pricePerKwh &&
                                    parseFloat(tariff.pricePerHour) <= 0 &&
                                    parseFloat(tariff.pricePerKwh) <= 0) ||
                                hasDurationLimit.length < 1 ||
                                (hasDurationLimit === 'YES' && durationLimit.length < 1) ||
                                (hasPubliclyAvailable.length < 1 && hasPublicAvailableFeature) ||
                                hasVisibility.length < 1 ||
                                (hasBroadcast.length < 1 && hasPublicBroadcastingFeature)
                            }
                        />
                    </Composite>
                </Group>
            )}
        </Group>
    );
}

export function PublicSettingsDifferentSettings({ match }: PublicSettingsProps) {
    const { id: chargeSystemId } = useParams<{ id: string }>();
    const [expanded, setExpanded] = useState<string>('');
    const [hasNewData, setHasNewData] = useState<boolean>(false);
    const history = useHistory();

    const groupMatch = useRouteMatch<{ id?: string }>(`${match.path}/:groupId/*`);

    const { id: groupId } = groupMatch?.params || { id: undefined };

    const { refetch, data = { chargeSystem: {} } } = useQuery(GET_CHARGE_SYSTEM, {
        variables: { id: chargeSystemId, groupId },
        fetchPolicy: 'network-only',
    });

    const { chargeSystem = {} } = data;

    const { groups = [] } = chargeSystem || {};

    const onSubmit = useCallback(() => {
        history.replace(`/charge-system/${chargeSystemId}/setup/private`);
    }, [history, chargeSystemId]);

    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        if (hasNewData) {
            refetch();
            setHasNewData(false);
        }
    }, [hasNewData, refetch]);
    return (
        <Group width="640px">
            <Group>
                <Heading level={Level.h1}>
                    {i18n.t('PublicSettingsDifferentSettings.Header')}
                </Heading>
            </Group>
            <Group>
                {groups.map((g: GroupModel) => (
                    <PublicSettingsDifferentSettingsGroup
                        key={g.id}
                        group={g}
                        isExpanded={expanded === g.id}
                        setIsExpanded={setExpanded}
                        setHasNewData={setHasNewData}
                    />
                ))}
            </Group>
            <Group>
                <Button
                    name="submit-button"
                    text={i18n.t('PublicSettingsDifferentSettings.Continue')}
                    onClick={onSubmit}
                    disabled={groups.some(
                        (g: GroupModel) => g.publicCharging === ChargingTypeState.SETUP_REQUIRED
                    )}
                />
            </Group>
        </Group>
    );
}
