import { gql } from '@apollo/client';
import { Connector } from '../../../models';

export interface StatusLogResponseType {
    connector: Connector;
}

export const GET_STATUS_LOG = gql`
    query Connectors($chargeSystemId: ID!, $id: ID!) {
        connector(chargeSystemId: $chargeSystemId, id: $id) {
            id
            alias
            connectorNumber
            statusLog {
                status
                statusHistory {
                    timeStamp
                    status
                    errorCode
                    errorInfo
                }
            }
        }
    }
`;
