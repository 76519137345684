import { gql } from '@apollo/client';

export const GROUP = gql`
    query Group($chargeSystemId: ID!, $id: ID!) {
        chargeSystem(id: $chargeSystemId) {
            group(id: $id) {
                id
                name
            }
        }
    }
`;

export const UPDATE_GROUP = gql`
    mutation updateGroup($chargeSystemId: ID!, $id: ID!, $name: String!) {
        chargeSystem(id: $chargeSystemId) {
            group(id: $id) {
                update(input: { name: $name }) {
                    id
                }
            }
        }
    }
`;
