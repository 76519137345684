import React from 'react';
import { Group, Heading, Level, Text } from '@defa/defa-component-library';
import { useParams } from 'react-router-dom';
import { Root } from './reset-password.styles';
import { BrandPanel } from '../../fractions/brand-panel';
import i18n from '../../i18n';

export const ResetPasswordResponse: React.FunctionComponent = () => {
    const { email } = useParams<{ email: string }>();

    return (
        <Root
            first={
                <Group>
                    <BrandPanel
                        heading={i18n.t('BrandPanel.Header')}
                        subHeading={i18n.t('BrandPanel.SubHeader')}
                    />
                </Group>
            }
            second={
                <Group minWidth="480px">
                    <Group>
                        <Heading level={Level.h1}>{i18n.t('ResetPasswordResponse.Header')}</Heading>
                        <Text>{i18n.t('ResetPasswordResponse.Description', { email })}</Text>
                    </Group>
                </Group>
            }
        />
    );
};
