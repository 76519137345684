import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    ActionRow,
    Button,
    Composite,
    Group,
    Heading,
    Icon,
    Level,
    Switch,
    Text,
    TextInput,
    Variant,
    useTheme,
    Loading,
    Accordion,
    AccordionItem,
    Size,
    Unit,
    TextInputMode,
} from '@defa/defa-component-library';
import { useMutation, useQuery } from '@apollo/client';
import i18n from '../../i18n';
import {
    ChargeSystemReponseType,
    GET_CHARGE_SYSTEM,
    UPDATE_CHARGE_SYSTEM,
} from './private.queries';
import { Feature, FeatureToggle } from '../../utils/feature';
import { useCurrency, useStripeStatus } from '../../utils/hooks';
import { BillingMethod, ChargingTypeState, PriceModel, StripeStatus, Tariff } from '../../models';
import { formatNumberToLocale } from '../../utils/math';
import { localizedTariff } from '../../utils/tariff';
import { hasEnergyMeterInGroup } from '../../utils/energy-meter';
import { EnergyMeterAvailableMessage } from '../../fractions/energy-meter-available';

enum BillingInterval {
    MONTHLY = 'MONTHLY',
    QUARTERLY = 'QUARTERLY',
}

export const Private = ({ onModalCloseClick }: { onModalCloseClick: () => void }) => {
    const theme = useTheme();
    const [stripeStatus] = useStripeStatus();

    const { id, groupId } = useParams<{ id: string; groupId: string }>();

    const [isPrivate, setIsPrivate] = useState(false);
    const [hasChargingFee, setHasChargingFee] = useState(false);
    const [currency] = useCurrency();
    const [activeTariffTypes, setActiveTariffTypes] = useState<string[]>([]);

    const { data, loading } = useQuery<ChargeSystemReponseType>(GET_CHARGE_SYSTEM, {
        variables: { id, groupId },
        fetchPolicy: 'network-only',
    });

    const [
        updateChargeSystem,
        { data: updatedChargeSystemData = {}, loading: loadingUpdateChargeSystem },
    ] = useMutation(UPDATE_CHARGE_SYSTEM);

    const { chargeSystem: updatedChargeSystem } = updatedChargeSystemData ?? {};
    const { chargeSystem } = data ?? {};
    const { group } = chargeSystem ?? {};
    const { privateChargingSettings } = group ?? {};
    const { privateCharging = ChargingTypeState.DISABLED } = group ?? {};

    const { tariff: initialTariff } = privateChargingSettings ?? {};

    const energyMeterAvailable = hasEnergyMeterInGroup(group);

    const [powerPrice, setPowerPrice] = useState('');
    const [timePrice, setTimePrice] = useState('');
    const [flatRate, setFlatRate] = useState('');

    const [ok, setOk] = useState(false);

    const onPowerPriceChanged = (newPowerPrice: string) => setPowerPrice(newPowerPrice);
    const onTimePriceChanged = (newTimePrice: string) => setTimePrice(newTimePrice);
    const onFlatRateChanged = (newFlatRate: string) => setFlatRate(newFlatRate);

    const payFlatMonthlyFee = ({ fixedCost }: Tariff) =>
        fixedCost && parseFloat(fixedCost) > 0 ? PriceModel.flatRate : '';
    const payForPowerUsage = ({ pricePerKwh }: Tariff) =>
        pricePerKwh && parseFloat(pricePerKwh) > 0 ? PriceModel.powerUsage : '';
    const payForTimeUsage = ({ pricePerHour }: Tariff) =>
        pricePerHour && parseFloat(pricePerHour) > 0 ? PriceModel.timeUsage : '';

    const onPriceChange = (value: string) => {
        if (activeTariffTypes.includes(value)) {
            setActiveTariffTypes((newActiveTariffTypes) =>
                newActiveTariffTypes.filter((item) => item !== value)
            );
        } else {
            setActiveTariffTypes([...activeTariffTypes, value]);
        }
    };

    const submit = useCallback(async () => {
        const newTariff = localizedTariff(
            activeTariffTypes,
            hasChargingFee,
            powerPrice,
            timePrice,
            flatRate
        );

        await updateChargeSystem({
            variables: {
                id,
                groupId,
                billingMethod: BillingMethod.CARD,
                billingInterval: BillingInterval.MONTHLY,
                privateCharging: isPrivate ? ChargingTypeState.ENABLED : ChargingTypeState.DISABLED,
                tariff: newTariff,
            },
        });
        onModalCloseClick();
    }, [
        updateChargeSystem,
        onModalCloseClick,
        id,
        groupId,
        isPrivate,
        powerPrice,
        timePrice,
        flatRate,
        activeTariffTypes,
        hasChargingFee,
    ]);

    useEffect(() => {
        setIsPrivate(privateCharging !== ChargingTypeState.DISABLED);
    }, [privateCharging]);

    useEffect(() => {
        if (initialTariff) {
            setHasChargingFee(
                [
                    payFlatMonthlyFee(initialTariff),
                    payForPowerUsage(initialTariff),
                    payForTimeUsage(initialTariff),
                ].filter(Boolean).length > 0
            );
            setActiveTariffTypes(
                [
                    payFlatMonthlyFee(initialTariff),
                    energyMeterAvailable ? payForPowerUsage(initialTariff) : '',
                    payForTimeUsage(initialTariff),
                ].filter(Boolean)
            );
            setPowerPrice(
                formatNumberToLocale(
                    parseFloat(initialTariff?.pricePerKwh ?? '0'),
                    navigator.language
                )
            );
            setTimePrice(
                formatNumberToLocale(
                    parseFloat(initialTariff?.pricePerHour ?? '0'),
                    navigator.language
                )
            );
            setFlatRate(
                formatNumberToLocale(
                    parseFloat(initialTariff?.fixedCost ?? '0'),
                    navigator.language
                )
            );
        }
    }, [initialTariff, energyMeterAvailable]);

    useEffect(() => {
        if (hasChargingFee) {
            if (activeTariffTypes.length === 0) {
                setOk(false);
                return;
            }
            if (activeTariffTypes.includes(PriceModel.powerUsage) && powerPrice === '') {
                setOk(false);
                return;
            }
            if (activeTariffTypes.includes(PriceModel.timeUsage) && timePrice === '') {
                setOk(false);
                return;
            }
            if (activeTariffTypes.includes(PriceModel.flatRate) && flatRate === '') {
                setOk(false);
                return;
            }
        }
        setOk(true);
    }, [setOk, powerPrice, timePrice, flatRate, hasChargingFee, activeTariffTypes]);

    useEffect(() => {
        if (updatedChargeSystem) {
            console.log('PRIVATE CHANGES SAVED');
        }
    }, [updatedChargeSystem]);

    return loading ? (
        <Loading />
    ) : (
        <>
            <Composite>
                <Composite>
                    <Heading level={Level.h2}>{i18n.t('GroupSettings.Private.Header')}</Heading>
                    <Switch
                        name="private"
                        checked={isPrivate}
                        onChange={() => {
                            setIsPrivate(!isPrivate);
                        }}
                    />
                </Composite>
                <Button
                    size={Size.TINY}
                    fillParent={false}
                    variant={Variant.SECONDARY}
                    icon="close"
                    onClick={onModalCloseClick}
                />
            </Composite>
            <Group>
                {isPrivate ? (
                    <>
                        <Group divider>
                            <Composite justify="start">
                                <Icon icon="privateCharging" size={42} color={theme.colorPrimary} />
                                <Text>{i18n.t('GroupSettings.Private.Description')}</Text>
                            </Composite>
                        </Group>
                        {stripeStatus === StripeStatus.DISABLED && (
                            <Group>
                                <Text color={theme.inputMessageColorInfo}>
                                    {i18n.t('Stripe.ConfigurationMissing')}
                                </Text>
                            </Group>
                        )}
                        <Group divider>
                            <Composite>
                                <Heading level={Level.h4}>
                                    {i18n.t('GroupSettings.Private.ChargingFee')}
                                </Heading>
                                {!hasChargingFee && (
                                    <Text>{i18n.t('GroupSettings.Private.Free')}</Text>
                                )}
                                <Switch
                                    name="charging-fee"
                                    checked={hasChargingFee}
                                    onChange={setHasChargingFee}
                                    disabled={stripeStatus === StripeStatus.DISABLED}
                                />
                            </Composite>
                            {hasChargingFee && (
                                <Accordion
                                    selected={activeTariffTypes}
                                    onChange={onPriceChange}
                                    multiple
                                >
                                    <AccordionItem
                                        title={i18n.t('PriceModel.PayForPowerUsageHeader')}
                                        icon="dashboard"
                                        value={PriceModel.powerUsage}
                                        disabled={!energyMeterAvailable}
                                    >
                                        {energyMeterAvailable ? (
                                            <TextInput
                                                name="powerPrice"
                                                label={i18n.t('PriceModel.PayForPowerUsageLabel')}
                                                value={powerPrice}
                                                mode={TextInputMode.CURRENCY}
                                                locale={navigator.language}
                                                currency={currency}
                                                unit={Unit.KILOWATTHOUR}
                                                onChange={onPowerPriceChanged}
                                                disabled={!energyMeterAvailable}
                                            />
                                        ) : (
                                            <EnergyMeterAvailableMessage id={id} />
                                        )}
                                    </AccordionItem>
                                    <AccordionItem
                                        title={i18n.t('PriceModel.PayForTimeUsageHeader')}
                                        icon="clock"
                                        value={PriceModel.timeUsage}
                                    >
                                        <TextInput
                                            name="timePrice"
                                            label={i18n.t('PriceModel.PayPerMinuteLabel')}
                                            mode={TextInputMode.CURRENCY}
                                            locale={navigator.language}
                                            currency={currency}
                                            unit={Unit.HOUR}
                                            value={timePrice}
                                            onChange={onTimePriceChanged}
                                        />
                                    </AccordionItem>
                                    <Feature feature={FeatureToggle.PrivateFlatMonthlyFee}>
                                        <AccordionItem
                                            title={i18n.t('PriceModel.PayFlatMonthlyHeader')}
                                            icon="refresh"
                                            value={PriceModel.flatRate}
                                        >
                                            <TextInput
                                                name="flatRate"
                                                label={i18n.t('PriceModel.PayFlatMonthlyLabel')}
                                                mode={TextInputMode.CURRENCY}
                                                locale={navigator.language}
                                                currency={currency}
                                                unit={Unit.MONTHLY}
                                                value={flatRate}
                                                onChange={onFlatRateChanged}
                                            />
                                        </AccordionItem>
                                    </Feature>
                                </Accordion>
                            )}
                        </Group>
                    </>
                ) : (
                    <>
                        <Icon icon="privateCharging" size={100} color={theme.colorPrimary} />
                        <Heading level={Level.h2}>
                            {i18n.t('GroupSettings.NotPrivate.Header')}
                        </Heading>
                        <Text>{i18n.t('GroupSettings.NotPrivate.Description')}</Text>
                    </>
                )}
            </Group>
            <ActionRow>
                <Button
                    name="submit-button"
                    text={i18n.t('Save')}
                    size={Size.SMALL}
                    variant={Variant.PRIMARY}
                    disabled={!ok || loadingUpdateChargeSystem}
                    loading={loadingUpdateChargeSystem}
                    onClick={submit}
                />
            </ActionRow>
        </>
    );
};
