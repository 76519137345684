import { Button, Group, Heading, Level, Modal, Table } from '@defa/defa-component-library';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { ChargerAccessContextWindow } from '../../../fractions/charger-access-context-window';
import i18n from '../../../i18n';
import {
    AddKeysResponseData,
    ConnectorAccess,
    GroupAccess,
    Rfid,
    RfidInputData,
} from '../../../models/user';
import { AccessDropdown } from '../../../fractions/access-dropdown';

import { Root } from './add-keys.styles';
import { ADD_KEYS } from './add-keys.queries';
import { DescriptionWrapper, emptyRfid, KeyIdWrapper } from '../../setup';
import { ChargeSystemGroupsAndConnectors } from '../../../models/charge-system';
import { NotificationContext } from '../../../utils/notification';
import { cleanRfidKey, keyExist } from '../../../utils/rfid';

export function AddKeysModal({
    onClose,
    onSubmit,
    dataset,
}: {
    onClose: () => void;
    onSubmit: Function;
    dataset: ChargeSystemGroupsAndConnectors;
}) {
    const { id } = useParams<{ id: string }>();
    const [selectedRfid, setSelectedRfid] = useState<RfidInputData>();
    const [rfids, setRfids] = useState<RfidInputData[]>([emptyRfid()]);
    const [addKeys, { loading }] = useMutation<AddKeysResponseData>(ADD_KEYS);
    const [menuPositioningElement, setMenuPositioningElement] = useState<HTMLElement | undefined>(
        undefined
    );
    const [ok, setOk] = useState(true);
    const { add: addNotification } = useContext(NotificationContext);

    const onDropdownClick = (e: React.MouseEvent, rfid: Rfid) => {
        const { target } = e;
        setSelectedRfid(rfid);
        setMenuPositioningElement(target as HTMLElement);
    };

    function setRfidKeyId(rfidKeyId: string, index: number) {
        setRfids((current) => {
            const cleanRfidKeyId = cleanRfidKey(rfidKeyId);
            const duplicateKey = keyExist(current, cleanRfidKeyId, index);

            return [
                ...current.slice(0, index),
                {
                    ...current[index],
                    keys: [cleanRfidKeyId],
                    errorMessage: duplicateKey ? 'DuplicateKeyID' : '',
                },
                ...current.slice(index + 1),
            ];
        });
    }

    const onLastRowBlur = () => {
        const [lastRfid] = rfids.slice(-1);
        if (lastRfid && lastRfid.name !== '') {
            setRfids((current) => [...current, emptyRfid(`${rfids.length + 1}`)]);
        }
    };

    function setRfidName(name: string, index: number) {
        setRfids((current) => [
            ...current.slice(0, index),
            { ...current[index], name },
            ...current.slice(index + 1),
        ]);
    }

    const setRfidErrorMessage = (rfidKey: string, errorMessage: string) => {
        const rfidIndex = rfids.findIndex((rfid) => rfid.keys.find((key) => key === rfidKey));
        const rfidInputFound = rfidIndex > -1;

        if (rfidInputFound) {
            setRfids((current) => [
                ...current.slice(0, rfidIndex),
                { ...current[rfidIndex], errorMessage },
                ...current.slice(rfidIndex + 1),
            ]);
        }
    };

    const onChargerAccessSubmit = (groups: GroupAccess[], connectors: ConnectorAccess[]) => {
        setRfids((current: Rfid[]) => {
            const rfidIndex = current.findIndex((r) => r.id === selectedRfid?.id);

            if (!selectedRfid || rfidIndex === -1) {
                return current;
            }

            return [
                ...current.slice(0, rfidIndex),
                { ...selectedRfid, groups, connectors },
                ...current.slice(rfidIndex + 1),
            ];
        });

        setMenuPositioningElement(undefined);
        setSelectedRfid(undefined);
        onLastRowBlur();
    };

    const onSubmitPress = async () => {
        const keysInputData = rfids
            .map((r: Rfid) => ({
                keys: r.keys,
                name: r.name,
                connectors: r.connectors.map((c) => ({
                    id: c.data.id,
                    accessLevel: c.accessLevel,
                })),
                groups: r.groups.map((g) => ({ id: g.data.id, accessLevel: g.accessLevel })),
            }))
            .filter((r) => r.name.length > 0);
        if (keysInputData.length > 0) {
            const response = await addKeys({
                variables: {
                    id,
                    rfidKeys: keysInputData,
                },
            });

            const addKeysResponse = response.data?.chargeSystem.addRfidKeys;
            if (addKeysResponse?.status === 200) {
                addNotification({
                    message: i18n.t('AddMoreKeys.AddKeysSuccessMessage'),
                });
                setRfids([emptyRfid()]);
                onSubmit();
            } else {
                addKeysResponse?.body?.forEach((body) =>
                    body.data.forEach((item) => setRfidErrorMessage(item, body.message))
                );
            }
        }
    };

    useEffect(() => {
        const errorRows = rfids.filter((r) => {
            const hasErrorMessage = r.errorMessage && r.errorMessage !== '';
            const hasIdNumber = r.keys.find((key) => key.length > 0);
            const hasNoName = r.name.length === 0;
            const hasNoConnectorAccess = r.connectors.length === 0;
            const hasNoGroupAccess = r.groups.length === 0;
            const hasNoAccess = hasNoConnectorAccess && hasNoGroupAccess;

            return hasIdNumber ? hasErrorMessage || hasNoAccess || hasNoName : false;
        });
        setOk(rfids.length > 1 && errorRows.length < 1);
    }, [rfids, setOk]);

    return (
        <Modal
            titleContent={<Heading level={Level.h3}>{i18n.t('AddMoreKeys.Header')}</Heading>}
            bodyContent={
                <Root>
                    <Group>
                        <Table<Rfid>
                            items={rfids}
                            columnWidths={[undefined, undefined, undefined]}
                            columnNames={[
                                i18n.t('Keys.ColumnDescription'),
                                i18n.t('Keys.ColumnRfid'),
                                i18n.t('Keys.ColumnAccess'),
                            ]}
                            columnGap="small"
                            columns={[
                                DescriptionWrapper(setRfidName),
                                KeyIdWrapper(setRfidKeyId),
                                AccessDropdown(onDropdownClick),
                            ]}
                        />
                        <ChargerAccessContextWindow
                            positioningElement={menuPositioningElement}
                            onClosePress={() => setMenuPositioningElement(undefined)}
                            onSubmit={onChargerAccessSubmit}
                            zIndex={10}
                            dataset={dataset}
                            userAccess={selectedRfid}
                            userCanBeOwner={false}
                        />
                    </Group>
                </Root>
            }
            actionContent={
                <Button
                    fillParent
                    text={i18n.t('AddMoreKeys.AddButton')}
                    disabled={!ok}
                    loading={loading}
                    onClick={onSubmitPress}
                />
            }
            onClosePress={onClose}
            zIndex={5}
            width="545px"
            showModal
        />
    );
}
