/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Variant } from '@defa/defa-component-library';
import React from 'react';
import i18n from '../../i18n';
import { PhoneCountryCode } from '../../models/phone';
import { UserChargeSystemAccess, ConnectorAccess, GroupAccess } from '../../models';

interface AccessDropDownUser {
    connectors?: ConnectorAccess[];
    groups?: GroupAccess[];
    chargeSystems?: UserChargeSystemAccess[];
    id: string | number;
    email?: string;
    phoneNumber?: string;
    phoneCountryCode?: PhoneCountryCode;
}

export function AccessDropdown<K extends AccessDropDownUser>(
    onClick: (e: any, user: K) => void = () => {}
) {
    return (user: K) => {
        const { connectors = [], groups = [], chargeSystems = [] } = user;
        const currentAccess = [
            ...chargeSystems.map((cs) => cs.data.id),
            ...connectors.map((c) => c.data.alias),
            ...groups.map((g) => g.data.name),
        ].join(',');

        const { id, email } = user;
        return (
            <Button
                key={`${id}-dropdown`}
                data-test-id={`user-${encodeURIComponent(email || id)}-dropdown`}
                onClick={(e) => onClick(e, user)}
                name={`access-${id}`}
                type="button"
                variant={Variant.DROPDOWN}
                iconAfter="chevron"
                text={i18n.t('AccessDropdown.Label')}
                tooltip={currentAccess}
                fillParent
            >
                {currentAccess}
            </Button>
        );
    };
}
