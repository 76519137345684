/* PrivateRoute component definition */
import React, { ReactElement } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuthState } from '../../auth';
import { Loading } from '../../screens/loading';

type PrivateRouteProps = RouteProps & {
    component: React.FunctionComponent;
};

export const PrivateRoute = ({
    component: Component,
    ...rest
}: PrivateRouteProps): ReactElement => {
    const { isAuthenticated, isLoading } = useAuthState();
    return (
        <Route
            {...rest}
            render={(props) =>
                // eslint-disable-next-line no-nested-ternary
                isAuthenticated === true ? (
                    <Component {...props} />
                ) : isLoading === true ? (
                    <Loading />
                ) : (
                    <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
            }
        />
    );
};
