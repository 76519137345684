import React, { useEffect, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
    Button,
    Composite,
    Group,
    Heading,
    Icon,
    Level,
    Size,
    Text,
    TextType,
    Variant,
    useTheme,
} from '@defa/defa-component-library';
import { ChargerDetailItem } from '../charger-details';
import {
    GET_CHARGEPOINT,
    GET_CHARGEPOINT_ID,
    GET_CHARGEPOINT_VARIABLES,
    RESET_CHARGEPOINT,
    VariablesResponseType,
} from './charge-point-details.queries';
import i18n from '../../../i18n';
import { ChargePoint, ResetResponseCode } from '../../../models/charge-point';
import { NotificationContext, Notification } from '../../../utils/notification';
import { DrillDown } from '../../../fractions/drilldown';

interface ConnectorResponse {
    connector: {
        chargePointId: string;
    };
}
interface ChargePointResponse {
    chargePoint: ChargePoint;
}

function getRestartMessage(
    status: boolean | undefined,
    responseCode: ResetResponseCode | undefined
): Notification {
    if (status && responseCode === ResetResponseCode.ACCEPTED) {
        return {
            id: 'RestartChargePointResponse',
            title: i18n.t('ChargePointDetails.Advanced.Restart.Confirmed.Title'),
            message: i18n.t('ChargePointDetails.Advanced.Restart.Confirmed.Message'),
        };
    }
    if (status) {
        return {
            id: 'RestartChargePointResponse',
            title: i18n.t('ChargePointDetails.Advanced.Restart.Unknown.Title'),
            message: i18n.t('ChargePointDetails.Advanced.Restart.Unknown.Message', {
                reason: responseCode ?? 'Unknown',
            }),
        };
    }

    return {
        id: 'RestartChargePointResponse',
        title: i18n.t('ChargePointDetails.Advanced.Restart.Error.Title'),
        message: i18n.t('ChargePointDetails.Advanced.Restart.Error.Message'),
    };
}

export function ChargePointDetails({
    onChargerDetailsCloseClick,
}: {
    onChargerDetailsCloseClick: () => void;
}) {
    const { id, connectorAlias } = useParams<{ id: string; connectorAlias: string }>();
    const theme = useTheme();
    const { add: addNotification } = useContext(NotificationContext);
    const { data } = useQuery<ConnectorResponse>(GET_CHARGEPOINT_ID, {
        variables: {
            chargeSystemId: id,
            id: connectorAlias,
        },
    });
    const { chargePointId } = data?.connector ?? {};
    const [getChargePoint, chargePointData] = useLazyQuery<ChargePointResponse>(GET_CHARGEPOINT, {
        variables: {
            chargeSystemId: id,
            chargePointId,
        },
    });
    const { chargePoint } = chargePointData?.data ?? {};

    const [getVariables, { loading: variablesLoading, data: variablesData }] = useLazyQuery<
        VariablesResponseType
    >(GET_CHARGEPOINT_VARIABLES, {
        variables: {
            chargePointId,
        },
    });
    const doGetVariables = useCallback(() => {
        getVariables();
    }, [getVariables]);

    const [restart, { loading: restartLoading, data: restartData, reset }] = useMutation<
        ChargePointResponse
    >(RESET_CHARGEPOINT, {
        fetchPolicy: 'network-only',
        variables: {
            chargeSystemId: id,
            chargePointId,
            type: 'HARD',
        },
    });
    const { status, responseCode } = restartData?.chargePoint.reset ?? {};

    const doRestart = useCallback(() => {
        restart();
    }, [restart]);

    useEffect(() => {
        if (chargePointId) {
            getChargePoint();
        }
    }, [chargePointId, getChargePoint]);

    useEffect(() => {
        if (!restartLoading && status !== undefined) {
            addNotification(getRestartMessage(status, responseCode));
            if (status && responseCode === ResetResponseCode.ACCEPTED) {
                onChargerDetailsCloseClick();
            }
            reset();
        }
    }, [
        addNotification,
        onChargerDetailsCloseClick,
        restartData,
        restartLoading,
        responseCode,
        reset,
        status,
    ]);

    return (
        <>
            <Group>
                <Composite>
                    <Heading level={Level.h2}>{i18n.t('ChargePointDetails.Header')}</Heading>
                    <Button
                        name="close-button"
                        icon="close"
                        fillParent={false}
                        variant={Variant.SECONDARY}
                        size={Size.TINY}
                        onClick={onChargerDetailsCloseClick}
                    />
                </Composite>
            </Group>
            <Group>
                <Group>
                    <Composite>
                        <Icon icon="boltBox" />
                        <Text type={TextType.description}>
                            {i18n.t('ChargePointDetails.Description')}
                        </Text>
                    </Composite>
                </Group>
                <Group divider>
                    <Heading level={Level.h4}>{i18n.t('ChargePointDetails.About.Header')}</Heading>
                    <ChargerDetailItem
                        primaryText={i18n.t('ChargePointDetails.About.ChargePointVendor')}
                        secondaryText={chargePoint?.chargePointVendor || ''}
                    />
                    <ChargerDetailItem
                        primaryText={i18n.t('ChargePointDetails.About.ChargePointModel')}
                        secondaryText={chargePoint?.chargePointModel || ''}
                    />
                    <ChargerDetailItem
                        primaryText={i18n.t('ChargePointDetails.About.ChargePointSerialNumber')}
                        secondaryText={chargePoint?.chargePointSerialNumber || ''}
                    />
                    <ChargerDetailItem
                        primaryText={i18n.t('ChargePointDetails.About.Id')}
                        secondaryText={chargePoint?.id || ''}
                    />
                    <ChargerDetailItem
                        primaryText={i18n.t('ChargePointDetails.About.FirmwareVersion')}
                        secondaryText={chargePoint?.firmwareVersion || ''}
                    />
                    <ChargerDetailItem
                        primaryText={i18n.t('ChargePointDetails.About.Protocol')}
                        secondaryText={chargePoint?.protocol || ''}
                    />
                    <ChargerDetailItem
                        primaryText={i18n.t('ChargePointDetails.About.LoadBalancingActive')}
                        secondaryText={i18n.t(
                            chargePoint?.loadBalancingActive
                                ? 'ChargePointDetails.About.LoadBalancingActiveValue.On'
                                : 'ChargePointDetails.About.LoadBalancingActiveValue.Off'
                        )}
                    />
                </Group>
                <Group>
                    <Heading level={Level.h3}>
                        {i18n.t('ChargePointDetails.Advanced.Header')}
                    </Heading>
                    <Composite>
                        <Group tight noMargin>
                            <Text type={TextType.descriptionBold}>
                                {i18n.t('ChargePointDetails.Advanced.Restart.Header')}
                            </Text>
                            <Text type={TextType.description} color={theme.descriptionColor}>
                                {i18n.t('ChargePointDetails.Advanced.Restart.Description')}
                            </Text>
                        </Group>

                        <Button
                            variant={Variant.SECONDARY}
                            size={Size.SMALL}
                            fillParent={false}
                            onClick={doRestart}
                            loading={restartLoading}
                            disabled={restartLoading}
                        >
                            {i18n.t('ChargePointDetails.Advanced.Restart.Button')}
                        </Button>
                    </Composite>
                    <Composite>
                        <Group tight noMargin>
                            <Text type={TextType.descriptionBold}>
                                {i18n.t('ChargePointDetails.Advanced.Variables.Header')}
                            </Text>
                            <Text type={TextType.description} color={theme.descriptionColor}>
                                {i18n.t('ChargePointDetails.Advanced.Variables.Description')}
                            </Text>
                        </Group>
                        <Button
                            variant={Variant.SECONDARY}
                            size={Size.SMALL}
                            fillParent={false}
                            onClick={doGetVariables}
                            loading={variablesLoading}
                            disabled={variablesLoading}
                        >
                            {i18n.t('ChargePointDetails.Advanced.Variables.Button')}
                        </Button>
                    </Composite>
                </Group>
                {variablesData?.readChargePointConfiguration?.status === 200 && (
                    <Group>
                        <Heading level={Level.h4}>
                            {i18n.t('ChargePointDetails.Advanced.Variables.SubHeader', {
                                chargePointId,
                            })}
                        </Heading>
                        <DrillDown data={variablesData?.readChargePointConfiguration?.keys} />
                    </Group>
                )}
            </Group>
        </>
    );
}
