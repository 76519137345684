import React from 'react';
import { Button, Heading, Level, Modal } from '@defa/defa-component-library';
import i18n from '../../i18n';
import { MarkDown } from '../mark-down';

export interface CantMoveModalProps {
    groupName: string;
    onClosePress: () => void;
}

export function CantMoveModal({ groupName, onClosePress }: CantMoveModalProps) {
    return (
        <Modal
            showModal={groupName !== ''}
            titleContent={
                <Heading level={Level.h3}>
                    {i18n.t('RearrangeGroups.CantMove.Title', {
                        groupName,
                    })}
                </Heading>
            }
            actionContent={
                <Button onClick={() => onClosePress()}>
                    {i18n.t('RearrangeGroups.CantMove.Button')}
                </Button>
            }
            maxWidth="440px"
            bodyContent={
                <MarkDown>
                    {i18n.t('RearrangeGroups.CantMove.Description', {
                        groupName,
                    })}
                </MarkDown>
            }
            onClosePress={() => onClosePress()}
        />
    );
}
