import { styled, Group } from '@defa/defa-component-library';

export const Root = styled(Group)`
    min-height: 50px;
    overflow: auto;

    & > * {
        margin-left: 0;
        margin-right: 0;
    }
`;
