import { Button, Heading, Level, Modal } from '@defa/defa-component-library';
import React, { useState } from 'react';
import { ImportedUser } from '.';
import { ImportMultipleUsers } from '../../../fractions/import-multiple-users';
import i18n from '../../../i18n';
import { NestedArray } from '../../../models/general';
import { Root } from './add-users.styles';

interface AddMultipleUsersModalProps {
    show: boolean;
    onClose: () => void;
    onSubmit: (users: ImportedUser[]) => void;
}

export function AddMultipleUsersModal({ show, onClose, onSubmit }: AddMultipleUsersModalProps) {
    const [multipleUsers, setMultipleUsers] = useState<ImportedUser[]>([]);
    const [localData, setLocalData] = useState<NestedArray<string>>([]);

    const onSubmitPress = () => {
        onSubmit(multipleUsers);
        setLocalData([]);
        setMultipleUsers([]);
    };

    const onClosePress = () => {
        onClose();
        setLocalData([]);
        setMultipleUsers([]);
    };

    return (
        <Modal
            titleContent={<Heading level={Level.h3}>{i18n.t('AddMultipleUsers.Header')}</Heading>}
            bodyContent={
                <Root>
                    <ImportMultipleUsers
                        localData={localData}
                        setLocalData={setLocalData}
                        setMultipleUsers={setMultipleUsers}
                    />
                </Root>
            }
            showModal={show}
            actionContent={
                <Button
                    fillParent
                    text={i18n.t('AddMultipleUsers.AddButton')}
                    disabled={multipleUsers.length < 1}
                    onClick={onSubmitPress}
                />
            }
            onClosePress={onClosePress}
            zIndex={5}
            width="545px"
        />
    );
}
