import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
    Button,
    Composite,
    Group,
    Heading,
    Icon,
    Level,
    Loading,
    Size,
    Text,
    TextInput,
    TextType,
    Variant,
} from '@defa/defa-component-library';
import { ChargerDetailItem } from '../charger-details';
import {
    ConnectorResponse,
    ConnectorMutationResponse,
    GET_CONNECTOR_DETAILS,
    UPDATE_DISPLAY_NAME,
} from './connector-details.queries';
import i18n from '../../../i18n';
import { NotificationContext } from '../../../utils/notification';

export function ConnectorDetails({
    onChargerDetailsCloseClick,
}: {
    onChargerDetailsCloseClick: () => void;
}) {
    const { id, connectorAlias } = useParams<{ id: string; connectorAlias: string }>();
    const { add: addNotification } = useContext(NotificationContext);

    const { data, refetch, loading } = useQuery<ConnectorResponse>(GET_CONNECTOR_DETAILS, {
        variables: {
            chargeSystemId: id,
            id: connectorAlias,
        },
    });
    const { connector } = data ?? {};

    const [displayName, setDisplayname] = useState('');
    const displayNameChanged = displayName !== connector?.displayName;

    const [updateConnector, { loading: loadingUpdateConnector }] = useMutation<
        ConnectorMutationResponse
    >(UPDATE_DISPLAY_NAME);

    const saveDisplayName = async () => {
        try {
            const { data: response } = await updateConnector({
                variables: {
                    id,
                    connector: {
                        id: connectorAlias,
                        displayName,
                    },
                },
            });
            if (!response?.chargeSystem?.updateConnector?.status) {
                throw new Error('Error updating display name');
            }
            addNotification({
                id: 'save-connector-display-name',
                message: i18n.t('ConnectorDetails.MessageSuccess'),
            });
            refetch();
        } catch (e) {
            addNotification({
                id: 'save-connector-display-name',
                message: i18n.t('ConnectorDetails.UpdateError'),
            });
        }
    };

    useEffect(() => {
        if (connector?.displayName && connector?.displayName !== '') {
            setDisplayname(connector?.displayName);
        }
    }, [connector?.displayName]);

    return (
        <>
            <Group>
                <Composite>
                    <Heading level={Level.h2}>{i18n.t('ConnectorDetails.Header')}</Heading>
                    <Button
                        name="close-button"
                        icon="close"
                        fillParent={false}
                        variant={Variant.SECONDARY}
                        size={Size.TINY}
                        onClick={onChargerDetailsCloseClick}
                    />
                </Composite>
            </Group>
            <Group>
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        <Group>
                            <Composite>
                                <Icon icon="charger2Socket" />
                                <Text type={TextType.description}>
                                    {i18n.t('ConnectorDetails.Description')}
                                </Text>
                            </Composite>
                        </Group>
                        <Group>
                            <Heading level={Level.h4}>
                                {i18n.t('ConnectorDetails.About.Header')}
                            </Heading>

                            <TextInput
                                label={i18n.t('ConnectorDetails.About.DisplayName')}
                                value={displayName}
                                name="DisplayName"
                                onChange={setDisplayname}
                                disabled={loadingUpdateConnector}
                            />
                            <ChargerDetailItem
                                primaryText={i18n.t('ConnectorDetails.About.Alias')}
                                secondaryText={connector?.alias}
                            />
                            <ChargerDetailItem
                                primaryText={i18n.t('ConnectorDetails.About.Id')}
                                secondaryText={connector?.id}
                            />
                            <ChargerDetailItem
                                primaryText={i18n.t('ConnectorDetails.About.ConnectorNumber')}
                                secondaryText={connector?.connectorNumber}
                            />
                        </Group>
                        <Group>
                            <ChargerDetailItem
                                primaryText={i18n.t('ConnectorDetails.Electricity.Power')}
                                secondaryText={connector?.power}
                            />
                            <ChargerDetailItem
                                primaryText={i18n.t('ConnectorDetails.Electricity.ConnectorType')}
                                secondaryText={connector?.type}
                            />
                            <ChargerDetailItem
                                primaryText={i18n.t('ConnectorDetails.Electricity.TotalEnergyUse')}
                                secondaryText={connector?.meterValue}
                            />
                        </Group>
                    </>
                )}
            </Group>
            {displayNameChanged && (
                <Group flex align="end">
                    <Button
                        name="save-button"
                        variant={Variant.PRIMARY}
                        size={Size.SMALL}
                        onClick={saveDisplayName}
                        fillParent={false}
                    >
                        {i18n.t('Save')}
                    </Button>
                </Group>
            )}
        </>
    );
}
