import { styled } from '@defa/defa-component-library';

export const Root = styled.div`
    flex: 1;
    display: flex;
`;

export const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
`;

export const Nav = styled.nav`
    padding-left: 0;
    margin-bottom: 0;
    flex: 1;
    display: flex;
    padding: 24px;
    flex-direction: row;
`;

export const List = styled.ul`
    border: none;
    flex: 1;
    display: flex;
    flex-direction: row;
`;

export const ListItem = styled.li`
    margin-left: 24px;
    margin-right: 24px;
    list-style: none;
`;

export const Logo = styled.img`
    height: 48px;
    pointer-events: none;
`;
