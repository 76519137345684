import { styled } from '@defa/defa-component-library';

export const Root = styled.div`
    display: flex;
    flex: 1;
    align-items: stretch;
    flex-direction: column;
    background-color: ${({ theme }) => theme.buttonColorDisabled};
    max-width: 100vw;
`;

export const Content = styled.section`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const TopSection = styled.div`
    padding: ${({ theme }) => theme.spacing(5)} ${({ theme }) => theme.spacing(10)}
        ${({ theme }) => theme.spacing(10)} ${({ theme }) => theme.spacing(10)};
`;
