import { gql } from '@apollo/client';

export const UPDATE_CHARGE_SYSTEM = gql`
    mutation UpdateChargeSystem($id: ID!, $groups: [GroupUpdateInput!]!) {
        chargeSystem(id: $id) {
            updateGroups(input: $groups) {
                status
            }
        }
    }
`;
export const GET_CHARGE_SYSTEM = gql`
    query GetChargeSystem($id: ID!) {
        chargeSystem(id: $id) {
            groups {
                id
            }
        }
    }
`;
