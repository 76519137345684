import { gql } from '@apollo/client';

export interface UpsertOrganizationResponseData {
    upsertOrganization: {
        id: string;
        chargeSystems: [
            {
                id: string;
            }
        ];
    };
}
export const UPSERT_ORGANIZATION = gql`
    mutation UpsertOrganization($id: ID!, $configurationStatus: ConfigurationStatus!) {
        upsertOrganization(input: { id: $id, configurationStatus: $configurationStatus }) {
            id
            chargeSystems {
                id
            }
        }
    }
`;

export interface ConfigurationStatusResponseData {
    organization: {
        chargeSystems: {
            configurationStatus: string;
        }[];
    };
}

export const CHARGE_SYSTEM_CONFIGURATION_STATUS = gql`
    query GetChargeSystemConfigurationStatus($id: ID!) {
        organization(id: $id) {
            chargeSystems {
                configurationStatus
            }
        }
    }
`;

export interface InviteAdminsResponseData {
    organization: {
        inviteAdmins: {
            status: boolean;
        };
    };
}
export const INVITE_ADMINS = gql`
    mutation InviteAdmins($id: ID!, $invites: [AdminInviteInput!]!) {
        organization(id: $id) {
            inviteAdmins(input: $invites) {
                status
            }
        }
    }
`;
