import { Connector } from '../connector';
import { Group } from '../group';

import { ChargeSystem } from '../charge-system';
import { PhoneCountryCode } from '../phone';

export enum UserAccessLevel {
    USER = 'USER',
    OWNER = 'OWNER',
}

export enum UserAdminAccessLevel {
    ADMIN = 'ADMIN',
    OWNER = 'OWNER',
}
export interface UserChargeSystemAccess {
    data: ChargeSystem;
    accessLevel: UserAdminAccessLevel;
}

export interface GroupAccess {
    data: Group;
    accessLevel: UserAccessLevel | UserAdminAccessLevel;
}

export interface ConnectorAccess {
    data: Connector;
    accessLevel: UserAccessLevel;
}

export interface User {
    id: string;
    name?: string;
    lastName?: string;
    discount?: number;
    phoneCountryCode?: PhoneCountryCode;
    phoneNumber?: string;
    email?: string;
    groups: GroupAccess[];
    connectors: ConnectorAccess[];
    expirationDate?: Date;
    errorMessage?: string;
    activeGroupInvoice?: boolean;
}

export interface Rfid {
    id: string;
    keys: string[];
    name: string;
    groups: GroupAccess[];
    connectors: ConnectorAccess[];
}

export interface RfidInputData extends Rfid {
    errorMessage?: string;
}

export interface ResponseBody {
    message: string;
    data: string[];
}

export interface AddKeysResponseData {
    chargeSystem: {
        addRfidKeys: {
            status: number;
            body: null | ResponseBody[];
        };
    };
}

export interface InviteUsersResponseData {
    chargeSystem: {
        inviteUsers: {
            status: number;
            body: null | ResponseBody[];
        };
    };
}

export interface UserDiscountResponseData {
    chargeSystem: {
        userDiscount: {
            status: number;
        };
    };
}

export interface UserPaymentTypeResponseData {
    chargeSystem: {
        userPaymentType: {
            status: number;
        };
    };
}
