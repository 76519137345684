import React, { useState } from 'react';

import { Heading, Level, Button, Group, Modal, TextInput } from '@defa/defa-component-library';
import i18n from '../../i18n';
import { Root } from './add-group-modal.styles';

export function AddGroupModal({
    onClose,
    onSave,
    showModal = false,
}: {
    onClose: () => void;
    onSave: (name: string) => void;
    showModal?: boolean;
}) {
    const [name, setName] = useState<string>('');

    const onSavePress = () => {
        onSave(name);
        setName('');
    };

    const onClosePress = () => {
        onClose();
        setName('');
    };

    const onTextChange = (value: string) => {
        setName(value);
    };

    const valid = name && name !== '';

    return (
        <Modal
            width="400px"
            showModal={showModal}
            titleContent={
                <Group tight>
                    <Heading level={Level.h3}>{i18n.t('ChargerGroups.GroupFormHeader')}</Heading>
                </Group>
            }
            bodyContent={
                <Root>
                    <TextInput
                        name="group-name"
                        label={i18n.t('ChargerGroups.GroupNameLabel')}
                        value={name}
                        onChange={onTextChange}
                    />
                </Root>
            }
            actionContent={
                <Button
                    text={i18n.t('Save')}
                    name="save-group-button"
                    onClick={onSavePress}
                    disabled={!valid}
                />
            }
            onClosePress={onClosePress}
        />
    );
}
