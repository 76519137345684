import { Connector } from '../connector';
import { Tariff } from '../general';

export enum ChargingTypeState {
    DISABLED = 'DISABLED',
    SETUP_REQUIRED = 'SETUP_REQUIRED',
    ENABLED = 'ENABLED',
}

export enum GroupPriceModel {
    FLAT_RATE = 'FLAT_RATE',
    POWER_USAGE = 'POWER_USAGE',
    TIME_USAGE = 'TIME_USAGE',
    FREE = 'FREE',
}

export enum BillingMethod {
    CARD = 'CARD',
    INVOICE = 'INVOICE',
}

export enum BillingInterval {
    DAILY = 'DAILY',
    MONTHLY = 'MONTHLY',
    QUARTERLY = 'QUARTERLY',
    SEMI_YEARLY = 'SEMI_YEARLY',
    YEARLY = 'YEARLY',
}

export interface Group {
    id: string;
    name: string;
    privateCharging: ChargingTypeState;
    privateChargingSettings: PrivateChargingSettings;
    publicChargingSettings: PublicChargingSettings | null;
    publicCharging: ChargingTypeState;
    connectors: Connector[];
    visibleInApps: boolean;
    broadcast: boolean;
}

export interface PublicChargingSettings {
    schema: PublicChargingTime[] | null;
    durationLimit: number;
    visibility?: Visibility;
    priceModel: GroupPriceModel;
    priceModelAmount: string;
    tariff?: Tariff;
}

export interface PrivateChargingSettings {
    priceModel: GroupPriceModel;
    priceModelAmount: string;
    billingMethod: BillingMethod;
    billingInterval: BillingInterval;
    accountNumber: string;
    tariff?: Tariff;
}

export interface PublicChargingTime {
    day: string;
    from?: string;
    to?: string;
    isAllDay?: boolean;
}

export interface PublicChargingDurationLimit {
    limit: number;
    idleFree: boolean;
}

export interface Visibility {
    isSearchable: boolean;
    isPublic: boolean;
}
