import React, { useEffect, useState } from 'react';

import {
    Heading,
    Level,
    Button,
    Group,
    Modal,
    Text,
    Switch,
    TextInput,
    TextInputMode,
    TextType,
} from '@defa/defa-component-library';
import i18n from '../../i18n';
import { testPasswordPolicy } from '../../utils/password-policy';

export function ChangePasswordModal({
    onClose,
    onSave,
    showModal = false,
    loading,
}: {
    onClose: () => void;
    onSave: (oldPassword: string, newPassword: string) => void;
    showModal?: boolean;
    loading?: boolean;
}) {
    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const onSavePress = () => onSave(currentPassword, newPassword);

    const onClosePress = () => onClose();

    const onCurrentPasswordChange = (value: string) => setCurrentPassword(value);

    const onNewPasswordChange = (value: string) => setNewPassword(value);

    const onConfirmPasswordChange = (value: string) => setConfirmPassword(value);

    const onShowPasswordChange = (value: boolean) => setShowPassword(value);

    const valid = newPassword === confirmPassword && testPasswordPolicy(newPassword);

    useEffect(() => {
        if (!showModal) {
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
            setShowPassword(false);
        }
    }, [showModal]);

    return (
        <Modal
            width="400px"
            showModal={showModal}
            titleContent={
                <Group tight>
                    <Heading level={Level.h3}>{i18n.t('ChangePassword.Header')}</Heading>
                </Group>
            }
            bodyContent={
                <Group tight>
                    <TextInput
                        name="current-password"
                        label={i18n.t('ChangePassword.CurrentPassword')}
                        mode={showPassword ? TextInputMode.PLAIN_TEXT : TextInputMode.PASSWORD}
                        value={currentPassword}
                        onChange={onCurrentPasswordChange}
                    />
                    <TextInput
                        name="new-password"
                        label={i18n.t('ChangePassword.NewPassword')}
                        mode={showPassword ? TextInputMode.PLAIN_TEXT : TextInputMode.PASSWORD}
                        value={newPassword}
                        message={
                            newPassword.length > 0 &&
                            !testPasswordPolicy(newPassword) &&
                            i18n.t('ChangePassword.PasswordPolicyErrorMessage')
                        }
                        onChange={onNewPasswordChange}
                    />
                    <TextInput
                        name="confirm-password"
                        label={i18n.t('ChangePassword.ConfirmPassword')}
                        mode={showPassword ? TextInputMode.PLAIN_TEXT : TextInputMode.PASSWORD}
                        value={confirmPassword}
                        onChange={onConfirmPasswordChange}
                        message={
                            confirmPassword.length > 0 &&
                            newPassword !== confirmPassword &&
                            i18n.t('ChangePassword.MessageMatch')
                        }
                    />
                    <Text type={TextType.description}>
                        {i18n.t('ChangePassword.PasswordDescription')}
                    </Text>
                    <Switch
                        justify="start"
                        reverse
                        label={i18n.t('CreateAccount.ShowPasswordSwitch')}
                        name="show-password-switch"
                        checked={showPassword}
                        onChange={onShowPasswordChange}
                    />
                </Group>
            }
            actionContent={
                <Button
                    text={i18n.t('Save')}
                    name="save-group-button"
                    onClick={onSavePress}
                    disabled={!valid}
                    loading={loading}
                />
            }
            onClosePress={onClosePress}
        />
    );
}
