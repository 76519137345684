import React, { ReactElement } from 'react';

import {
    Button,
    Composite,
    Group,
    Heading,
    Level,
    Size,
    Variant,
} from '@defa/defa-component-library';
import { Image, Root } from './company-item.styles';
import { logos } from '../../assets/logos';

export interface CompanyItemProps {
    logoIndex: number;
    heading: string;
    description: ReactElement;
    primaryButtonText: string;
    onClick?: () => void;
    activated?: boolean;
    children?: ReactElement;
}

export const CompanyItem = ({
    logoIndex,
    heading,
    description,
    primaryButtonText,
    onClick,
    activated,
    children,
}: CompanyItemProps) => (
    <Root activated={activated} data-test-id={`company-item-${heading}`}>
        <Image src={logos[logoIndex]} />
        <Group tight noMargin>
            <Composite justify="start" align="center">
                <Heading level={Level.h4}>{heading}</Heading>
                {description}
                {onClick && (
                    <Button
                        name={`setup-button-${heading}`}
                        size={Size.SMALL}
                        variant={activated ? Variant.TEXT : Variant.PRIMARY}
                        fillParent={false}
                        onClick={onClick}
                        icon={activated ? 'externalLink' : undefined}
                        iconUseMargin={activated}
                    >
                        {primaryButtonText}
                    </Button>
                )}
            </Composite>
            {children}
        </Group>
    </Root>
);
