import React from 'react';
import { useClient } from '@splitsoftware/splitio-react';
import { useAuthState } from '../../auth';

export enum FeatureToggle {
    ShowEnvironment = 'SHOW_ENVIRONMENT',
    PriceOptimization = 'PRICE_OPTIMIZATION',
    IncompletePayments = 'INCOMPLETE_PAYMENTS',
    InvoiceArvato = 'INVOICE_ARVATO',
    PendingPayments = 'PENDING_PAYMENTS',
    PublicBroadcasting = 'PUBLIC_BROADCASTING',
    PublicAvailable = 'PUBLIC_AVAILABLE',
    PrivateFlatMonthlyFee = 'PRIVATE_FLAT_MONTHLY_FEE',
}

export const userHasFeature = (feature: FeatureToggle, client: any) => {
    const treatment = client.getTreatment(feature);
    return treatment === 'on';
};

export const UserHasFeature = (feature: FeatureToggle) => {
    const { adminId = 'default-user-id' } = useAuthState();
    const client = useClient(adminId);
    return [userHasFeature(feature, client)];
};

export const Feature = ({
    feature,
    children,
}: {
    feature: FeatureToggle;
    children: React.ReactNode;
}) => {
    const [hasFeature] = UserHasFeature(feature);
    if (hasFeature) {
        return <>{children}</>;
    }
    return null;
};
