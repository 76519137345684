import React from 'react';

import {
    Heading,
    Level,
    Button,
    Group,
    Modal,
    Text,
    TextType,
    Composite,
    Variant,
} from '@defa/defa-component-library';
import i18n from '../../i18n';
import { Root } from './delete-group-modal.styles';

export function DeleteGroupModal({
    onClosePress,
    onSubmit,
    showModal = false,
    groupName,
}: {
    onClosePress: () => void;
    onSubmit: () => void;
    showModal?: boolean;
    groupName: string;
}) {
    return (
        <Modal
            width="400px"
            showModal={showModal}
            titleContent={
                <Group tight>
                    <Heading level={Level.h3}>{i18n.t('DeleteGroupModal.Header')}</Heading>
                </Group>
            }
            bodyContent={
                <Root>
                    <Text type={TextType.descriptionBold}>
                        {i18n.t('DeleteGroupModal.Description', { groupName })}
                    </Text>
                </Root>
            }
            actionContent={
                <Composite>
                    <Button
                        text={i18n.t('Cancel')}
                        name="cancel-delete-group-button"
                        onClick={onClosePress}
                        variant={Variant.TERTIARY}
                    />
                    <Button
                        text={i18n.t('Delete')}
                        name="delete-group-button"
                        onClick={onSubmit}
                        variant={Variant.PRIMARY}
                    />
                </Composite>
            }
            onClosePress={onClosePress}
        />
    );
}
