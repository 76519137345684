import { styled } from '@defa/defa-component-library';

export const Cell = styled.td`
    font-size: ${({ theme }) => theme.descriptionSize};
    line-height: ${({ theme }) => theme.descriptionLineHeight};
    font-weight: 400;
    color: ${({ theme }) => theme.simpleTableBodyColor};

    padding: ${({ theme }) => theme.spacing(2)};
    text-align: left;
    border-bottom: 1px solid ${({ theme }) => theme.simpleTableBorderColor};
    border-right: 1px solid ${({ theme }) => theme.simpleTableBorderColor};
    &:last-child {
        border-right: none;
    }
`;

export const DateCell = styled(Cell)`
    white-space: nowrap;
`;
