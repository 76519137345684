import { styled } from '@defa/defa-component-library';

export const Root = styled.div`
    display: flex;
    flex: 1;
    align-items: stretch;
    flex-direction: column;
    background-color: ${({ theme }) => theme.buttonColorDisabled};
`;

export const Content = styled.section`
    display: grid;
    flex: 1;
    padding: 0 ${({ theme }) => theme.spacing(10)} ${({ theme }) => theme.spacing(10)};
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: minmax(min-content, max-content);
    gap: ${({ theme }) => theme.spacing(6)};
`;

export const InfoBoxRoot = styled.div<{ color: string }>`
    padding: ${({ theme }) => theme.spacing(2)};
    background-color: ${({ color }) => `${color}`};
    
    border-radius: ${({ theme }) => theme.spacing(2)};;

    & > * {
        color: #fff;
    }
`;
