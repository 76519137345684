import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import {
    ActionRow,
    Button,
    Composite,
    Group,
    Heading,
    Level,
    Size,
    TextInput,
    Variant,
} from '@defa/defa-component-library';
import { useParams } from 'react-router-dom';

import i18n from '../../i18n';
import { GROUP, UPDATE_GROUP } from './about.queries';

export const About = ({ onModalCloseClick }: { onModalCloseClick: () => void }) => {
    const { id: chargeSystemId, groupId: id } = useParams<{ id: string; groupId: string }>();

    const { refetch, data: chargeSystemData = { chargeSystem: {} } } = useQuery(GROUP, {
        variables: { chargeSystemId, id },
    });

    const { group } = chargeSystemData?.chargeSystem || {};

    const defaultGroup = React.useMemo(() => ({}), []);

    const { name: initialName } = group || defaultGroup;

    const [name, setName] = useState<string>('');

    const valid = !!name;

    const [updateGroup, { loading }] = useMutation(UPDATE_GROUP);

    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        setName(initialName);
    }, [initialName]);

    const onNameChange = useCallback(
        (text: string) => {
            setName(text);
        },
        [setName]
    );

    const submit = useCallback(() => {
        if (valid) {
            updateGroup({
                variables: {
                    id,
                    chargeSystemId,
                    name,
                },
            }).then(() => {
                onModalCloseClick();
            });
        }
    }, [valid, updateGroup, onModalCloseClick, id, chargeSystemId, name]);

    return (
        <>
            <Group>
                <Composite>
                    <Heading level={Level.h2}>{i18n.t('GroupSettings.Header')}</Heading>
                    <Button
                        name="close-button"
                        size={Size.TINY}
                        fillParent={false}
                        variant={Variant.SECONDARY}
                        icon="close"
                        onClick={onModalCloseClick}
                    />
                </Composite>
            </Group>
            <Group>
                <TextInput
                    label={i18n.t('GroupSettings.NameLabel')}
                    name="name"
                    value={name}
                    onChange={onNameChange}
                />
            </Group>
            <ActionRow>
                <Button
                    name="submit-button"
                    text={i18n.t('Save')}
                    size={Size.SMALL}
                    variant={Variant.PRIMARY}
                    disabled={!valid}
                    loading={loading}
                    onClick={submit}
                />
            </ActionRow>
        </>
    );
};
