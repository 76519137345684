import { gql } from '@apollo/client';

export const LOGIN = gql`
    mutation login($username: String!, $password: String!) {
        login(input: { username: $username, password: $password }) {
            status
            token
        }
    }
`;

export const CREATE_ACCOUNT = gql`
    mutation createAccount(
        $adminId: ID!
        $firstName: String!
        $lastName: String!
        $phoneNumber: String!
        $email: String!
        $password: String!
        $inviteToken: String!
        $acceptedPrivacyConditions: Boolean!
    ) {
        addAdmin(
            input: {
                id: $adminId
                firstName: $firstName
                lastName: $lastName
                phoneNumber: $phoneNumber
                email: $email
                inviteToken: $inviteToken
                password: $password
                acceptedPrivacyConditions: $acceptedPrivacyConditions
            }
        ) {
            status
            token
        }
    }
`;

export const AUTHENTICATED_STATUS = gql`
    query {
        authenticated {
            status
        }
        admin {
            id
        }
    }
`;
