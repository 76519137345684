import { gql } from '@apollo/client';
import { ChargeSystem } from '../../../models';

export interface ChargeSystemResponseType {
    chargeSystem: ChargeSystem;
}

export const GET_CHARGE_SYSTEM = gql`
    query GetChargeSystem($id: ID!) {
        chargeSystem(id: $id) {
            id
            groups {
                id
                name
                connectors {
                    id
                    alias
                    displayName
                    energyMeterAvailable
                }
                privateCharging
                publicCharging
                privateChargingSettings {
                    tariff {
                        pricePerKwh
                    }
                }
                publicChargingSettings {
                    tariff {
                        pricePerKwh
                    }
                }
            }
            connectors {
                id
                alias
                displayName
                energyMeterAvailable
            }
        }
    }
`;

export const UPDATE_CHARGE_SYSTEM = gql`
    mutation UpdateChargeSystem(
        $id: ID!
        $groupsWithConnectors: [ConnectConnectorsToGroupInput!]!
    ) {
        chargeSystem(id: $id) {
            connectConnectorsToGroup(input: $groupsWithConnectors) {
                status
            }
        }
    }
`;

export const ADD_GROUP = gql`
    mutation AddGroup($id: ID!, $group: GroupInput!) {
        chargeSystem(id: $id) {
            addGroup(input: $group) {
                id
                name
                privateCharging
                publicCharging
            }
        }
    }
`;

export const DELETE_GROUP = gql`
    mutation DeleteGroup($id: ID!, $group: DeleteGroupInput!) {
        chargeSystem(id: $id) {
            deleteGroup(input: $group) {
                status
            }
        }
    }
`;
