import React from 'react';
import { Chip, useTheme } from '@defa/defa-component-library';
import { StripeAccountStatus } from '../../models/organization';
import i18n from '../../i18n';

export function StripeStatusBadge({
    status = StripeAccountStatus.NA,
}: {
    status: StripeAccountStatus;
}) {
    const theme = useTheme();
    let textColor;
    let backgroundColor;

    switch (status) {
        case StripeAccountStatus.COMPLETED:
            textColor = theme.colorSecondary;
            backgroundColor = theme.colorPrimary;
            break;
        case StripeAccountStatus.INCOMPLETE:
            textColor = theme.textColor;
            backgroundColor = theme.errorColor;
            break;
        default:
            textColor = theme.textColor;
            backgroundColor = theme.disabledColor;
            break;
    }
    return (
        <Chip color={backgroundColor} textColor={textColor}>
            {i18n.t(`PaymentMethodSettings.Stripe.Status.${status}`)}
        </Chip>
    );
}
