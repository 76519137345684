import { gql } from '@apollo/client';

export const ADD_KEYS = gql`
    mutation addKeys($id: ID!, $rfidKeys: [RfidKeyInput!]!) {
        chargeSystem(id: $id) {
            addRfidKeys(input: $rfidKeys) {
                status
                body {
                    message
                    data
                }
            }
        }
    }
`;
