import { Country, Currency } from '@defa/defa-component-library';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    COUNTRY_STORAGE_KEY,
    CURRENCY_STORAGE_KEY,
    ORGANIZATION_STORAGE_KEY,
    STRIPE_STATUS_STORAGE_KEY,
} from '../constants';
import { StripeStatus } from '../models/organization';

export function useQueryParam() {
    return new URLSearchParams(useLocation().search);
}

export function useOrganization(): [string, (org: string) => void] {
    let defaultOrganization = '';
    const organization = window.sessionStorage.getItem(ORGANIZATION_STORAGE_KEY);
    if (organization && organization !== '') {
        defaultOrganization = organization;
    }

    const [state, setState] = useState<string>(defaultOrganization);

    function dispatch(newOrganization: string) {
        window.sessionStorage.setItem(ORGANIZATION_STORAGE_KEY, newOrganization);
        setState(newOrganization);
    }
    return [state, dispatch];
}

export function useCurrency(): [Currency, (currency: Currency) => void] {
    let defaultCurrency = Currency.EUR;
    const currency = window.sessionStorage.getItem(CURRENCY_STORAGE_KEY) as Currency;
    if (currency) {
        defaultCurrency = currency;
    }

    const [state, setState] = useState<Currency>(defaultCurrency);

    function dispatch(newCurrency: Currency) {
        window.sessionStorage.setItem(CURRENCY_STORAGE_KEY, newCurrency);
        setState(newCurrency);
    }
    return [state, dispatch];
}

export function useCountry(): [Country, (country: Country) => void] {
    let defaultCountry = Country.SE;
    const country = window.sessionStorage.getItem(COUNTRY_STORAGE_KEY) as Country;
    if (country) {
        defaultCountry = country;
    }

    const [state, setState] = useState<Country>(defaultCountry);

    function dispatch(newCountry: Country) {
        window.sessionStorage.setItem(COUNTRY_STORAGE_KEY, newCountry);
        setState(newCountry);
    }
    return [state, dispatch];
}

export function useStripeStatus(): [StripeStatus, (status: StripeStatus) => void] {
    let defaultStatus = StripeStatus.DISABLED;
    const status = window.sessionStorage.getItem(STRIPE_STATUS_STORAGE_KEY) as StripeStatus;
    if (status) {
        defaultStatus = status;
    }

    const [state, setState] = useState<StripeStatus>(defaultStatus);

    function dispatch(newStatus: StripeStatus) {
        window.sessionStorage.setItem(
            STRIPE_STATUS_STORAGE_KEY,
            newStatus ?? StripeStatus.DISABLED
        );
        setState(newStatus);
    }
    return [state, dispatch];
}
