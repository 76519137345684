import { DEFAULT_TARIFF } from '../constants';
import { PriceModel } from '../models';
import { parseLocaleFormattedNumber } from './math';

export const localizedTariff = (
    activeTariffTypes: string[],
    hasChargingFee: boolean,
    powerPrice: string = '0',
    timePrice: string = '0',
    flatRate: string = '0'
) => {
    const newTariff = { ...DEFAULT_TARIFF };
    if (hasChargingFee) {
        if (activeTariffTypes.includes(PriceModel.powerUsage)) {
            newTariff.pricePerKwh = parseLocaleFormattedNumber(powerPrice, navigator.language);
        }
        if (activeTariffTypes.includes(PriceModel.timeUsage)) {
            newTariff.pricePerHour = parseLocaleFormattedNumber(timePrice, navigator.language);
        }
        if (activeTariffTypes.includes(PriceModel.flatRate)) {
            newTariff.fixedCost = parseLocaleFormattedNumber(flatRate, navigator.language);
        }
    }
    return newTariff;
};
