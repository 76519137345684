import React from 'react';
import { Root, List, Term, Definition } from './drilldown.styles';

function display(value: unknown): string {
    switch (typeof value) {
        case 'string':
            return value;
        case 'number':
            return value.toString();
        default:
            return '';
    }
}

export function DrillDown({ data }: { data?: Record<string, any> | null }) {
    if (data === undefined || data === null) {
        return <></>;
    }
    const items = Object.entries(data);
    return (
        <Root>
            <List>
                {items.sort().map((item) => {
                    const [key, value] = item;
                    const valueType = (typeof value).toLocaleLowerCase();
                    const empty = valueType === 'object' && Object.keys(value).length === 0;
                    const showValue =
                        valueType === 'string' ||
                        valueType === 'number' ||
                        valueType === 'undefined' ||
                        empty;

                    return (
                        <React.Fragment key={key}>
                            <Term>{key}</Term>
                            {showValue ? (
                                <Definition>{display(value)}</Definition>
                            ) : (
                                <Definition>
                                    <DrillDown data={value} />
                                </Definition>
                            )}
                        </React.Fragment>
                    );
                })}
            </List>
        </Root>
    );
}
