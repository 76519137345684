import React, { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import {
    SplitScreen,
    Group,
    Heading,
    Level,
    Button,
    Size,
    Text,
    TextType,
    Icon,
} from '@defa/defa-component-library';
import { BrandPanel } from '../../fractions/brand-panel';
import i18n from '../../i18n';
import { SetupMenu, Step } from '../../fractions/setup-menu';
import {
    GET_CHARGE_SYSTEM_NAME,
    UPDATE_CHARGE_SYSTEM,
} from './setup-charge-system-complete.queries';
import { ConfigurationStatus } from '../../models/organization';

export function SetupChargeSystemComplete() {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const { data = { chargeSystem: {} }, loading } = useQuery(GET_CHARGE_SYSTEM_NAME, {
        variables: { id },
        fetchPolicy: 'network-only',
    });

    const [updateChargeSystem, { loading: savingChargeSystem }] = useMutation(UPDATE_CHARGE_SYSTEM);

    const submitOnClick = useCallback(async () => {
        await updateChargeSystem({
            variables: {
                id,
                configurationStatus: ConfigurationStatus.COMPLETED,
            },
        });
        history.push(`/charge-system/${id}`);
    }, [updateChargeSystem, history, id]);

    return (
        <SplitScreen
            first={
                <Group>
                    <Group>
                        <BrandPanel
                            heading={i18n.t('BrandPanel.Header')}
                            subHeading={i18n.t('BrandPanel.SubHeader')}
                        />
                        <SetupMenu id={id} currentStep={Step.KEYS + 10} />
                    </Group>
                </Group>
            }
            secondSideLoading={loading}
            second={
                <Group flex align="center" justify="center" minWidth="480px">
                    <Icon icon="checkOutline" color="#2FCB76" size={60} />
                    <Group>
                        <Heading level={Level.h1} textAlign="center">
                            {i18n.t('SetupComplete.Header')}
                        </Heading>
                        <Text type={TextType.body} alignment="center" color="#4F4F4F">
                            {i18n.t('SetupComplete.Description', { name: data.chargeSystem.name })}
                        </Text>
                    </Group>
                    <Button
                        name="submit-button"
                        size={Size.LARGE}
                        onClick={submitOnClick}
                        disabled={savingChargeSystem}
                        loading={savingChargeSystem}
                    >
                        {i18n.t('SetupComplete.SubmitButton')}
                    </Button>
                </Group>
            }
        />
    );
}
