import React, { useCallback, useState } from 'react';
import {
    Accordion,
    AccordionItem,
    Composite,
    Currency,
    Group,
    Heading,
    Level,
    Switch,
    TextInput,
    TextInputMode,
    Unit,
    useTheme,
} from '@defa/defa-component-library';
import i18n from '../../i18n';
import { useStripeStatus } from '../../utils/hooks';
import { StripeStatus } from '../../models/organization';
import { PriceModel, Tariff } from '../../models';
import { formatNumberToLocale, parseLocaleFormattedNumber } from '../../utils/math';
import { EnergyMeterAvailableMessage } from '../energy-meter-available';

export function SetupPrivateSettings({
    currency,
    tariff,
    tariffChanged,
    chargingFeeChanged,
    hasChargingFee,
    energyMeterAvailable,
}: {
    currency: Currency;
    tariff: Tariff;
    tariffChanged: (newTariff: Tariff) => void;
    chargingFeeChanged: (newChargingFee: boolean) => void;
    hasChargingFee: boolean;
    energyMeterAvailable: boolean;
}) {
    const [stripeStatus] = useStripeStatus();
    const theme = useTheme();

    const stripeStatusDisabled = stripeStatus === StripeStatus.DISABLED;

    const [activeTariffTypes, setActiveTariffTypes] = useState<string[]>([]);
    const [powerPrice, setPowerPrice] = useState(
        formatNumberToLocale(parseFloat(tariff.pricePerKwh ?? '0'), navigator.language)
    );
    const [timePrice, setTimePrice] = useState(
        formatNumberToLocale(parseFloat(tariff.pricePerHour ?? '0'), navigator.language)
    );

    const onPowerPriceChanged = (newPowerPrice: string) => {
        setPowerPrice(newPowerPrice);
        tariffChanged({
            ...tariff,
            pricePerKwh: parseLocaleFormattedNumber(newPowerPrice, navigator.language),
        });
    };
    const onTimePriceChanged = (newTimePrice: string) => {
        setTimePrice(newTimePrice);
        tariffChanged({
            ...tariff,
            pricePerHour: parseLocaleFormattedNumber(newTimePrice, navigator.language),
        });
    };

    const updateChargeFee = useCallback(
        (useChargingFee) => {
            chargingFeeChanged(!useChargingFee);
        },
        [chargingFeeChanged]
    );

    const onPriceChange = (value: string) => {
        if (activeTariffTypes.includes(value)) {
            switch (value) {
                case PriceModel.timeUsage:
                    onTimePriceChanged('0');
                    break;
                case PriceModel.powerUsage:
                    onPowerPriceChanged('0');
                    break;
                default:
                    break;
            }
            setActiveTariffTypes((currentTariffTypes) =>
                currentTariffTypes.filter((item) => item !== value)
            );
        } else {
            setActiveTariffTypes([...activeTariffTypes, value]);
        }
    };

    return (
        <>
            <Group divider>
                <Heading level={Level.h4}>
                    {i18n.t('SetupPrivateSettings.ChargingFee.Header')}
                </Heading>
                {stripeStatusDisabled && (
                    <Group>
                        <Heading level={Level.h4} color={theme.inputMessageColorInfo}>
                            {i18n.t('Stripe.ConfigurationMissing')}
                        </Heading>
                    </Group>
                )}
            </Group>
            <Composite>
                <Heading level={Level.h4}>{i18n.t('GroupSettings.Private.ChargingFee')}</Heading>
                <Switch
                    name="charging-fee"
                    checked={hasChargingFee}
                    onChange={updateChargeFee}
                    disabled={stripeStatusDisabled}
                />
            </Composite>
            {hasChargingFee && (
                <>
                    <Accordion selected={activeTariffTypes} onChange={onPriceChange} multiple>
                        <AccordionItem
                            icon="clock"
                            title={i18n.t('RentalFee.TimeOptionHeader')}
                            description={i18n.t('RentalFee.TimeOptionDescription')}
                            value={PriceModel.timeUsage}
                        >
                            <TextInput
                                name="rental-fee-hours"
                                mode={TextInputMode.CURRENCY}
                                locale={navigator.language}
                                currency={currency}
                                unit={Unit.HOUR}
                                label={i18n.t('RentalFee.TimeInputLabel')}
                                value={timePrice}
                                onChange={onTimePriceChanged}
                            />
                        </AccordionItem>
                        <AccordionItem
                            icon="dashboard"
                            title={i18n.t('RentalFee.PowerUsageOptionHeader')}
                            description={i18n.t('RentalFee.PowerUsageOptionDescription')}
                            value={PriceModel.powerUsage}
                            disabled={!energyMeterAvailable}
                        >
                            {energyMeterAvailable ? (
                                <TextInput
                                    name="rental-fee-hours"
                                    mode={TextInputMode.CURRENCY}
                                    locale={navigator.language}
                                    currency={currency}
                                    unit={Unit.KILOWATTHOUR}
                                    label={i18n.t('RentalFee.PowerUsageInputLabel')}
                                    value={powerPrice}
                                    onChange={onPowerPriceChanged}
                                />
                            ) : (
                                <EnergyMeterAvailableMessage />
                            )}
                        </AccordionItem>
                    </Accordion>
                </>
            )}
        </>
    );
}
