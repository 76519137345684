import { gql } from '@apollo/client';

export const GET_ORGANIZATION = gql`
    query GetOrganization($id: ID!) {
        organization(id: $id) {
            id
            name
            configurationStatus
            chargeSystems {
                id
                name
                configurationStatus
                connectors {
                    status
                    operationalData {
                        ocpp {
                            chargingState
                            status
                            version
                        }
                        hbTimeout
                        hbLastAlive
                    }
                }
            }
        }
    }
`;
