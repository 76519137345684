import { ApolloClient, NormalizedCacheObject, useApolloClient, useMutation } from '@apollo/client';
import React, { ReactNode, useEffect } from 'react';
import { ORGANIZATION_STORAGE_KEY } from '../constants';
import { AUTHENTICATED_STATUS, CREATE_ACCOUNT, LOGIN } from './auth.queries';

const TOKEN_KEY = 'cc-t';

enum AuthStatus {
    UNAUTHENTICATED,
    AUTHENTICATED,
    PENDING,
    ERROR,
}

interface AuthContextInnerState {
    status: AuthStatus;
    token?: string;
    error?: {};
    adminId?: string;
}

interface AuthContextState extends AuthContextInnerState {
    login: (username: string, password: string) => Promise<boolean>;
    logout: () => void;
    createAccount: (variables: any) => void;
}

const AuthContext = React.createContext<AuthContextState>({
    status: AuthStatus.UNAUTHENTICATED,
    login: async () => false,
    logout: () => {},
    createAccount: () => {},
});

export function AuthProvider({
    children,
    apolloClient,
}: {
    children: ReactNode;
    apolloClient: ApolloClient<NormalizedCacheObject>;
}): React.ReactElement {
    const [doLogin] = useMutation(LOGIN);
    const [doCreateAccount] = useMutation(CREATE_ACCOUNT);

    const client = useApolloClient();
    const tokenInStorage = sessionStorage.getItem(TOKEN_KEY) as string;

    const [state, setState] = React.useState<AuthContextInnerState>({
        status: AuthStatus.PENDING,
        token: tokenInStorage,
    });

    useEffect(() => {
        const t = sessionStorage.getItem(TOKEN_KEY);
        if (t) {
            const checkAuth = async (): Promise<void> => {
                const { data } = await client.query({
                    query: AUTHENTICATED_STATUS,
                });
                const { authenticated, admin } = data;
                const { status } = authenticated;
                if (status) {
                    setState({ token: t, status: AuthStatus.AUTHENTICATED, adminId: admin.id });
                } else {
                    sessionStorage.removeItem(TOKEN_KEY);
                    setState({ status: AuthStatus.UNAUTHENTICATED, token: undefined });
                }
            };
            checkAuth();
        } else {
            apolloClient.clearStore();
            setState({ status: AuthStatus.UNAUTHENTICATED });
        }
    }, [apolloClient, client]);

    const login = async (username: string, password: string): Promise<boolean> => {
        setState({ status: AuthStatus.PENDING });
        const { data: authResponse = {} as any } = await doLogin({
            variables: {
                username,
                password,
            },
        });

        const { login: loginData } = authResponse;

        const { status, token } = loginData || {};
        if (status) {
            sessionStorage.setItem(TOKEN_KEY, token);
            sessionStorage.removeItem(ORGANIZATION_STORAGE_KEY);
            apolloClient.clearStore();
            setState({ token, status: AuthStatus.AUTHENTICATED });
            return true;
        }
        apolloClient.clearStore();
        setState({ status: AuthStatus.ERROR });
        return false;
    };
    const logout = (): void => {
        sessionStorage.removeItem(TOKEN_KEY);
        sessionStorage.removeItem(ORGANIZATION_STORAGE_KEY);
        apolloClient.clearStore();
        setState({ status: AuthStatus.UNAUTHENTICATED, token: undefined });
    };

    const createAccount = async (variables: any): Promise<void> => {
        setState({ status: AuthStatus.PENDING });
        const { data: authResponse = {} as any } = await doCreateAccount({
            variables,
        });

        const { addAdmin: loginData } = authResponse;

        const { status, token } = loginData || {};

        if (status) {
            sessionStorage.setItem(TOKEN_KEY, token);
            apolloClient.clearStore();
            setState({ token, status: AuthStatus.AUTHENTICATED });
        } else {
            apolloClient.clearStore();
            setState({ status: AuthStatus.ERROR });
        }
    };

    const contextState = { ...state, login, logout, createAccount };
    return <AuthContext.Provider value={contextState}>{children}</AuthContext.Provider>;
}

interface AuthState {
    isAuthenticated: boolean;
    isLoading: boolean;
}

export function useAuthState(): AuthContextState & AuthState {
    const state = React.useContext(AuthContext);
    const isSuccess = state.status === AuthStatus.AUTHENTICATED;
    const isAuthenticated = isSuccess; // state.user && isSuccess;
    const isLoading = state.status === AuthStatus.PENDING;
    return {
        ...state,
        isAuthenticated,
        isLoading,
    };
}
