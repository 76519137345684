import { ChargeSystem } from '../charge-system';
import { ConnectorAccess, GroupAccess } from '../user';

export enum AdminAccessLevel {
    ADMIN = 'ADMIN',
    OWNER = 'OWNER',
}
export interface ChargeSystemAccess {
    data: ChargeSystem;
    accessLevel: AdminAccessLevel;
}

export interface Admin {
    id: number;
    name?: string;
    firstName?: string;
    lastName?: string;
    email: string;
    phoneNumber?: string;
    chargeSystems?: ChargeSystemAccess[];
    groups?: GroupAccess[];
    connectors?: ConnectorAccess[];
    isSignedIn?: boolean;
    hasSmsAlerts?: boolean;
    expirationDate?: Date;
    acceptedPrivacyConditions?: boolean;
}

export interface AdminInvite {
    email?: string;
    chargeSystems?: ChargeSystemAccess[];
    groups?: GroupAccess[];
    connectors?: ConnectorAccess[];
    expirationDate?: Date;
}
