import React, { useEffect, useRef, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TextAlign,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getLocalDay } from './reports';
import { ChartProps } from './reports.types';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        tooltip: {
            titleAlign: 'center' as TextAlign,
            bodyAlign: 'center' as TextAlign,
            displayColors: false,
            padding: 8,
            callbacks: {
                title: (context: any) => {
                    const [current] = context;
                    return getLocalDay(current.dataset.dates[current.dataIndex]);
                },
                label: (context: any) => `${context.formattedValue} ${context.dataset.unit}`,
            },
        },
        legend: {
            display: false,
        },
    },
    scales: {
        x: {
            grid: {
                color: 'rgba(242, 242, 244, 1)',
            },
            ticks: {
                maxRotation: 0,
                minRotation: 0,
            },
        },
        y: {
            grid: {
                color: 'rgba(0,0,0,0)',
            },
            ticks: {
                autoSkip: false,
            },
            beginAtZero: true,
        },
    },
};

interface ChartDataObject {
    labels: string[];
    datasets: any[];
}

export function EnergyConsumptionChart({
    id,
    label,
    datalabels,
    datapoints,
    dates,
    unit,
}: ChartProps) {
    const chartRef = useRef<any>(null);
    const [data, setData] = useState<ChartDataObject>({ labels: [], datasets: [{}] });

    useEffect(() => {
        setData({
            labels: datalabels,
            datasets: [
                {
                    label,
                    data: datapoints,
                    borderColor: 'rgba(67, 97, 238, 1)',
                    backgroundColor: 'rgba(67, 97, 238, 1)',
                    tension: 0.1,
                    dates,
                    unit,
                    // pointRadius: 0,
                },
            ],
        });
        chartRef?.current?.update();
    }, [label, datapoints, datalabels, dates, unit]);

    return <Line ref={chartRef} datasetIdKey={id} options={options} data={data} />;
}
