import { gql } from '@apollo/client';

export const GET_CHARGE_SYSTEM_NAME = gql`
    query GetChargeSystem($id: ID) {
        chargeSystem(id: $id) {
            name
        }
    }
`;

export const UPDATE_CHARGE_SYSTEM = gql`
    mutation UpdateChargeSystem($id: ID!, $configurationStatus: ConfigurationStatus!) {
        chargeSystem(id: $id) {
            update(input: { configurationStatus: $configurationStatus }) {
                id
            }
        }
    }
`;
