import React, { useState } from 'react';
import {
    Button,
    Group,
    Heading,
    Level,
    RadioList,
    RadioType,
    Size,
    useTheme,
    Variant,
} from '@defa/defa-component-library';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import i18n from '../../../../i18n';
import { PrivateSettingsProps } from '../../../../models/charge-system';
import { ChargingTypeState } from '../../../../models/group';
import { GET_CHARGE_SYSTEM, UPDATE_CHARGE_SYSTEM } from './private-settings-group-info.queries';

export function PrivateSettingsGroupInfo({ match }: PrivateSettingsProps) {
    const theme = useTheme();
    const history = useHistory();
    const [settings, setSettings] = useState<string>('');
    const { id } = useParams<{ id: string }>();
    const { data = { chargeSystem: {} } } = useQuery(GET_CHARGE_SYSTEM, {
        variables: { id },
        fetchPolicy: 'network-only',
    });
    const [updateChargeSystem, { loading }] = useMutation(UPDATE_CHARGE_SYSTEM);

    const onSubmit = async () => {
        await updateChargeSystem({
            variables: {
                id,
                groups: data.chargeSystem.groups.map((g: any) => ({
                    id: g.id,
                    privateCharging: ChargingTypeState.SETUP_REQUIRED,
                })),
            },
        });
        if (settings === 'same') {
            history.push(`${match.url}/same`);
        } else {
            history.push(`${match.url}/different`);
        }
    };

    const onSettingChanged = (setting: string) => {
        setSettings(setting);
    };

    return (
        <Group maxWidth="640px" flex>
            <Group>
                <Heading level={Level.h1}>{i18n.t('PrivateSettingsGroupInfo.Header')}</Heading>
            </Group>
            <Group>
                <RadioList
                    name="plan"
                    onChange={onSettingChanged}
                    defaultChecked={settings}
                    textColors={theme.radioListTextColors}
                    items={[
                        {
                            value: 'same',
                            label: i18n.t('PrivateSettingsGroupInfo.SameSettingsHeader'),
                            icon: 'boltBox',
                            description: i18n.t('PrivateSettingsGroupInfo.SameSettingsDescription'),
                        },
                        {
                            value: 'different',
                            label: i18n.t('PrivateSettingsGroupInfo.DifferentSettingsHeader'),
                            icon: 'chargerGroup',
                            description: i18n.t(
                                'PrivateSettingsGroupInfo.DifferentSettingsDescription'
                            ),
                        },
                    ]}
                    type={RadioType.CARD}
                />
            </Group>
            <Group>
                <Button
                    name="submit-button"
                    size={Size.LARGE}
                    variant={Variant.PRIMARY}
                    onClick={onSubmit}
                    disabled={settings.length < 1 || loading}
                    loading={loading}
                >
                    {i18n.t('PrivateSettingsGroupInfo.SubmitButton')}
                </Button>
            </Group>
        </Group>
    );
}
