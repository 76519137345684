import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
    Heading,
    Level,
    Group,
    Text,
    ProgressIndicator,
    ProgressType,
} from '@defa/defa-component-library';
import { Subscription as SubscriptionFraction } from '../../fractions/subscription';

import i18n from '../../i18n';

import { GET_ORGANIZATION } from './subscription.queries';
import { ChargeSystem } from '../../models/charge-system';
import { Product } from '../../models/subscription';
import { Organization } from '../../models/organization';
import { UIState } from '../../models/ui';
import { useOrganization } from '../../utils/hooks';
import { LoadingWrapper } from './settings.styles';

export function SubscriptionSettings() {
    const [organizationId] = useOrganization();
    const [uiState, setUiState] = useState<UIState>(UIState.LOADING);

    const { data, loading } = useQuery(GET_ORGANIZATION, {
        variables: { id: organizationId },
        fetchPolicy: 'network-only',
    });

    const { organization = {} }: { organization: Organization; products: Product[] } = data || {};

    const { subscription } = organization || {};

    const connectorCount =
        organization?.chargeSystems
            ?.flatMap((cs: ChargeSystem) => cs?.connectors?.length ?? 0)
            .reduce((a, b) => a + b, 0) ?? 0;
    const chargeSystemCount = organization?.chargeSystems?.length ?? 0;

    useEffect(() => {
        setUiState(loading ? UIState.LOADING : UIState.READY);
    }, [loading, subscription]);

    return (
        <>
            {uiState === UIState.ERROR ? (
                <p>ERROR...</p>
            ) : (
                <>
                    <Group divider>
                        <Heading level={Level.h4}>{i18n.t('SubscriptionSettings.Header')}</Heading>
                        {uiState === UIState.LOADING ? (
                            <LoadingWrapper>
                                <ProgressIndicator
                                    type={ProgressType.DONUT_LOADING}
                                    progress={50}
                                />
                            </LoadingWrapper>
                        ) : (
                            <SubscriptionFraction
                                subscription={subscription}
                                connectorCount={connectorCount}
                                chargeSystemCount={chargeSystemCount}
                            />
                        )}
                    </Group>
                    <Group>
                        <Text>{i18n.t('SubscriptionSettings.Description')}</Text>
                    </Group>
                </>
            )}
        </>
    );
}
