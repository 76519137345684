import { useQuery } from '@apollo/client';
import { Button, Loading, Size, Variant } from '@defa/defa-component-library';
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Header } from '../../fractions/header';
import i18n from '../../i18n';
import { UIState } from '../../models/ui';
import { useOrganization } from '../../utils/hooks';
import { GET_ORGANIZATION } from './reports.queries';
import { RedirectErrorContent, Root } from './reports.styles';

export function getDefaultReportsPathForChargeSystem(chargeSystemId: string): string {
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    return `/reports/${chargeSystemId}/${year}/${month}`;
}

export const ReportsRedirect: React.FunctionComponent = () => {
    const history = useHistory();
    const [organizationId] = useOrganization();
    const [uiState, setUiState] = useState<UIState>(UIState.LOADING);

    const { data, loading, refetch } = useQuery(GET_ORGANIZATION, {
        variables: {
            id: organizationId,
        },
        notifyOnNetworkStatusChange: true,
    });

    const reloadOrganizationData = useCallback(() => refetch(), [refetch]);

    useEffect(() => {
        const id = data?.organization?.chargeSystems[0].id;
        if (id) {
            history.replace(getDefaultReportsPathForChargeSystem(id));
        } else {
            setUiState(loading ? UIState.LOADING : UIState.ERROR);
        }
    }, [data, history, loading]);

    return (
        <Root>
            <Header />
            {uiState === UIState.LOADING ? (
                <Loading />
            ) : (
                <RedirectErrorContent>
                    <Button
                        icon="alertTriangle"
                        iconUseMargin
                        onClick={reloadOrganizationData}
                        variant={Variant.TERTIARY}
                        size={Size.NORMAL}
                        fillParent={false}
                    >
                        {i18n.t('Reports.ReloadButtonText')}
                    </Button>
                </RedirectErrorContent>
            )}
        </Root>
    );
};
