import React, { useContext, useState, useEffect, FormEvent } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import {
    Button,
    Group,
    Heading,
    InformationType,
    Level,
    Text,
    TextInput,
    TextInputMode,
    useTheme,
    Variant,
} from '@defa/defa-component-library';
import i18n from '../../i18n';
import { useAuthState } from '../../auth';
import { Root } from './login.styles';

import { BrandPanel } from '../../fractions/brand-panel';
import { NotificationContext } from '../../utils/notification/notification';

export const Login: React.FunctionComponent = () => {
    const { isAuthenticated, login } = useAuthState();
    const history = useHistory();
    const { msg } = useParams<{ msg: string }>();
    const theme = useTheme();

    const { add: addNotification } = useContext(NotificationContext);

    const [password, setPassword] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [ok, setOk] = useState<boolean>(true);
    const valid = email && password;

    useEffect(() => {
        if (msg === 'invalidtokenoradminexist') {
            addNotification({
                message: i18n.t('LogIn.InvalidTokenOrAdminExist'),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [msg]);

    const submit = React.useCallback(
        async (event: FormEvent) => {
            event.preventDefault();
            if (valid) {
                const loginOk = await login(email, password);
                setOk(loginOk);
                if (!loginOk) {
                    addNotification({
                        message: i18n.t('LogIn.Failed'),
                    });
                }
            }
        },
        [addNotification, login, password, email, valid]
    );

    const forgotPasswordClick = React.useMemo(
        () => (): void => {
            history.push('/reset-password');
        },
        [history]
    );

    const onEmailChange = React.useMemo(
        () => (text: string): void => {
            setEmail(text);
        },
        [setEmail]
    );

    const onPasswordChange = React.useMemo(
        () => (text: string): void => {
            setPassword(text);
        },
        [setPassword]
    );

    if (isAuthenticated) {
        return <Redirect to="/" />;
    }

    return (
        <>
            <Root
                first={
                    <Group>
                        <BrandPanel
                            heading={i18n.t('BrandPanel.Header')}
                            subHeading={i18n.t('BrandPanel.SubHeader')}
                        />
                    </Group>
                }
                second={
                    <Group minWidth="480px" form onSubmit={submit}>
                        <Heading level={Level.h1}>{i18n.t('LogIn.Header')}</Heading>
                        <Group>
                            <TextInput
                                label={i18n.t('LogIn.EmailLabel')}
                                name="email"
                                value={email}
                                onChange={onEmailChange}
                                type="email"
                                autocomplete="username"
                                informationType={InformationType.ERROR}
                                message={ok ? undefined : ' '}
                            />
                            <TextInput
                                label={i18n.t('LogIn.PasswordLabel')}
                                name="password"
                                mode={TextInputMode.PASSWORD}
                                value={password}
                                onChange={onPasswordChange}
                                autocomplete="password"
                                informationType={InformationType.ERROR}
                                message={ok ? undefined : ' '}
                            />
                            {!ok && (
                                <Text color={theme.errorColor}>
                                    {i18n.t('LogIn.FailedMessage')}
                                </Text>
                            )}
                            {ok && msg === 'invalidtokenoradminexist' && (
                                <Text>{i18n.t('LogIn.InvalidTokenOrAdminExistMessage')}</Text>
                            )}
                        </Group>
                        <Group>
                            <Button
                                text={i18n.t('LogIn.SubmitButton')}
                                disabled={!valid}
                                type="submit"
                                name="login-button"
                            />
                            <Button
                                text={i18n.t('LogIn.ForgotPassword')}
                                onClick={forgotPasswordClick}
                                variant={Variant.SECONDARY}
                                name="forgotten-password"
                            />
                        </Group>
                    </Group>
                }
            />
        </>
    );
};
