import React from 'react';
import { Heading, Level, Button, Group, Modal, Variant } from '@defa/defa-component-library';
import i18n from '../../i18n';
import { localeObjectSort } from '../../utils/localeObjectSort';

export function MoveGroupModal({
    onClosePress,
    onSubmit,
    showModal = false,
    groupName,
    groups,
}: {
    onClosePress: () => void;
    onSubmit: (id: string) => void;
    showModal: boolean;
    groupName: string;
    groups: any;
}) {
    return (
        <Modal
            width="400px"
            showModal={showModal}
            titleContent={
                <Group tight>
                    <Heading level={Level.h3}>
                        {i18n.t('MoveGroupModal.Header', { groupName })}
                    </Heading>
                </Group>
            }
            bodyContent={
                <Group tight>
                    {localeObjectSort(groups, 'name').map((group: any) => (
                        <Button
                            name={`move-${group.name}`}
                            key={group.name}
                            text={group.name}
                            variant={Variant.TERTIARY}
                            onClick={() => onSubmit(group.id)}
                        />
                    ))}
                </Group>
            }
            actionContent={<></>}
            onClosePress={onClosePress}
        />
    );
}
