import { useMutation } from '@apollo/client';
import {
    Button,
    Heading,
    Level,
    Modal,
    TextInput,
    Text,
    TextInputMode,
    TextType,
    Group,
} from '@defa/defa-component-library';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import i18n from '../../../i18n';
import { UserDiscountResponseData, User } from '../../../models/user';

import { Root } from './user-discount.styles';
import { UPDATE_USER_DISCOUNT } from './user-discount.queries';
import { NotificationContext } from '../../../utils/notification';

export function UserDiscountModal({
    onClose,
    onSubmit,
    selectedUser,
}: {
    onClose: () => void;
    onSubmit: Function;
    selectedUser: User;
}) {
    const { id } = useParams<{ id: string }>();

    const [discountValue, setDiscountValue] = useState(`${selectedUser.discount ?? 0}`);
    const [ok, setOk] = useState(true);
    const { add: addNotification } = useContext(NotificationContext);

    const [updateDiscount, { loading }] = useMutation<UserDiscountResponseData>(
        UPDATE_USER_DISCOUNT
    );

    const onSubmitPress = async () => {
        const response = await updateDiscount({
            variables: {
                id,
                userId: selectedUser.id,
                discount: parseInt(discountValue, 10),
            },
        });
        const responseStatus = response.data?.chargeSystem.userDiscount.status;
        if (responseStatus) {
            addNotification({
                message: i18n.t('UserDiscount.MessageSuccess'),
            });
            onSubmit();
        } else {
            addNotification({
                message: i18n.t('UserDiscount.MessageFail'),
            });
        }
    };

    useEffect(() => {
        const discount = parseInt(discountValue, 10);
        setOk(discount >= 0 && discount <= 100);
    }, [discountValue, setOk]);

    return (
        <Modal
            titleContent={<Heading level={Level.h3}>{i18n.t('UserDiscount.Header')}</Heading>}
            bodyContent={
                <Root>
                    <Group>
                        <Text type={TextType.description}>
                            {i18n.t('UserDiscount.Description')}
                        </Text>
                        <TextInput
                            name="discount"
                            label={i18n.t('UserDiscount.Label')}
                            value={discountValue}
                            max={100}
                            min={0}
                            step={1}
                            mode={TextInputMode.NUMBER}
                            type="number"
                            onChange={(text) => {
                                setDiscountValue(text);
                            }}
                        />
                    </Group>
                </Root>
            }
            actionContent={
                <Button
                    fillParent
                    disabled={!ok}
                    loading={loading}
                    text={i18n.t('Save')}
                    onClick={onSubmitPress}
                />
            }
            onClosePress={onClose}
            zIndex={5}
            width="400px"
            showModal
        />
    );
}
