import { Theme } from '@defa/defa-component-library';
import { StatusLogType } from './log';

export enum ConnectorType {
    'TYPE1' = 'TYPE1',
    'TYPE2_CABLE' = 'TYPE2_CABLE',
    'TYPE2_SOCKET' = 'TYPE2_SOCKET',
    'TYPE3' = 'TYPE3',
    'CHADEMO' = 'CHADEMO',
    'CCS' = 'CCS',
    'SHUKO' = 'SHUKO',
    'TESLA_SC' = 'TESLA_SC',
    'UNKNOWN' = 'UNKNOWN',
}

export enum ConnectorStatus {
    'AVAILABLE' = 'AVAILABLE',
    'OCCUPIED' = 'OCCUPIED',
    'FAULTED' = 'FAULTED',
    'UNAVAILABLE' = 'UNAVAILABLE',
    'RESERVED' = 'RESERVED',
    'FINISHING' = 'FINISHING',
    'PREPARING' = 'PREPARING',
    'CHARGING' = 'CHARGING',
    'SUSPENDED_EV' = 'SUSPENDED_EV',
    'SUSPENDED_EVSE' = 'SUSPENDED_EVSE',
    'RESTARTING' = 'RESTARTING',
    'FACILITY_FINISHING' = 'FACILITY_FINISHING',
}

export enum OCCPStatus {
    'OCPP15_UNAVAILABLE' = 'OCPP15_UNAVAILABLE',
    'OCPP15_AVAILABLE' = 'OCPP15_AVAILABLE',
    'OCPP15_OCCUPIED' = 'OCPP15_OCCUPIED',
    'OCPP15_FAULTED' = 'OCPP15_FAULTED',
    'OCPP15_RESERVED' = 'OCPP15_RESERVED',
    'OCPP16_UNAVAILABLE' = 'OCPP16_UNAVAILABLE',
    'OCPP16_FAULTED' = 'OCPP16_FAULTED',
    'OCPP16_RESERVED' = 'OCPP16_RESERVED',
    'OCPP16_AVAILABLE' = 'OCPP16_AVAILABLE',
    'OCPP16_PREPARING' = 'OCPP16_PREPARING',
    'OCPP16_CHARGING' = 'OCPP16_CHARGING',
    'OCPP16_SUSPENDED_EVSE' = 'OCPP16_SUSPENDED_EVSE',
    'OCPP16_SUSPENDED_EV' = 'OCPP16_SUSPENDED_EV',
    'OCPP16_FINISHING' = 'OCPP16_FINISHING',
    'OCPP20_UNAVAILABLE' = 'OCPP20_UNAVAILABLE',
    'OCPP20_AVAILABLE' = 'OCPP20_AVAILABLE_IDLE',
    'OCPP20_AVAILABLE_IDLE' = 'OCPP20_AVAILABLE_IDLE',
    'OCPP20_OCCUPIED_EVCONNECTED' = 'OCPP20_OCCUPIED_EVCONNECTED',
    'OCPP20_OCCUPIED_CHARGING' = 'OCPP20_OCCUPIED_CHARGING',
    'OCPP20_OCCUPIED_SUSPENDEDEVSE' = 'OCPP20_OCCUPIED_SUSPENDEDEVSE',
    'OCPP20_OCCUPIED_SUSPENDEDEV' = 'OCPP20_OCCUPIED_SUSPENDEDEV',
    'OCPP20_FAULTED' = 'OCPP20_FAULTED',
    'OCPP20_RESERVED' = 'OCPP20_RESERVED',
    'OFFLINE' = 'OFFLINE',
    'FACILITY_FINISHING' = 'FACILITY_FINISHING',
}

export enum EnergyMeterAvailable {
    'PRESENT' = 'PRESENT',
    'ABSENT' = 'ABSENT',
    'UNKNOWN' = 'UNKNOWN',
}

export function getOCPPStatus(
    version: string = '',
    status: string = '',
    state: string = ''
): OCCPStatus {
    if (status === ConnectorStatus.FACILITY_FINISHING) {
        return OCCPStatus.FACILITY_FINISHING;
    }
    const key = [version, status, state.toUpperCase()].filter(Boolean).join('_') as OCCPStatus;
    if (Object.keys(OCCPStatus).includes(key)) {
        return OCCPStatus[key];
    }
    return OCCPStatus.OFFLINE;
}

export type Ocpp = {
    chargingState: string;
    status: string;
    version: string;
};

export type OperationalData = {
    ocpp: Ocpp;
    hbTimeout: boolean;
    hbLastAlive: string;
};
export type Connector = {
    id: string;
    displayName: string;
    alias: string;
    status: ConnectorStatus;
    power: number;
    type: ConnectorType;
    isPublic: boolean;
    operationalData: OperationalData;
    connectorNumber?: number;
    statusLog?: StatusLogType;
    eventLogUrl?: string;
    chargePointId?: string;
    energyMeterAvailable?: EnergyMeterAvailable;
    meterValue?: number;
};

export interface ConectorVisualStatus {
    background: string;
    icon: string;
    iconActive: string;
    iconColor: string;
    iconBackgroundColor: string;
    iconColorActive: string;
    titleColor: string;
    descriptionColor: string;
    start: boolean;
    stop: boolean;
    help: boolean;
}

export function getConnectorTypeIcon(type?: ConnectorType): string {
    switch (type) {
        case ConnectorType.TYPE1:
            return 'typeType1';
        case ConnectorType.TYPE2_CABLE:
            return 'typeType2Cable';
        case ConnectorType.TYPE2_SOCKET:
            return 'typeType2Socket';
        case ConnectorType.CHADEMO:
            return 'typeChademo';
        case ConnectorType.CCS:
            return 'typeCCS';
        case ConnectorType.SHUKO:
        case ConnectorType.TESLA_SC:
        case ConnectorType.UNKNOWN:
        default:
            return 'boltCircle';
    }
}

const defaBlue = '#4361EE';

export function getVisualStatus(
    status: OCCPStatus | string,
    theme: Theme,
    hbTimeout: boolean = false,
    isRestarting: boolean = false,
    connectorType?: ConnectorType
): ConectorVisualStatus {
    const defaultVisualStatus = {
        background: theme.cardBackground,
        titleColor: theme.textColor,
        descriptionColor: theme.cardSupportingTextColor,
        iconColor: '#fff',
        iconBackgroundColor: theme.successColor,
        iconColorActive: theme.successColor,
        icon: getConnectorTypeIcon(connectorType),
        iconActive: 'boltCircle',
        start: false,
        stop: false,
        help: false,
    };

    if (hbTimeout || status === OCCPStatus.OFFLINE || isRestarting) {
        return {
            ...defaultVisualStatus,
            help: true,
            titleColor: theme.buttonTextColorDisabled,
            iconColor: '#fff',
            iconBackgroundColor: theme.buttonTextColorDisabled,
            iconColorActive: theme.buttonTextColorDisabled,
        };
    }

    switch (status) {
        case OCCPStatus.OCPP20_UNAVAILABLE:
        case OCCPStatus.OCPP15_UNAVAILABLE:
        case OCCPStatus.OCPP16_UNAVAILABLE:
            return {
                ...defaultVisualStatus,
                help: true,
                iconColor: '#fff',
                iconBackgroundColor: theme.buttonTextColorDisabled,
                iconColorActive: theme.buttonTextColorDisabled,
                titleColor: theme.buttonTextColorDisabled,
            };
            break;

        case OCCPStatus.OCPP15_OCCUPIED:
            return {
                ...defaultVisualStatus,
                start: true,
                stop: true,
                iconColor: defaBlue,
                iconBackgroundColor: '#fff',
                titleColor: '#fff',
                descriptionColor: '#fff',
                iconColorActive: defaBlue,
                background: defaBlue,
            };
            break;

        case OCCPStatus.OCPP20_FAULTED:
        case OCCPStatus.OCPP15_FAULTED:
        case OCCPStatus.OCPP16_FAULTED:
            return {
                ...defaultVisualStatus,
                help: true,
                iconColor: theme.errorColor,
                iconBackgroundColor: '#fff',
                titleColor: '#fff',
                descriptionColor: '#fff',
                iconColorActive: theme.errorColor,
                background: theme.errorColor,
            };
            break;

        case OCCPStatus.OCPP20_OCCUPIED_EVCONNECTED:
        case OCCPStatus.OCPP16_PREPARING:
            return {
                ...defaultVisualStatus,
                iconColor: defaBlue,
                iconBackgroundColor: '#fff',
                iconColorActive: defaBlue,
                titleColor: '#fff',
                descriptionColor: '#fff',
                start: true,
                background: defaBlue,
            };
            break;

        case OCCPStatus.OCPP20_OCCUPIED_CHARGING:
        case OCCPStatus.OCPP16_CHARGING:
            return {
                ...defaultVisualStatus,
                stop: true,
                iconColor: defaBlue,
                iconBackgroundColor: '#fff',
                iconColorActive: defaBlue,
                titleColor: '#fff',
                descriptionColor: '#fff',
                background: defaBlue,
            };
            break;

        case OCCPStatus.OCPP16_FINISHING:
        case OCCPStatus.FACILITY_FINISHING:
            return {
                ...defaultVisualStatus,
                iconColor: defaBlue,
                iconBackgroundColor: '#fff',
                iconColorActive: defaBlue,
                titleColor: '#fff',
                descriptionColor: '#fff',
                background: defaBlue,
            };
            break;

        case OCCPStatus.OCPP20_OCCUPIED_SUSPENDEDEVSE:
        case OCCPStatus.OCPP20_OCCUPIED_SUSPENDEDEV:
        case OCCPStatus.OCPP16_SUSPENDED_EVSE:
        case OCCPStatus.OCPP16_SUSPENDED_EV:
            return {
                ...defaultVisualStatus,
                stop: true,
                iconColor: defaBlue,
                iconBackgroundColor: '#fff',
                titleColor: '#fff',
                descriptionColor: '#fff',
                iconColorActive: defaBlue,
                background: defaBlue,
            };
            break;

        case OCCPStatus.OCPP20_RESERVED:
        case OCCPStatus.OCPP15_RESERVED:
        case OCCPStatus.OCPP16_RESERVED:
            return {
                ...defaultVisualStatus,
                help: true,
                iconColor: defaBlue,
                titleColor: '#fff',
                descriptionColor: '#fff',
                iconBackgroundColor: '#fff',
                iconColorActive: defaBlue,
                background: defaBlue,
            };
            break;
        default:
            // Do nothing
            return defaultVisualStatus;
    }
}
