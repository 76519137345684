export type NestedArray<T> = T[][];

export enum TariffType {
    LINEAR_TARIFF = 'LinearTariff',
    PROGRESSIVE_TARIFF = 'ProgressiveTariff',
    TIMEZONED_TARIFF = 'TimezonedTariff',
}

export interface Tariff {
    type: TariffType | null;
    fixedCost?: string;
    pricePerKwh?: string;
    pricePerHour?: string;
    hourDivisor?: string;
}

export enum PriceModel {
    powerUsage = 'POWER_USAGE',
    timeUsage = 'TIME_USAGE',
    flatRate = 'FLAT_RATE',
    free = 'FREE',
}
