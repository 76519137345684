import { gql } from '@apollo/client';

export const GET_ORGANIZATION = gql`
    query GetOrganization($id: ID!) {
        organization(id: $id) {
            id
            chargeSystems {
                id
                connectors {
                    id
                }
            }
            subscription {
                subscriptionId
                startDate
                endDate
                nextInvoiceDate
                products {
                    productId
                    productName
                    productPrices {
                        productPriceId
                        price
                        priceInclVAT
                        billingPeriod
                        currency
                    }
                }
            }
        }
        products(input: { organizationId: $id }) {
            productId
            productName
            productPrices {
                productPriceId
                priceInclVAT
                price
                billingPeriod
                currency
            }
        }
    }
`;

export const ADD_SUBSCRIPTION = gql`
    mutation AddSubscription($organizationId: ID!, $productPriceId: String!) {
        addSubscription(
            input: { organizationId: $organizationId, productPriceId: $productPriceId }
        ) {
            status
        }
        upsertOrganization(input: { id: $organizationId, acceptedTermsAndConditions: true }) {
            id
        }
    }
`;
