import { Country } from '@defa/defa-component-library';
import { Tariff, TariffType } from './models';

export const THEME_INDEX = 0;
export const STRIPE_INDEX = 1;
export const ARVATO_INDEX = 2;
export const STRIPE_TEXT_INDEX = 3;

export const ORGANIZATION_STORAGE_KEY = 'org';
export const CURRENCY_STORAGE_KEY = 'currency';
export const COUNTRY_STORAGE_KEY = 'country';
export const STRIPE_STATUS_STORAGE_KEY = 'stripe_status';

export const GRAPHQL_POLLINTERVAL_SECONDS = 10000;

export const PHONE_COUNTRY_CODES = [
    { label: '🇩🇰 Denmark +45', value: '45', country: Country.DK },
    { label: '🇪🇪 Estonia +372', value: '372', country: Country.EE },
    { label: '🇫🇮 Finland +358', value: '358', country: Country.FI },
    { label: '🇬🇧 Great Britain +44', value: '44', country: Country.GB },
    { label: '🇮🇸 Iceland +354', value: '354', country: Country.IS },
    { label: '🇱🇻 Latvia +371', value: '371', country: Country.LV },
    { label: '🇱🇹 Lithuania +370', value: '370', country: Country.LT },
    { label: '🇳🇴 Norway +47', value: '47', country: Country.NO },
    { label: '🇵🇱 Poland +48', value: '48', country: Country.PL },
    { label: '🇸🇪 Sweden +46', value: '46', country: Country.SE },
];

export const DEFAULT_TARIFF: Tariff = {
    type: TariffType.LINEAR_TARIFF,
    fixedCost: '0',
    pricePerKwh: '0',
    pricePerHour: '0',
    hourDivisor: '60',
};
