import React, { ReactNode, ReactElement, useReducer } from 'react';
import { v4 as uuid } from 'uuid';
import { Toasts } from '@defa/defa-component-library';

enum NotificationActionType {
    'ADD',
    'REMOVE',
}

export interface Notification {
    id?: string;
    title?: string;
    message: string;
}
interface NotificationContextState {
    add: (notification: Notification) => void;
    remove: (notification: Notification) => void;
}
interface NotificationState {
    notifications: Notification[];
}
interface NotificationAction {
    type: NotificationActionType;
    notification: Notification;
}

export const NotificationContext = React.createContext<NotificationContextState>({
    add: () => {},
    remove: () => {},
});

const initialState: NotificationState = { notifications: [] };

export function reducer(state: NotificationState, action: NotificationAction) {
    switch (action.type) {
        case NotificationActionType.ADD:
            return {
                notifications: [...state.notifications, { id: uuid(), ...action.notification }],
            };
        case NotificationActionType.REMOVE:
            return {
                notifications: [
                    ...state.notifications.filter((item) => item.id !== action.notification?.id),
                ],
            };
        default:
            throw new Error();
    }
}

export function NotificationProvider({ children }: { children: ReactNode }): ReactElement {
    const [state, dispatch] = useReducer(reducer, initialState);

    const add = (notification: Notification) => {
        dispatch({
            type: NotificationActionType.ADD,
            notification,
        });
    };

    const remove = (notification: Notification) => {
        dispatch({
            type: NotificationActionType.REMOVE,
            notification,
        });
    };

    const contextState = { ...state, add, remove };
    return (
        <NotificationContext.Provider value={contextState}>
            {children}
            <Toasts remove={remove} toastState={state} />
        </NotificationContext.Provider>
    );
}
