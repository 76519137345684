import React, { useEffect, useState } from 'react';

import {
    Composite,
    Group,
    Heading,
    Icon,
    Level,
    Modal,
    SplitScreen,
    Text,
    TextType,
    Button,
    Variant,
    useTheme,
} from '@defa/defa-component-library';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Root, InfoWrapper } from './payment-methods.styles';
import { ARVATO_INDEX, STRIPE_INDEX } from '../../../constants';
import i18n from '../../../i18n';
import { OnboardingMenu, Step } from '../../../fractions/onboarding-menu';
import { BrandPanel } from '../../../fractions/brand-panel';
import { CompanyItem } from '../../../fractions/company-item';
import { StripeModal } from '../../../fractions/stripe-modal';
import { CreationStatus, StripeStatus as STRIPE_STATUS } from '../../../models/organization';
import { StripeStatusBadge } from '../../../fractions/stripe-status-badge';
import {
    CREATE_STRIPE_ACCOUNT,
    GENERATE_STRIPE_LINK,
    GET_ORGANIZATION,
    UPDATE_STRIPE_STATUS,
} from './payment-methods.queries';
import { Feature, FeatureToggle } from '../../../utils/feature';
import { useStripeStatus } from '../../../utils/hooks';

export const PaymentMethods: React.FunctionComponent = () => {
    const theme = useTheme();
    const [, storeStripeStatus] = useStripeStatus();

    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    const [paymentMethodsConfigured, setPaymentMethodsConfigured] = useState(false);

    const [arvatoActivated, setArvatoActivated] = useState(false);
    const [stripeModalOpen, setStripeModalOpen] = useState(false);

    const { refetch, loading, data: organizationsData } = useQuery(GET_ORGANIZATION, {
        variables: {
            id,
        },
    });

    const [createAccount, { data: accountData }] = useLazyQuery(CREATE_STRIPE_ACCOUNT, {
        variables: { organizationId: id, onboarding: true },
        fetchPolicy: 'network-only',
    });
    const { createStripeAccount: accountStatus } = accountData ?? {};

    const [generateLink, { data: linkData }] = useLazyQuery(GENERATE_STRIPE_LINK, {
        variables: { organizationId: id, onboarding: true },
        fetchPolicy: 'network-only',
    });
    const { generateStripeAccountLink: url } = linkData ?? {};

    const [updateStripeStatus] = useMutation(UPDATE_STRIPE_STATUS);
    const params = new URLSearchParams(window.location.search);
    const connectStripeAccountId = params.get('accountId');
    const { organization } = organizationsData || { organization: {} };

    const defaultOrg = React.useMemo(() => ({}), []);
    const { stripeStatus, stripeAccountId, stripeAccount } = organization || defaultOrg;

    const submit = () => {
        history.push(`/onboarding/done/${id}`);
    };

    const doGenerateLink = () => {
        if (stripeAccountId) {
            generateLink();
        } else {
            createAccount();
        }
    };

    useEffect(() => {
        if (stripeStatus) {
            storeStripeStatus(stripeStatus);
        }
    }, [storeStripeStatus, stripeStatus]);

    useEffect(() => {
        if (accountStatus === CreationStatus.EXISTS || accountStatus === CreationStatus.CREATED) {
            refetch();
            generateLink();
        }
    }, [accountStatus, generateLink, refetch, stripeAccountId]);

    useEffect(() => {
        if (url) {
            if (stripeStatus === STRIPE_STATUS.ENABLED) {
                window.open(url);
            } else {
                window.location.href = url;
            }
        }
    }, [stripeStatus, url]);

    useEffect(() => {
        if (connectStripeAccountId && id) {
            const runUpdate = async () => {
                await updateStripeStatus({
                    variables: {
                        id,
                        stripeStatus: STRIPE_STATUS.ENABLED,
                    },
                });
                refetch();
                setPaymentMethodsConfigured(true);
            };
            runUpdate();
        }
    }, [connectStripeAccountId, organization, refetch, id, updateStripeStatus]);

    useEffect(() => {
        refetch();
    }, [refetch]);

    return (
        <Root>
            <SplitScreen
                first={
                    <Group>
                        <Group>
                            <BrandPanel
                                heading={i18n.t('BrandPanel.Header')}
                                subHeading={i18n.t('BrandPanel.SubHeader')}
                            />
                            <OnboardingMenu id={id} currentStep={Step.PAYMENT_OPTIONS} />
                        </Group>
                    </Group>
                }
                secondSideLoading={loading}
                second={
                    <>
                        <Group minWidth="480px">
                            <Group>
                                <Heading level={Level.h1}>
                                    {i18n.t('PaymentMethodSettings.Header')}
                                </Heading>
                                <ReactMarkdown>
                                    {i18n.t('PaymentMethodSettings.Description')}
                                </ReactMarkdown>
                            </Group>
                            <Group>
                                <Text type={TextType.descriptionBold}>
                                    {i18n.t('PaymentMethodSettings.CreditCard')}
                                </Text>
                                {stripeStatus === STRIPE_STATUS.ENABLED ? (
                                    <CompanyItem
                                        logoIndex={STRIPE_INDEX}
                                        heading={i18n.t('PaymentMethodSettings.Stripe.Header')}
                                        description={
                                            <StripeStatusBadge status={stripeAccount?.status} />
                                        }
                                        primaryButtonText={i18n.t(
                                            'PaymentMethodSettings.Stripe.ActivatedText'
                                        )}
                                        activated
                                    >
                                        <InfoWrapper>
                                            <Composite>
                                                <Icon
                                                    icon="store"
                                                    size={theme.spacingRaw(4)}
                                                    color={theme.buttonTextColorDisabled}
                                                />
                                                <Text type={TextType.descriptionBold}>
                                                    {stripeAccount?.name}
                                                </Text>
                                            </Composite>
                                            <Composite>
                                                <Text>
                                                    {i18n.t('PaymentMethodSettings.Stripe.Id')}
                                                </Text>
                                                <Text color={theme.buttonTextColorDisabled}>
                                                    {stripeAccountId}
                                                </Text>
                                            </Composite>
                                            <Composite>
                                                <Text>
                                                    {i18n.t('PaymentMethodSettings.Stripe.Email')}
                                                </Text>
                                                <Text>{stripeAccount?.email}</Text>
                                            </Composite>
                                        </InfoWrapper>
                                    </CompanyItem>
                                ) : (
                                    <CompanyItem
                                        logoIndex={STRIPE_INDEX}
                                        heading={i18n.t('PaymentMethodSettings.Stripe.Header')}
                                        description={
                                            <Text type={TextType.descriptionSmall}>
                                                {i18n.t('PaymentMethodSettings.Stripe.Description')}
                                            </Text>
                                        }
                                        primaryButtonText={i18n.t(
                                            'PaymentMethodSettings.PrimaryButton'
                                        )}
                                        onClick={() => setStripeModalOpen(true)}
                                        activated={false}
                                    />
                                )}
                            </Group>
                            <Feature feature={FeatureToggle.InvoiceArvato}>
                                <Group>
                                    <Text type={TextType.descriptionBold}>
                                        {i18n.t('PaymentMethodSettings.Invoice')}
                                    </Text>
                                    <CompanyItem
                                        logoIndex={ARVATO_INDEX}
                                        heading={i18n.t('PaymentMethodSettings.Arvato.Header')}
                                        description={
                                            <Text type={TextType.descriptionSmall}>
                                                {i18n.t('PaymentMethodSettings.Arvato.Description')}
                                            </Text>
                                        }
                                        primaryButtonText={i18n.t(
                                            arvatoActivated
                                                ? 'PaymentMethodSettings.Arvato.ActivatedText'
                                                : 'PaymentMethodSettings.PrimaryButton'
                                        )}
                                        onClick={() => setArvatoActivated(!arvatoActivated)}
                                        activated={arvatoActivated}
                                    />
                                </Group>
                            </Feature>
                            <Button
                                name="submit-button"
                                variant={
                                    paymentMethodsConfigured ? Variant.PRIMARY : Variant.SECONDARY
                                }
                                text={i18n.t(paymentMethodsConfigured ? 'Continue' : 'Skip')}
                                onClick={submit}
                            />
                        </Group>
                        <Modal
                            maxWidth="750px"
                            padding="0"
                            onClosePress={() => setStripeModalOpen(false)}
                            showModal={stripeModalOpen}
                            bodyContent={
                                <StripeModal
                                    onClick={doGenerateLink}
                                    onClose={() => setStripeModalOpen(false)}
                                />
                            }
                            actionContent={<></>}
                        />
                    </>
                }
            />
        </Root>
    );
};
