import { styled } from '@defa/defa-component-library';

export const Root = styled.div``;

export const Description = styled.div`
    & p {
        text-align: center;
        color: ${(props) => props.theme.descriptionColor};
        font-size: ${(props) => props.theme.spacing(4)};
    }
    & p + p {
        margin-top: ${(props) => props.theme.spacing(4)};
    }
`;
