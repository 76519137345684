import React, { useEffect, useState, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
    SplitScreen,
    Heading,
    Level,
    Button,
    Group,
    Text,
    RadioList,
    RadioType,
    Composite,
    Switch,
    TextType,
    Variant,
    CheckBox,
    Link,
    Size,
} from '@defa/defa-component-library';
import { useHistory, useParams } from 'react-router-dom';

import ReactMarkdown from 'react-markdown';
import { BrandPanel } from '../../../fractions/brand-panel';
import { Subscription as SubscriptionFraction } from '../../../fractions/subscription';

import i18n from '../../../i18n';
import { OnboardingMenu, Step } from '../../../fractions/onboarding-menu';

import { GET_ORGANIZATION, ADD_SUBSCRIPTION } from './subscription.queries';
import { Summery, Block } from './subscription.styles';
import { ChargeSystem } from '../../../models/charge-system';
import { Product, ProductPrice } from '../../../models/subscription';
import { Organization } from '../../../models/organization';
import { UIState } from '../../../models/ui';
import { formatCurrencyWithLocale } from '../../../utils/math';
import { InviteAdmin } from '../../../fractions/invite-admin';

const getProductIndexById = (products: Product[], id: string) =>
    products.findIndex((p: Product) => `${p.productId}` === id);

const getProductsAsList = (products: Product[]) =>
    products?.map((p: Product) => ({
        value: `${p.productId}`,
        label: i18n.t(`Subscription.Product.${p.productName}.Header`),
        description: i18n.t(`Subscription.Product.${p.productName}.Description`),
    })) ?? [];

const getProductPricesAsList = (products: Product[], activeProduct: string) => {
    const productIndex = getProductIndexById(products, activeProduct);
    return (
        products[productIndex]?.productPrices.map((productPrice: ProductPrice) => ({
            value: `${productPrice.productPriceId}`,
            label: i18n.t(`Subscription.ProductPrice.${productPrice.billingPeriod}.Header`),
            description: i18n.t(
                `Subscription.ProductPrice.${productPrice.billingPeriod}.Description`
            ),
        })) ?? []
    );
};

export function Subscription() {
    const [uiState, setUiState] = useState<UIState>(UIState.LOADING);
    const [readOnly, setReadOnly] = useState<boolean>(true);
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    const { data, loading } = useQuery(GET_ORGANIZATION, {
        variables: { id },
        fetchPolicy: 'network-only',
    });

    const [addSubscription, { data: newSubscription }] = useMutation(ADD_SUBSCRIPTION);

    const {
        organization = {},
        products = [],
    }: { organization: Organization; products: Product[] } = data || {};

    const { subscription } = organization || {};

    const csCount = organization?.chargeSystems?.length ?? 0;
    const connectorCount =
        organization?.chargeSystems
            ?.flatMap((cs: ChargeSystem) => cs?.connectors?.length ?? 0)
            .reduce((a, b) => a + b, 0) ?? 0;

    const [outletMonthlyCost, setOutletMonthlyCost] = useState(0);
    const [outletMonthlyCostVAT, setOutletMonthlyCostVAT] = useState(0);

    const [totalMonthlyCost, setTotalMonthlyCost] = useState(0);
    const [totalMonthlyCostVAT, setTotalMonthlyCostVAT] = useState(0);

    const [totalBillingPeriodCost, setTotalBillingPeriodCost] = useState(0);
    const [totalBillingPeriodCostVAT, setTotalBillingPeriodCostVAT] = useState(0);

    const [showVAT, setShowVAT] = useState(false);
    const [productPriceIntervall, setProductPriceIntervall] = useState(1);

    const [activeProductId, setActiveProductId] = useState('');
    const [activeProductPrice, setActiveProductPrice] = useState('');

    const [currency, setCurrency] = useState<string>('EUR');

    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

    const [showInvite, setShowInvite] = useState(false);

    const submit = useCallback(() => {
        addSubscription({
            variables: {
                organizationId: id,
                productPriceId: activeProductPrice,
            },
        });
    }, [id, activeProductPrice, addSubscription]);

    const switchProductPrice = useCallback(
        (productId: string, productPriceId: string) => {
            const selectedProductIndex = getProductIndexById(products, productId);
            const { productPrices } = products[selectedProductIndex];
            const selectedProductPriceIndex = productPrices.findIndex(
                (pp: ProductPrice) => `${pp.productPriceId}` === productPriceId
            );
            const productPrice: ProductPrice = productPrices[selectedProductPriceIndex];
            const newOutletMonthlyCost = productPrice.price / productPrice.billingPeriod;
            const newOutletMonthlyCostVAT = productPrice.priceInclVAT / productPrice.billingPeriod;
            const newTotalMonthlyCost = newOutletMonthlyCost * connectorCount;
            const newTotalMonthlyCostVAT = newOutletMonthlyCostVAT * connectorCount;
            const newTotalBillingPeriodCost = productPrice.price * connectorCount;
            const newTotalBillingPeriodCostVAT = productPrice.priceInclVAT * connectorCount;

            setOutletMonthlyCost(newOutletMonthlyCost);
            setOutletMonthlyCostVAT(newOutletMonthlyCostVAT);
            setTotalMonthlyCost(newTotalMonthlyCost);
            setTotalMonthlyCostVAT(newTotalMonthlyCostVAT);
            setTotalBillingPeriodCost(newTotalBillingPeriodCost);
            setTotalBillingPeriodCostVAT(newTotalBillingPeriodCostVAT);
            setProductPriceIntervall(productPrice.billingPeriod);
            setActiveProductPrice(`${productPriceId}`);
            setCurrency(productPrice.currency);
        },
        [
            connectorCount,
            setOutletMonthlyCost,
            setOutletMonthlyCostVAT,
            setTotalMonthlyCost,
            setTotalMonthlyCostVAT,
            setTotalBillingPeriodCost,
            setTotalBillingPeriodCostVAT,
            setProductPriceIntervall,
            setActiveProductPrice,
            products,
        ]
    );
    const switchProduct = useCallback(
        (productId: string) => {
            const selectedProductIndex = getProductIndexById(products, productId);
            const defaultProductPrice =
                products[selectedProductIndex]?.productPrices[0]?.productPriceId;

            setActiveProductId(productId);
            switchProductPrice(productId, `${defaultProductPrice}`);
        },
        [setActiveProductId, switchProductPrice, products]
    );

    const next = useCallback(() => history.push(`/onboarding/payment-methods/${id}`), [
        id,
        history,
    ]);

    const onProductPriceChanged = (selected: string) => {
        switchProductPrice(activeProductId, selected);
    };

    const onProductChanged = (selected: string) => {
        switchProduct(selected);
    };

    const onShowVATChange = (show: boolean) => {
        setShowVAT(show);
    };

    const onTermsAcceptedChange = React.useMemo(
        () => (checked: boolean): void => {
            setTermsAccepted(checked);
        },
        [setTermsAccepted]
    );

    const showTerms = () => {
        window.open('/general_terms_cloudcharge.pdf', '_blank');
    };

    useEffect(() => {
        if (!loading) {
            const isReadOnly = typeof subscription?.subscriptionId === 'number';
            setReadOnly(isReadOnly);
            setUiState(UIState.READY);
        }
    }, [loading, subscription, switchProduct, switchProductPrice]);

    useEffect(() => {
        if (products.length > 0) {
            switchProduct(`${products[0].productId}`);
        }
    }, [products, switchProduct]);

    useEffect(() => {
        if (newSubscription?.addSubscription && newSubscription?.addSubscription?.status) {
            history.push(`/onboarding/payment-methods/${id}`);
        }
    }, [newSubscription, id, history]);

    return (
        <SplitScreen
            first={
                <Group>
                    <Group>
                        <BrandPanel
                            heading={i18n.t('BrandPanel.Header')}
                            subHeading={i18n.t('BrandPanel.SubHeader')}
                        />
                        <OnboardingMenu id={id} currentStep={Step.SUBSCRIPTION} />
                    </Group>
                </Group>
            }
            secondSideLoading={uiState === UIState.LOADING}
            second={
                <Group minWidth="625px">
                    <Group>
                        <Composite>
                            <Heading level={Level.h1}>{i18n.t('Subscription.Header')}</Heading>
                            <Button
                                variant={Variant.TERTIARY}
                                size={Size.SMALL}
                                text={i18n.t('Subscription.InviteAdmin')}
                                fillParent={false}
                                icon="add"
                                onClick={() => setShowInvite(true)}
                            />
                        </Composite>
                        <ReactMarkdown>{i18n.t('Subscription.Description')}</ReactMarkdown>
                    </Group>
                    {uiState === UIState.ERROR && <p>ERROR...</p>}
                    {uiState === UIState.READY && !readOnly && (
                        <>
                            <Group>
                                <Heading level={Level.h4}>
                                    {i18n.t('Subscription.ProductHeader')}
                                </Heading>
                                <RadioList
                                    name="product"
                                    items={getProductsAsList(products)}
                                    type={RadioType.BOX}
                                    onChange={onProductChanged}
                                    defaultChecked={activeProductId}
                                />
                            </Group>

                            <Group>
                                <Heading level={Level.h4}>
                                    {i18n.t('Subscription.PeriodHeader')}
                                </Heading>
                                <RadioList
                                    name="productPrice"
                                    items={getProductPricesAsList(products, activeProductId)}
                                    type={RadioType.BOX}
                                    onChange={onProductPriceChanged}
                                    defaultChecked={activeProductPrice}
                                />
                            </Group>
                            <Group>
                                <Composite>
                                    <Heading level={Level.h4}>
                                        {i18n.t('Subscription.Summary.Header')}
                                    </Heading>
                                    <Switch
                                        name="vat"
                                        label={i18n.t('Subscription.Summary.VATToggle')}
                                        onChange={onShowVATChange}
                                    />
                                </Composite>
                                <ReactMarkdown>
                                    {i18n.t('Subscription.Summary.Description')}
                                </ReactMarkdown>
                                <Summery>
                                    <Block>
                                        <Text type={TextType.descriptionMedium}>
                                            {i18n.t('Subscription.Summary.Outlets.Header')}
                                        </Text>
                                        <Heading level={Level.h1}>{connectorCount}</Heading>
                                        <Text type={TextType.descriptionSmall}>
                                            {i18n.t('Subscription.Summary.Outlets.Description', {
                                                count: csCount,
                                            })}
                                        </Text>
                                    </Block>
                                    <Block>
                                        <Text type={TextType.descriptionMedium}>
                                            {i18n.t('Subscription.Summary.Single.Header')}
                                        </Text>
                                        <Heading level={Level.h1}>
                                            {i18n.t('Subscription.Summary.Single.Value', {
                                                amount: formatCurrencyWithLocale(
                                                    showVAT
                                                        ? outletMonthlyCostVAT
                                                        : outletMonthlyCost,
                                                    navigator.language,
                                                    currency
                                                ),
                                            })}
                                        </Heading>
                                        <Text type={TextType.descriptionSmall}>
                                            {i18n.t('Subscription.Summary.Single.Description')}
                                        </Text>
                                    </Block>
                                    <Block>
                                        <Text type={TextType.descriptionMedium}>
                                            {i18n.t('Subscription.Summary.Monthly.Header')}
                                        </Text>
                                        <Heading level={Level.h1}>
                                            {i18n.t('Subscription.Summary.Monthly.Value', {
                                                amount: formatCurrencyWithLocale(
                                                    showVAT
                                                        ? totalMonthlyCostVAT
                                                        : totalMonthlyCost,
                                                    navigator.language,
                                                    currency
                                                ),
                                            })}
                                        </Heading>
                                        <Text type={TextType.descriptionSmall}>
                                            {i18n.t(
                                                `Subscription.Summary.Monthly.Description.${productPriceIntervall}`,
                                                {
                                                    amount: formatCurrencyWithLocale(
                                                        showVAT
                                                            ? totalBillingPeriodCostVAT
                                                            : totalBillingPeriodCost,
                                                        navigator.language,
                                                        currency
                                                    ),
                                                }
                                            )}
                                        </Text>
                                    </Block>
                                </Summery>
                            </Group>
                            <Group>
                                <Heading level={Level.h4}>
                                    {i18n.t('Subscription.TermsAndConditionsHeader')}
                                </Heading>
                                <CheckBox
                                    name="terms"
                                    label={i18n.t('Subscription.TermsAndConditionsLabel')}
                                    checked={termsAccepted}
                                    onChange={onTermsAcceptedChange}
                                    extra={
                                        <Link href="#" onClick={showTerms}>
                                            {i18n.t('Subscription.TermsAndConditionsLink')}
                                        </Link>
                                    }
                                />
                            </Group>

                            <Button
                                fillParent
                                name="submit-button"
                                text={i18n.t('Subscription.SubmitButton')}
                                onClick={submit}
                                disabled={!termsAccepted}
                            />
                            <InviteAdmin setShowInvite={setShowInvite} showInvite={showInvite} />
                        </>
                    )}
                    {uiState === UIState.READY && readOnly && (
                        <>
                            <SubscriptionFraction
                                subscription={subscription}
                                connectorCount={connectorCount}
                                chargeSystemCount={csCount}
                            />
                            <Button
                                fillParent
                                text={i18n.t('Next')}
                                name="submit-button"
                                onClick={next}
                                variant={Variant.SECONDARY}
                            />
                        </>
                    )}
                </Group>
            }
        />
    );
}
