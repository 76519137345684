import { gql } from '@apollo/client';

export const ORGANIZATIONS = gql`
    query {
        organizations {
            id
            name
            stripeStatus
            chargeSystems {
                id
            }
            contactAddress {
                country
            }
        }
    }
`;
