import { ConnectorStatus, OCCPStatus } from '../models/connector';

export function getStatus(hbTimeout: boolean, ocpp: OCCPStatus, isRestarting: boolean) {
    if (isRestarting) {
        return ConnectorStatus.RESTARTING;
    }
    if (hbTimeout) {
        return 'hbTimeout';
    }
    return ocpp;
}
