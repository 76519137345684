import React from 'react';
import {
    SplitScreen,
    Heading,
    Level,
    Button,
    Group,
    ActionRow,
    useTheme,
    Icon,
} from '@defa/defa-component-library';
import ReactMarkdown from 'react-markdown';
import { BrandPanel } from '../../fractions/brand-panel';

import i18n from '../../i18n';
import { useAuthState } from '../../auth';
import { Description } from './no-organization.styles';

export function NoOrganization() {
    const theme = useTheme();
    const { logout } = useAuthState();

    return (
        <SplitScreen
            first={
                <Group>
                    <Group>
                        <BrandPanel
                            heading={i18n.t('AboutChargeSystem.FlowHeader')}
                            subHeading={i18n.t('BrandPanel.SubHeader')}
                        />
                    </Group>
                </Group>
            }
            second={
                <Group flex align="center" justify="center" minWidth="480px" maxWidth="600px">
                    <Group flex align="center" justify="center">
                        <Icon icon="alertTriangle" color={theme.errorColor} size={60} />
                        <Heading level={Level.h1} textAlign="center">
                            {i18n.t('NoOrganization.Header')}
                        </Heading>
                        <Description>
                            <ReactMarkdown>{i18n.t('NoOrganization.Description')}</ReactMarkdown>
                        </Description>
                        <ActionRow>
                            <Button
                                name="submit-button"
                                text={i18n.t('Header.Account.LogOut')}
                                onClick={() => logout()}
                            />
                        </ActionRow>
                    </Group>
                </Group>
            }
        />
    );
}
