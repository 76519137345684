import React from 'react';
import { Group, Text, TextType, useTheme } from '@defa/defa-component-library';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { ChargerDetailsSideMenu } from '../../fractions/charger-details-side-menu';
import { Second, Content } from '../group-settings/group-settings.styles';
import { ConnectorDetails } from './connector-details';
import { ChargePointDetails } from './charge-point-details';
import { StatusLog } from './status-log';
import { EventLog } from './event-log';

export function ChargerDetailItem({
    primaryText,
    secondaryText,
}: {
    primaryText: string;
    secondaryText?: string | number;
}) {
    const theme = useTheme();
    return (
        <Group tight>
            <Text type={TextType.descriptionBold}>{primaryText}</Text>
            {secondaryText && (
                <Text type={TextType.description} color={theme.descriptionColor}>
                    {secondaryText}
                </Text>
            )}
        </Group>
    );
}

interface ChargerDetailsProps extends RouteComponentProps<{}>, React.Props<{}> {
    onChargerDetailsCloseClick: () => void;
}

export const ChargerDetails = ({ match, onChargerDetailsCloseClick }: ChargerDetailsProps) => (
    <Content>
        <ChargerDetailsSideMenu />
        <Second>
            <Switch>
                <Route
                    path={`${match.path}/connector`}
                    component={() => (
                        <ConnectorDetails onChargerDetailsCloseClick={onChargerDetailsCloseClick} />
                    )}
                />
                <Route
                    path={`${match.path}/charge-point`}
                    component={() => (
                        <ChargePointDetails
                            onChargerDetailsCloseClick={onChargerDetailsCloseClick}
                        />
                    )}
                />
                <Route
                    path={`${match.path}/status-log`}
                    component={() => (
                        <StatusLog onChargerDetailsCloseClick={onChargerDetailsCloseClick} />
                    )}
                />
                <Route
                    path={`${match.path}/event-log`}
                    component={() => (
                        <EventLog onChargerDetailsCloseClick={onChargerDetailsCloseClick} />
                    )}
                />
            </Switch>
        </Second>
    </Content>
);
