import React, { useEffect, useState } from 'react';
import {
    Heading,
    Level,
    Group,
    Button,
    Size,
    Variant,
    Composite,
} from '@defa/defa-component-library';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import i18n from '../../i18n';

import { Rfid } from '../../models/user';
import { GET_CHARGE_SYSTEM } from './keys.queries';
import { KeyList } from '../../fractions/key-list';
import { AddKeysModal } from './add-keys';
import { Group as GroupModel } from '../../models/group';
import { ChargeSystemGroupsAndConnectors } from '../../models/charge-system';
import { getSortedPrivateGroupsAndConnectors } from '../../utils/sorted-private-groups-and-connectors';

interface ChargeSystemData {
    chargeSystem: {
        id: string;
        groups: GroupModel[];
        rfidKeys: Rfid[];
    };
}

export const MoreButton = (onClick: (rfidKey?: Rfid, e?: MouseEvent) => void) => (data: Rfid) => {
    const { id, name } = data;
    return (
        <Button
            size={Size.TINY}
            fillParent={false}
            variant={Variant.TERTIARY}
            icon="more"
            key={`${encodeURIComponent(name || id)}-more`}
            onClick={(e) => onClick(data, e)}
        />
    );
};

export function Keys({ onModalCloseClick }: { onModalCloseClick: () => void }) {
    const { id } = useParams<{ id: string }>();

    const [initialRfids, setInitialRfids] = useState<Rfid[]>([]);
    const [privateDataset, setPrivateDataset] = useState<ChargeSystemGroupsAndConnectors>({
        groups: [],
        connectors: [],
    });

    const { data, refetch } = useQuery<ChargeSystemData>(GET_CHARGE_SYSTEM, {
        variables: { id },
    });

    const { chargeSystem } = data || {};
    const { rfidKeys } = chargeSystem || {};

    const [showAddKeysModal, setShowAddKeysModal] = useState(false);

    useEffect(() => {
        const { groups } = chargeSystem || {};
        if (groups) {
            setPrivateDataset(getSortedPrivateGroupsAndConnectors(groups));
        }
    }, [chargeSystem]);

    useEffect(() => {
        if (rfidKeys && rfidKeys.length > 0) {
            setInitialRfids(rfidKeys);
        }
    }, [rfidKeys]);

    return (
        <>
            <Group>
                <Composite>
                    <Heading level={Level.h1}>{i18n.t('ChargeSystemSettings.Keys')}</Heading>
                    <Composite>
                        <Button
                            name="add-button"
                            size={Size.TINY}
                            text={i18n.t('ChargeSystemSettings.AddKeys')}
                            variant={Variant.TERTIARY}
                            icon="add"
                            iconUseMargin
                            fillParent={false}
                            onClick={() => setShowAddKeysModal(true)}
                        />
                        <Button
                            name="close-button"
                            size={Size.TINY}
                            fillParent={false}
                            variant={Variant.SECONDARY}
                            icon="close"
                            onClick={onModalCloseClick}
                        />
                    </Composite>
                </Composite>
            </Group>
            <KeyList rfids={initialRfids} dataset={privateDataset} refetchKeys={refetch} />
            {showAddKeysModal && (
                <AddKeysModal
                    onClose={() => setShowAddKeysModal(false)}
                    onSubmit={() => {
                        refetch();
                        setShowAddKeysModal(false);
                    }}
                    dataset={privateDataset}
                />
            )}
        </>
    );
}
