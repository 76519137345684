import React from 'react';
import { useQuery } from '@apollo/client';

import { Route, RouteComponentProps, Switch, useParams } from 'react-router-dom';
import { Second, Content } from './group-settings.styles';

import { GroupSettingsSideMenu } from '../../fractions/group-settings-side-menu';
import { About } from './about';
import { Private } from './private';
import { Public } from './public';
import { GROUP } from './about.queries';

interface GroupSettingsProps extends RouteComponentProps<{}>, React.Props<{}> {
    onModalCloseClick: () => void;
}

export const GroupSettings = ({ match, onModalCloseClick }: GroupSettingsProps) => {
    const { id: chargeSystemId, groupId: id } = useParams<{ id: string; groupId: string }>();
    const { data: chargeSystemData = { chargeSystem: {} } } = useQuery(GROUP, {
        variables: { chargeSystemId, id },
    });
    const { group } = chargeSystemData?.chargeSystem || {};
    const defaultGroup = React.useMemo(() => ({}), []);
    const { name: initialName } = group || defaultGroup;

    console.log({ initialName });

    return (
        <Content>
            <GroupSettingsSideMenu />
            <Second>
                <Switch>
                    <Route
                        path={`${match.path}/about`}
                        component={() => <About onModalCloseClick={onModalCloseClick} />}
                    />
                    <Route
                        path={`${match.path}/private`}
                        component={() => <Private onModalCloseClick={onModalCloseClick} />}
                    />
                    <Route
                        path={`${match.path}/public`}
                        component={() => <Public onModalCloseClick={onModalCloseClick} />}
                    />
                </Switch>
            </Second>
        </Content>
    );
};
